<div class="close"
     *ngIf="image || showCamera">
    <mat-icon (click)="closeCamera();"
              [svgIcon]="'cross'"></mat-icon>
    <span *ngIf="displaySkip"
          (click)="updateSkip();">{{skipText | translate}}</span>
</div>
<div class="camera-wrapper"
     [ngClass]="{'show-cam':showCamera || image, 'and': platform.ANDROID, 'ios': platform.IOS}">
    <div class="camera-container"
         [hidden]="!showCamera">
        <video #videoElement
               autoplay
               [ngClass]="{'ios' : platform.IOS}"
               playsinline>
        </video>
    </div>
    <div class="shutter-container"
         *ngIf="showCamera && !processing">
        <mat-icon (click)="isRecording?stopRecording(): startRecording()"
                  [svgIcon]="isRecording? 'stop': 'shutter'"></mat-icon>
        <span>{{(isRecording ? 'recording': 'record') | translate}}</span>
    </div>
</div>