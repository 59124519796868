export const CasesTableConfig = {
  columns: [
    {
      id: 'caseGuid',
      width: '0px',
      style: 'display: none',
      header: {
        text: 'caseGuid',
        tooltip: '',
        style: 'display: none; max-width: 0'
      },
      cellStyle: 'display: none; max-width: 0',
      selector: {
        field: 'CaseGuid',
      },
      widget: {
        type: 'text'
      }
    },
    {
      id: 'CaseNumber',
      sortable: true,
      header: {
        text: 'case_id',
        tooltip: '',
      },
      selector: {
        field: 'CaseNumber',
      },
      widget: {
        type: 'text',
        /*config: {
            style: 'color: #3B82F6; cursor: pointer;',
          }*/
      }
    },
    {
      id: 'CreatedDate',
      sortable: true,
      selector: {
        field: 'CreatedDate',
      },
      header: {
        text: 'creation_date',
        tooltip: '',
      },
      widget: {
        type: 'date',
      },
    },
    {
      id: 'CreatedByUserName',
      sortable: true,
      selector: {
        field: 'CreatedByUserName',
      },
      header: {
        text: 'created_by',
        tooltip: '',
      },
      widget: {
        type: 'text',
      },
    },
    {
      id: 'OrgName',
      sortable: true,
      selector: {
        field: 'OrgName',
      },
      header: {
        text: 'organization',
        tooltip: '',
      },
      widget: {
        type: 'text',
      },
    },
    {
      id: 'WorkProviderName',
      sortable: true,
      selector: {
        field: 'WorkProviderName',
      },
      header: {
        text: 'work_provider',
        tooltip: '',
      },
      widget: {
        type: 'text',
      },
    },
    {
      id: 'VehicleMake',
      sortable: true,
      selector: {
        field: 'VehicleMake',
      },
      header: {
        text: 'vehicle_make',
        tooltip: '',
      },
      widget: {
        type: 'text',
      },
    },
    {
      id: 'VehicleModel',
      sortable: true,
      selector: {
        field: 'VehicleModel',
      },
      header: {
        text: 'vehicle_model',
        tooltip: '',
      },
      widget: {
        type: 'text',
      },
    },
    {
      id: 'EstimateCost',
      sortable: true,
      selector: {
        field: 'EstimateCost',
      },
      header: {
        text: 'est_cost',
        tooltip: '',
        style: 'text-align: right;'
      },
      cellStyle: 'justify-content: end;',
      widget: {
        type: 'currency'
      },
    },
    {
      id: 'Status',
      sortable: true,
      width: '105px',
      style: 'border-right: 0',
      selector: {
        field: 'IntegrationStatus',
      },
      header: {
        text: 'status',
        tooltip: '',
      },
      cellStyle: 'border-right: 0',
      widget: {
        type: 'status'
      }
    },
    {
      id: 'delete',
      width: '50px',
      header: {
        text: '',
        tooltip: '',
        style: 'display: none; max-width: 0'
      },
      widget: {
        type: 'status-check-icon',
        config: {
          svgIcon: 'delete',
          style: 'fill: var(--xa-red) !important'
        }
      }
    }
  ]
};

export const ExternalCasesTableConfig = {
  columns: [
    {
      id: 'caseGuid',
      width: '0px',
      style: 'display: none',
      header: {
        text: 'caseGuid',
        tooltip: '',
        style: 'display: none; max-width: 0'
      },
      cellStyle: 'display: none; max-width: 0',
      selector: {
        field: 'CaseGuid',
      },
      widget: {
        type: 'text'
      }
    },
    {
      id: 'ExternalId',
      sortable: true,
      header: {
        text: 'job_number',
        tooltip: '',
      },
      selector: {
        field: 'ExternalId',
      },
      widget: {
        type: 'text',
        /*config: {
            style: 'color: #3B82F6; cursor: pointer;',
          }*/
      }
    },
    {
      id: 'CreatedDate',
      sortable: true,
      selector: {
        field: 'CreatedDate',
      },
      header: {
        text: 'creation_date',
        tooltip: '',
      },
      widget: {
        type: 'date',
      },
    },
    {
      id: 'CreatedByUserName',
      sortable: true,
      selector: {
        field: 'CreatedByUserName',
      },
      header: {
        text: 'created_by',
        tooltip: '',
      },
      widget: {
        type: 'text',
      },
    },
    {
      id: 'OrgName',
      sortable: true,
      selector: {
        field: 'OrgName',
      },
      header: {
        text: 'organization',
        tooltip: '',
      },
      widget: {
        type: 'text',
      },
    },
    {
      id: 'WorkProviderName',
      sortable: true,
      selector: {
        field: 'WorkProviderName',
      },
      header: {
        text: 'work_provider',
        tooltip: '',
      },
      widget: {
        type: 'text',
      },
    },
    {
      id: 'VehicleMake',
      sortable: true,
      selector: {
        field: 'VehicleMake',
      },
      header: {
        text: 'vehicle_make',
        tooltip: '',
      },
      widget: {
        type: 'text',
      },
    },
    {
      id: 'VehicleModel',
      sortable: true,
      selector: {
        field: 'VehicleModel',
      },
      header: {
        text: 'vehicle_model',
        tooltip: '',
      },
      widget: {
        type: 'text',
      },
    },
    {
      id: 'EstimateCost',
      sortable: true,
      selector: {
        field: 'EstimateCost',
      },
      header: {
        text: 'est_cost',
        tooltip: '',
        style: 'text-align: right;'
      },
      cellStyle: 'justify-content: end;',
      widget: {
        type: 'currency'
      },
    },
    {
      id: 'Status',
      sortable: true,
      width: '105px',
      style: 'border-right: 0',
      selector: {
        field: 'IntegrationStatus',
      },
      header: {
        text: 'status',
        tooltip: '',
      },
      cellStyle: 'border-right: 0',
      widget: {
        type: 'status'
      }
    },
    {
      id: 'delete',
      width: '50px',
      header: {
        text: '',
        tooltip: '',
        style: 'display: none; max-width: 0'
      },
      widget: {
        type: 'status-check-icon',
        config: {
          svgIcon: 'delete',
          style: 'fill: var(--xa-red) !important'
        }
      }
    }
  ]
};