import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, map, of } from 'rxjs';
import { User } from 'src/app/model/user-account.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  /**
   * Creates an instance of UserService.
   * @constructor
   * @param {HttpClient} http
   */
  constructor( private http: HttpClient){}

  private userProfileSource: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  userProfile$ = this.userProfileSource.asObservable();
  private readonly destroying$ = new Subject<void>();
  notificationSource$ = new BehaviorSubject([]);
  notificationLoading$ = new BehaviorSubject(false);


  /**
   * setLocalUserAccount
   * @param user
   */
  setLocalUserAccount(user: User): void {
    this.userProfileSource.next(user);
  }

  /**
   * getNotifications
   * @date 7/10/2023 - 12:44:43 PM
   */
  getNotifications(top, skip, orderBy, domainIds: any[]): void {
    this.notificationLoading$.next(true);
    const url = `${environment.communicationApi}/odata/notification?$count=true&$top=${top}&$skip=${skip}&$orderBy=${orderBy}&$filter=DomainId in [${domainIds.join(',')}]`;
    this.http.get(url).subscribe({
      next: (notificationData: any[]) => { this.notificationLoading$.next(false); this.notificationSource$.next(notificationData) }
    });
  }

  /**
   * getNotifications Unread count
   * @date 7/10/2023 - 12:44:43 PM
   */
  getNotificationsUnreadCount(domainIds: any[]): Observable<any> {
    const url = `${environment.communicationApi}/odata/notification?$count=true&$top=0&$filter=IsRead eq false and DomainId in [${domainIds.join(',')}]`;
    return this.http.get(url).pipe(map((el:any)=> {
      return el ? el['@odata.count'] : 0
    }))
  }

  /**
   * markAsReadNotification
   * @param {*} notificationGuid
   */
  markAsReadNotification(notificationGuid) : Observable<any>{
    const url = `${environment.communicationApi}/api/notification/${notificationGuid}/mark-as-read`;
    return this.http.put(url, {});
  }

  /**
   * markAllAsReadNotification
   * @returns {Observable<any>}
   */
  markAllAsReadNotification(domainId: any) : Observable<any>{
    const url = `${environment.communicationApi}/api/notification/mark-all-read`;
    return this.http.put(url, { domainId: domainId });
  }

  /**
   * ngOnDestroy
   */
  ngOnDestroy(): void {
    this.destroying$.next(undefined);
    this.destroying$.complete();
    this.notificationSource$.complete();
  }
}
