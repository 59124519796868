import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetsModule } from 'src/app/widgets/widgets.module';
import { DynamicFormBase } from './dynamic-form-base';
import { FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { XaAnimations } from '../animations/public-api';
import { DynamicFormService } from './dynamic-form.service';
import { CommonService } from 'src/app/services/common/common.service';
import * as expEval from 'expression-eval';
import { pairwise, startWith } from 'rxjs';
import { serviceURLMapper } from '../../constants/app.constants';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'dynamic-form',
  standalone: true,
  imports: [ CommonModule, WidgetsModule, ReactiveFormsModule, TranslateModule, MatIconModule ],
  templateUrl: './dynamic-form.component.html',
  styleUrls: [ './dynamic-form.component.scss' ],
  animations: [ XaAnimations ]
})
export class DynamicFormComponent implements OnChanges, OnInit {
  @Input() formControls: DynamicFormBase<string>[] | null = [];
  @Input() form!: FormGroup;
  @Input() entityId:any;
  @Input() isSectionContainer:boolean;
  @Output() formSaved: EventEmitter<any> = new EventEmitter();
  
  dropdownUrlControlMapper:any = {};
  parentControlMapper:any = {};
  dropdownOptionMapper:any = {};
  locationMapper:any = {};
  pendingDropdownPrefillValue:any = {};

  nonValidationControlType: string[] = [ 'title', 'description', 'section', 'button' ]

  /**
   * constructor
   * @param translateService 
   */
  constructor(private translateService: TranslateService, private dynamicFormService: DynamicFormService
    , private commonService:CommonService
  ) {

  }

  /**ng on init
   * 
   */
  ngOnInit():void{
    if(this.isSectionContainer && this.form && this.formControls){
      this.dynamicFormService.addControlsInFormGroup(this.formControls, this.form);
    }
    if(this.form){
      this.form.valueChanges.subscribe(()=>{
        this.formControls.forEach((control) => {
          this.checkRequired(control);
        })
      });
    }
  }

  /**
   * on simple change
   * @param changes 
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['formControls']?.currentValue) {
      this.formControls.map((dropdown: any) => {
        if (dropdown?.controlType === 'dropdown' || dropdown?.controlType === 'multiselect-dropdown') {
          dropdown['dropdownConfig'] = {
            ...dropdown['dropdownConfig'],
            placeHolder: dropdown.placeHolder || dropdown.placeholder,
            label: dropdown.label,
            showRequiredLabel: dropdown.required,
            disabled: dropdown.disabled,
            multiple: dropdown.controlType === 'multiselect-dropdown',
            labelKey: dropdown.optionKeys.label,
            valueKey: dropdown.optionKeys.value,
            optionsByUrl: dropdown.optionsByUrl,
            key: dropdown.key,
            showAllOption: dropdown.showAllOption
          }
        }
      });
      this.dropdownControls();
      this.textboxWithPrefill();
    }
  }

  /**
   * check visibility condition
   * @control
   */
  checkVisibility(control: any): boolean {
    if (control.visible) {
      return true;
    } 
    
    if(control.visibleIf === 'false'){
      return false;
    }
    const visibleCondition = control.conditions?.find(x=>x.type ==='visibleIf');
    return this.evaluateExpression(visibleCondition);
  }

  /**
  * check visibility condition
  * @control
  */
  evaluateExpression(condition:any): boolean {
    if(!condition){
      return false;
    }
    const { controlKey, operator, conditionalValue } = condition;
    if(controlKey && operator){
      const expressionText = `a ${operator} b`;
      const expression = expEval.parse(expressionText);
      const operatorValues = {};
      const field = this.formControls.find(x=>x.label === controlKey || x.key === controlKey);
      operatorValues['a'] = this.form.controls[field?.key]?.value || '';
      operatorValues['b'] = conditionalValue;
      const result = expEval.eval(expression, operatorValues);
      return result;
    }
    return false;
  }

  /**
   * check disable condition
   * @control
   */
  checkDisabled(control: any): boolean {
    if (control.disabled) {
      return true;
    } else {
      const conditions = control.conditions?.find(x=>x.type ==='disabledIf');
      const result = this.evaluateExpression(conditions);
      if(control.controlType === 'dropdown' || control.controlType === 'multiselect-dropdown'){
        const index = this.formControls.findIndex(x=>x === control);
        if(this.formControls[index]){
          this.formControls[index].dropdownConfig.disabled = result;
        }
      }
      return result;
    }
  }

  /**
   * checkRequiredVal
   */
  checkRequiredValue(control:any):boolean{
    if (control.required) {
      return true;
    } else {
      return control.requiredIf;
    }
  }

  /**
   * check required
   */
  checkRequired(control:any):boolean{
    if (control.required) {
      return true;
    } else {
      const conditions = control.conditions?.find(x=>x.type ==='requiredIf');
      const result = this.evaluateExpression(conditions);
      const index = this.formControls.findIndex(x=>x === control);
      this.formControls[index].requiredIf = result;
      if(control.controlType === 'dropdown' || control.controlType === 'multiselect-dropdown'){
        if(this.formControls[index]){
          this.formControls[index].dropdownConfig.showRequiredLabel = result;
        }
      }
      if(result){
        this.form.get(control.key)?.addValidators([ Validators.required ]);
        this.form.get(control.key)?.updateValueAndValidity({ onlySelf: true, emitEvent: false });
      }else{
        this.form.get(control.key)?.removeValidators([ Validators.required ]);
        this.form.get(control.key)?.updateValueAndValidity({ onlySelf: true, emitEvent: false });
      }
      
      return result;
    }
  }

  /**
   * url dependency parser
   */
  urlParser(key:string):void{
    const conditionControls = this.dropdownUrlControlMapper[key];
    if(!conditionControls){
      return;
    }
    
    const index = this.formControls.findIndex(x=>x.key === key);
    let url = this.formControls[index]?.optionsByUrl?.placeHolderUrl;
    conditionControls.forEach((ctrl)=>{
      const ckey = ctrl.replace('{', '').replace('}', '');
      url = url.replace(`{${ckey}}`, this.form.get(ckey)?.value);
    });
    this.formControls[index].optionsByUrl.url = url;
    if(!url.includes('{')){
      this.formControls[index].optionsByUrl.preventExecutionByDefault = false;
      this.formControls[index] = { ...this.formControls[index] };
    }
  } 

  /**
   * trip dropdown value
   */
  trimValue(val:any):any{
    return val?.replace(/[()]/g, '').trim();
  }

  /**
   * prefill dropdown
   */
  prefillDropdown(prefillValue:any, dropdownControl:DynamicFormBase<any>):void{
    const { label, value }= dropdownControl.optionKeys;
    const selectedControlOption = this.dropdownOptionMapper[dropdownControl.key]?.find(x=>x[label] === this.trimValue(prefillValue) || x[value] === this.trimValue(prefillValue));
    if(selectedControlOption){
      this.form.get(dropdownControl.key)?.setValue(selectedControlOption[value]);
    }else{
      this.pendingDropdownPrefillValue[dropdownControl.key] = this.trimValue(prefillValue);
    }
  }

  /**
   * prefill api data
   */
  prefillAPIData(control:DynamicFormBase<any>, prefillData:any):void{
    const prefillControls = control.prefillControls;
    if(prefillControls && prefillControls.length > 0){
      prefillControls.forEach((x)=>{
        const { controlName, prefillAttribute } = x;
        const currentControl = this.formControls.find(y=>y.key === controlName);
        if(currentControl){
          if(currentControl.controlType === 'dropdown'){
            this.prefillDropdown(prefillData[prefillAttribute], currentControl);
          }else{
            this.form.get(controlName)?.setValue(prefillData[prefillAttribute]);
          }
        }
      });
    }
  }

  /**
   * autofill places
   */
  placesWithPrefill():void{
    const prefillPlaces = this.formControls.filter(x=>x.controlType === 'places' && x.prefillControls?.length > 0);
    for(const places of prefillPlaces){
      const prefilKey = places.key;
      this.form.get(prefilKey)?.valueChanges.subscribe((opt)=>{
        const placeData = this.locationMapper[prefilKey];
        if(placeData){
          this.prefillAPIData(places, placeData);
        }
      });
    }
  }

  /**
   * autofill prefill textbox
   */
  textboxWithPrefill():void{
    const textBoxList = this.formControls.filter(x=>x.controlType === 'textbox' && x.optionsByUrl !== null && x.optionsByUrl.url);
    textBoxList.forEach((txt)=>{
      const { service, url } = txt.optionsByUrl;
      this.form.get(txt.key)?.valueChanges
        .pipe(startWith(null), pairwise())
        .subscribe(([ prev, next ]: [any, any]) => {
          if (prev !== next && next) {
            if (next.length === txt.prefillMinLength) {
              let apiURL = url;
              if(url.includes('{this}')){
                apiURL = url.replace('{this}', next);
              }

              this.commonService.showLoading();
              this.dynamicFormService.callDynamicServiceUrl(apiURL, service, '', 'get').subscribe({
                next: (resp)=>{
                  this.commonService.hideLoading();
                  if(resp){
                    let responseData:any;
                    let apiData = {};
                    if(resp.data){
                      responseData = resp.data; 
                    }else if(resp.value){
                      responseData = resp.data; 
                    }else{
                      responseData = resp.data;
                    }
                    if(responseData?.length){
                      apiData = responseData[0];
                    }else{
                      apiData = responseData;
                    }
                    if(apiData){
                      this.prefillAPIData(txt, apiData);
                    }
                  }
                },
                error: ()=>{
                  this.commonService.hideLoading();
                }
              });
            }
          }
        });
    });
  }
   


  /**
   * parse  dropdown controls
   */
  dropdownControls():void{
    const dropdowns = this.formControls.filter(x=>x.controlType === 'dropdown' && x.optionsByUrl !== null && x.optionsByUrl?.url
      .includes('{'));
    const keyList = dropdowns.map(x=>x.key);
    const prefillDropdowns = this.formControls.filter(x=>x.controlType === 'dropdown' && x.prefillControls?.length > 0 && !keyList.includes(x.key));

    for(const dropdownCtrl of prefillDropdowns){
      const prefilKey = dropdownCtrl.key;
      this.form.get(prefilKey)?.valueChanges.subscribe((opt)=>{
        const options = this.dropdownOptionMapper[prefilKey];
        const selectedOpt = options?.find(x=>x.id === opt);
        if(selectedOpt){
          this.prefillAPIData(dropdownCtrl, selectedOpt);
        }
      });
    }

    dropdowns.forEach((x)=>{
      const { url } = x.optionsByUrl;
      const conditions = url.match(/{(.*?)}/g);
      conditions.forEach((ctrl)=>{
        const key = ctrl.replace('{', '').replace('}', '');
        if(!this.parentControlMapper[key]){
          this.parentControlMapper[key] = [];
        }
        this.parentControlMapper[key].push(x.key);
      });
      this.dropdownUrlControlMapper[x.key] = conditions;
    });

    const parentObjKeys = Object.keys(this.parentControlMapper);
    for(const parentKey of parentObjKeys){
      this.form.get(parentKey)?.valueChanges.subscribe((opt)=>{
        const parentCtrl = this.formControls.find(x=>x.key === parentKey);
        const options = this.dropdownOptionMapper[parentKey];
        const selectedOpt = options?.find(x=>x.id === opt);
        if(selectedOpt){
          this.prefillAPIData(parentCtrl, selectedOpt);

          const childKeys = this.parentControlMapper[parentKey];
          childKeys.forEach((ck)=>{
            this.urlParser(ck);
          });
          
        }
        
        
      });
    }
    
  }


  /**
   *
   * @param control
   * @returns
   */
  getErrorMessage(control: any): string {
    const field = this.form.get(control?.key);
    if (field.dirty || field.touched) {
      if (control.validators) { 
        for (const key of control.validators) {
          if (field.hasError(key.type.toLowerCase())){
            return key.message;
          } else if(key.type === 'custom' && field.hasError(key.customType)) {
            return key.message
          }        
        }
      }
      const controlValidator = control.validators?.find(x => x.type === 'required');
      if(!controlValidator && field.hasError('required')) {
        return control.label ? `${this.translateService.instant(control.label)} is required` : 'This field is required';
      }
    }
    return '';
  }

  /**
   * on option update
   * @param option 
   */
  onOptionsUpdated(updatedOption: any): void {
    const key = updatedOption.item;
    this.dropdownOptionMapper[key] = updatedOption.values;
    if(this.pendingDropdownPrefillValue[key]){
      const currentControl = this.formControls.find(y=>y.key === key);
      const { label, value }= currentControl.optionKeys;
      const selectedControlOption = this.dropdownOptionMapper[key]?.find(x=>x[label] === this.pendingDropdownPrefillValue[key] || x[value] === this.pendingDropdownPrefillValue[key]);
      if(selectedControlOption){
        this.form.get(key)?.setValue(selectedControlOption[value]);
        delete this.pendingDropdownPrefillValue[key];
      }
    }
    this.dynamicFormService.broadcastOption(updatedOption);
  }

  /**
   * on address changed
   * @param address 
   */
  onOptionsAddress(address: any): void {
    this.dynamicFormService.broadcastAddress(address);
    this.locationMapper[address.name] = address.places;
  }

  /**
   * handle button action
   */
  handleButtonClick(control:DynamicFormBase<any>):void{
    const { buttonAction } = control;
    const { linkUrl, actionType, alertMessage, formSubmitService, formSubmitUrl, formSubmitMethod } = buttonAction;
    switch(actionType){
    case 'alert':
      if(alertMessage){
        this.commonService.openAlertDialog('info', alertMessage, true);
      }
      break;
    case 'link':
      window.open(linkUrl, '_blank');
      break;
    case 'submit':
      if(formSubmitService && formSubmitUrl){
        const data = this.form.value;
        this.commonService.showLoading();
        this.dynamicFormService.callDynamicServiceUrl(formSubmitUrl.replace('{entityId}', this.entityId || ''), serviceURLMapper[formSubmitService], data, formSubmitMethod).subscribe({
          next: (res)=>{
            this.formSaved.emit({ formData: data, apiResponse: res });
          }, error: ()=>{
            this.commonService.hideLoading();
          }
        });
      }
      break
    case 'custom': 
      if (formSubmitMethod){
        this.formSaved.emit({ action: formSubmitMethod, control });
      }
      break;
    }
  }

  /**
   * check if the selected control need validation
   * @returns 
   */
  showValidationContainer(controlType): boolean {
    return !this.nonValidationControlType.some(x => x === controlType)
  }


}
