<div class="container"
     *ngIf="!dataLoading">
    <xa-photo-upload-mode-selector (mode)="handleMode($event);"
                                   [hidden]="hideSelector"
                                   *ngIf="xaImages.displaySelector"
                                   [uploadModeSelector]="xaImages.uploadSelector">
    </xa-photo-upload-mode-selector>
    <xa-file-review-section #reviewSection
                            *ngIf="xaImages.displayReviewSection"
                            (fileUpload)="handleUpload($event)"
                            (updateMassUpload)="handleMassUpload($event)"
                            (handleBackAction)="handleBack();"
                            [xaReviewOptions]="xaImages.fileReviewSection"
                            (triggerHeaderAction)="handleIconActions($event);"
                            (iconClick)="handleAction($event);">
        <xa-button (click)="handleFooterActions(step);"
                   [hidden]="(step.isUploadAction && xaImages.fileReviewSection?.files?.length > 0) || (!step.isUploadAction && xaImages.fileReviewSection?.files?.length === 0)"
                   *ngFor="let step of xaImages?.fileReviewSection?.footerActions">
            <mat-icon [svgIcon]="step.icon"
                      aria-hidden="false"
                      *ngIf="step.icon"
                      aria-label="unchecked icon"></mat-icon>
            <span>{{step.label | translate}}</span>
        </xa-button></xa-file-review-section>
</div>
<div *ngIf="dataLoading"
     class="spinner-loader">
    <mat-spinner [strokeWidth]="5"
                 [diameter]="50"
                 class="inner-loading"></mat-spinner>
</div>