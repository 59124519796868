import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { catchError, forkJoin, of } from 'rxjs';
import { MediaCollectionService } from 'src/app/services/media-collection/media-collection.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';

@Component({
  selector: 'document-intelligence-dialog',
  templateUrl: './document-intelligence-dialog.component.html',
  styleUrls: [ './document-intelligence-dialog.component.scss' ]
})
export class DocumentIntelligenceDialogComponent implements OnInit {

  titleContainsHtml: boolean = false;
  messageContainsHtml: boolean = false;
  htmlTags = [ '<span>', '<b>', '<div>', '<h1>', '<h2>', '<h3>', '<h4>', '<h5>', '<a>', '<p>', '<br />', '<mat-icon>', '</a>', '<em>' ];
  isDestroyed:boolean = false;

  /**
   * constructor
   * @param dialogRef 
   * @param data 
   */
  constructor(public dialogRef: MatDialogRef<DocumentIntelligenceDialogComponent>,
    private monitorService: MonitorService,
    private mediaCollectionService: MediaCollectionService,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    for (const key in this.htmlTags) {
      if (!this.messageContainsHtml && data.message.indexOf(this.htmlTags[key]) !== -1) {
        this.messageContainsHtml = true;
      }
    }
  }

  /**
   * get ocr details
   */
  getOCRDetails(timeout:any):void{
    if(this.isDestroyed){
      return;
    }
    const apiForkObj:any = {};
    const fieldToCheck = [];
    if(this.data.policeReportTemplateId){
      apiForkObj.policeReportOCR = this.mediaCollectionService.ocrDetails(this.data.objectId, this.data.domainId, this.data.policeReportTemplateId);
    }
    if(this.data.drivingLicenseTemplateId){
      apiForkObj.driverReportOCR = this.mediaCollectionService.ocrDetails(this.data.objectId, this.data.domainId, this.data.drivingLicenseTemplateId);
    }
    if(this.data.registrationCardTemplateId){
      apiForkObj.registrationReportOCR = this.mediaCollectionService.ocrDetails(this.data.objectId, this.data.domainId, this.data.registrationCardTemplateId);
    }
    const keys = Object.keys(apiForkObj);
    if(keys.length == 0){
      this.close();
      clearTimeout(timeout);
      return;
    }
    const multiJoin = forkJoin(apiForkObj).pipe(
      catchError(error => of(error))
    )
    multiJoin.subscribe({
      next: (resMap:any)=>{
        let apiCheckComplete = true;
        if(resMap){
          const keys = Object.keys(resMap);
          for(const key of keys){
            if(key === 'policeReportOCR'){
              if(!resMap[key] || !resMap[key]?.data || !resMap[key]?.data?.entities){
                apiCheckComplete = false;
                break;
              }
            }

            if(key === 'driverReportOCR'){
              if(!resMap[key] || !resMap[key]?.data || !resMap[key]?.data?.dlDetails){
                apiCheckComplete = false;
                break;
              }
            }

            if(key === 'registrationReportOCR'){
              if(!resMap[key] || !resMap[key]?.data || !resMap[key]?.data?.rcDetails || !resMap[key]?.data?.rcDetails?.ownerDetail || !resMap[key]?.data?.rcDetails?.vehicleDetail){
                apiCheckComplete = false;
                break;
              }
            }
            
          }
          if(apiCheckComplete){
            this.close();
            clearTimeout(timeout);
          }else{
            setTimeout(()=>{
              this.getOCRDetails(timeout);
            }, 3000);
          }
        }else{
          setTimeout(()=>{
            this.getOCRDetails(timeout);
          }, 3000);
        }
        
      }
    });
  }

  /**
   * ng on init
   */
  ngOnInit():void{
    const timeout = setTimeout(() => { 
      this.isDestroyed= true;
      this.close();

    }, 30000); 
    this.getOCRDetails(timeout);
  }

  /**
   * close dialog
   */
  close(): void {
    this.dialogRef.close(true);
    this.monitorService.logEvent('close', [ 'AlertDialogComponent', 'addenda-quote' ]);
  }

}
