import { Component, ElementRef, EventEmitter, inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CameraService } from 'src/app/services/camera/camera.service';
import { CameraOverlayComponent } from '../camera-overlay/camera-overlay.component';
import { CommonService } from 'src/app/services/common/common.service';
import { ConfigurationService } from 'src/app/config/configuration/service/configuration.service';
import {
  Platform
} from '@angular/cdk/platform';

@Component({
  selector: 'xa-camera',
  templateUrl: './camera.component.html',
  styleUrls: [ './camera.component.scss' ]
})
export class CameraComponent implements OnInit, OnDestroy {
  @ViewChild('videoElement') videoElement: ElementRef<HTMLVideoElement>;
  @ViewChild('overlayElement') overlayElement: CameraOverlayComponent;
  @Input() autoStart:boolean = false;
  @Input() outlineUrl:string = '';
  @Input() procedureStep:string = '';
  @Input() displaySkip:boolean = false;
  @Input() currentStep:string='';
  @Input() skipText:string = '';
  @Input() forceOrientation:boolean = false;
  @Input() showTiltText:boolean = false;
  @ViewChild('div') div:HTMLDivElement;
  @Output() imageUploaded = new EventEmitter<any>();
  @Output() skipped = new EventEmitter<any>();
  public image:string;
  public processing:boolean = false;
  public showCamera:boolean = false;
  private location:any;
  platform = inject(Platform);


  /**
   * constructor
   * @param cameraService 
   */
  constructor(private cameraService: CameraService, private commonService:CommonService,
    private configurationService:ConfigurationService
  ) {}


  /**
   * ng oninit
   */
  ngOnInit(): void {
    if(this.autoStart){
      this.startCamera();
    }
  }

  /**
   * get orientation
   */
  get getOrientationLock():boolean{
    //|| this.configurationService.getCameraFlags()?.Camera_Orientation?.toLowerCase() === 'landscape'
    return this.forceOrientation ;
  }

  /**
   * start camera
   * 
   */
  async startCamera():Promise<any>{
  
    this.initializeGeoLocation();
    await this.cameraService.initCamera(this.videoElement.nativeElement, this.platform.ANDROID || this.platform.IOS).catch((e) => {
      this.commonService.showToast(0, `Error initializing camera: ${e.message}`);
      this.closeCamera();
    });
    if(window.innerHeight > window.innerWidth){
      this.showTiltText = true;
    }
    this.showCamera = true;
  }

  /**
   * close camera
   */
  closeCamera():void{
    this.image = null;
    this.showCamera = false;
    this.cameraService.stopCamera();
  }

  /**
   * Capture a frame from the video and overlay the vehicle outline on it.
   * Then convert it to a base64 encoded string and log it to the console.
   * Finally, read the image using OpenCV.js and delete the image once done.
   */
  captureImage():void {
    this.processing = true;
    setTimeout(()=>{
      const video = this.videoElement.nativeElement;
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext('2d');
      // Capture video frame    
      if(window.innerHeight > window.innerWidth){
        canvas.height = video.videoWidth;
        canvas.width = video.videoHeight;
        ctx.translate(canvas.width/2, canvas.height/2);
        ctx.rotate((270 * Math.PI) / 180);
        ctx.drawImage(video, -video.videoWidth/2, -video.videoHeight/2);

      }else{
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      }
  
      // Convert to image
      const image = canvas.toDataURL('image/jpeg', 1);
      this.image = image;
      this.cameraService.stopCamera();
      this.showCamera = false;
      this.commonService.hideLoading();
      this.processing = false;
    });
    
  }

  /**
   * initialize geolocation
   */
  initializeGeoLocation():void{
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.location = position.coords;
      });
    }
  }
  
  /**
   * emit upload
   */
  emitUpload():void{
    const file = this.cameraService.insertExifData(this.image, this.location, this.videoElement.nativeElement.videoHeight, this.videoElement.nativeElement.videoWidth);
    const fileObj = new File([ file ], 'capture_image.jpeg', { type: 'image/jpeg' });
    this.imageUploaded.emit({ target: { files: [ fileObj ] } });
    this.showCamera = false;
    this.image = null;
  }

  /***
   * update skip
   */
  updateSkip():void{
    this.skipped.emit();
  }

  /**
   * ng on destroy
   */
  ngOnDestroy(): void {
    this.cameraService.stopCamera();
  }
}
