<div class="dialog-container">
    <div class="header">
        <h3>{{ 'adjust_reserve' | translate }}</h3>
        <div class="close-icon"
             (click)="cancel();">
            <mat-icon [svgIcon]="'cross'"></mat-icon>
        </div>
    </div>
    <div mat-dialog-content
         class="adjustment-body">
        <div class="form-container">
            <div class="input-wrapper"
                 [formGroup]="formGroup">
                <xa-select formControlName="coverage"
                           class="coverage-select"
                           [options]="options"
                           [config]="config"></xa-select>
            </div>
            <div class="table-container"
                 *ngIf="tableData && tableData.length > 0">
                <div class="table-wrapper">
                    <xa-table #reserveTable
                              [config]="tableConfig"
                              [tableData]="tableData"
                              [cellClicked]="handleCellClick"
                              [i18n]="i18n"></xa-table>
                </div>
            </div>
        </div>
    </div>
    <div class="btn-container"
         *ngIf="tableData && tableData.length > 0">
        <ng-container *ngIf="!(data.isForTotalLoss)">
            <xa-button class="right"
                    (click)="saveReserve()">
                {{'save'|translate}}
            </xa-button>
            <xa-button class="right"
                    (click)="confirmReserve()">
                {{'confirm_reserve_and_approve'|translate}}
            </xa-button>
        </ng-container>
        <ng-container *ngIf="(data.isForTotalLoss)">
            <xa-button class="right"
                    (click)="confirmTotalLoss()">
                {{'confirm_reserve_and_total_loss'|translate}}
            </xa-button>
        </ng-container>
        <xa-button [type]="'outline'"
                   (click)="close();"
                   class="right">
            {{'cancel'|translate}}
        </xa-button>
    </div>
</div>