import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { EChartsOption, LegendComponentOption, TitleComponentOption, graphic } from 'echarts';
import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ApexDataLabels,
  ApexPlotOptions,
  ApexXAxis,
  ApexLegend,
  ApexFill,
  ApexStroke,
  ApexAnnotations,
  ApexAxisChartSeries,
  ApexMarkers,
  ApexNoData,
  ApexTooltip,
  ApexYAxis,
  ApexForecastDataPoints,
  ApexGrid,
  ApexStates,
  ApexTitleSubtitle,
  ApexTheme,
  ChartComponent
} from 'ng-apexcharts';
import { BehaviorSubject, Observable, Observer, finalize, of, switchMap, tap } from 'rxjs';
import { DashboardViewDetailsComponent } from 'src/app/modules/home/dashboard-view-details/dashboard-view-details.component';
import { CommonService } from 'src/app/services/common/common.service';



const colorGenerator = (colorArray: any[], strokeLength: number): any[] => {
  const colorsList = [];
  const length = colorArray.length;
  const partitions: number = parseInt(`${100 / length}`);
  colorsList.push([ 0, colorArray[0] ]);
  for (let i = 0; i < 100; (i = i + (strokeLength * 2))) {
    const currentColor = colorArray[parseInt(`${i / partitions}`)];
    if (i + strokeLength < 100) {
      colorsList.push([ (i + strokeLength) / 100, '#FFFF' ]);
      colorsList.push([ (i + strokeLength + strokeLength) / 100, currentColor ]);
    }
  }
  colorsList.push([ 1, colorArray[length - 1] ]);
  return colorsList;
}

export const titleStyles = {
  fontFamily: 'Inter, sans-serif',
  opacity: 1,
  fontWeight: 700,
  fontSize: '20px',
  color: '#111827'
}

export const subTitleStyles = {
  fontFamily: 'Inter, sans-serif',
  opacity: 1,
  fontWeight: 400,
  fontSize: '16px',
  color: '#B8C0CC'

}

export const generateExportFileNamesObj: any = (filename: string) => {
  return {
    csv: {
      filename
    },
    svg: {
      filename
    },
    png: {
      filename
    }
  }
}

export type XaGaugeLegends = {
  title: string,
  icon: string,
  value: string,
  active?: boolean
}

export type XaGaugeContainer = {
  height: string,
  width: string
}

export interface XACustomToolbarIcons {
  icon?: string;
  title?: string;
  index?: number;
  class?: string;
  click?(chart?: any, options?: any, e?: any): any;
}

export enum ChartPlugins {
  eCharts = 1,
  apex = 2
}

export interface XaGaugeChartOption extends EChartsOption {
  chart: {
    type: 'Gauge',
    plugin: ChartPlugins.eCharts,
    id?: string
  },
  observable?: Observable<any>;
  observer?: Observer<any>;
  colorList?: any[],
  wrapperStyle?: XaGaugeContainer,
  legends?: XaGaugeLegends[]
}

export interface XaEPieChartOption extends EChartsOption {
  chart: {
    type: 'Pie',
    plugin: ChartPlugins.eCharts,
    id?: string
  },
  title: TitleComponentOption,
  isDrillDown?: boolean,
  observable?: Observable<any>;
  observer?: Observer<any>;
  customButton?: CustomButton;
  onClick?: ($event: any) => void,
  onLegendClick?: ($event: any) => void;
  wrapperStyle?: XaGaugeContainer,
  drillDownEvent?: events,
  legend: LegendComponentOption
}

export type XaChartOptions = {
  series: ApexNonAxisChartSeries | ApexAxisChartSeries;
  chart: XADonutChart | XABarChart | XAStackedChart | XALineChart | XAPolarChart | XAPieChart | XARadialBarChart | XAAreaChart;
  responsive?: ApexResponsive[];
  labels?: string[];
  colors?: any[];
  dataLabels?: ApexDataLabels;
  stroke?: ApexStroke;
  plotOptions?: ApexPlotOptions;
  states?: ApexStates;
  legend?: ApexLegend;
  xaxis?: ApexXAxis;
  yaxis?: ApexYAxis | ApexYAxis[],
  fill?: ApexFill;
  tooltip?: ApexTooltip;
  forecastDataPoints?: ApexForecastDataPoints;
  grid?: ApexGrid;
  annotations?: ApexAnnotations;
  markers?: ApexMarkers;
  noData?: ApexNoData;
  title?: ApexTitleSubtitle;
  subtitle?: ApexTitleSubtitle;
  theme?: ApexTheme;
  autoUpdateSeries?: boolean;
  observable?: Observable<any>;
  observer?: Observer<any>;
};

export enum events {
  xAxis,
  legend,
  marker,
  dataSelection
}

export interface XADonutChart extends ApexChart {
  type: 'donut',
  plugin: ChartPlugins.apex,
  isDrillDown: boolean,
  drillDownEvent?: events
}

export interface XABarChart extends ApexChart {
  type: 'bar',
  isDrillDown: boolean,
  plugin: ChartPlugins.apex,
  drillDownEvent?: events
}

export interface XAStackedChart extends ApexChart {
  type: 'bar',
  plugin: ChartPlugins.apex,
  stacked: true,
  isDrillDown: boolean,
  drillDownEvent?: events
}

export interface XALineChart extends ApexChart {
  type: 'line',
  plugin: ChartPlugins.apex,
  isDrillDown: boolean,
  drillDownEvent?: events
}

export interface XAPolarChart extends ApexChart {
  type: 'polarArea',
  plugin: ChartPlugins.apex,
  isDrillDown: boolean,
  drillDownEvent?: events
}

export interface XAPieChart extends ApexChart {
  type: 'pie',
  plugin: ChartPlugins.apex,
  isDrillDown: boolean,
  drillDownEvent?: events
}

export interface XARadialBarChart extends ApexChart {
  type: 'radialBar',
  plugin: ChartPlugins.apex,
  isDrillDown: boolean,
  drillDownEvent?: events
}

export const defaultLocaleData = {
  'name': 'en',
  'options': {
    'toolbar': {
      'exportToSVG': '',
      'exportToPNG': '<div class="icon-wrapper"><span alt="" class="material-icons">download</span><span>Download Graph</span> </div>',
      'exportToCSV': '',
      //'exportToCSV': '<div class="icon-wrapper"><span alt="" class="material-icons"/>remove_red_eye</span><span>Show full report</span> </div>'
    }
  }
}

export const toolbarToolsWithViewDetails = {
  show: true,
  tools: {
    customIcons: [ {
      icon: '<button>View Details</button>',
      index: -2,
      title: 'View Details',
      class: 'view-details-button',
      click: function (chart, options): void {
        const selectionFired = new Event(`viewDetails-${options.config.chart.id}`);
        window.dispatchEvent(selectionFired);
      }
    } ],
    download: '&#8942;',
    selection: false,
    zoom: false,
    zoomin: false,
    zoomout: false,
    pan: false,
    reset: false,
  }
}

export const toolbarTools = {
  show: true,
  tools: {
    download: '&#8942;'
  }
}

export interface XAAreaChart extends ApexChart {
  type: 'area',
  isDrillDown: boolean,
  plugin: ChartPlugins.apex,
  drillDownEvent?: events
}

export interface CustomButton {
  icon: string,
  onClick: ($event: any) => void
}


export const XaDonutChart: XaChartOptions = {
  autoUpdateSeries: true,
  series: [],
  chart: {
    type: 'donut',
    height: 350,
    plugin: ChartPlugins.apex,
    toolbar: toolbarTools,
    isDrillDown: false,
    locales: [ defaultLocaleData ]
  },
  labels: [],
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'top',
          fontFamily: 'inter',
          fontSize: '12px',
          fontWeight: 400
        }
      }
    }
  ],
  legend: {
    position: 'top',
    fontFamily: 'inter',
    fontSize: '12px',
    fontWeight: 400,
  },
  colors: [],
  stroke: {
    width: 0,
  },
  plotOptions: {
    pie: {
      donut: {
        labels: {
          show: true,
          total: {
            show: true,
            label: 'All',
            formatter: () => '100%',
            color: '#000'
          },
          value: {
            formatter: (val: string): string => val + '%'
          }
        }
      }
    }
  },
  states: {
    hover: {
      filter: {
        type: 'none',
      }
    },
    active: {
      filter: {
        type: 'darken',
        value: 0.75,
      }
    }
  }
};

export const XaStackedChart: XaChartOptions = {
  series: [],
  chart: {
    type: 'bar',
    plugin: ChartPlugins.apex,
    stacked: true,
    stackType: 'normal',
    toolbar: toolbarTools,
    isDrillDown: false,
    locales: [ defaultLocaleData ]
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        legend: {
          position: 'top',
          offsetX: -10,
          offsetY: 0
        }
      }
    }
  ],
  plotOptions: {
    bar: {
      horizontal: false
    }
  },
  xaxis: {},
  yaxis: {},
  legend: {
    fontFamily: 'inter',
    fontSize: '12px',
    fontWeight: 400,
    position: 'top',
    horizontalAlign: 'left'
  },
  fill: {
    opacity: 1,
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    colors: [ 'transparent' ],
    width: 2,
  }
};

export const XaLineChart: XaChartOptions = {
  series: [
  ],
  title: {
    text: 'Repair Details',
    align: 'left'
  },
  chart: {
    type: 'line',
    plugin: ChartPlugins.apex,
    height: 350,
    zoom: {
      enabled: false
    },
    toolbar: {
      show: true
    },
    isDrillDown: false
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }
  ],
  grid: {
    row: {
      colors: [ 'transparent' ]
    },
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: 'smooth'
  },
  legend: {
    fontFamily: 'inter',
    fontSize: '12px',
    fontWeight: 400,
    position: 'top',
  },
  xaxis: {
    categories: []
  }
};

export const XaBarChart: XaChartOptions = {
  series: [ {
    data: [ {
      x: '2019',
      y: 10
    }, {
      x: '2020',
      y: 18
    }, {
      x: '2021',
      y: 13
    }, {
      x: '2022',
      y: 29
    }, {
      x: '2023',
      y: 89
    } ]
  } ],
  title: {
    text: 'Repair Details',
    align: 'left',
    style: {
      fontSize: '22px',
      fontFamily: 'inter',
      fontWeight: 700,
      color: '#0B0E15'
    }
  },
  chart: {
    type: 'bar',
    height: 350,
    plugin: ChartPlugins.apex,
    zoom: {
      enabled: false
    },
    toolbar: {
      show: true,
      tools: {
        download: false,
        customIcons: [ {
          icon: '<button type="button" class="xa-button" size="sm" appearanceType="outline">Show Details</button>',
          index: 0,
          title: 'Download Graph',
          class: 'custom-icon',
          click: (chart, options, e): any => {
            return { chart, options, e };
          }
        } ]
      }
    },
    locales: [ defaultLocaleData ],
    defaultLocale: 'en',
    isDrillDown: true,
    drillDownEvent: events.xAxis
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }
  ],
  plotOptions: {
    bar: {
      borderRadius: 4,
      horizontal: false,
    }
  },
  dataLabels: {
    enabled: false
  },
  xaxis: {
    type: 'category',
    categories: [ '2019', '2020', '2021', '2022', '2023' ],
    labels: {
      show: true,
      style: {
        fontSize: '12px',
        fontFamily: 'inter',
        fontWeight: 400,
        colors: '#111827'
      }
    }
  }
};

export const XaRadialBarChart: XaChartOptions = {
  series: [],
  chart: {
    type: 'radialBar',
    plugin: ChartPlugins.apex,
    toolbar: toolbarTools,
    isDrillDown: false,
    locales: [ defaultLocaleData ]
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        legend: {
          position: 'top',
          offsetX: -10,
          offsetY: 0
        }
      }
    }
  ],
  plotOptions: {
    radialBar: {
      startAngle: -90,
      endAngle: 90,
      dataLabels: {
        total: {
          show: false,
          label: 'TOTAL'
        }
      }
    }
  },
  labels: [],
  xaxis: {},
  yaxis: {},
  legend: {
    fontFamily: 'inter',
    fontSize: '12px',
    fontWeight: 400,
    position: 'top',
    horizontalAlign: 'left'
  },
  fill: {
    opacity: 1,
  },
  dataLabels: {
    enabled: false
  }
};

export const XaPolarChart: XaChartOptions = {
  series: [],
  labels: [],
  chart: {
    type: 'polarArea',
    plugin: ChartPlugins.apex,
    toolbar: toolbarTools,
    isDrillDown: false
  },
  stroke: {
    colors: [ '#fff' ]
  },
  fill: {
    opacity: 0.8
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }
  ]
};

export const XaPieChart: XaChartOptions = {
  series: [],
  chart: {
    width: 380,
    type: 'pie',
    plugin: ChartPlugins.apex,
    toolbar: {
      show: true
    },
    isDrillDown: false
  },
  title: {
    text: 'Pie Chart',
    align: 'left',
    style: {
      fontSize: '22px',
      fontFamily: 'inter',
      fontWeight: 700,
      color: '#0B0E15'
    }
  },
  labels: [],
  legend: {
    fontFamily: 'inter',
    fontSize: '12px',
    fontWeight: 400,
    position: 'right',
    horizontalAlign: 'left'
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }
  ]
};

export const XaRedialBarChart: XaChartOptions = {
  series: [],
  chart: {
    height: 350,
    plugin: ChartPlugins.apex,
    type: 'radialBar',
    toolbar: {
      show: true
    },
    isDrillDown: false
  },
  plotOptions: {
    radialBar: {
      hollow: {
        size: '70%'
      }
    }
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }
  ],
  labels: []
};

export const XaGaugeChart: XaGaugeChartOption = {
  series: [
    {
      type: 'gauge',
      startAngle: 180,
      endAngle: 0,
      min: 0,
      radius: '100%', // this
      center: [ '50%', '50%' ],
      max: 240,
      z: 2,
      splitNumber: 8,
      progress: {
        show: true,
        roundCap: false,
        width: 32,
        itemStyle: {
          color: new graphic.RadialGradient(0, 0, 1, [
            { offset: 0, color: 'rgba(41, 231, 205, 0.4)' },
            { offset: 0, color: 'rgba(255, 255, 255, 1)' },
            { offset: 1, color: 'rgba(150, 216, 132, 0.5)' },
          ])
        }
      },
      pointer: {
        // eslint-disable-next-line max-len
        icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
        length: '110%',
        width: 8,
        offsetCenter: [ 1, '5%' ],
        itemStyle: {
          color: '#000'
        }
      },
      axisLine: {
        roundCap: false,
        lineStyle: {
          width: 32
        }
      },
      axisTick: {
        splitNumber: 2,
        lineStyle: {
          width: 2,
          color: '#999'
        }
      },
      splitLine: {
        length: 12,
        lineStyle: {
          width: 2,
          color: '#999'
        }
      },
      axisLabel: {
        distance: 36,
        color: '#999',
        fontSize: 10
      },
      title: {
        show: false
      },
      detail: {
        backgroundColor: '#fff',
        borderWidth: 0,
        width: '60%',
        lineHeight: 40,
        height: 40,
        offsetCenter: [ 0, '35%' ],
        valueAnimation: true,
        formatter: (value): string => {
          return '{value|' + value.toFixed(0) + '%}\n{unit|NPS}';
        },
        rich: {
          value: {
            fontSize: 32,
            fontWeight: 'bolder',
            color: '#44A33E'
          },
          unit: {
            fontSize: 16,
            color: '#000000'
          }
        }
      },
      data: [
        {
          value: 100
        }
      ]
    }
  ],
  chart: {
    type: 'Gauge',
    plugin: ChartPlugins.eCharts,
  },
  wrapperStyle: {
    height: '320px',
    width: '320px'
  },
  legends: [
    {
      icon: 'peoples',
      title: 'Detractors',
      value: '5 (28%)'
    },
    {
      icon: 'passives',
      title: 'Passive',
      value: '2 (11%)'
    },
    {
      icon: 'promoters',
      title: 'Promoters',
      value: '11 (61%)'
    }
  ],
  colorList: [
    new graphic.RadialGradient(0, 0, 1, [
      { offset: 0, color: 'rgba(41, 231, 205, 1)' },
      { offset: 1, color: 'rgba(150, 216, 132, 1)' },
    ]),
    '#29E7CD',
    '#FDCA40',
    '#FFA15B'
  ]
};


export const XaEPieChart: XaEPieChartOption = {
  title: {
    text: 'Xa E Pie Charts'
  },
  customButton: {
    icon: '<span>View Details</span>',
    onClick: (evt): void => {
      const selectionFired = new Event(`viewDetails-${evt.chart.id}`);
      window.dispatchEvent(selectionFired);

    }
  },
  toolbox: {
    show: true,
    feature: {
      saveAsImage: {},
    }
  },
  series: [
    {
      type: 'pie',
      radius: '65%',
      center: [ '50%', '50%' ],
      selectedMode: 'single',
      data: [ {
        name: 'test1',
        value: 1000
      }, {
        name: 'test2',
        value: 1012
      }, {
        name: 'test3',
        value: 1022
      }, {
        name: 'test4',
        value: 1044
      }, {
        name: 'test5',
        value: 1333
      } ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ],
  legend: {
    orient: 'vertical',
    right: 100,
    top: 90,
    bottom: 20,
    data: [ 'test1', 'test2', 'test3', 'test4', 'test5' ]
  },
  plugin: ChartPlugins.eCharts,
  onClick: ($event) => {
    console.log('$event:', $event)

  },
  wrapperStyle: {
    height: '400px',
    width: '100%'
  },
  drillDownEvent: events.legend,
  isDrillDown: true,
  chart: {
    type: 'Pie',
    plugin: ChartPlugins.eCharts,
  }
};

export const XaAreaChart: XaChartOptions = {
  series: [
  ],
  chart: {
    type: 'area',
    plugin: ChartPlugins.apex,
    height: 350,
    zoom: {
      enabled: false
    },
    toolbar: toolbarTools,
    isDrillDown: false
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: 'straight'
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }
  ],
  title: {
    text: ' ',
    align: 'left'
  },
  subtitle: {
    text: ' ',
    align: 'left'
  },
  labels: [],
  xaxis: {
    type: 'datetime'
  },
  yaxis: {
    opposite: true
  },
  legend: {
    horizontalAlign: 'left'
  }
};

export const XaMultiChart: XaChartOptions = {
  series: [
    {
      name: 'Website Blog',
      type: 'column',
      data: [ 440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160 ]
    },
    {
      name: 'Social Media',
      type: 'line',
      data: [ 23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16 ]
    }
  ],
  chart: {
    height: 350,
    plugin: ChartPlugins.apex,
    type: 'line',
    toolbar: {
      show: true
    },
    isDrillDown: false
  },
  stroke: {
    width: [ 0, 4 ]
  },
  title: {
    text: 'Traffic Sources',
    style: {
      fontSize: '22px',
      fontFamily: 'inter',
      fontWeight: 700,
      color: '#0B0E15'
    }
  },
  dataLabels: {
    enabled: true,
    enabledOnSeries: [ 1 ]
  },
  labels: [
    '01 Jan 2001',
    '02 Jan 2001',
    '03 Jan 2001',
    '04 Jan 2001',
    '05 Jan 2001',
    '06 Jan 2001',
    '07 Jan 2001',
    '08 Jan 2001',
    '09 Jan 2001',
    '10 Jan 2001',
    '11 Jan 2001',
    '12 Jan 2001'
  ],
  xaxis: {
    type: 'datetime',
    labels: {
      show: true,
      style: {
        fontSize: '12px',
        fontFamily: 'inter',
        fontWeight: 400,
        colors: '#111827'
      }
    }
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }
  ],
  yaxis: [
    {
      title: {
        text: 'Website Blog',
        style: {
          fontSize: '12px',
          fontFamily: 'inter',
          fontWeight: 400,
          color: '#111827'
        }

      }
    },
    {
      opposite: true,
      title: {
        text: 'Social Media',
        style: {
          fontSize: '12px',
          fontFamily: 'inter',
          fontWeight: 400,
          color: '#111827'
        }
      }
    }
  ]
};

@Component({
  selector: 'xa-chart',
  templateUrl: './xa-chart.component.html',
  styleUrls: [ './xa-chart.component.scss' ]
})
export class XaChart implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  /**
   * onResize
   *
   * @param {*} event
   */
  @HostListener('window:resize', [ '$event' ])
  onResize(): void {
    this.updateChartTitle();
  }
  //viewchilds
  @ViewChild('chartRef') chartRef: ChartComponent;
  @ViewChild('custom_button') custom_button: ElementRef;

  // input attributes
  @Input() exportConfig: any;
  @Input() options: Partial<XaChartOptions | XaGaugeChartOption | XaEPieChartOption>;
  @Input() drillDownText: string = '';
  @Input() drillDownLevel: number;
  @Input() noDataFound = false;

  //output events
  @Output() drillDown: EventEmitter<any> = new EventEmitter();
  @Output() backClick: EventEmitter<any> = new EventEmitter();
  @Output() markerClick: EventEmitter<any> = new EventEmitter();
  @Output() legendClick: EventEmitter<any> = new EventEmitter();
  @Output() xAxisClick: EventEmitter<any> = new EventEmitter();
  @Output() dataSelectionClick: EventEmitter<any> = new EventEmitter();
  @Output() gaugeLegendSelection: EventEmitter<any> = new EventEmitter();

  public exportConfigClicked: boolean = false;

  public currentDrillDownLevel: number = 0;
  public isObservableLoading: boolean = false;
  public chartInitialized = false;
  public eCharts = ChartPlugins;
  public currentParent: any[] = [];

  /**
   * constructor
   */
  constructor(
    private commonService: CommonService,
    public dialog: MatDialog,
    private sanitized: DomSanitizer
  ) {
    if (this.commonService.reloadChartData.closed) {
      this.commonService.reloadChartData = new BehaviorSubject<boolean>(false);
    }

    this.commonService.reloadChartData.subscribe((res) => {
      if (res) {
        this.refreshData();
      }
    });
  }

  /**
   * ngAfterViewInit
   */
  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.custom_button) {
        if (this.custom_button.nativeElement.children) {
          this.custom_button.nativeElement.children.forEach((elem) => {
            elem.addEventListener('click', this.handleCustomButtonClick.bind(this, this.pieEChartOptions));
          });
        }
      }
    }, 1000);
    this.updateChartTitle();
    if (this.chartRef?.chart?.id || this.eChartOptions?.chart?.id) {
      window.addEventListener(`viewDetails-${this.chartRef?.chart?.id || this.eChartOptions?.chart?.id}`, this.showDashboardViewDetailsComponent, false);
    }
  }

  /**ngAfterViewChecked
   *
  */
  ngOnChanges(): void {
    setTimeout(() => {
      if (this.custom_button) {
        if (this.custom_button.nativeElement.children) {
          this.custom_button.nativeElement.children.forEach((elem) => {
            elem.addEventListener('click', this.handleCustomButtonClick.bind(this, this.pieEChartOptions));
          });
        }
      }
    });

    this.updateChartTitle();
  }

  /**
   * showDashboardViewDetailsComponent
   * @param {*} e
   */
  showDashboardViewDetailsComponent = (): void => {
    if (!this.exportConfigClicked) {
      const dialogRef = this.dialog.open(DashboardViewDetailsComponent, { data: this.exportConfig, maxHeight: '90vh', disableClose: true });
      this.exportConfigClicked = true;

      dialogRef.afterClosed().subscribe(() => {
        this.exportConfigClicked = false;
      });
    }
  }

  /**
   * get sanitized html
   */
  public get sanitizedHTML(): SafeHtml {
    return this.sanitized.bypassSecurityTrustHtml(this.pieEChartOptions.customButton.icon);
  }
  /**
   * Update chart titles
   */
  private updateChartTitle(): void {
    setTimeout(() => {
      document.querySelectorAll('.exportSVG')?.forEach((elem) => {
        elem?.setAttribute('title', 'Download Graph');
      });
      document.querySelectorAll('.exportPNG')?.forEach((elem) => {
        elem?.setAttribute('title', 'Download Graph');
      });
      document.querySelectorAll('.exportCSV')?.forEach((elem) => {
        elem?.setAttribute('title', '');
      });
    }, 1000)
  }

  /**
   * gaugeLegendClick
   */
  gaugeLegendClick(legend: XaGaugeLegends): void {
    this.eChartOptions.legends.forEach((l: XaGaugeLegends) => {
      l.active = false;
    });
    legend.active = true;
    this.gaugeLegendSelection.emit({ legend, chartOptions: this.eChartOptions });
  }

  /**
   * ng init
   */
  ngOnInit(): void {
    if (this.options.observable) {
      this.wrapWithLoadingScreen(this.options.observable).subscribe(this.options.observer);
    }
    if (this.options.chart.plugin !== ChartPlugins.eCharts) {
      this.options = this.options as XaChartOptions;

      if (!this.options.chart.events) {
        this.options.chart.events = {};
      }


      this.options.chart.events.markerClick = this.onMarkerClick.bind(this);
      this.options.chart.events.dataPointSelection = this.dataPointSelection.bind(this);
      this.options.chart.events.xAxisLabelClick = this.onXAxisClick.bind(this);
      this.options.chart.events.legendClick = this.onLegendClick.bind(this);
    } else {
      if (this.options.chart.type === 'Gauge') {
        this.options = this.options as XaGaugeChartOption;
        if (this.options && this.options.series && this.options.colorList) {
          this.options.series[0].axisLine.lineStyle.color = colorGenerator(this.options.colorList, 0.5);
        }
      } else {
        this.options = this.options as XaEPieChartOption;

      }


    }
    this.chartInitialized = true;
  }

  /**
   * handle echarts drilldown
   */
  handleTriggerDrillDown(event: any): void {
    const selectedLabel = event.name;
    if (this.currentDrillDownLevel < this.drillDownLevel) {
      this.currentParent.push(selectedLabel);
    }
    if (this.options.title) {
      if (this.options?.chart?.plugin === ChartPlugins.eCharts) {
        ((this.options as XaEPieChartOption).title).show = false;
      } else {
        (this.options as XaChartOptions).title.offsetX = -1000;
      }
    }
    this.drillDown?.emit({ selected: this.currentParent, currentDrillDownLevel: this.currentDrillDownLevel, event });
  }

  /**
   * handleLegendSelectedChange
   */
  handleLegendSelectedChange($event: any): void {
    if (this.options.chart.plugin === ChartPlugins.eCharts && this.options.chart.type === 'Gauge') {
      return;
    }
    this.options = this.options as XaEPieChartOption;
    this.handleEventChange($event, events.legend, this.options.onLegendClick);
  }


  /**
   * handleLegentSelectedChange
   */
  handleDataSelection($event: any): void {
    if (this.options.chart.plugin === ChartPlugins.eCharts && this.options.chart.type === 'Gauge') {
      return;
    }
    this.options = this.options as XaEPieChartOption;
    this.handleEventChange($event, events.dataSelection, this.options.onClick);
  }

  /**
   * handleLegentSelectedChange
   */
  handleEventChange($event: any, eventType: events, callback: ($e: any) => void): void {
    if (this.options.chart.plugin === ChartPlugins.eCharts && this.options.chart.type !== 'Gauge') {
      this.options = this.options as XaEPieChartOption;
      if (this.options.isDrillDown && this.options.drillDownEvent === eventType) {
        this.handleTriggerDrillDown($event);
      } else {
        callback($event);
      }
    }

  }


  /**
   * dataPointSelection
   */
  dataPointSelection(event: any, chartContext: any, { seriesIndex, dataPointIndex, w }: any): void {
    this.options = this.options as XaChartOptions;
    if (this.options.chart.isDrillDown && this.options.chart.drillDownEvent === events.dataSelection) {
      w.labelIndex = dataPointIndex;
      this.handleDrillDown(event, chartContext, w);
    } else {
      const markerObj = { seriesIndex, dataPointIndex, w };
      this.dataSelectionClick?.emit({ event, chartContext, markerObj });
    }
  }

  /**
   * on marker click
   */
  onMarkerClick(event: any, chartContext: any, { seriesIndex, dataPointIndex, w }: any): void {
    this.options = this.options as XaChartOptions;
    if (this.options.chart.isDrillDown && this.options.chart.drillDownEvent === events.marker) {
      w.labelIndex = dataPointIndex;
      this.handleDrillDown(event, chartContext, w);
    } else {
      const markerObj = { seriesIndex, dataPointIndex, w };
      this.markerClick?.emit({ event, chartContext, markerObj });
    }
  }


  /**
   * on destroy of component
   */
  ngOnDestroy(): void {
    if (this.chartRef?.chart?.id || this.eChartOptions?.chart?.id)
      window.removeEventListener(`viewDetails-${this.chartRef?.chart?.id || this.eChartOptions?.chart?.id}`, this.showDashboardViewDetailsComponent, false);
    this.commonService.reloadChartData.unsubscribe();
  }

  /**
   * on legend click
   */
  onLegendClick(chartContext, seriesIndex, config): void {
    this.options = this.options as XaChartOptions;
    if (this.options.chart.isDrillDown && this.options.chart.drillDownEvent === events.legend) {
      config.labelIndex = seriesIndex;
      this.handleDrillDown(null, chartContext, config);
    } else {
      this.legendClick?.emit({ seriesIndex: seriesIndex, event: null, chartContext, config });
    }
  }

  /**
   * refresh chart data
   */
  refreshData(chartUpdate?: XaChartOptions, isDrillDownDetail: boolean = false, isDrillDownBack: boolean = false): void {
    if (this.options?.observable) {
      this.wrapWithLoadingScreen(this.options.observable, isDrillDownDetail, isDrillDownBack).subscribe(this.options.observer);
    } else {
      if (chartUpdate) {
        this.options = this.options as XaChartOptions;
        this.chartRef.updateSeries(chartUpdate.series);
      }

      if (isDrillDownBack && this.currentDrillDownLevel > 0) {
        this.currentDrillDownLevel--;
      }
      if (isDrillDownDetail && this.currentDrillDownLevel < this.drillDownLevel) {
        this.currentDrillDownLevel++;
      }
      if (this.currentDrillDownLevel === 0 && this.options.title) {
        if (this.options.chart.plugin === ChartPlugins.eCharts) {
          ((this.options as XaEPieChartOption).title).show = true;
        } else {
          (this.options as XaChartOptions).title.offsetX = 0;
        }
      }
    }

    this.updateChartTitle();
  }

  /**
   * wrap observable
   */
  wrapWithLoadingScreen<T>(obs$: Observable<T>, isDrillDownDetail: boolean = false, isDrillDownBack: boolean = false): Observable<T> {
    return of(null).pipe(
      tap(() => { this.isObservableLoading = true; }),
      switchMap(() => obs$),
      finalize(() => {
        if (isDrillDownBack && this.currentDrillDownLevel > 0) {
          this.currentDrillDownLevel--;
        }
        if (isDrillDownDetail && this.currentDrillDownLevel < this.drillDownLevel) {
          this.currentDrillDownLevel++;
        }
        if (this.currentDrillDownLevel === 0 && this.options.title) {
          if (this.options.chart.plugin === ChartPlugins.eCharts) {
            ((this.options as XaEPieChartOption).title).show = true;
          } else {
            (this.options as XaChartOptions).title.offsetX = 0;
          }
        }
        this.isObservableLoading = false;
      })
    );
  }

  /**
   * handle drillDown
   */
  handleDrillDown(event: any, chartContext: any, config: any): void {
    const selectedLabel = config.globals.labels[config.labelIndex];
    if (this.currentDrillDownLevel < this.drillDownLevel) {
      this.currentParent.push(selectedLabel);
    }
    if (this.options.title) {
      (this.options as XaChartOptions).title.offsetX = -1000;
    }
    this.drillDown?.emit({ selected: this.currentParent, currentDrillDownLevel: this.currentDrillDownLevel, event, chartContext, config });
  }

  /**
   * get option
   */
  get eChartOptions(): XaGaugeChartOption {
    return this.options as XaGaugeChartOption;
  }

  /**
   * handle custom button click
   */
  handleCustomButtonClick(options: any): void {
    options.customButton.onClick(this.options);
  }

  /**
   * get option
   */
  get pieEChartOptions(): XaEPieChartOption {
    return this.options as XaEPieChartOption;
  }

  /**
   * get XaChartOptions
   */
  get xaChartOptions(): XaChartOptions {
    return this.options as XaChartOptions;
  }


  /**
   * on x axis click
   */
  onXAxisClick(event: any, chartContext: any, config: any): void {
    this.options = this.options as XaChartOptions;
    if (this.options.chart.isDrillDown && this.options.chart.drillDownEvent === events.xAxis) {
      this.handleDrillDown(event, chartContext, config);
    } else {
      this.xAxisClick?.emit({ event, chartContext, config });
    }
  }

  /**
   * on back click
   */
  onBackClick(): void {
    this.currentParent.pop();
    this.backClick?.emit({ currentDrillDownLevel: this.currentDrillDownLevel, selected: this.currentParent });
  }

}

