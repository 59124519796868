<div>
    <div class="car-carousel" *ngIf="!isDamageRecommendationsLoading">
        <dx-gallery [dataSource]="damageRecommendations?.damageDetails"
                    [height]="300"
                    [showNavButtons]="true"
                    [showIndicator]="false"
                    (onSelectionChanged)="handleSelectionChange($event)">
            <div *dxTemplate="let data of 'item'">
                <div>
                    <mat-icon class="expand-preview" [svgIcon]="'expand-preview'" (click)="imageDialog('AFTER',(data.annotationImageUrl || data.sourceImageUrl), data.view);"></mat-icon>
                    <img alt="{{ data.vehicleType }}-{{data.view}}"
                        src="{{ data.annotationImageUrl || data.sourceImageUrl}}" />
                </div>
                <div class="damage-info"
                     width="100%">
                    <table class="table-head" width="100%">
                        <thead>
                            <tr>
                                <th width="33%">Part-Wise Damage</th>
                                <th width="20%">Part Conf(%)</th>
                                <th width="20%">Damage Conf(%)</th>
                                <th width="27%">Action</th>
                            </tr>
                        </thead>
                    </table>
                    <div class="table-scroll">
                        <table class="table-body"
                               width="100%">
                            <tbody>
                                <tr *ngFor="let damageItem of data?.damageDetails">
                                    <td width="33%">{{damageItem.partWiseDetails}}</td>
                                    <td width="20%">{{damageItem.partConfidence ? ((damageItem.partConfidence * 100 | number:'1.0-0') + '%'):''}}</td>
                                    <td width="20%">{{damageItem.damageConfidence ? ((damageItem.damageConfidence * 100 | number:'1.0-0') + '%'):''}}</td>
                                    <td width="27%">{{damageItem.damageRecommendation}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </dx-gallery>
    </div>
    <div *ngIf="isDamageRecommendationsLoading" class="ai-estimate-repair-recommendation-loading">
        <mat-spinner [strokeWidth]="5" [diameter]="50"></mat-spinner>
    </div>
</div>