import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SearchSectionModel, caseToSearchSectionMapper, checkIfCountExceeds } from 'src/app/model/chq-search-result-section-model';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { menuItem, menuItems } from 'src/app/model/menu';
import { CommonService } from 'src/app/services/common/common.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { SearchService } from 'src/app/services/search/search.service';
import { ApiPagination, defaultPagination } from '../xa-paginator/xa-paginator.component';

@Component({
  selector: 'chq-search-bar',
  templateUrl: './chq-search-bar.component.html',
  styleUrls: [ './chq-search-bar.component.scss' ]
})
export class ChqSearchBarComponent implements OnInit, OnDestroy {

  public pagination: ApiPagination = { ...defaultPagination, orderBy: 'CreatedDate' };
  public searchResult = [];
  public currentWidth = '';
  public groupedSearchResult: SearchSectionModel[] = [];
  public showSearchResults = false;
  public hasMoreResult = false;
  private currentSearchTerm = '';
  public clickedBack = false;
  
  public bookingButton: ChqWidgetsButtonModel = {
    label: 'New Booking',
    type: 'primary',
    icon: 'plus-active',
    color: 'primary'
  }

  public estimateButton: ChqWidgetsButtonModel = {
    label: 'New Quote',
    type: 'primary',
    icon: 'plus-active',
    color: 'primary'
  }

  repairPermission: boolean = false;
  quotePermission: boolean = false;
  @ViewChild('row', { static: false }) row: ElementRef;
  @ViewChild('searchBox', { static: false }) searchBox: ElementRef;

  @Input() placeHolder: string = '';
  @Input() isClaimModule: boolean = false;
  @Output() closeSearch: EventEmitter<any> = new EventEmitter();
  @Output() handleMoreResultClick: EventEmitter<{ searchKey: string }> = new EventEmitter();

  subscription: Subscription
  isExternal: boolean = false;
  menuItems: menuItem[] = menuItems;
  repairerView: boolean = false;
  isRepairOrderVehicle: boolean = false;
  isRepairOrderCustomer: boolean = false;
  public inspectionId: number;
  public domainId: number;
  objectId: number = 0;
  repairGuid: string = '';

  /**
   * constructor
   * @param router 
   */
  constructor(private commonService: CommonService,
    private searchService: SearchService,
    private eRef: ElementRef,
    private router: Router,
    private monitorService: MonitorService) {
    this.commonService.userPermission.subscribe((res: any) => {
      this.repairPermission = res.repair;
      this.quotePermission = res.quote;
    });

    this.subscription = this.commonService.userProfileData.subscribe({
      next: (res: any) => {
        if (res) {
          const automotiveServices = res?.data?.userPermission?.automotiveServices;
          const permissionList = automotiveServices.find(x => x.automotiveServiceName.toLowerCase() === 'carheal quote')?.permissions;
          const quoteMenus = this.menuItems.find(x => x.name === 'quote')?.childMenu;
          if (quoteMenus && quoteMenus?.length > 0 && permissionList && permissionList?.length > 0) {
            this.isExternal = this.commonService.hasPermission('case.external', permissionList);
          }
        }

      }
    });

    this.commonService.isRepairOrderVehicle.subscribe((res: boolean) => {
      this.isRepairOrderVehicle = res;
    });

    this.commonService.isRepairOrderCustomer.subscribe((res: boolean) => {
      this.isRepairOrderCustomer = res;
    });
    
  }

  /**
   * close
   */
  backToSearchResultPage(value: boolean): void {
    this.clickedBack = value;
    this.showSearchResults = true;
    this.isRepairOrderVehicle = false;
    this.isRepairOrderCustomer= false;
  }

  /**
   * close
   */
  close(): void {
    this.showSearchResults = false;
    this.searchBox.nativeElement.value = '';
    this.closeSearch.emit();
    this.disableEnableScroll();
  }

  /**
   * open new booking 
   */
  openNewBooking(isFromSearchResult?: boolean, caseGuid?: string): void {
    if (!isFromSearchResult) {
      this.commonService.setSessionStorage('selectedSearchItem', '');
    }
    this.router.navigateByUrl('repair/booking/0/refresh', { skipLocationChange: true }).then(() => {
      this.router.navigate([ 'repair/booking/0' ], { queryParams: { 'caseId': caseGuid } });
    });
    this.close();
  }

  /**
   * open new case 
   */
  openNewQuote(isFromSearchResult?: boolean): void {
    if (!isFromSearchResult) {
      this.commonService.setSessionStorage('selectedSearchItem', '');
    }
    this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
      this.router.navigate([ 'quote/case/0' ]);
    });
    this.close();
  }

  /**
   * handle user icon click in search result
   */
  handleUserAction($event: any): void {
    if ($event['module'] === 'quote') {
      this.openNewQuote(true);
    } else {
      this.openNewBooking(true, $event.caseId);
    }
  }

  /**
   * on init
   */
  ngOnInit(): void {
    this.bookingButton.onclick = this.openNewBooking.bind(this);
    this.estimateButton.onclick = this.openNewQuote.bind(this);

    if (this.router.url.indexOf('repairer-view') != -1 ) {
      this.repairerView = true;
    }
  }

  /**
   * handle More click
   */
  more(): void {
    const body = document.querySelector('body');
    body.style.overflow = 'auto';
    this.handleMoreResultClick.emit({ searchKey: this.currentSearchTerm });
  }

  /**
   * is search Empty
   */
  get empty(): boolean {
    return this.groupedSearchResult.filter((value) => {
      return value?.count > 0
    }).length === 0;
  }

  /**
   * document click event handler
   */
  @HostListener('document:click', [ '$event' ])
  clickout(event: Event): void {
    this.monitorService.logEvent('clickout', [ 'ChqDropdownComponent', 'addenda-quote', {
      event
    } ]);
    if (!this.showSearchResults) {
      return;
    }
    if(this.clickedBack){
      this.showSearchResults = true;
    } else if (!this.eRef.nativeElement.contains(event.target)) {
      this.showSearchResults = false;
      this.disableEnableScroll();
    }
  }

  /**
 * disable scroll
 */
  disableEnableScroll(): void {
    const body = document.querySelector('body');
    if (this.showSearchResults) {
      body.style.overflow = 'hidden';
    } else {
      body.style.overflow = 'auto';
    }
  }

  /**
 * CHecks if current browser is mac or not
 * @returns boolean
 */
  get isiOS(): boolean {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  }

  /**
   * check if current browser is android
   * 
   */
  get isAndroid(): boolean {
    return /Android/i.test(navigator.userAgent);
  }

  /**
 * handle search event
 * @param $event 
 * @returns 
 */
  search(searchKey: string): void {
    if (!searchKey) {
      return;
    }
    if (this.currentSearchTerm === searchKey && this.showSearchResults === true) {
      return;
    }
    this.commonService.showLoading();
    const searchObject = {
      'pageSize': 3,
      'pageNumber': 1,
      searchKey
    }
    this.currentWidth = `${this.row.nativeElement.clientWidth}px`;
    this.monitorService.logEvent('search', [ 'ChqSearchBarComponent', 'Addenda-Quote' ]);

    if (this.isClaimModule && !this.repairerView) {
      this.searchService.generalSearchClaim(searchObject).subscribe({
        next: (results) => {
          if (results?.data) {
            this.searchResult = results.data;
          } else {
            this.searchResult = [];
            this.groupedSearchResult = [];
          }
          this.currentSearchTerm = searchKey;
          this.commonService.hideLoading();
          this.showSearchResults = true;
          this.disableEnableScroll();
          this.hasMoreResult = checkIfCountExceeds(this.searchResult);
          this.groupedSearchResult = caseToSearchSectionMapper(this.searchResult, searchKey, false);
        },
        error: () => {
          this.searchResult = [];
          this.showSearchResults = true;
          this.disableEnableScroll();
          this.commonService.hideLoading();
        }
      });
    } else {
      this.searchService.generalSearch(searchObject).subscribe({
        next: (results) => {
          if (results?.data) {
            this.searchResult = results.data;
            const repairItem = results.data?.repairs?.items[0];
            if(repairItem){
              this.objectId = repairItem.id;
              this.domainId = repairItem.domainId;
              this.repairGuid = repairItem.repairGuid;
              this.inspectionId = repairItem.inspectionId;
            }
          } else {
            this.searchResult = [];
            this.groupedSearchResult = [];
          }
          this.currentSearchTerm = searchKey;
          this.commonService.hideLoading();
          this.showSearchResults = true;
          this.disableEnableScroll();
          this.hasMoreResult = checkIfCountExceeds(this.searchResult);
          this.groupedSearchResult = caseToSearchSectionMapper(this.searchResult, searchKey, this.isExternal);
        },
        error: () => {
          this.searchResult = [];
          this.showSearchResults = true;
          this.disableEnableScroll();
          this.commonService.hideLoading();
        }
      })
    }
  }

  /**
 * on destroy
 */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.commonService.isRepairOrderVehicle.next(null);
    this.commonService.isRepairOrderCustomer.next(null);
  }

}
