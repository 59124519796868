<form *ngIf="form"
      [formGroup]="form"
      style="display: flex; flex-flow: wrap; margin: 0 -5px;">
    <ng-container *ngFor="let control of formControls">
        <div class="form-row"
             [ngStyle]="{
            'visibility':  checkVisibility(control) ? 'visible' : 'hidden',
            'display':  (control?.visibleElement === 'control' && !checkVisibility(control)) 
                ? 'none' : (control?.layout?.alignment === 'right') ? 'inline-flex' : 'block',
            'flex-basis': control?.controlType === 'section'? '100%': control?.layout?.width,
            'min-width': control?.layout?.minWidth, 
            'max-width': control?.layout?.maxWidth, 
            'margin-left' : (control?.layout?.fullWidth && control?.layout?.alignment === 'right') ? 'calc(100% - '+control?.layout?.width+')' : null,
            'margin-right' : (control?.layout?.fullWidth && control?.layout?.alignment !== 'right') ? control?.layout?.width : null,
            'justify-content': (control?.layout?.alignment === 'right') ? 'flex-end': ''
        }"
             [ngClass]="{'disabled': checkDisabled(control), 'enabled': !checkDisabled(control)}"
             [attr.key]="control.key">
            <div [ngSwitch]="control.controlType">
                <div class="xa-form-group">
                    <xa-text *ngSwitchCase="'textbox'"
                             [label]="control.label"
                             [formControlName]="control?.key"
                             [placeholder]="control.placeholder"
                             [name]="control?.key"
                             [required]="checkRequiredValue(control)"
                             [disabled]="control.disabled"
                             [maxLength]="control.maxLength"
                             [minLength]="control.minLength"
                             [blockMaxLength]="control.blockMaxLength">
                    </xa-text>

                    <xa-search *ngSwitchCase="'search'"
                               [label]="control.label"
                               [formControlName]="control?.key"
                               [placeholder]="control.placeholder"
                               [name]="control?.key"
                               [required]="checkRequiredValue(control)"
                               [disabled]="control.disabled"
                               [maxLength]="control.maxLength"
                               [minLength]="control.minLength"
                               [blockMaxLength]="control.blockMaxLength"
                               (searchIconEmitter)="onOptionsUpdated($event)">
                    </xa-search>

                    <xa-tel *ngSwitchCase="'tel'"
                            [label]="control.label"
                            [formControlName]="control?.key"
                            [placeholder]="control.placeholder"
                            [name]="control?.key"
                            [required]="checkRequiredValue(control)"
                            [disabled]="control.disabled"
                            [maxLength]="control.maxLength"
                            [minLength]="control.minLength"
                            [blockMaxLength]="control.blockMaxLength">
                    </xa-tel>
                    <xa-numeric *ngSwitchCase="'numeric'"
                                [label]="control.label"
                                [formControlName]="control?.key"
                                [placeholder]="control.placeholder"
                                [name]="control?.key"
                                [required]="checkRequiredValue(control)"
                                [disabled]="control.disabled"
                                [min]="control.min"
                                [max]="control.max"></xa-numeric>
                    <xa-select *ngSwitchCase="'dropdown'"
                               [formControlName]="control?.key"
                               [showRequired]="checkRequiredValue(control)"
                               [options]="control.options"
                               [config]="control.dropdownConfig"
                               (updateOptions)="onOptionsUpdated($event)"></xa-select>
                    <xa-select *ngSwitchCase="'multiselect-dropdown'"
                               [formControlName]="control?.key"
                               [options]="control.options"
                               [showRequired]="checkRequiredValue(control)"
                               [allOption]="control.allOption"
                               [config]="control.dropdownConfig"
                               (updateOptions)="onOptionsUpdated($event)"></xa-select>
                    <xa-textarea *ngSwitchCase="'textarea'"
                                 [label]="control.label"
                                 [formControlName]="control?.key"
                                 [placeholder]="control.placeholder"
                                 [name]="control?.key"
                                 [required]="checkRequiredValue(control)"
                                 [disabled]="control.disabled"
                                 [rows]="control.rows"></xa-textarea>
                    <xa-checkbox *ngSwitchCase="'checkbox'"
                                 [formControlName]="control?.key"
                                 [required]="checkRequiredValue(control)"
                                 [disabled]="control.disabled"
                                 [position]="control.position">{{ control.label | translate}}</xa-checkbox>

                    <xa-heading *ngSwitchCase="'title'"
                                size="16"
                                [required]="checkRequiredValue(control)">{{control?.textPrefillValue | translate}}</xa-heading>
                    <xa-paragraph *ngSwitchCase="'description'"
                                  weight="700"
                                  [required]="checkRequiredValue(control)">{{control?.textPrefillValue | translate}} </xa-paragraph>
                    <xa-toggle *ngSwitchCase="'toggle'"
                               [formControlName]="control?.key"
                               [required]="checkRequiredValue(control)"
                               [disabled]="control.disabled"
                               [position]="control.position">{{ control.label | translate}}</xa-toggle>
                    <xa-radio-group *ngSwitchCase="'radio'"
                                    [formControlName]="control?.key"
                                    [name]="control?.key"
                                    [required]="checkRequiredValue(control)"
                                    class="radio-group-display-flex">
                        <xa-radio [value]="item.value"
                                  *ngFor="let item of control.options">{{ item.label | translate}}</xa-radio>
                    </xa-radio-group>
                    <xa-checkbox-group *ngSwitchCase="'checkbox-group'"
                                       [formControlName]="control?.key"
                                       [required]="checkRequiredValue(control)"
                                       [disabled]="control.disabled">
                        <xa-checkbox-item *ngFor="let item of control.options"
                                          [value]="item.value"
                                          [disabled]="control.disabled">{{ item.label | translate}}</xa-checkbox-item>
                    </xa-checkbox-group>
                    <xa-date-picker *ngSwitchCase="'date'"
                                    [formControlName]="control?.key"
                                    [label]="control.label"
                                    [placeholder]="control.placeholder"
                                    [required]="checkRequiredValue(control)"
                                    [maxDate]="control.maxDate"
                                    [minDate]="control.minDate"
                                    [disable]="control.disabled"
                                    [type]="'date'"></xa-date-picker>
                    <xa-date-picker *ngSwitchCase="'datetime'"
                                    [formControlName]="control?.key"
                                    [label]="control.label"
                                    [placeholder]="control.placeholder"
                                    [required]="checkRequiredValue(control)"
                                    [maxDate]="control.maxDate"
                                    [minDate]="control.minDate"
                                    [required]="control.required"
                                    [disable]="control.disabled"
                                    [type]="'datetime'"></xa-date-picker>
                    <xa-date-picker *ngSwitchCase="'time'"
                                    [formControlName]="control?.key"
                                    [label]="control.label"
                                    [placeholder]="control.placeholder"
                                    [required]="checkRequiredValue(control)"
                                    [maxDate]="control.maxDate"
                                    [minDate]="control.minDate"
                                    [disable]="control.disabled"
                                    [type]="'time'"></xa-date-picker>

                    <xa-places *ngSwitchCase="'places'"
                               [label]="control.label"
                               [formControlName]="control?.key"
                               [placeholder]="control.placeholder"
                               [name]="control?.key"
                               [required]="checkRequiredValue(control)"
                               [disabled]="control.disabled"
                               (changedAddress)="onOptionsAddress($event)"></xa-places>

                    <xa-rating *ngSwitchCase="'rating'"
                               [formControlName]="control?.key"
                               [name]="control?.key"
                               [required]="checkRequiredValue(control)"
                               class="radio-group-display-flex">
                        <xa-rating-option [value]="item.value"
                                          [iconType]="item.iconType"
                                          [iconName]="item.icon"
                                          *ngFor="let item of control.options">{{ item.label | translate}}</xa-rating-option>
                    </xa-rating>

                    <xa-recommendation *ngSwitchCase="'recommendation'"
                                       [label]="control.label"
                                       [formControlName]="control?.key"></xa-recommendation>
                    <xa-rich-text-editor *ngSwitchCase="'html'"
                                         [label]="control.label"
                                         [formControlName]="control?.key"></xa-rich-text-editor>
                    <xa-button *ngSwitchCase="'button'"
                               (click)="handleButtonClick(control)">
                        <mat-icon *ngIf="control?.icon"
                                  [svgIcon]="control.icon"></mat-icon>
                        {{ control?.label | translate}}
                    </xa-button>
                    <xa-section [sectionControl]="control"
                                *ngSwitchCase="'section'">
                        <dynamic-form [form]="form"
                                      [formControls]="control.childControls"
                                      [isSectionContainer]="true"></dynamic-form>
                    </xa-section>
                    <xa-label *ngSwitchCase="'label'"
                              [label]="control.label"
                              [formControlName]="control?.key"
                              [name]="control?.key"
                              [disabled]="control.disabled">
                    </xa-label>
                    <ng-container *ngIf="showValidationContainer(control.controlType)">
                        <div class="xa-form-field-error-wrapper">
                            <div class="errorMessage"
                                 *ngIf="!form.controls[control?.key]?.valid && form.controls[control?.key]?.touched"
                                 @slideInTop
                                 @slideOutTop>
                                <xa-error>{{getErrorMessage(control) | translate}}</xa-error>
                            </div>
                        </div>
                    </ng-container>

                </div>
            </div>
        </div>
    </ng-container>
</form>
<!-- <div *ngIf="isSectionContainer" [formGroup]="form">
    <ng-container *ngFor="let control of formControls">
        <div class="form-row"
             [ngStyle]="{
            'visibility':  checkVisibility(control) ? 'visible' : 'hidden',
            'display':  (control?.visibleElement === 'control' && !checkVisibility(control)) ? 'none' : 'block',
            'flex-basis': control?.layout?.width,
            'min-width': control?.layout?.minWidth, 
            'max-width': control?.layout?.maxWidth, 
            'margin-left' : (control?.layout?.fullWidth && control?.layout?.alignment === 'right') ? 'calc(100% - '+control?.layout?.width+')' : null,
            'margin-right' : (control?.layout?.fullWidth && control?.layout?.alignment !== 'right') ? control?.layout?.width : null
        }"
             [ngClass]="{'disabled': checkDisabled(control), 'enabled': !checkDisabled(control)}">
            <div [ngSwitch]="control.controlType">
                <div class="xa-form-group">
                    <xa-text *ngSwitchCase="'textbox'"
                             [label]="control.label"
                             [formControlName]="control?.key"
                             [placeholder]="control.placeholder"
                             [name]="control?.key"
                             [required]="checkRequiredValue(control)"
                             [disabled]="control.disabled"
                             [maxLength]="control.maxLength"
                             [minLength]="control.minLength"
                             [blockMaxLength]="control.blockMaxLength">
                    </xa-text>

                    <xa-search *ngSwitchCase="'search'"
                               [label]="control.label"
                               [formControlName]="control?.key"
                               [placeholder]="control.placeholder"
                               [name]="control?.key"
                               [required]="checkRequiredValue(control)"
                               [disabled]="control.disabled"
                               [maxLength]="control.maxLength"
                               [minLength]="control.minLength"
                               [blockMaxLength]="control.blockMaxLength"
                               (searchIconEmitter)="onOptionsUpdated($event)">
                    </xa-search>

                    <xa-tel *ngSwitchCase="'tel'"
                            [label]="control.label"
                            [formControlName]="control?.key"
                            [placeholder]="control.placeholder"
                            [name]="control?.key"
                            [required]="checkRequiredValue(control)"
                            [disabled]="control.disabled"
                            [maxLength]="control.maxLength"
                            [minLength]="control.minLength"
                            [blockMaxLength]="control.blockMaxLength">
                    </xa-tel>
                    <xa-numeric *ngSwitchCase="'numeric'"
                                [label]="control.label"
                                [formControlName]="control?.key"
                                [placeholder]="control.placeholder"
                                [name]="control?.key"
                                [required]="checkRequiredValue(control)"
                                [disabled]="control.disabled"
                                [min]="control.min"
                                [max]="control.max"></xa-numeric>
                    <xa-select *ngSwitchCase="'dropdown'"
                               [formControlName]="control?.key"
                               [options]="control.options"
                               [showRequired]="checkRequiredValue(control)"
                               [config]="control.dropdownConfig"
                               (updateOptions)="onOptionsUpdated($event)"></xa-select>
                    <xa-select *ngSwitchCase="'multiselect-dropdown'"
                               [formControlName]="control?.key"
                               [options]="control.options"
                               [showRequired]="checkRequiredValue(control)"
                               [config]="control.dropdownConfig"
                               (updateOptions)="onOptionsUpdated($event)"></xa-select>
                    <xa-textarea *ngSwitchCase="'textarea'"
                                 [label]="control.label"
                                 [formControlName]="control?.key"
                                 [placeholder]="control.placeholder"
                                 [name]="control?.key"
                                 [disabled]="control.disabled"
                                 [rows]="control.rows"></xa-textarea>
                    <xa-checkbox *ngSwitchCase="'checkbox'"
                                 [formControlName]="control?.key"
                                 [required]="checkRequiredValue(control)"
                                 [disabled]="control.disabled"
                                 [position]="control.position">{{ control.label | translate}}</xa-checkbox>
                    <xa-heading *ngSwitchCase="'title'"
                                size="16"
                                [required]="checkRequiredValue(control)">{{control?.value | translate}}</xa-heading>
                    <xa-paragraph *ngSwitchCase="'description'"
                                  weight="700"
                                  [required]="checkRequiredValue(control)">{{control?.value | translate}}</xa-paragraph>
                    <xa-toggle *ngSwitchCase="'toggle'"
                               [formControlName]="control?.key"
                               [required]="checkRequiredValue(control)"
                               [disabled]="control.disabled"
                               [position]="control.position">{{ control.label | translate}}</xa-toggle>
                    <xa-radio-group *ngSwitchCase="'radio'"
                                    [formControlName]="control?.key"
                                    [name]="control?.key"
                                    [required]="checkRequiredValue(control)"
                                    class="radio-group-display-flex">
                        <xa-radio [value]="item.value"
                                  *ngFor="let item of control.options">{{ item.label | translate}}</xa-radio>
                    </xa-radio-group>
                    <xa-checkbox-group *ngSwitchCase="'checkbox-group'"
                                       [formControlName]="control?.key"
                                       [required]="checkRequiredValue(control)"
                                       [disabled]="control.disabled">
                        <xa-checkbox-item [value]="item.value"
                                          *ngFor="let item of control.options">{{ item.label | translate}}</xa-checkbox-item>
                    </xa-checkbox-group>
                    <xa-date-picker *ngSwitchCase="'date'"
                                    [formControlName]="control?.key"
                                    [label]="control.label"
                                    [maxDate]="control.maxDate"
                                    [type]="'date'"
                                    [disable]="control.disabled"></xa-date-picker>
                    <xa-date-picker *ngSwitchCase="'datetime'"
                                    [formControlName]="control?.key"
                                    [label]="control.label"
                                    [maxDate]="control.maxDate"
                                    [type]="'datetime'"
                                    [disable]="control.disabled"></xa-date-picker>
                    <xa-date-picker *ngSwitchCase="'time'"
                                    [formControlName]="control?.key"
                                    [label]="control.label"
                                    [maxDate]="control.maxDate"
                                    [type]="'time'"
                                    [disable]="control.disabled"></xa-date-picker>

                    <xa-places *ngSwitchCase="'places'"
                               [label]="control.label"
                               [formControlName]="control?.key"
                               [placeholder]="control.placeholder"
                               [name]="control?.key"
                               [required]="checkRequiredValue(control)"
                               [disabled]="control.disabled"
                               (changedAddress)="onOptionsAddress($event)"></xa-places>

                    <xa-rating *ngSwitchCase="'rating'"
                               [formControlName]="control?.key"
                               [name]="control?.key"
                               [required]="checkRequiredValue(control)"
                               class="radio-group-display-flex">
                        <xa-rating-option [value]="item.value"
                                          [iconType]="item.iconType"
                                          [iconName]="item.icon"
                                          *ngFor="let item of control.options">{{ item.label | translate}}</xa-rating-option>
                    </xa-rating>

                    <xa-recommendation *ngSwitchCase="'recommendation'"
                                       [label]="control.label"
                                       [formControlName]="control?.key"></xa-recommendation>
                    <xa-rich-text-editor *ngSwitchCase="'html'"
                                         [label]="control.label"
                                         [formControlName]="control?.key"></xa-rich-text-editor>
                    <xa-button *ngSwitchCase="'button'"
                               (click)="handleButtonClick(control)">
                        <mat-icon *ngIf="control?.icon"
                                  [svgIcon]="control.icon"></mat-icon>
                        {{ control?.label | translate}}
                    </xa-button>

                    <ng-container *ngIf="showValidationContainer(control.controlType)">
                        <div class="xa-form-field-error-wrapper">
                            <div class="errorMessage"
                                 *ngIf="!form.controls[control?.key]?.valid && form.controls[control?.key]?.touched"
                                 @slideInTop
                                 @slideOutTop>
                                <xa-error>{{getErrorMessage(control) | translate}}</xa-error>
                            </div>
                        </div>
                    </ng-container>


                </div>
            </div>
        </div>
    </ng-container>
</div> -->