<div class="dialog-container">
    <div mat-dialog-title
         *ngIf="!data.hideTitle">
        <span *ngIf="data.title &&
                               !titleContainsHtml">
            {{ data.title | translate }}
        </span>
        <div *ngIf="data.title && titleContainsHtml"
             [innerHTML]="data.title | translate"></div>
        <div *ngIf="data.showCross"
             class="close-icon">
            <a (click)="close();">
                <mat-icon [svgIcon]="'cross'"
                          color="white"></mat-icon>
            </a>
        </div>
    </div>

    <div mat-dialog-content
         *ngIf="!messageContainsHtml">
        {{ data.message | translate:{ value: (data.messageEntity || 'item') } }}
    </div>
    <div mat-dialog-content
         *ngIf="messageContainsHtml"
         [innerHTML]="data.message | translate"></div>


</div>

<div class="btn-container"
     *ngIf="!data.hideButton">
    <button class="btn-ok"
            mat-dialog-close
            (click)="close()">Ok</button>
</div>