<div class="dialog-container">
  <div mat-dialog-title
       *ngIf="!titleContainsHtml">
    {{ data.title | translate }}
  </div>
  <div mat-dialog-title
       *ngIf="titleContainsHtml"
       [innerHTML]="data.title | translate"></div>
  <div mat-dialog-content
       *ngIf="!messageContainsHtml">
    <p>{{ data.message | translate:{ value: (data.messageEntity || 'item') } }}</p>
    <div class="button-list">
      <xa-button (click)="close(vehicle);"
                 type="outline"
                 color="gray"
                 size="lg"
                 *ngFor="let vehicle of data.vehicleDetails">
        {{vehicle['driver_details']['driver_Name']}}
      </xa-button>
    </div>
  </div>
</div>