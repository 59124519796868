import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiPagination } from 'src/app/widgets/xa-paginator/xa-paginator.component';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RepairEstimateOperationService {
  private apiUrl: string = 'operation';
  repairOperationType = []
  /**
   * constructor
   * @param http
  */
  constructor(private http: HttpClient) { }

  /**
    Retrieves a list of operations from the API.
    @param {ApiPagination} pagination - The pagination parameters for the request.
    @param {string} [queryParams] - Additional query parameters to include in the request.
    @returns {Observable} - An Observable that emits the response from the API.
  */
  public getOperations(pagination: ApiPagination, queryParams: string = null): Observable<any> {
    const skip = (pagination.currentPage - 1) * pagination.pageSize;
    queryParams = (!queryParams) ? `$top=${pagination.pageSize}&$skip=${skip}` : `$top=${pagination.pageSize}&$skip=${skip}&${queryParams}`
    const orderBy = `&$orderby=${pagination.orderBy} ${pagination.sortDirection}`
    const url = `${environment.caseMgmtUrl}/odata/${this.apiUrl}?${queryParams}${orderBy}&count=true`;
    return this.http.get<any>(url);
  }

  /**
    Retrieves a list of repair operation types from a backend API.
    @returns {Observable} An Observable that emits the list of repair operation types.
  */
  public getRepairOperationType(): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/lookup?type=RepairOperationType`;
    return this.http.get(url);
  }

  /**
    Adds a repair operation to a case with the given GUID.
    @param {string} reapirGuid - The GUID of the case to add the repair operation to.
    @param {object} data - The data for the repair operation to be added.
    @returns {Observable} - An Observable that emits the response from the server.
  */
  addRepairOperation(reapirGuid:string, data: object): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/operation/${reapirGuid}`;
    return this.http.post(url, data);
  }

  /**
    Edits a repair operation in the case management system.
    @param {object} data - The data to be edited.
    @returns {Observable} - An Observable that emits the edited data.
  */
  editRepairOperation(data: object): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/operation`;
    return this.http.put(url, data);
  }

  /**
    Deletes an operation with the specified ID.
    @param {string} operationId - The ID of the operation to be deleted.
    @returns {Observable} - An Observable that emits the result of the delete operation.
  */
  deleteOperation(operationId: string): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/operation/${operationId}`;
    return this.http.delete(url);
  }

  /**
    Edits the repair estimate operation with the given GUID.
    @param {string} repairGuid - The GUID of the repair estimate operation to edit.
    @param {object} data - The data to update the repair estimate operation with.
    @returns {Observable} - An Observable that emits the updated repair estimate operation.
  */
  editRepairEstimateOperation(repairGuid:string, data: object): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/operation/repairoperation/${repairGuid}`;
    return this.http.put(url, data);
  }

  /**
    Retrieves details of a repair operation with the given GUID.
    @param {string} repairOperationGuid - The GUID of the repair operation to retrieve details for.
    @returns {Observable} - An Observable that emits the details of the repair operation.
  */
  getRepairEstimateOperationDetails(repairOperationGuid:string): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/operation/repairoperation/${repairOperationGuid}`;
    return this.http.get<any>(url);
  }


  /**
    Deletes a repair estimate operation with the specified GUIDs.
    @param {string} repairGuid - The GUID of the repair estimate.
    @param {string} repairOperationGuid - The GUID of the repair estimate operation.
    @returns {Observable} An Observable that emits the result of the operation.
  */
  deleteRepairEstimateOperation(repairGuid: string, repairOperationGuid:string): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/operation/repairoperation/${repairGuid}/${repairOperationGuid}`;
    return this.http.delete<any>(url);
  }

  /**
    Adds operations to a repair case.
    @param {string} repairGuid - The ID of the repair case.
    @param {Array} data - An array of operation IDs to add to the case.
    @returns {Observable} - An Observable that emits the response from the server.
  */
  addOperationsToReapir(repairGuid: string, data: Array<string>): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/operation/repairoperation/${repairGuid}`;
    return this.http.post<any>(url, data);
  }

  /**
   * Get Parts from the estimation
   * @param {string} repairGuid - The ID of the repair
   * @returns {Observable} - An Observable that emits the response from the server.
   */
  getParts(repairGuid: string): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/repair/${repairGuid}/parts`;
    return this.http.get(url);
  }

  /**
   * get repair types
   * @returns {Observable} - An Observable that emits the response from the server.
   */
  public getPartStatuses(): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/lookup?type=PartStatus`;
    return this.http.get(url);
  }

  /**
   * edit part status
   * @param formData
   * @param repairGuid
   * @returns {Observable} - An Observable that emits the response from the server.
   */
  public editPartStatuses(formData: any, repairGuid: string): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/repair/${repairGuid}/partStatus`;
    return this.http.put(url, formData);
  }

  /**
   * edit part status
   * @param repairGuid
   * @param estimateChargeId
   * @returns {Observable} - An Observable that emits the response from the server.
   */
  public deleteEstimateCharge(repairGuid: string, estimateChargeId, domainId:number = 3100): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/addendaestimate/${repairGuid}/${domainId}/${estimateChargeId}`;
    return this.http.delete(url);
  }

  /**
   * edit part status
   * @param repairGuid
   * @param estimateChargeId
   * @returns {Observable} - An Observable that emits the response from the server.
   */
  public addAddendaEstimateOperation(repairGuid: string, estimationMode, payload, domainId:number = 3100): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/addendaestimate/${repairGuid}/${domainId}/${estimationMode}`;
    return this.http.post(url, payload);
  }

  /**
   * Description placeholder
   *
   * @public
   * @param {string} repairGuid
   * @param {*} estimationMode
   * @param {*} payload
   * @returns {Observable<any>}
   */
  public addSingleAddendaEstimateOperation(repairGuid: string, estimationMode, payload, domainId:number = 3100): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/addendaestimate/createestimateitem/${repairGuid}/${domainId}/${estimationMode}`;
    return this.http.post(url, payload);
  }
}
