<div class="dialog-container">
     <div mat-dialog-title>
          <span> {{ 'ai_inference_report' | translate }} </span>
          <mat-icon class="pointer"
                    svgIcon="cross"
                    (click)="close()"></mat-icon>
     </div>

     <div mat-dialog-content>
          <div class="main-content">
               <div class="case-info">
                    <span><strong>{{'case_id' | translate}}:</strong> {{data?.caseNo}}</span>
                    <span><strong>{{'creation_date' | translate}}:</strong> {{data?.createdDate | date: 'dd/MM/yyyy hh:mm a'}}</span>
               </div>
               <div class="vehicle-info">
                    <div class="header">
                         <div class="img-container">
                              <img src="assets/img/front-right.png"
                                   alt="vehicle logo" />
                         </div>
                         <div class="vehicle-detail">
                              <h2 class="title">{{data?.vehicleInfo?.make}} {{data?.vehicleInfo?.model}}</h2>
                              <span class="sub-title">{{data?.vehicleInfo?.year || '-'}} </span>&nbsp;&nbsp; <span *ngIf="data?.vehicleInfo?.trim">({{data?.vehicleInfo?.trim}})</span>
                         </div>
                    </div>
                    <div class="table">
                         <div class="table-row">
                              <span class="label">{{'vin' | translate}}</span>
                              <span class="value">{{data?.vehicleInfo?.vin || '-'}}</span>
                         </div>
                         <div class="table-row">
                              <span class="label">{{'color' | translate}}</span>
                              <span class="value">{{data?.vehicleInfo?.color || '-'}}</span>
                         </div>
                         <div class="table-row">
                              <span class="label">{{'odometer' | translate}}(KM)</span>
                              <span class="value">
                                   <ng-container *ngIf="data?.vehicleInfo?.odometer; else noOdometer">
                                        {{data?.vehicleInfo?.odometer | number: '1.0-2'}}
                                   </ng-container>
                                   <ng-template #noOdometer>
                                        -
                                   </ng-template>
                              </span>
                         </div>
                         <div class="table-row">
                              <span class="label">{{'license_plate' | translate}}</span>
                              <span class="value">{{data?.vehicleInfo?.plateNo || '-'}}</span>
                         </div>
                    </div>
               </div>
               <div class="vehicle-images"
                    *ngIf="data?.isVideo">
                    <div class="image-item"
                         *ngFor="let item of data?.vehicleImages">
                         <h1 class="item-name">{{'inference' | translate}}</h1>
                         <div class="item-info">
                              <ng-container *ngFor="let info of item.vehicleInfo">
                                   <div class="item-row"
                                        *ngIf="info.key?.toLowerCase() === 'make'">
                                        <span class="label">{{'make' | translate}}</span>
                                        <span class="value">{{info.value || '-'}}</span>
                                   </div>
                                   <div class="item-row"
                                        *ngIf="info.key?.toLowerCase() === 'model'">
                                        <span class="label">{{'model' | translate}}</span>
                                        <span class="value">{{info.value || '-'}}</span>
                                   </div>
                                   <div class="item-row"
                                        *ngIf="info.key?.toLowerCase() === 'trim'">
                                        <span class="label">{{'trim' | translate}}</span>
                                        <span class="value">{{info.value || '-'}}</span>
                                   </div>
                                   <div class="item-row"
                                        *ngIf="info.key?.toLowerCase() === 'color'">
                                        <span class="label">{{'color' | translate}}</span>
                                        <span class="value">{{info.value || '-'}}</span>
                                   </div>
                                   <div class="item-row"
                                        *ngIf="info.key?.toLowerCase() === 'vinnumber'">
                                        <span class="label">{{'vin' | translate}}</span>
                                        <span class="value">{{info.value || '-'}}</span>
                                   </div>
                                   <div class="item-row"
                                        *ngIf="info.key?.toLowerCase() === 'odometerreading'">
                                        <span class="label">{{'odometer' | translate}}</span>
                                        <span class="value">
                                             <ng-container *ngIf="data?.vehicleInfo?.odometer; else noOdometerInfo">
                                                  {{info.value | number : '1.0-2'}}
                                             </ng-container>
                                             <ng-template #noOdometerInfo>
                                                  -
                                             </ng-template>
                                        </span>
                                   </div>
                              </ng-container>
                         </div>
                         <div *ngFor="let info of item.imagesInfo">
                              <h1 class="item-name">{{imageMapper[info.key] || info.key}}</h1>
                              <div class="item-img-container">
                                   <img [src]="info.value"
                                        alt="{{info.key}}"
                                        (load)="setImageLoading(info.index);"
                                        (error)="setImageLoading(info.index);" />
                                   <div class="placeholder-loader"
                                        *ngIf="!imageLoading[info.index]">
                                        <chq-custom-loader [showLoading]="!imageLoading[info.index]"></chq-custom-loader>
                                   </div>
                              </div>
                         </div>

                    </div>
               </div>
               <div class="vehicle-images"
                    *ngIf="!data?.isVideo">
                    <div class="image-item"
                         *ngFor="let item of data?.vehicleImages">
                         <h1 class="item-name">{{item.name}}</h1>
                         <div class="item-img-container">
                              <img [src]="item.imageUrl"
                                   alt="{{item.name}}"
                                   (load)="setImageLoading(item.index);"
                                   (error)="setImageLoading(item.index);" />
                              <div class="placeholder-loader"
                                   *ngIf="!imageLoading[item.index]">
                                   <chq-custom-loader [showLoading]="!imageLoading[item.index]"></chq-custom-loader>
                              </div>
                         </div>
                         <h1 class="item-name">{{'inference' | translate}}</h1>
                         <div class="item-info">
                              <ng-container *ngFor="let info of item.vehicleInfo">
                                   <div class="item-row"
                                        *ngIf="info.key?.toLowerCase() === 'make'">
                                        <span class="label">{{'make' | translate}}</span>
                                        <span class="value">{{info.value || '-'}}</span>
                                   </div>
                                   <div class="item-row"
                                        *ngIf="info.key?.toLowerCase() === 'model'">
                                        <span class="label">{{'model' | translate}}</span>
                                        <span class="value">{{info.value || '-'}}</span>
                                   </div>
                                   <div class="item-row"
                                        *ngIf="info.key?.toLowerCase() === 'trim'">
                                        <span class="label">{{'trim' | translate}}</span>
                                        <span class="value">{{info.value || '-'}}</span>
                                   </div>
                                   <div class="item-row"
                                        *ngIf="info.key?.toLowerCase() === 'color'">
                                        <span class="label">{{'color' | translate}}</span>
                                        <span class="value">{{info.value || '-'}}</span>
                                   </div>
                                   <div class="item-row"
                                        *ngIf="info.key?.toLowerCase() === 'vinnumber'">
                                        <span class="label">{{'vin' | translate}}</span>
                                        <span class="value">{{info.value || '-'}}</span>
                                   </div>
                                   <div class="item-row"
                                        *ngIf="info.key?.toLowerCase() === 'odometerreading'">
                                        <span class="label">{{'odometer' | translate}}</span>
                                        <span class="value">
                                             <ng-container *ngIf="data?.vehicleInfo?.odometer; else noOdometerInfo">
                                                  {{info.value | number : '1.0-2'}}
                                             </ng-container>
                                             <ng-template #noOdometerInfo>
                                                  -
                                             </ng-template>
                                        </span>
                                   </div>
                              </ng-container>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</div>