import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { ReserveType } from 'src/app/config/constants/app.constants';
import { CLAIM_STATUS, GARAGE_REGISTRATION_STATUS } from 'src/app/config/constants/claims.constants';
import { ReserveModificationConfig } from 'src/app/config/display/reserve-modification-config';
import { XADropdownConfig } from 'src/app/model/xa-dropdown-model';
import { ClaimsService } from 'src/app/services/claims/claims.service';
import { CommonService, Localization } from 'src/app/services/common/common.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { CellClickedCallback, TableCellData, TableConfig, TableRowData, XaTable } from 'src/app/widgets/xa-table/xa-table.component';
import { cloneDeep } from 'lodash';
@Component({
  selector: 'reserve-adjustment-dialog',
  templateUrl: './reserve-adjustment-dialog.component.html',
  styleUrls: [ './reserve-adjustment-dialog.component.scss' ]
})
export class ReserveAdjustmentDialogComponent implements OnInit {
  @ViewChild('reserveTable') reserveTable: XaTable;
  options: any[] = [];
  formGroup: FormGroup;
  coverageMapper: any = {};
  public tableConfig: TableConfig = ReserveModificationConfig;
  config: XADropdownConfig = {
    name: 'singleSelect',
    label: 'select_coverage',
    placeHolder: 'Select...',
    labelKey: 'name',
    valueKey: 'name'
  }
  tableData: any[] = [];
  i18n: Localization;
  selectedCoverage: string = ''
  /**
   * handle close
   */
  cancel(): void {
    this.monitorService.logEvent('send', [ 'ReserveAdjustmentDialogComponent', 'addenda' ]);
    this.dialogRef.close();
  }

  /**
    Handles a cell click event.
    @param {TableRowData} row - The row data.
    @param {Partial} column - The column data.
    @returns {void}
  */
  handleCellClick: CellClickedCallback = (row: TableRowData, column: Partial<TableCellData>, event: PointerEvent): void => {
    if (column.id === 'editable') {
      //todo get latest value from row and use updated value while saving reserve setting
    }
  };

  /**
   * constructor
   * @param dialogRef 
   * @param data 
   */
  constructor(public dialogRef: MatDialogRef<ReserveAdjustmentDialogComponent>,
    private monitorService: MonitorService,
    private commonService: CommonService,
    private claimService: ClaimsService,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.i18n = this.commonService.geti18nInfo();
  }

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    if(this.data.isForTotalLoss) { // IF for total loss then only loss reserve need to show
      this.data.reserveData.forEach((coverage) => {
        coverage.coverageDetails = coverage.coverageDetails.filter(x => x.reserveType === ReserveType.Loss)
      })
    }

    this.options = this.data.reserveData?.map((x) => { return { 'name': x.coverage } });
    this.data.reserveData?.forEach(x => this.coverageMapper[x.coverage] = x.coverageDetails);
    this.formGroup = new FormGroup({
      'coverage': new FormControl('',),
    });
    this.formGroup.get('coverage').valueChanges.subscribe((v) => {
      if (v) {
        this.selectedCoverage = v;
        this.tableData = this.coverageMapper[v];
      }
    })
  }

  /**
   * save
   */
  saveReserve(isConfirmReserve: boolean = false): any {
    if (this.reserveTable?.dataSource?.['_data']?.['_value']) {
      const tableDataStructured = this.reserveTable?.dataSource?.['_data']?.['_value'];
      const payload = [];
      let isFormInvalid = false
      tableDataStructured.forEach((row) => {
        const amountField = row.find(ele => ele.id === 'Amount')
        const guid = row.find(ele => ele.id === 'reserveDetailGuid');
        const reserveCode = row.find(ele => ele.id === 'ReserveCode');
        const reserveType = row.find(ele => ele.id === 'ReserveType');
        if (amountField && guid) {
          payload.push({
            Amount: amountField?.result?.value,
            ReserveDetailGuid: guid?.result?.value,
            ReserveCode: reserveCode?.result?.value,
            ReserveType: reserveType?.result?.value
          })
        }
        if (amountField?.isValid === false) {
          isFormInvalid = true
        }
      });
      if (isFormInvalid) {
        this.commonService.showToast(5000, 'Form is invalid.')
        return null
      }
      // Make sure loss value should not be 0
      const lossReserveAmount = payload?.find(ele => ele.ReserveType === ReserveType.Loss)?.Amount || 0;
      if (lossReserveAmount === 0) {
        this.commonService.showToast(5000, 'Loss reserve should not be zero');
        return null
      }

      this.commonService.showLoading()
      return firstValueFrom(this.claimService.updateClaimReserve({
        'ObjectGuid': this.data?.claimGuid,
        'ClaimReserveDetails': payload
      })).then((res) => {
        if(!isConfirmReserve) {
          this.commonService.hideLoading();
          this.commonService.showInfoToast(5000, this.translateService.instant('reserve_update_success'));
        }
        // Update the table data
        const currentTableData = cloneDeep(this.tableData)
        currentTableData.forEach((reserve) => {
          const updateData = payload.find(ele => ele.ReserveDetailGuid === reserve.reserveDetailGuid);
          if(updateData) {
            reserve.toBePaid = updateData.Amount
          }
        })
        this.coverageMapper[this.selectedCoverage] = currentTableData
      }).catch(() => {
        this.commonService.hideLoading();
      });
    }
  }

  /**
   * confirm reserve
   */
  async confirmReserve(): Promise<void> {
    await this.saveReserve(true);
    this.commonService.showLoading();
    if (this.data?.garageType != GARAGE_REGISTRATION_STATUS.internal) {
      this.claimService.thirdPartyApproveEstimate(this.data.claimGuid, 'Approved').subscribe({
        next: () => {
          this.approveClaim();
        }, error: (err) => {
          this.commonService.hideLoading();
          this.monitorService.logException(err, SeverityLevel.Error);
        }
      })
    } else {
      
      this.approveInternalEstimate();
    }
  }

  /**
   * approve internal estimate
   */
  approveInternalEstimate(): void {
    this.claimService.approveRejectEstimate(this.data?.objectId, { status: 'Approved', reason: 'Approved', isReassigned: false, domainId: 4100 }).subscribe({
      next: () => {
        this.commonService.hideLoading();
        this.claimService.EstimateStatus.next('Approved')
        this.approveClaim();
      },
      error: () => {
        this.commonService.hideLoading();
      }
    })
  }

  /**
   * approve claim
   */
  approveClaim(): void {
    this.claimService.updateClaimStatus(this.data?.claimGuid, 'Approved', 'Claim Approved').subscribe({
      next: (response) => {
        if (response) {
          this.commonService.showInfoToast(5000, this.translateService.instant('estimate_approved_successfully'));
          if (this.data?.garageType === GARAGE_REGISTRATION_STATUS.internal) {
            this.claimService.lockEstimation(this.data?.objectId, CLAIM_STATUS.Approved).subscribe();
          }
          this.dialogRef.close();
          this.claimService.ClaimStatus.next(CLAIM_STATUS.Approved)
        }
        this.commonService.hideLoading();
      },
      error: () => {
        this.commonService.hideLoading();
      }
    });
  }

  /**
   * getPayload
   */
  getPayload(): any {
    if (this.reserveTable?.dataSource?.['_data']?.['_value']) {
      const tableDataStructured = this.reserveTable?.dataSource?.['_data']?.['_value'];
      const payload = [];
      let isFormInvalid = false
      tableDataStructured.forEach((row) => {
        const amountField = row.find(ele => ele.id === 'Amount')
        const guid = row.find(ele => ele.id === 'reserveDetailGuid');
        const reserveCode = row.find(ele => ele.id === 'ReserveCode');
        const reserveType = row.find(ele => ele.id === 'ReserveType');
        if (amountField && guid) {
          payload.push({
            Amount: amountField?.result?.value,
            ReserveDetailGuid: guid?.result?.value,
            ReserveCode: reserveCode?.result?.value,
            ReserveType: reserveType?.result?.value
          })
        }
        if (amountField?.isValid === false) {
          isFormInvalid = true
        }
      });
      if (isFormInvalid) {
        this.commonService.showToast(5000, 'Form is invalid.')
        return null
      }
      // Make sure loss value should not be 0
      const lossReserveAmount = payload?.find(ele => ele.ReserveType === ReserveType.Loss)?.Amount || 0;
      if (lossReserveAmount == 0) {
        this.commonService.showToast(5000, 'Loss reserve should not be zero');
        return null
      }

      return payload
    }
    return null
  }

  /**
   * close
   */
  close(): void {
    this.monitorService.logEvent('send', [ 'ReserveAdjustmentDialogComponent', 'addenda' ]);
    this.dialogRef.close();
  }
  /**
   * confirmTotalLoss
   */
  confirmTotalLoss(): Promise<void> {
    const payload = this.getPayload();
    if(!payload) return null
    this.commonService.showLoading()
    return firstValueFrom(this.claimService.updateReserveWithTotalLoss({
      'claimGuid': this.data?.claimGuid,
      'clmReserveDetailGuid': payload[0]?.ReserveDetailGuid,
      'amount': payload[0]?.Amount
    })).then((res) => {
      this.commonService.hideLoading();
      if(res) {
        this.commonService.showInfoToast(5000, this.translateService.instant('total_loss_reserve_update_success'));
        this.claimService.isTotalLoss.next({
          claimGuid: this.data?.claimGuid,
          isTotalLoss: true
        })
        this.dialogRef.close();
      }
    }).catch(() => {
      this.commonService.hideLoading();
    });
  }
}

