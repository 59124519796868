<div class="case-submit-success-message-container fx-col">
    <div class="header">
        <div class="close-icon">
            <mat-icon [svgIcon]="'cross'" (click)="cancelDialog(false);"></mat-icon>
        </div>
    </div>
    <div class="content fx-col fx-1">
        <mat-icon [svgIcon]="'case-submit-success'"></mat-icon>
        <h4>Thank You!</h4>
        <p>The case information with quote ID {{data.quoteId}} is now updated successfully</p>
    </div>
</div>
