import { Injectable, OnDestroy } from '@angular/core';
import { DynamicFormBase, Validator } from './dynamic-form-base';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { serviceURL, serviceURLMapper } from '../../constants/app.constants';
import { HttpClient } from '@angular/common/http';

export const customerMockData: DynamicFormBase<string>[] = [

  new DynamicFormBase({
    value: '',
    key: 'work_provider',
    label: 'work_provider',
    placeholder: 'select_dot',
    required: true,
    controlType: 'dropdown',
    optionsByUrl: {
      service: 'AutoCore',
      url: '/api/Partnership/orgType-by-partnership',
      mapper: (resp): [] => {
        const arr = resp.data;
        const options = arr.map((opt) => {
          return {
            'label': opt,
            'value': opt
          }
        });
        return options;
      }
    },
    optionKeys: {
      label: 'label',
      value: 'value'
    },
    layout: {
      width: '50%',
      fullWidth: false
    }
  }),
  new DynamicFormBase({
    label: 'CLAIM_NO',
    value: '',
    key: 'claimNumber',
    placeholder: 'claims_no',
    required: false,
    controlType: 'textbox',
    visible: false,
    visibleIf: 'work_provider == Insurance Company',
    visibleElement: 'container',
    layout: {
      width: '50%',
      fullWidth: false
    },
    maxLength: 25,
    validators: [
      {
        type: 'maxlength',
        message: 'max_length_claim'
      }
    ]
  }),
  new DynamicFormBase({
    label: 'company_name',
    value: '',
    key: 'company_name',
    placeholder: 'company_name',
    required: false,
    controlType: 'textbox',
    visible: false,
    visibleIf: 'work_provider == Retail',
    visibleElement: 'control'
  }),
  new DynamicFormBase({
    value: '',
    key: 'company_name',
    label: 'company_name',
    placeholder: 'select_dot',
    required: true,
    controlType: 'dropdown',
    optionsByUrl: {
      service: 'AutoCore',
      url: '/api/Partnership/partnership-by-org/{work_provider}'
    },
    optionKeys: {
      label: 'companyName',
      value: 'companyName'
    },
    layout: {
      width: '50%',
      fullWidth: false
    },
    visible: false,
    visibleIf: 'work_provider != Retail',
    visibleElement: 'control',
  }),
  new DynamicFormBase({
    label: 'license_id_cin',
    value: '',
    key: 'license_id_cin',
    placeholder: 'license_id_cin',
    required: true,
    controlType: 'textbox',
    maxLength: 40,
    validators: [
      {
        type: 'maxlength',
        message: 'max_length_license'
      },
      {
        type: 'pattern',
        regex: '^[a-zA-Z0-9]*$',
        message: 'pattern_digit_alphabets'
      }
    ]
  }),
  new DynamicFormBase({
    label: 'drivers_first',
    value: '',
    key: 'drivers_first',
    placeholder: 'drivers_first',
    required: true,
    controlType: 'textbox',
    maxLength: 50,
    minLength: 2,
    validators: [
      {
        type: 'maxlength',
        message: 'max_length_driver_first_name'
      },
      {
        type: 'minlength',
        message: 'min_length_driver_first_name'
      }, {
        type: 'pattern',
        regex: '^[a-zA-Z]+[a-zA-Z ,.\'-]+$',
        message: 'pattern_driver_name'
      }
    ]
  }),
  new DynamicFormBase({
    label: 'drivers_last',
    value: '',
    key: 'drivers_last',
    placeholder: 'drivers_last',
    required: true,
    controlType: 'textbox',
    maxLength: 50,
    minLength: 2,
    validators: [
      {
        type: 'maxlength',
        message: 'max_length_driver_last_name'
      },
      {
        type: 'minlength',
        message: 'min_length_driver_last_name'
      },
      {
        type: 'pattern',
        regex: '^[a-zA-Z]+[a-zA-Z ,.\'-]+$',
        message: 'pattern_driver_name'
      }
    ]
  }),
  new DynamicFormBase({
    label: 'email_address',
    value: '',
    key: 'email_address',
    placeholder: 'drivers_email',
    required: true,
    controlType: 'textbox',
    layout: {
      width: '50%',
      fullWidth: false
    },
    maxLength: 50,
    validators: [
      {
        type: 'email',
        message: 'email_valid'
      },
      {
        type: 'maxlength',
        message: 'email_length'
      },
      {
        type: 'pattern',
        regex: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$',
        message: 'email_valid'
      }
    ]
  }),
  new DynamicFormBase({
    value: '',
    key: 'code',
    label: 'code',
    placeholder: 'select_dot',
    required: true,
    controlType: 'dropdown',
    optionsByUrl: {
      service: 'AutoCore',
      url: 'api/Countries'
    },
    optionKeys: {
      label: 'phoneCountryCode',
      value: 'phoneCountryCode'
    },
    layout: {
      width: '20%',
      fullWidth: false
    }
  }),
  new DynamicFormBase({
    label: 'mobile_number',
    value: '',
    key: 'driverPhone',
    placeholder: 'mobile_number',
    required: true,
    controlType: 'textbox',
    layout: {
      width: '30%',
      fullWidth: false
    },
    validators: [
      {
        type: 'pattern',
        regex: '^[0-9]+[0-9 ,.\'-]+$',
        message: 'valid_mobile_number'
      }
    ]
  })
]

export const vehicleMockData: DynamicFormBase<string>[] = [
  new DynamicFormBase({
    label: 'Vin Number',
    value: '',
    key: 'vin',
    placeholder: 'Search VIN',
    required: true,
    controlType: 'textbox',
    maxLength: 17,
    minLength: 17,
    validators: [
      {
        type: 'maxlength',
        message: 'min_length_vin'
      },
      {
        type: 'minlength',
        message: 'min_length_vin'
      }
    ]
  }),

  new DynamicFormBase({
    value: '',
    key: 'make',
    required: true,
    controlType: 'dropdown',
    label: 'make',
    placeholder: 'select_dot',
    disabled: true,
    optionsByUrl: {
      service: 'AutoApi',
      url: 'api/v1/VehicleMakes'
    },
    optionKeys: {
      label: 'name',
      value: 'name'
    },
  }),

  new DynamicFormBase({
    value: '',
    key: 'model',
    required: true,
    controlType: 'dropdown',
    disabledIf: 'make == ',
    disabled: true,
    label: 'model',
    placeholder: 'select_dot',
    options: [],
    optionsByUrl: {
      service: 'AutoApi',
      url: 'api/v1/VehicleMakes/VehicleModels/{makeId}/{estimateId}?$orderBy=modelDescription%20asc'
    },
    optionKeys: {
      label: 'modelDescription',
      value: 'modelDescription'
    },
  }),

  new DynamicFormBase({
    label: 'Car Mileage',
    value: '',
    key: 'carMileage',
    placeholder: 'Car Mileage',
    required: true,
    disabled: true,
    controlType: 'textbox'
  }),
  new DynamicFormBase({
    label: 'Registration Date',
    value: '',
    key: 'registrationDate',
    placeholder: 'Registration Date',
    disabled: true,
    required: true,
    controlType: 'date'
  }),
  new DynamicFormBase({
    label: 'Manufacturing  date',
    value: '',
    key: 'manufacturingDate',
    placeholder: 'Manufacturing  date',
    disabled: true,
    required: true,
    controlType: 'date'
  }),
  new DynamicFormBase({
    value: '',
    key: 'year',
    required: true,
    controlType: 'dropdown',
    label: 'Import Interval',
    placeholder: 'Import Interval',
    disabled: true,
    optionsByUrl: {
      service: 'AutoApi',
      url: 'api/v1/VehicleMakes/VehicleModelYears/{modelId}/{estimateId}'
    },
    optionKeys: {
      label: 'name',
      value: 'name'
    }
  }),
  new DynamicFormBase({
    value: '',
    key: 'vehicleType',
    required: true,
    controlType: 'dropdown',
    label: 'Body Type',
    placeholder: 'Body Type',
    disabled: true,
    optionsByUrl: {
      service: 'AutoApi',
      url: 'api/v1/VehicleMakes/bodytypes/{year}'
    },
    optionKeys: {
      label: 'name',
      value: 'id'
    }
  }),
  new DynamicFormBase({
    label: 'License Plate',
    value: '',
    key: 'licensePlate',
    placeholder: 'License Plate',
    required: true,
    disabled: true,
    controlType: 'textbox'
  })
]

@Injectable()
export class DynamicFormService implements OnDestroy {
  private envUrl = serviceURL;

  // eslint-disable-next-line require-jsdoc
  constructor(private http: HttpClient) {
  }


  /**
     * return form controls by sorting
     * @param controls 
     */
  getSortedFormControls(controls: any): DynamicFormBase<any>[] {
    const sortedArray = controls.sort((a, b) => a.order - b.order);
    const newFormJSON: DynamicFormBase<any>[] = [];
    sortedArray.forEach((control) => {
      const newControl = new DynamicFormBase(control);
      newFormJSON.push(newControl);
    });
    return newFormJSON;
  }

  /**
   * 
   * @param items 
   * @returns 
   */
  apiToValidationMapper = (items: any): Array<Validator> => {
    if (!items || items.length === 0) {
      return []
    }
    const mapped: Validator[] = items.filter(x => x.itemType === 'validations').map((vd) => {
      return {
        'type': vd.type,
        'message': vd.ItemValue || vd.Itemvalue,
        'name': vd.ItemKey || vd.Itemkey,
        'regex': vd.regex
      }
    })
    return mapped || [];
  }

  /**
   * 
   * @param items 
   * @returns 
   */
  apiToConditionMapper = (items: any): any[] => {
    if (!items || items.length === 0) {
      return []
    }
    const mapped = items.filter(x => x.itemType === 'conditions').map((vd) => {
      return {
        'type': vd.type,
        'message': vd.ItemValue,
        'name': vd.ItemKey,
        'controlKey': vd.controlKey,
        'operator': vd.operator,
        'conditionalValue': vd.conditionalValue

      }
    })
    return mapped || [];
  }

  /**
   * parse child controls
   */
  parseChildControls(value: any): DynamicFormBase<any>[] {
    const dynamicChildControls = [];
    value.forEach((val) => {
      dynamicChildControls.push(this.controlMapper(val));
    })
    return dynamicChildControls;
  }

  /**
   * parse default value
   */
  parseDefaultValue(val: string, contextObj: any): any {
    if (!contextObj) {
      return '';
    }
    if (!val) {
      return '';
    }
    let defaultValue = val;
    const conditions = val.match(/\[(.*?)\]/g);
    if (conditions && conditions.length > 0) {
      conditions.forEach((v) => {
        const defaultProp = v.replace('[', '').replace(']', '');
        if (defaultProp.includes('.')) {
          const props = defaultProp.split('.');
          defaultValue = defaultValue.replace(v, contextObj[props[0]][props[1]]);
        } else {
          defaultValue = defaultValue.replace(v, contextObj[defaultProp]);
        }
      });
    }
    return defaultValue;
  }

  /**
   * 
   * @param apiObj 
   */
  controlMapper(apiObj: any, contextObj?: any): DynamicFormBase<any> {
    const control: DynamicFormBase<any> = {
      controlType: apiObj.controlType,
      label: apiObj.ItemKey,
      placeholder: apiObj.placeholder,
      required: apiObj.required === 'true',
      disabled: apiObj.readonly === 'true',
      visible: apiObj.visible === 'true',
      maxLength: apiObj.maxLength,
      minLength: apiObj.minLength,
      minDate: new Date(apiObj.minDate),
      order: parseInt(apiObj.SortOrder),
      maxDate: new Date(apiObj.maxDate),
      visibleElement: apiObj.display,
      blockMaxLength: apiObj.blockMaxLength === 'true',
      prefillControls: apiObj.prefillControls ? JSON.parse(apiObj.prefillControls) : undefined,
      prefillMinLength: parseInt(apiObj.prefillMinLength),
      conditions: apiObj.controlType !== 'section' ? this.apiToConditionMapper(apiObj.Value) || [] : undefined,
      validators: apiObj.controlType !== 'section' ? this.apiToValidationMapper(apiObj.Value) || [] : undefined,
      childControls: apiObj.controlType === 'section' ? this.parseChildControls(apiObj.Value) : [] as DynamicFormBase<any>[],
      buttonAction: {
        linkUrl: apiObj.linkUrl,
        formSubmitService: apiObj.formSubmitService,
        formSubmitUrl: apiObj.formSubmitUrl,
        formSubmitMethod: apiObj.formSubmitMethod,
        actionType: apiObj.actionType,
        alertMessage: apiObj.alertMessage
      },
      optionsByUrl: {
        service: serviceURLMapper[apiObj.optionsService] || '',
        url: apiObj.optionsUrl || '',
        placeHolderUrl: apiObj.optionsUrl,
        preventExecutionByDefault: apiObj.optionsUrl?.includes('{')
      },
      optionKeys: {
        label: apiObj.optionsKeyLabel || '',
        value: apiObj.optionsKeyValue || ''
      },
      key: apiObj.fieldValue || apiObj.ItemKey,
      textPrefillValue: this.parseDefaultValue(apiObj.textPrefillValue, contextObj),
      defaultValue: this.parseDefaultValue(apiObj.defaultValue, contextObj),
      layout: {
        width: `${apiObj.width}%`,
        fullWidth: apiObj.fullWidth === 'true',
        maxWidth: `${apiObj.maxWidth}%`,
        minWidth: `${apiObj.minWidth}%`,
        alignment: apiObj.alignment
      },
      options: apiObj.options,
      icon: apiObj.icon
    };
    return control;
  }

  /**
    * return Validators array
    * @param control 
    */
  createValidators(control: any): any {
    const validators = [];
    if (control.required && control.visible) {
      validators.push(Validators.required);
    }
    if (control.validators) {
      control.validators.forEach((ctrl) => {
        if (ctrl.type === 'email') {
          validators.push(Validators.email);
        } else if (ctrl.type === 'pattern' || ctrl.type === 'regex') {
          validators.push(Validators.pattern(ctrl.regex));
        } else if (ctrl.type?.toLowerCase() === 'minlength') {
          validators.push(Validators.minLength(parseInt(control.minLength)));
        } else if (ctrl.type?.toLowerCase() === 'maxlength') {
          validators.push(Validators.maxLength(parseInt(control.maxLength)));
        } else if (ctrl.type === 'custom') {
          validators.push(ctrl.customFunction())
        }
      })
    }
    return validators
  }
  /**
   * generate form group
   * @param controls 
   * @returns 
   */
  createFormGroup(controls: DynamicFormBase<string>[]): FormGroup {
    const group: any = {};

    controls.forEach((control) => {
      const validators = this.createValidators(control)
      group[control.key] = new FormControl(control.value || control.defaultValue)
      group[control.key].setValidators(Validators.compose(validators))
    });
    return new FormGroup(group);
  }

  /**
   * generate form group
   * @param controls 
   * @returns 
   */
  addControlsInFormGroup(controls: DynamicFormBase<string>[], formGroup: FormGroup): void {
    controls.forEach((control) => {
      const validators = this.createValidators(control)
      const ctrl = new FormControl(control.value || control.defaultValue)
      ctrl.setValidators(Validators.compose(validators));
      formGroup.addControl(control.key, ctrl);
    });
  }

  private optionSource = new BehaviorSubject<any>(null);
  updatedOption = this.optionSource.asObservable();
  private addressSource = new BehaviorSubject<any>(null);
  updatedAddress = this.addressSource.asObservable();

  /**
    * return options
    * @param control 
    */
  broadcastOption(val: any): any {
    this.optionSource.next(val);
  }

  /**
   * get service url
   */
  callDynamicServiceUrl(formServiceUrl: string, formServiceType: string, obj: any, method: string): Observable<any> {
    const url = `${this.envUrl[formServiceType]}/${formServiceUrl}`;
    return this.http[method.toLowerCase()](url, obj);
  }

  /**
    * return address
    * @param control 
    */
  broadcastAddress(val: any): any {
    this.addressSource.next(val);
  }

  /**
   * ng on destroy
   */
  ngOnDestroy(): void {
    this.optionSource.complete();
    this.addressSource.complete();
  }
}