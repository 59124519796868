import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ApiPagination } from 'src/app/widgets/xa-paginator/xa-paginator.component';
import { environment } from 'src/environments/environment';
import { FileService } from '../file/file.service';
import * as dayjs from 'dayjs';

@Injectable({
  providedIn: 'root'
})
export class QuoteManagementService {
  caseDashboardFilters$ = new BehaviorSubject({});
  /**
   * @constructor
   * @param {HttpClient} http
   */
  constructor(private http: HttpClient, private fileService: FileService) { }

  /**
   * getClaimList
   * @returns Observable
   *
   * @param pagination
   * @param queryParams
   * @returns
   */
  getQuoteCaseList(pagination: ApiPagination, queryParams: string = null): Observable<any> {
    const skip = (pagination.currentPage - 1) * pagination.pageSize;
    queryParams = (!queryParams) ? `$top=${pagination.pageSize}&$skip=${skip}` : `$top=${pagination.pageSize}&$skip=${skip}&${queryParams}`
    const orderBy = `&$orderby=${pagination.orderBy} ${pagination.sortDirection}`
    const select = '$select=AssigneeId, AssigneeName, ClaimGuid, ClaimNo, CreatedDate, CustomerName, FnolId, Id, PolicyNo, Severity, Status, VehicleName, VehicleNumber, CreatedByUserName, IsAssignedBack, CreatedUserId&';
    const url = `${environment.fnolApiUrl}/odata/IQACase?$count=true&$expand=VehicleMetaData,DriverDetails&${queryParams}${orderBy}`;
    return this.http.get(url);
  }

  /**
   * getQuoteCaseListCount
   * @returns Observable
   *
   * @param queryParams
   * @returns
   */
  getQuoteCaseListCount(queryParams: string = null): Observable<any> {
    const url = `${environment.fnolApiUrl}/odata/IQACase/$count?${queryParams}`;
    return this.http.get(url);
  }

  /**
   * get case detail
   * @param guid 
   * @returns 
   */
  getCaseDetail(guid: string): Observable<any> {
    //https://app-platform-fnolmanagement-dev-001.azurewebsites.net/api/Iqacase/c2499f7f-1e4f-4d6a-b133-7378fbb1ff34/details
    const url = `${environment.fnolApiUrl}/api/Iqacase/${guid}/details`;
    return this.http.get<any>(url);
  }

  /**
   *
   * @param queryParams
   * @returns
   */
  getTotalCountClaim(queryParams: string = ''): Observable<any> {
    const url = `${environment.fnolApiUrl}/odata/Claim/$count?${queryParams}`;
    return this.http.get(url);
  }

  /**
   * get fraud detection and damage severity
   */
  updateFraudDetection(guid: string): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/IQACase/fraud-detection/${guid}`;
    return this.http.put(url, null);
  }

  /**
   * update fraud detection document
   * @param guid 
   * @returns 
   */
  updateFraudDetectionDocument(guid: string): Observable<any> {
    /*const url = `${environment.fnolApiUrl}/api/IQACase/document-fraud-detection/${guid}`;
    return this.http.put(url, null);*/
    return null;
  }

  /**
   * Save new Quote case
   * @returns Observable
   */
  public createQuoteCase(claimFormData: any): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Iqacase`;
    return this.http.post(url, claimFormData);
  }

  /**
   * get claim detail
   * @param id claim guid
   * @returns observable
   */
  public updateQuoteCase(payload: any, guid: string): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Iqacase/${guid}`;
    return this.http.put(url, payload);
  }

  /**
   * get fraud detection vehicle
   * @param guid
   * @param version
   * @param inspectionId
   * @returns
   */
  getFraudDetectionVehicleQuote(guid: string, version: string, inspectionId: number): Observable<any> {
    const url = `${environment.xaAggregationApi}/quoteinspectionfrauddetails/${guid}/${version}/${inspectionId}`;
    return this.http.get(url);
  }

  /**
   * get fraud detection pdf
   * @param params 
   * @returns 
   */
  getFraudDetectionPdfQuote(params: any): Observable<any> {
    const url = `${environment.communicationApi}/api/Report/fraud-detection`;
    return this.http.post(url, params);
  }

  /**
   * download fraud detection pdf
   * @param params 
   */
  downloadFraudDetectionPdf(params): void {
    const url = `${environment.communicationApi}/api/Report/fraud-detection`;

    this.http
      .post(url, params, { responseType: 'blob' as 'json' },)
      .subscribe({
        next: (res: any) => {
          const file = new Blob([ res ], { type: res.type });

          // IE
          const nav = (window.navigator as any);
          if (nav.msSaveOrOpenBlob) {
            nav.msSaveOrOpenBlob(file);
            return
          }

          const blob = window.URL.createObjectURL(file);
          const link = document.createElement('a');

          const createdDate = dayjs(new Date()).format('MMDDYYYY');
          link.href = blob;
          link.download = `Fraud_Detection_${createdDate}.pdf`;

          // Version link.click() to work at firefox
          link.dispatchEvent(
            new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              view: window
            })
          );

          setTimeout(() => {
            // firefox
            window.URL.revokeObjectURL(blob);
            link.remove();
          }, 100);
        },
        error: (error: any) => {
          console.log(error);
        }
      });
  }
    
 
  /**
   * send missing info
   * @param payload 
   * @returns 
   */
  public sendMissingInfo(payload: any): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Iqacase/sendMissingInformation`;
    return this.http.post(url, payload);
  }

  /**
   * getMissingInfo
   * @param id case guid
   * @returns observable
   */
  public getMissingInfo(caseGuid, domainId): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Iqacase/missing-information/${caseGuid}?domainId=${domainId}`;
    return this.http.get(url);
  }

  /**
   * send missing info
   * @param payload 
   * @returns 
   */
  public updateQuoteId(payload: any, caseid: any): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Iqacase/${caseid}/submit-quote`;
    return this.http.patch(url, payload);
  }

  /**
   * send missing info
   * @param payload 
   * @returns 
   */
  public updateCaseStatus(payload: any, caseGuid: any): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Iqacase/${caseGuid}/update-status`;
    return this.http.patch(url, payload);
  }

  /**
   * send missing info
   * @param payload 
   * @returns 
   */
  public getCreatedBy(payload): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/iqacase/createdby`;
    return this.http.post(url, payload);
  }

  /**
   * send missing info
   * @param payload 
   * @returns 
   */
  public getCustomers(payload): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/iqacase/customers`;
    return this.http.post(url, payload);
  }

  /**
   * getTilesData
   * @param payload 
   * @returns 
   */
  public getTilesData(payload): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Dashboard/case-dashboard`;
    return this.http.post(url, payload);
   
  }
  
  /**
   * getTilesData
   * @param payload 
   * @returns 
   */
  public getAvgCaseTotal(payload): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Dashboard/case-avg-total`;
    return this.http.post(url, payload);
   
  }

  /**
   * get ai correlation
   * @param guid 
   */
  public getAICorrelation(obj: any): Observable<any> {
    
    // const data1 = 
    //   [
    //     {
    //       'data': {
    //         'documents': {
    //           'items': [
    //             {
    //               'itemName': 'RegistrationCard',
    //               'make': {
    //                 'isMatch': true,
    //                 'actual': 'TOYOTA',
    //                 'result': 'TOYOTA'
    //               },
    //               'model': {
    //                 'isMatch': false,
    //                 'actual': 'BMW',
    //                 'result': 'TOYOTA PRADO'
    //               },
    //               'vin': {
    //                 'isMatch': false,
    //                 'actual': 'WBA8E3102HK792265',
    //                 'result': 'JTEJU9FJ8F5062072'
    //               },
    //               'plateNumber': {
    //                 'isMatch': false,
    //                 'actual': '1 / 66083',
    //                 'result': ''
    //               },
    //               'trim': null,
    //               'color': null,
    //               'odometer': null
    //             }
    //           ]
    //         }
    //       },
    //       'msg': 'AI Correlation details are loaded successfully',
    //       'message': 'AI Correlation details are loaded successfully',
    //       'success': true,
    //       'errorlst': null
    //     },
    //     {
    //       'inspectionDetail': {
    //         'items': [
    //           {
    //             'itemName': 'Rear Right',
    //             'make': {
    //               'isMatch': true,
    //               'actual': 'TOYOTA',
    //               'result': 'Toyota'
    //             },
    //             'model': {
    //               'isMatch': false,
    //               'actual': 'BMW',
    //               'result': 'Corolla'
    //             },
    //             'plateNumber': {
    //               'isMatch': false,
    //               'actual': '1 / 66083',
    //               'result': 'c-91641-dubai'
    //             },
    //             'trim': {
    //               'isMatch': false,
    //               'actual': '',
    //               'result': 'LE'
    //             },
    //             'color': {
    //               'isMatch': false,
    //               'actual': '',
    //               'result': 'Black'
    //             }
    //           }
    //         ],
    //         'additionalPhotos': [
    //           {
    //             'itemName': 'vin',
    //             'item': {
    //               'isMatch': false,
    //               'actual': 'WBA8E3102HK792265',
    //               'result': 'XAA8E3102HK792265'
    //             }
    //           },
    //           {
    //             'itemName': 'odometer',
    //             'item': {
    //               'isMatch': false,
    //               'actual': '4',
    //               'result': '3'
    //             }
    //           }
    //         ],
    //         'video': []
    //       }
    //     }
    //   ]
    
    //return of(data1);

    const url = `${environment.xaAggregationApi}/aicorrelationdetails/1`;
    const headers = new HttpHeaders({
      'objectId': obj.objectId,
      'make': obj.make,
      'model': obj.model,
      'vin': obj.vin,
      'plateNo': obj.plateNo,
      'domainId': obj.domainId,
      'inspectionId': obj.inspectionId,
      'vehicleId': obj.vehicleId
    });
    return this.http.get(url, { headers: headers })
  } 
}
