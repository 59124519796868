import { HttpHeaders } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { firstValueFrom } from 'rxjs';
import { DamageReport } from 'src/app/model/chq-damage-report';
import { UploadStepModel } from 'src/app/model/chq-upload-model';
import { ClaimsService } from 'src/app/services/claims/claims.service';
import { CommonService } from 'src/app/services/common/common.service';
import { InspectionService } from 'src/app/services/inspection/inspection.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { ChqPdfGeneratorComponent } from 'src/app/widgets/chq-pdf-generator/chq-pdf-generator.component';
import { damagePartMapper } from 'src/app/modules/quote/chq-new-cases/chq-new-customer/chq-new-customer-form-data-helper';
@Component({
  selector: 'third-party-common-layout',
  templateUrl: './common-layout.component.html',
  styleUrls: [ './common-layout.component.scss' ]
})
export class ThirdPartyCommonLayoutComponent {
  @ViewChild(ChqPdfGeneratorComponent) pdfElement: ChqPdfGeneratorComponent;
  public imageStatusSteps: UploadStepModel[] = [];
  public damageParts: DamageReport[] = [];
  public damageRecommendationList: any = [];
  damageReportData: any;
  public pdfImageSteps: UploadStepModel[] = [];
  public isClaimModule = true;

  private token: string = ''; 
  public claimGuid: string = '';
  public claimDetails: any= {};
  public domainId: number;
  public objectId: number;
  public idToken: string = '';
  public logoUrl: string = ''; 
  /**
   * constructor
   */
  constructor(
    private monitorService: MonitorService,
    private claimsService: ClaimsService,
    private route: ActivatedRoute,
    public commonService: CommonService,
    private inspectionService: InspectionService,
  ) { 
    this.token = this.route.snapshot?.queryParams['token'] || '';
    if(this.token != '') {
      const obj:any = atob(this.token);
      const parsedObj = JSON.parse(obj);
      this.claimGuid = parsedObj.ClaimGuid; 
    }
    let userDetails: any = localStorage.getItem('XAuserDetails');
    userDetails = JSON.parse(userDetails);
    this.idToken = userDetails?.idToken;
    this.getClaimDetails()
  }

  /**
  * on init 
  */
  ngOnInit(): void {
    this.monitorService.logEvent('ngOnInit', [ 'ThirdPartyCommonLayoutComponent', 'addenda-claim' ]); 
    const el: any = document.getElementById('custom-loader');
    if(el) {
      el.style.display = 'none';
    }
  }

  /**
  *getClaimDetails
  */
  getClaimDetails(): void {
    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${this.idToken}` );
    this.commonService.showLoading();
    firstValueFrom(this.claimsService.getClaimDetail(this.claimGuid, headers)).then((response) => {
      this.commonService.hideLoading();
      if (response) {
        this.claimDetails = response;
        this.domainId = response.domainId;
        this.objectId = response.id;
        this.logoUrl = response?.organizationDetail?.logoOfOrg
        this.commonService.thirdPartyClaimDetails.next(response);
      }
    }).catch((err) => {
      this.commonService.hideLoading();
      this.monitorService.logException(err, SeverityLevel.Error);
    });
  }

  /**
   * handleViewRepairDetails
   */
  handleViewRepairDetails(): void {
    // this.pdfElement.generatePDF();
    this.commonService.showLoading();
    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${this.idToken}` );
    this.inspectionService.getDamageReportPDFThirdParty(this.claimGuid, this.claimDetails.inspectionId, headers).subscribe({
      next: (resp: any) => {

        this.damageParts = resp[1].damageDetails;
        this.damageReportData = resp[1];
        this.damageRecommendationList = resp[1].damageRecommendations;
        this.getDamageStepsByObject();
        this.pdfImageSteps = this.imageStatusSteps.filter((s: UploadStepModel) => s.analysisStatus === 'Analysed' && s.url !== '' && s.url !== null &&
          s.inspectionItem);

        setTimeout(() => {
          this.pdfElement.generatePDF();
          this.checkPdfLoad();
        });
      },
      error: () => this.commonService.hideLoading()
    })
  }

  /**
   * inspection detail by id
   */
  getDamageStepsByObject(): void {
    const damageSteps = [];
    for (let i = 0; i < this.damageParts?.length; i++) {
      const templatePart = this.damageParts[i];
      const damageStep = damagePartMapper(templatePart, i);
      damageSteps.push({
        ...damageStep, mandatory: true,
        isSkipEnabled: true,
        isMassUpload: false,
        domainId: this.domainId, objectId: this.objectId,
        uploadedPartGuid: '',
        uploadedGuid: ''
      });
    }
    this.imageStatusSteps = [ ...damageSteps ];
  }

  /**
 * checkPdf load
 */
  checkPdfLoad(): void {
    setTimeout(() => {
      this.commonService.hideLoading();
    });
  }
}
