import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { ChqWidgetsInputModel } from 'src/app/model/chq-widgets-input-model';
import { validateEmails } from 'src/app/modules/quote/chq-new-cases/chq-new-customer/chq-new-customer-form-data-helper';
import { CommonService } from 'src/app/services/common/common.service';
import { CoreService } from 'src/app/services/core/core.service';
import { InspectionService } from 'src/app/services/inspection/inspection.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { ChqInputComponent } from 'src/app/widgets/chq-input/chq-input.component';
import { MediaCollectionService } from 'src/app/services/media-collection/media-collection.service';
import { TranslateService } from '@ngx-translate/core';

export interface EmailRequest{
  subject:string;
  email:string[];
  message:string;
  cc?:string;
  type?:string;
  objectId?:string;
  files?:any,
  domainId?:any
}

interface KeyValueType<T> {
  [k: string]: T;
}

export interface ApprovalForm extends KeyValueType<ChqWidgetsInputModel> {
  code: ChqWidgetsInputModel;
  phone: ChqWidgetsInputModel;
  email: ChqWidgetsInputModel;
  photos:ChqWidgetsInputModel;
  documents:ChqWidgetsInputModel;
  reportType:ChqWidgetsInputModel;
}

@Component({
  selector: 'request-approval-dialog',
  templateUrl: './request-approval-dialog.component.html',
  styleUrls: [ './request-approval-dialog.component.scss' ]
})
export class RequestApprovalDialogComponent implements OnInit {

  @ViewChild('code') code: ChqInputComponent;
  @ViewChild('phone') phone: ChqInputComponent;

  @ViewChild('uploadPhoto', { static: false }) uploadPhoto: ElementRef;
  @ViewChild('uploadDoc', { static: false }) uploadDoc: ElementRef;

  public imgAttachmentsBtnModel: ChqWidgetsButtonModel = {
    label: 'Add Attachment',
    type: 'outline',
    icon: 'attachment',
    onclick: ()=> {this.uploadPhoto.nativeElement.click()}
  }
  public docAttachmentsBtnModel: ChqWidgetsButtonModel = {
    label: 'Add Attachment',
    type: 'outline',
    icon: 'attachment',
    onclick: ()=> {this.uploadDoc.nativeElement.click()}
  }
  public sendButton: ChqWidgetsButtonModel;
  public emailRequest:any = {
    subject: '',
    email: [],
    message: '',
    phone: '',
    code: ''
  }
  estimateDocumentTypes:any[] = [];
  mediaData:any;
  comMediaDetail:any[] = [];
  comInspectionDetail:any;
  mediaCollectionId:number;
  mediaTemplateId:number;
  mediaPartTemplateId:number;
  isAdditionalImage = false;

  public availableDocumentsSlot = 0;
  public availablePhotosSlot = 0;

  readonly maxFileCount = 20;
  readonly maxBulkPhotosCount = 20;
  readonly maxSPIPhotosCount = 12;
  private currentFileCount = 0;

  // 30 MB file size limit
  readonly maxTotalFileSizeLimit = 31457280;
  private currentTotalFileSize = 0;

  private readonly allowedPhotoType = [ 'image/jpg', 'image/jpeg', 'image/png' ];
  private readonly allowedDocumentType = [ 'image/jpg', 'image/jpeg', 'image/png', 'application/pdf' ];
  public step:any = {};

  public emailForm: ApprovalForm = {
    subject: {
      placeHolder: 'subject',
      label: 'subject',
      name: 'subject',
      value: 0,
      type: 'text',
      validation: {
        name: 'subject',
        validationFunction: ():ValidatorFn => {
          return Validators.compose([ Validators.required, Validators.maxLength(100) ])
        },
        validationMessage: (error: ValidationErrors):string => {
          if (error['required']) {
            return 'send_message'
          }
          if (error['maxlength']) {
            return 'subject_length'
          }
          return '';
        }
      }
    },
    email: {
      label: 'email_address',
      name: 'email',
      placeHolder: 'email_address',
      value: '',
      type: 'text',
      required: true,
      validation: {
        name: 'email',
        validationFunction: ():ValidatorFn => {
          return Validators.compose([ Validators.required,
            validateEmails('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$') ])
        },
        validationMessage: (error: ValidationErrors):string => {
          if (error['required']) {
            return 'email_required'
          }

          if(error['invalidEmailNumber']){
            return 'email_invalid_number';
          }

          if (error['invalidEmail']) {
            return 'email_valid'
          }
          return '';
        }
      }
    },
    code: {
      placeHolder: 'select_dot',
      label: 'code',
      name: 'code',
      value: +91,
      showImageFlag: true,
      type: 'select',
      selectedOption: { 'phoneCountryCode': '+91' },
      displayValue: 'phoneCountryCode',
      fieldValue: 'phoneCountryCode',
      options: [ { label: '+90' }, { label: '+971' }, { label: '+977' } ],
      required: true,
      validation: {
        name: 'driverCode',
        validationFunction: ():ValidatorFn => {
          return Validators.compose([ Validators.required ])
        },
        validationMessage: (error: ValidationErrors):string => {
          if (error['required']) {
            return 'required_code'
          }
          return '';
        }
      }
    },
    phone: {
      placeHolder: 'mobile_number',
      label: 'mobile_number',
      name: 'phone',
      value: 0,
      type: 'number',
      required: true,
      validation: {
        name: 'driverPhone',
        validationFunction: ():ValidatorFn => {
          return Validators.compose([ Validators.required, Validators.pattern('^[0-9]+[0-9 ,.\'-]+$') ])
        },
        validationMessage: (error: ValidationErrors):string => {
          if (error['required']) {
            return 'required_mobile_number'
          }
          if (error['pattern']) {
            return 'valid_mobile_number'
          }
          return '';
        }
      }
    },
    photos: {
      placeHolder: 'Image Selection',
      name: 'photos',
      label: 'PHOTO ATTACHMENTS',
      value: '',
      type: 'select',
      displayValue: 'name',
      fieldValue: 'blobUrl',
      options: [],
      isMultiSelect: true,
      selectedOption: []
    },
    documents: {
      placeHolder: 'Document Selection',
      name: 'documents',
      label: 'DOCUMENT ATTACHMENTS',
      value: '',
      type: 'select',
      displayValue: 'name',
      fieldValue: 'blobUrl',
      options: [],
      isMultiSelect: true,
      selectedOption: []
    },
    reportType: {
      placeHolder: 'Select Report',
      name: 'reportType',
      label: 'ESTIMATE REPORT',
      value: '',
      type: 'select',
      displayValue: 'value',
      required: true,
      fieldValue: 'key',
      options: [],
      selectedOption: null
    }
  }

  selectedCode: string = '';
  additionalPhotos:any[] = [];
  additionalDocs:any[] = [];
  matchinginspectionItems: any[] = [];
  matchingMediaItems: any[] = [];
  bulkTemplateId:number;

  private readonly uploadSizeLimit = 10485760;


  /**
   * constructor
   * @param dialogRef
   * @param data
   */
  constructor(public dialogRef: MatDialogRef<RequestApprovalDialogComponent>,
      private monitorService:MonitorService,
      private coreService: CoreService,
      private commonService: CommonService,
      private inspectionService:InspectionService,
      private mediaCollectionService:MediaCollectionService,
      private translationService: TranslateService,
      @Inject(MAT_DIALOG_DATA) public data: any) {
    this.estimateDocumentTypes = this.data?.estimateDocumentTypes?.length > 0 ? this.data?.estimateDocumentTypes: [];
    this.emailForm['reportType'] = { ...this.emailForm['reportType'], options: [ ...this.estimateDocumentTypes ] }
    this.mediaData = this.data?.mediaData;
    if(this.data.estimateType === 'GTMotive' && this.estimateDocumentTypes.length > 0) {
      this.emailForm['reportType'] = { ...this.emailForm['reportType'], selectedOption: this.estimateDocumentTypes[0], disabled: true }
    }

    this.bulkTemplateId = this.mediaData[3].inspectionItems.find(obj=> obj.procedureSteps === 'Bulk Upload Image')?.inspectionItemTemplateID;

    this.mediaData[2].data?.medias?.forEach((el)=> {
      this.comMediaDetail.push(...el.mediaParts.filter(fl=> fl.fileSize > 0).map((m)=> {
        return { blobUrl: m.getURL, name: m.name, imageSize: m.fileSize, }
      }))
    })
    this.comInspectionDetail = this.mediaData[3].inspectionItems.filter(el=> el.imageSize !== 0).map((m)=> {
      return { blobUrl: m.rawAzureBlobUrl, name: m.name, imageSize: m.imageSize }
    })


    // Get inspectionitemId
    if(this.mediaData[3].uploadType === 'Spi') {
      this.matchinginspectionItems = this.mediaData[3].inspectionItems.filter(obj => obj.name.startsWith('Additional photo') && obj.rawAzureBlobUrl === '');
    } else {
      this.matchinginspectionItems = this.mediaData[3].inspectionItems.filter(obj => (obj.procedureSteps === 'Bulk Upload Image' && obj.rawAzureBlobUrl !== ''));
    }
    this.isAdditionalImage = this.mediaData[3].uploadType === 'Spi';

    // Get Doc info
    const additionalMedia = this.mediaData[2]?.data?.medias[2];
    if(additionalMedia) this.mediaTemplateId = additionalMedia?.mediaTemplateGuid;

    this.matchingMediaItems = additionalMedia?.mediaParts.filter(obj=>obj.fileSize === 0);

    // Set Available slots for Documents and Photos
    this.availableDocumentsSlot = this.matchingMediaItems?.length;
    this.availablePhotosSlot = this.mediaData[3].uploadType === 'Spi' ? this.matchinginspectionItems.length : this.maxBulkPhotosCount - this.matchinginspectionItems.length;


    this.emailForm.photos = { ...this.emailForm.photos, options: [ ...this.comInspectionDetail ] }
    this.emailForm.documents = { ...this.emailForm.documents, options: [ ...this.comMediaDetail ] }
  }
  /**
   * File Validations
   */
  isAttachmentValid():boolean {
    this.currentFileCount = 0;
    this.currentTotalFileSize = 0;

    this.currentFileCount = this.emailForm.photos.selectedOption.length + this.emailForm.documents.selectedOption.length + this.additionalPhotos.length + this.additionalDocs.length

    this.emailForm.photos.selectedOption.forEach((el)=> {
      this.currentTotalFileSize += el.imageSize;
    })
    this.additionalPhotos.forEach((el)=> {
      this.currentTotalFileSize += el.size;
    })
    this.emailForm.documents.selectedOption.forEach((el)=> {
      this.currentTotalFileSize += el.imageSize;
    })
    this.additionalDocs.forEach((el)=> {
      this.currentTotalFileSize += el.size;
    })

    if(this.currentFileCount > this.maxFileCount) {
      this.commonService.openConfirmationSingleOkDialog('Oops! You exceeded the total maximum file count', 'Please remove some files to continue adding more attachments');
    }
    if(this.currentTotalFileSize > this.maxTotalFileSizeLimit) {
      this.commonService.openConfirmationSingleOkDialog('Oops! You exceeded the total maximum file size', 'Please remove some files to continue adding more attachments');
    }
    if((this.emailForm.photos.selectedOption.length > 0 || this.emailForm.documents.selectedOption.length > 0) && (this.emailRequest.email && this.emailForm['reportType'].selectedOption)) {
      if(this.currentFileCount <= this.maxFileCount && this.currentTotalFileSize <= this.maxTotalFileSizeLimit) {
        this.sendButton = { ...this.sendButton, type: 'primary' };
      } else {
        this.sendButton = { ...this.sendButton, type: 'disabled' };
      }
    }
    return this.currentFileCount <= this.maxFileCount && this.currentTotalFileSize <= this.maxTotalFileSizeLimit
  }
  /**
   * upload Photos
   */
  uploadBulkPhotos(files:any): Promise<any> {

    let uploadCount = 0;

    return new Promise((resolve, reject)=> {
      if(files?.length > 0) {
        const uploadedFiles:any[] = [];
        for (let i = 0; i < files?.length; i++) {
          const reader = new FileReader();
          reader.readAsDataURL(files[i]);
          reader.onload = (e):void => {
            const img = new Image();
            img.src = e.target.result.toString();
            img.onload = ():void=> {
              const obj = {
                'height': img.height,
                'width': img.width,
                'inspectionId': this.data.inspectionId,
                'inspectionitemId': this.matchinginspectionItems[i]?.id,
                'templateId': this.isAdditionalImage ?this.matchinginspectionItems[i]?.inspectionItemTemplateID: this.bulkTemplateId,
                'isBulk': !this.isAdditionalImage,
              }
              this.inspectionService.uploadImageBulk(obj, files[i]).subscribe({ next: (res)=> {
                uploadedFiles.push({ name: `${res.name}_${new Date().getTime()}`, blobUrl: res.rawImage.rawAzureBlobUrl, imageSize: res.rawImage.imageSize });
                uploadCount++;
                if(uploadCount === files.length) {
                  resolve(uploadedFiles);
                }
              }, error: (err)=> {
                reject(err);
              } })
            }
          }
        }
      }
    })
  }
  /**
   * upload Docs
   */
  uploadDocs(files:any): Promise<any> {
    let uploadCount = 0;
    return new Promise((resolve, reject)=> {
      if(files?.length > 0) {
        const uploadedFiles:any[] = [];
        for (let i = 0; i < files?.length; i++) {
          const obj = {
            'mediaCollectionId': this.data.mediaCollectionGuid,
            'domainId': this.mediaData[0]?.data.domainId || this.mediaData[0]?.data.domainID,
            'objectId': this.mediaData[0]?.data.id,
            'mediaTemplateId': this.mediaData[2]?.data?.medias[2].mediaTemplateGuid,
            'mediaPartTemplateId': this.matchingMediaItems[i]?.mediaPartTemplateGuid,
          }
          this.mediaCollectionService.uploadDocument(files[i], obj).subscribe({ next: (res)=> {
            uploadedFiles.push({ name: `${files[i].name}_${new Date().getTime()}`, blobUrl: res.data.blobUrl, imageSize: files[i].size });
            uploadCount++;
            if(uploadCount === files.length) {
              resolve(uploadedFiles);
            }
          }, error: (err)=> {
            reject(err);
          } })
        }
      }
    })
  }

  /**
   * on data update
   */
  onFormUpdate(output):void{
    if(output.isValid){
      this.emailRequest[output.name] = output.name === 'email'? (output.value?.split(',') || []) : output.value;
    }else{
      this.emailRequest[output.name] = false;
    }

    if(output.name === 'photos') {
      this.emailForm.photos = { ...this.emailForm.photos, selectedOption: output?.value ? [ ...output.value ]: null }
      if(!this.isAttachmentValid()) {
        this.emailForm.photos.selectedOption.splice(-1, 1)
      }
    }
    if(output.name === 'documents') {
      this.emailForm.documents = { ...this.emailForm.documents, selectedOption: output?.value ? [ ...output.value ]: null }
      if(!this.isAttachmentValid()) {
        this.emailForm.documents.selectedOption.splice(-1, 1)
      }
    }
    if(output.name === 'reportType') {
      this.emailForm['reportType'] = { ...this.emailForm['reportType'], selectedOption: output?.value ?{ ...output?.value }:null }
    }
    if (output.name === 'code') {
      if(this.selectedCode !== output.value.phoneCountryCode) {
        const validationPattern = output.value['regExpression'];
        this.phone?.updateCurrentValidation(validationPattern);
        this.selectedCode = output.value.phoneCountryCode;
        this.emailForm.code = { ...this.emailForm.code, selectedOption: output.value };
        this.emailForm.phone.value = '';
      }

    }

    // Checks for validations based on channels -> EMAIL, WHATSAPP, SMS
    this.checkFieldValidation();
  }


  /**
   * handleUpload
   */
  handleUpload(event:any, type:string):void {
    const currentFile = event.target.files || event.srcElement.files || event.dataTransfer.files;
    if(currentFile.length === 0) {
      return;
    }
    // Check Size limit;
    if(currentFile[0]?.size > this.uploadSizeLimit) {
      this.commonService.openConfirmationSingleOkDialog('Oops! You exceeded the total maximum file size', 'Please remove some files to continue adding more attachments');
      return;
    }

    if(type === 'photo' && currentFile?.length > 0) {
      // check if still emppty
      if(!this.matchinginspectionItems) {
        this.commonService.openConfirmationSingleOkDialog('Oops! Maximum limit reached for additional photos attachments', 'Please remove some files to continue adding more attachments');
        return;
      }

      if(!this.allowedPhotoType.includes(currentFile[0].type)) {
        this.commonService.openConfirmationSingleOkDialog('Oops! Invalid file type', 'Please select a JPG, JPEG, or PNG file.');
        return;
      }
      if(this.additionalPhotos.length >= this.availablePhotosSlot) {
        this.commonService.openConfirmationSingleOkDialog('Oops! Maximum limit reached for additional photos attachments', 'Please remove some files to continue adding more attachments');
        return;
      }
      this.additionalPhotos.push(...currentFile)
      if(!this.isAttachmentValid()){
        this.additionalPhotos.splice(-1, 1);
      }
    }
    if(type === 'document' && currentFile?.length > 0) {
      // check if still emppty
      if(!this.matchingMediaItems) {
        this.commonService.openConfirmationSingleOkDialog('Oops! Maximum limit reached for additional document attachments', 'Please remove some files to continue adding more attachments');
        return;
      }
      if(this.additionalDocs.length >= this.availableDocumentsSlot) {
        this.commonService.openConfirmationSingleOkDialog('Oops! Maximum limit reached for additional document attachments', 'Please remove some files to continue adding more attachments');
        return;
      }
      if(!this.allowedDocumentType.includes(currentFile[0].type)) {
        this.commonService.openConfirmationSingleOkDialog('Oops! Invalid file type', 'Please select a JPG, JPEG, PNG, or PDF file.');
        return;
      }
      this.additionalDocs.push(...currentFile)
      if(!this.isAttachmentValid()){
        this.additionalDocs.splice(-1, 1);
      }
    }

  }

  /**
   * handle close
   */
  cancel():void{
    this.monitorService.logEvent('send', [ 'EmailDialogComponent', 'addenda' ]);
    this.dialogRef.close();
  }

  /**
   * handle send
   */
  async send():Promise<any>{
    this.monitorService.logEvent('send', [ 'EmailDialogComponent', 'addenda' ]);
    if(this.data.channel === 'send_to_insurance_company'){
      const dialogRef = this.commonService.openConfirmYesNoDialog('', this.translationService.instant('do_you_want_to_send_the_estimate_to_insurer_for_approval'))
      return new Promise((resolve, reject) =>{
        dialogRef.afterClosed().subscribe((response: boolean) => {
          if(response) {
            this.commonService.showLoading();
            this.inspectionService.sendToInsurer(this.data.repairGuid, this.emailForm['reportType']?.selectedOption?.key).subscribe({
              next: (data)=>{
                if(data.success){
                  this.commonService.showInfoToast(5000, data.message ? data.message : data.data);
                }
                this.commonService.hideLoading();
                this.dialogRef.close(false);
                resolve('');
              }
            })
          }else{
            //reject(null);
          }
        })
      })
    }
    this.commonService.showLoading();
    const uploadedImg:any[] =this.additionalPhotos.length > 0 ? await this.uploadBulkPhotos(this.additionalPhotos): [];
    const uploadedDoc:any[] =this.additionalDocs.length > 0 ? await this.uploadDocs(this.additionalDocs): [];
    this.commonService.hideLoading();
    let obj;
    if(this.data.channel === 'email') {
      const photoAttachments = this.emailForm.photos.selectedOption.map((el, index)=> {
        return { ...el, name: el.name + index }
      })
      if(uploadedImg.length > 0) {
        photoAttachments.push(...uploadedImg)
      }
      const documentAttachments = this.emailForm.documents.selectedOption.map((el, index)=> {
        return { ...el, name: el.name + index }
      })
      if(uploadedDoc.length > 0) {
        documentAttachments.push(...uploadedDoc)
      }
      obj = {
        'toEmails': this.emailRequest.email,
        'communicationTypeIds': [ 2 ],
        'templateKey': this.data.templateKey,
        'mediaDocumentAttachments': [
          {
            'mediaDocumentType': 'Photos',
            'attachments': photoAttachments
          },
          {
            'mediaDocumentType': 'Documents',
            'attachments': documentAttachments
          },
          {
            'mediaDocumentType': 'Reports',
            'attachments': this.emailForm['reportType']?.selectedOption?.value ? [
              {
                'name': this.emailForm['reportType']?.selectedOption?.label,
                'type': this.emailForm['reportType']?.selectedOption?.key,
              }
            ] : []
          }
        ]
      }
    } else if(this.data.channel === 'whatsapp') {
      obj = {
        'messageContent': btoa(this.data.body),
        'PhoneNumber': `${this.emailForm.code.selectedOption.phoneCountryCode}${this.emailRequest.phone}`,
        'communicationTypeIds': [ 1 ],
        'templateKey': this.data.templateKey,
        'mediaDocumentAttachments': [
          {
            'mediaDocumentType': 'Reports',
            'attachments': this.emailForm['reportType']?.selectedOption?.value ? [
              {
                'name': this.emailForm['reportType']?.selectedOption?.label,
                'type': this.emailForm['reportType']?.selectedOption?.key,
              }
            ] : []
          }
        ]
      }
    } else {
      obj = {
        messageContent: btoa(this.data.body),
        PhoneNumber: `${this.emailForm.code.selectedOption.phoneCountryCode}${this.emailRequest.phone}`,
        communicationTypeIds: [ 3 ],
        templateKey: this.data.templateKey,
        mediaDocumentAttachments: [
          {
            'mediaDocumentType': 'Reports',
            'attachments': this.emailForm['reportType']?.selectedOption?.value ? [
              {
                'name': this.emailForm['reportType']?.selectedOption?.label,
                'type': this.emailForm['reportType']?.selectedOption?.key,
              }
            ] : []
          }
        ]
      }
    }
    this.dialogRef.close(obj);
  }

  /**
   * set message
   */
  setMessageHTML(value:string):void{
    this.emailRequest.message = btoa(value);
  }

  /**
   * Remove file
   */
  removeFile(index:number, type:string):void{
    if(type === 'photo') {
      this.emailForm.photos.selectedOption.splice(index, 1)
    }
    if(type === 'additionalPhotos') {
      this.additionalPhotos.splice(index, 1)
    }
    if(type === 'document') {
      this.emailForm.documents.selectedOption.splice(index, 1)
    }
    if(type === 'additionalDocs') {
      this.additionalDocs.splice(index, 1)
    }
    this.isAttachmentValid();
  }

  /**
   * ng on init
   */
  ngOnInit(): void {
    this.monitorService.logEvent('ngOnInit', [ 'EmailDialogComponent', 'addenda' ]);

    if (!this.emailForm.code?.observable) {
      this.emailForm.code.observable = this.coreService.getPhoneNumber();
      this.emailForm.code.onObservableLoad = this.onObservableLoad.bind(this);
    }

    if(this.data.email){
      this.emailForm.email = { ...this.emailForm.email, value: this.data.email };
      this.emailRequest = { ...this.emailRequest, email: [ this.data.email ] }
    }

    this.sendButton = {
      label: 'send',
      type: 'disabled',
      icon: 'send',
      onclick: this.send.bind(this)
    }

    this.emailForm.code.selectedOption[this.emailForm.code.displayValue] = this.data.code;
    this.emailForm.phone = { ...this.emailForm.phone, value: this.data.phone };

    this.checkFieldValidation();

  }

  /**
   * check Field Validation
   */
  checkFieldValidation():void {
    if(this.data.channel === 'email') {
      if(this.estimateDocumentTypes?.length > 0) {
        if(this.emailRequest.email && this.emailForm['reportType'].selectedOption){
          this.sendButton = { ...this.sendButton, type: 'primary' };
        }else{
          this.sendButton = { ...this.sendButton, type: 'disabled' };
        }
      } else {
        if(this.emailRequest.email){
          this.sendButton = { ...this.sendButton, type: 'primary' };
        }else{
          this.sendButton = { ...this.sendButton, type: 'disabled' };
        }
      }

    } else if(this.data.channel === 'whatsapp') {
      if(this.estimateDocumentTypes?.length > 0) {
        if(this.emailRequest.phone && this.emailForm.code.selectedOption && this.emailForm['reportType'].selectedOption){
          this.sendButton = { ...this.sendButton, type: 'primary' };
        }else{
          this.sendButton = { ...this.sendButton, type: 'disabled' };
        }
      } else {
        if(this.emailRequest.phone && this.emailForm.code.selectedOption){
          this.sendButton = { ...this.sendButton, type: 'primary' };
        }else{
          this.sendButton = { ...this.sendButton, type: 'disabled' };
        }
      }

    } else if(this.data.channel === 'send_to_insurance_company') {
      if(this.emailForm['reportType'].selectedOption) {
        this.sendButton = { ...this.sendButton, type: 'primary' };
      }else{
        this.sendButton = { ...this.sendButton, type: 'disabled' };
      }

    } else {
      if(this.emailRequest.phone && this.emailForm.code.selectedOption){
        this.sendButton = { ...this.sendButton, type: 'primary' };
      }else{
        this.sendButton = { ...this.sendButton, type: 'disabled' };
      }
    }
  }

  /**
   * on dropdown observable load
   */
  onObservableLoad(items: any): void {
    this.commonService.hideLoading();

    if (!items) {
      return;
    }
    if (items && items.length > 0) {
      const selectedItem = items.filter((it: any) => {
        return it.phoneCountryCode === this.emailForm.code.selectedOption?.phoneCountryCode;
      });
      if (selectedItem.length > 0) {
        const output = selectedItem[0];
        const validationPattern = output['regExpression'];
        this.emailForm.code = { ...this.emailForm.code, selectedOption: output };
        if (this.phone) {
          this.phone.updateCurrentValidation(validationPattern, true);
        }
        // if (!this.repairId) {
        //   this.setPrefillData(items);
        // }
      }
    }
  }

}

