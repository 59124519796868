import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: [ './info-dialog.component.scss' ]
})
export class InfoDialogComponent {
  /**
      Constructor
      @constructor
      @param {any} data - Data passed to the component.
      @param {MatDialogRef} dialogRef - Reference to the dialog box.
      @param {MonitorService} monitorService - Service for monitoring operations.
    */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<InfoDialogComponent>,
  ) { }

  /**
     Closes the dialog.
   */
  cancelDialog(): void {
    this.dialogRef.close(false);
  }
}
