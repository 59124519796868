import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, filter } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FileService } from '../file/file.service';

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  /**
   * constructor
   * @param http 
   */
  constructor(
    private http: HttpClient,
    private fileService: FileService
  ) {
  }

  /**
   * get phone number
   * @returns Observable
   */
  public getPhoneNumber(): Observable<any> {
    const url = `${environment.autoCoreApi}/api/Countries`;
    return this.http.get<any>(url);
  }

  /**
   * verify otp
   */
  public generateOTP(phoneNumber: string): Observable<any> {
    const url = `${environment.autoCoreApi}/api/Verify/generate-otp`;
    const headers = new HttpHeaders().append('AppKey', 'AddendaQuote');
    return this.http.post<any>(url, { phoneNumber, 'channel': 'sms', }, { headers });
  }

  /**
   * verify otp
   * @returns Observable
   */
  public verifyOTP(body: any): Observable<any> {
    const url = `${environment.autoCoreApi}/api/users/Mobile`;
    const headers = new HttpHeaders().append('mobilenumber', body.value)
      .append('value', body.value)
      .append('otpCode', body.otpCode)
      .append('AppKey', 'AddendaQuote')

    return this.http.put<any>(url, body, { headers });
  }

  /**
   * get place detail
   */
  getPlaceDetails(placeId: string, placeService:any): Promise<google.maps.places.PlaceResult> {
    return new Promise((resolve, reject) => {
      const request: google.maps.places.PlaceDetailsRequest = { placeId };
      placeService.getDetails(request, (result, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && result) {
          resolve(result);
        } else {
          reject(`Place Details failed: ${status}`);
        }
      });
    });
  }
  /**
   * get lat long from text
   */
  public getLatLongFromText(text: string, callback:(param:any)=>void):void {
    const request: google.maps.places.TextSearchRequest = {
      query: text,
    };
    const div = document.createElement('div');
    const placeService = new google.maps.places.PlacesService(div);
    placeService.textSearch(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && results) {
        if(results && results.length > 0){
          this.getPlaceDetails(results[0].place_id, placeService).then((place) => {
            if(callback){
              callback(place);
            }
          })
        }
      } 
    });
  }

  /**
   * Generate email OTP
   */
  public generateEmailOTP(emailId: string): Observable<any> {
    const url = `${environment.autoCoreApi}/api/Verify/generate-email-otp`;
    const headers = new HttpHeaders()
      .set('isExternalToken', 'true')
    const payload = { emailId, 'channel': 'email', }
    return this.http.post<any>(url, payload, { headers });
  }

  /**
   * Generate email OTP
   */
  public generateSmsOrWhatsappOTP(phoneNumber: string): Observable<any> {
    const url = `${environment.autoCoreApi}/api/Verify/generate-otp`;
    const headers = new HttpHeaders()
      .set('isExternalToken', 'true')
    const payload = { phoneNumber, 'channel': 'sms', }
    return this.http.post<any>(url, payload, { headers });
  }

  /**
   * Generate email OTP
   */
  public generateTokenFromOTP(payload: any): Observable<any> {

    const url = `${environment.autoCoreApi}/api/Users/token`;
    const headers = new HttpHeaders()
      .set('userIdType', payload.userIdType)
      .set('otpCode', payload.otpCode)
      .set('isExternalToken', payload.isExternalToken)
      .set('emailorPhoneNumber', payload.emailorPhoneNumber)
    return this.http.get<any>(url, { headers: headers });
  }

  /**
   * Generate email OTP
   */
  public generateRefreshToken(payload: any): Observable<any> {

    const url = `${environment.autoCoreApi}/api/Token/refresh-token`;
    const headers = new HttpHeaders()
      .set('isExternalToken', 'true')
    return this.http.post<any>(url, payload, { headers });
  }

  /**
   * update user number
   */
  updateUserPhoneNumber(userBody: any): Observable<any> {
    const url = `${environment.autoCoreApi}/api/Users`;
    return this.http.put<any>(url, userBody);
  }

  /**
   * get user profile
   * @returns 
   */
  getUserProfile(): Observable<any> {
    return this.http.get(`${environment.autoCoreApi}/api/Users/user-Details`);
  }

  /**
   * upload user profile
   * @returns 
   */
  uploadProfileImage(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('userProfileImage', file);
    const url = `${environment.autoCoreApi}/api/Users/upload-profile-image`;
    return this.fileService.uploadFile(url, formData);
  }

  /**
   * delete user profile
   * @returns 
   */
  deleteUserProfile(): Observable<any> {
    return this.http.delete(`${environment.autoCoreApi}/api/Users/delete-profile-image`);
  }

  /**
   * clear token
   * @returns 
   */
  clearToken(): Observable<any> {
    return this.http.post(`${environment.autoCoreApi}/api/Token/clear`, {});
  }

  /**
   * get case history
   * @param objectId
   * @param domainId
   */
  public getHistory(objectId: number, domainId: number): Observable<any> {
    const url = `${environment.autoCoreApi}/api/history/${objectId}/${domainId}`;
    return this.http.get<any>(url);
  }

  /**
   * get fnol history
   */
  public getFNOLHistory($filter:string): Observable<any> {
    const filterQuery = $filter ? `?filter=${$filter}`: '';
    const url = `${environment.autoCoreApi}/odata/history${filterQuery}`;
    return this.http.get<any>(url);
  }

}