import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SelectionChangedEvent } from 'devextreme/ui/gallery';
import { ImgDialogComponent } from 'src/app/dialogs/img-dialog/img-dialog.component';
@Component({
  selector: 'ai-estimate-repair-recommendation',
  templateUrl: './ai-estimate-repair-recommendation.component.html',
  styleUrls: [ './ai-estimate-repair-recommendation.component.scss' ]
})
export class AiEstimateRepairRecommendationComponent {
  @Input() isAiGeneratedEstimate: boolean;
  @Input() damageRecommendations: any;
  @Input() isDamageRecommendationsLoading: boolean;
  @Output() handleImageChange: EventEmitter<any> = new EventEmitter();

  /**
   * constructor
   * @param router
   */
  constructor(private dialog: MatDialog) { }

  /**
      handleSelectionChange
  */
  handleSelectionChange(e: SelectionChangedEvent): void {
    this.handleImageChange.emit({
      partIds: e.addedItems[0]?.damageDetails?.map(ele => ele.partId)
    })
  }

  /**
   * open image dialog
   */
  imageDialog(state: string, url: string | ArrayBuffer, view: string): void {
    const dialogRef = this.dialog.open(ImgDialogComponent, {
      data: {
        state,
        title: view,
        image: url
      }, autoFocus: false
    });
    dialogRef.afterClosed().subscribe();
  }

}
