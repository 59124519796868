import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MonitorService } from 'src/app/services/monitor/monitor.service';

@Component({
  selector: 'driver-confirm-dialog',
  templateUrl: './driver-confirm-dialog.component.html',
  styleUrls: [ './driver-confirm-dialog.component.scss' ]
})
export class DriverConfirmDialogComponent {

  /**
   * constructor
   * @param dialogRef
   * @param data
   */
  titleContainsHtml: boolean = false;
  messageContainsHtml: boolean = false;
  htmlTags = [ '<span>', '<b>', '<div>', '<h1>', '<h2>', '<h3>', '<h4>', '<h5>', '<a>', '<p>', '<br />', '<mat-icon>', '</a>' ];

  /**
   * constructor
   */
  constructor(
    public dialogRef: MatDialogRef<DriverConfirmDialogComponent>,
    private monitorService: MonitorService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    for(const key in this.htmlTags){
      if(!this.titleContainsHtml && data.title.indexOf(this.htmlTags[key]) !== -1){
        this.titleContainsHtml = true;
      }

      if(!this.messageContainsHtml && data.message.indexOf(this.htmlTags[key]) !== -1){
        this.messageContainsHtml = true;
      }
    }
  }

  /**
   * close dialog
   */
  close(vehicle:any): void {
    this.dialogRef.close(vehicle);
    this.monitorService.logEvent('close', [ 'ConfirmDialogComponent', 'addenda-quote' ]);
  }

}
