import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CLAIM_STATUS } from 'src/app/config/constants/claims.constants';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { ChqWidgetsHeaderModel } from 'src/app/model/chq-widgets-header-model';
import { CalendarService } from 'src/app/services/calendar/calendar.service';
import { CaseService } from 'src/app/services/case/case.service';
import { ClaimsService } from 'src/app/services/claims/claims.service';
import { CommonService } from 'src/app/services/common/common.service';
import { InspectionService } from 'src/app/services/inspection/inspection.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { RepairEstimateService } from 'src/app/services/repair-estimate/repair-estimate.service';

const photosHeader: ChqWidgetsHeaderModel = {
  title: 'add_damage_photos',
  step: 4,
  description: 'damage_photos_msg',
  stepName: 'step_four',
};
@Component({
  selector: 'add-photos',
  templateUrl: './add-photos.component.html',
  styleUrls: [ './add-photos.component.scss' ]
})
export class AddPhotosComponent implements OnChanges, OnInit {
  @Output() mode: EventEmitter<{ 'mode': string }> = new EventEmitter();
  @Input() caseNumber: string;
  @Input() caseData: any;
  @Input() showNotify: boolean;
  @Input() showComplete: boolean;
  @Input() showEstimate: boolean = false;
  @Input() repairUnapproved: boolean;
  @Input() repairData: any;
  @Input() claimData: any;
  @Output() notify: EventEmitter<any> = new EventEmitter();
  @Output() completeRepair: EventEmitter<any> = new EventEmitter();
  @Output() nextEvent: EventEmitter<any> = new EventEmitter();

  public nextButton: ChqWidgetsButtonModel = {
    label: 'Next create estimate',
    type: 'primary',
    color: 'primary',
    icon: 'boardview'
  }
  @Input() photosHeader: ChqWidgetsHeaderModel = photosHeader;

  @Input() activateRepairButton: boolean;
  @Input() showSPI: boolean;
  @Input() hideBulk: boolean;
  @Input() isRepairComplete: boolean;
  @Input() hideEstimate: boolean;

  userPermission: any;
  showSubmitForReview: boolean;
  showSubmitForApproval: boolean;
  showAssignBackButton: boolean;
  fromRepairerView: boolean = false;

  public notifyButtonModel: ChqWidgetsButtonModel = {
    label: 'notify',
    type: 'outline',
    icon: 'send',
    onclick: () => {
      this.notify.emit()
    }
  }

  public addPartButtonModel: ChqWidgetsButtonModel = {
    label: 'Complete Repair',
    type: 'disabled',
    onclick: () => {
      this.completeRepair.emit()
    }
  }

  public submitForReviewModel: ChqWidgetsButtonModel = {
    label: 'Submit for review',
    type: 'primary',
    onclick: this.submissionForReviewClaim.bind(this)
  }

  public submitForApprovalButtonModel: ChqWidgetsButtonModel = {
    label: 'Submit for Approval',
    type: 'primary',
    onclick: this.submitForApproval.bind(this)
  }

  public assignBackApprovalButtonModel: ChqWidgetsButtonModel = {
    label: 'Assign Back',
    type: 'outline',
    icon: 'save-checked',
    onclick: this.assignBack.bind(this)
  }

  public navigateToGarageButton: ChqWidgetsButtonModel = {
    label: 'go_to_garages',
    type: 'primary',
    onclick: this.navigateToGarage.bind(this)
  }

  /**
   * initialize single Upload
   */
  allowSingleUpload(): void {
    this.mode.emit({ 'mode': 'single' })
  }

  /**
   * show delete
   */
  get showDelete(): boolean {
    if ((this.repairData?.repairGuid && this.repairData?.status?.toLowerCase() === 'draft') ) {
      return true;
    }
    return false;
  }
  @Input() sendRequest: number;
  /**
   * constructor
   */
  constructor(private monitorService: MonitorService,
    public commonService: CommonService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private caseService: CaseService,
    private router: Router,
    private inspectionService: InspectionService,
    private estimateService: RepairEstimateService,
    private calendarService: CalendarService,
    private claimService: ClaimsService,
    private translateService: TranslateService,) { 
    this.commonService.userProfileData.subscribe((res) => {
      if (res?.data) {
        const { userPermission } = res.data;
        this.userPermission = userPermission;
      }

      if (this.router.url.indexOf('/repairer-view/') != -1) {
        this.fromRepairerView = true; 
      }
    });
  }

  /**
   * detects changes in input attributes
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.monitorService.logEvent('allowMultipleUpload', [ 'ChqAddPhotosComponent', 'addenda-quote', {
      changes
    } ]);
    if (changes) {
      if (changes['caseNumber']?.currentValue) {
        this.photosHeader = { ...this.photosHeader, caseNumber: this.caseNumber };
      }
 
      if (changes['activateRepairButton']?.currentValue) {
        this.addPartButtonModel = { ...this.addPartButtonModel, type: 'primary' };
      }
 
      if (changes['isRepairComplete']?.currentValue) {
        this.addPartButtonModel = { ...this.addPartButtonModel, label: 'go_invoice' }
      }
    }
  }

  /**
   * initialize multiple Upload
   */
  allowMultipleUpload(): void {
    this.monitorService.logEvent('allowMultipleUpload', [ 'ChqAddPhotosComponent', 'addenda-quote' ]);
    this.mode.emit({ 'mode': 'multiple' })
  }

  public cameraButtonModel: ChqWidgetsButtonModel = {
    label: 'camera_upload',
    type: 'primary',
    icon: 'camera',
    color: 'primary',
    onclick: this.allowSingleUpload.bind(this)
  }
  public bulkUploadButtonModel: ChqWidgetsButtonModel = {
    label: 'btn_bulk_upload',
    type: 'primary',
    icon: 'upload',
    color: 'primary',
    onclick: this.allowMultipleUpload.bind(this)
  }

  public cancelButtonModel: ChqWidgetsButtonModel = {
    label: 'Cancel Booking',
    type: 'outline-danger',
    onclick: this.cancelBooking.bind(this)
  }

  /**
   * go to gtEstimate
   */
  redirectToGtEstimate(): void {

    this.commonService.showLoading();
    const { status, caseGuid, inspections, vehicleId, domainID } = this.caseData;
    let inspectionId = 0;
    if (inspections?.length > 0) {
      inspectionId = inspections[0].inspectionId;
    }
    if (status?.toLowerCase() === 'uploaddocuments') {
      this.monitorService.logEvent('redirectToGtEstimate', [ 'ChqDamageAnalysisComponent', 'addenda-quote', {
        caseId: caseGuid
      } ]);
      this.caseService.updateCaseStatus(caseGuid, 'UploadPhotos').subscribe({
        next: (resp: any): void => {
          this.estimateService.createEstimate(caseGuid, inspectionId, vehicleId, domainID).subscribe({
            next: (resp: any) => {
              if (resp) {
                if (resp?.data?.url) {
                  location.href = resp?.data?.url;
                  sessionStorage.setItem('update', '1');
                }
              }
            },
            complete: () => {
              this.commonService.hideLoading();
            }
          });
        }, error: () => {
          this.commonService.hideLoading();
        }
      })
    } else if (status?.toLowerCase() === 'uploadphotos') {
      this.monitorService.logEvent('redirectToGtEstimate', [ 'ChqDamageAnalysisComponent', 'addenda-quote', {
        caseId: caseGuid
      } ]);
      this.estimateService.createEstimate(caseGuid, inspectionId, vehicleId, domainID).subscribe({
        next: (resp: any) => {
          if (resp) {
            if (resp?.data) {
              location.href = resp.data.url;
              sessionStorage.setItem('update', '1');
            }
          }
        },
        complete: () => {
          this.commonService.hideLoading();
        }
      });
    } else {
      this.monitorService.logEvent('skipped_redirectToGtEstimate', [ 'ChqDamageAnalysisComponent', 'addenda-quote', {
        caseId: caseGuid
      } ]);
      this.router.navigate([ `/quote/case/${caseGuid}/estimate` ]);
    }

  }

  /**
   * oninit
   */
  ngOnInit(): void {
    this.showSubmitForReview = this.displaySubmitForReview();
    this.showSubmitForApproval = this.displaySubmitForApprovalButton();
    this.showAssignBackButton = this.displayAssignBackButton();
    this.nextButton.onclick = this.redirectToGtEstimate.bind(this);
  }

  /**
   * Cancel booking
   */
  cancelBooking(): void {
    this.commonService.openDeleteDialog()
      .afterClosed().subscribe((data) => {
        if (data) {
          this.commonService.showLoading();
          this.calendarService.deleteRepair(this.repairData?.repairGuid).subscribe({
            next: () => {
              this.commonService.hideLoading();
              this.router.navigate([ '/repair/booking-journal' ]);
            }
          });
        }
      });
  }

  /**
   * submission for review for claim
   */
  submissionForReviewClaim(): void {
    this.commonService.showLoading();
    this.claimService.updateClaimStatus(this.claimData?.claimGuid, CLAIM_STATUS.Submitted).subscribe({
      next: (response) => {
        if (response) {
          this.router.navigate([ `claim/claim-detail/${this.claimData?.claimGuid}/customer-view` ]);
        }
        this.commonService.hideLoading();
      },
      error: () => {
        this.commonService.hideLoading();
      }
    });
  }

  /**
     * submitForApproval
  */
  submitForApproval(): void {
    const dialogRef = this.commonService.openConfirmYesNoDialogBig('', this.translateService.instant('msg_submit_approve_claim'));
    dialogRef.afterClosed().subscribe((action: boolean) => {
      if (action) {
        this.commonService.showLoading();
        this.claimService.updateClaimStatus(this.claimData?.claimGuid, CLAIM_STATUS.ClaimSubmittedForApproval).subscribe({
          next: (response) => {
            if (response) {
              this.claimData.claimStatus = CLAIM_STATUS.ClaimSubmittedForApproval;
              this.commonService.claimStatus.next(this.claimData?.claimStatus);
              this.showSubmitForApproval = false;
            }
            this.commonService.hideLoading();
          },
          error: () => {
            this.commonService.hideLoading();
          }
        });
      }
    });
  }

  /**
 * display submit for review
 */
  displaySubmitForReview(): boolean {

    const validStatuses = [
      CLAIM_STATUS.UploadDocuments,
      CLAIM_STATUS.DetailsUpdated,
      CLAIM_STATUS.UploadPhotos, 
      CLAIM_STATUS.AssignedBackFromReview 
    ];

    //
    const fromClaimMaker = this.checkClaimPermission('claim.submitted')
      && (validStatuses.includes(this.claimData?.claimStatus))

    return fromClaimMaker;
  }


  /**
   * display a flag indicating show/hide of  submit for approval button
   */
  displaySubmitForApprovalButton(): boolean {

    //claim filed as claim maker and submitted for review 3 stage; the user should be claim reviewer
    const fromClaimMaker = this.checkClaimPermission('claim.submitforapproval') 
      && [ CLAIM_STATUS.ReviewInProgress, CLAIM_STATUS.AssignedBackFromApproval ].includes(this.claimData?.claimStatus)

    // claim filed as claim reviewer in stage 2 and user should be claim reviewer
    const fromClaimReviewerStage2 = [ CLAIM_STATUS.UploadPhotos, CLAIM_STATUS.DetailsUpdated, CLAIM_STATUS.UploadDocuments, CLAIM_STATUS.AssignedBackFromApproval ].includes(this.claimData?.claimStatus)
      && this.commonService.claimWorkflowStage?.includes('2Stage')
      && this.checkClaimPermission('claim.submitforapproval')

    //claim filed as claim reviewer from stage 3, the created user id must be equal to the current user id
    //and the permission should be submit for approval
    const fromClaimReviewerStage3 = (this.commonService.claimCreatedId === this.commonService.userId)
      && this.checkClaimPermission('claim.submitforapproval') && this.commonService.claimWorkflowStage?.includes('3Stage')
      && [ CLAIM_STATUS.UploadPhotos, CLAIM_STATUS.DetailsUpdated, CLAIM_STATUS.UploadDocuments, CLAIM_STATUS.AssignedBackFromApproval ].includes(this.claimData?.claimStatus)

    return fromClaimMaker || fromClaimReviewerStage2 || fromClaimReviewerStage3;
  }

  /**
   * claim permission
   * @param permissionName 
   */
  checkClaimPermission(permissionName: string): boolean {

    const automotiveService = this.userPermission?.automotiveServices;
    const permissionList = automotiveService?.find(x => x.automotiveServiceName.toLowerCase() === 'addenda claims')?.permissions;
  
    return this.commonService.hasPermission(permissionName, permissionList);
  }

  /**
   * assign back
   */
  assignBack(): void {
    const dialogRef = this.commonService.openConfirmYesNoDialogBig(this.translateService.instant('assign_back'), this.translateService.instant('msg_assign_back'));
    dialogRef.afterClosed().subscribe((action: boolean) => {
      if (action) {
        this.commonService.showLoading();
        this.claimService.updateClaimStatus(this.claimData?.claimGuid, CLAIM_STATUS.AssignedBack).subscribe({
          next: (response) => {
            if (response) {
              this.claimData.claimStatus = CLAIM_STATUS.AssignedBack;
              this.showAssignBackButton = this.displayAssignBackButton();
            }
            this.commonService.hideLoading();
          },
          error: () => {
            this.commonService.hideLoading();
          }
        });
      }
    });
  }

  
  /**
   * display assign back button
   */
  displayAssignBackButton(): boolean {

    const condition1 = ((this.commonService.fnolId === '' || this.commonService.fnolId === '0') 
        && this.claimData?.claimStatus === CLAIM_STATUS.ReviewInProgress
        && this.commonService.claimWorkflowStage?.includes('3Stage') && this.checkClaimPermission('claim.submitforapproval'))

    const condition2 = !this.commonService.claimWorkflowStage?.includes('1Stage') && 
      (this.claimData?.claimStatus === CLAIM_STATUS.ApprovalReviewInProgress
       && this.checkClaimPermission('claim.selfapproval'))

    return condition1 || condition2;
  }

  /**
   * navigate to garage
   */
  navigateToGarage(): void {
    this.router.navigate([ `/claim/claim-detail/${this.claimData?.claimGuid}/garages` ]);
  }
}
