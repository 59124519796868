import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common/common.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';

@Component({
  selector: 'video-player',
  templateUrl: './video-player.component.html',
  styleUrls: [ './video-player.component.scss' ]
})
export class VideoPlayerComponent {

  /**
   * constructor
   * @param dialogRef 
   * @param data 
   */
  constructor(public dialogRef: MatDialogRef<VideoPlayerComponent>,
    private monitorService: MonitorService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  /**
   * close dialog
   */
  close(): void {
    this.monitorService.logEvent('close', [ 'VideoPlayerComponent', 'addenda-quote' ]);
    this.dialogRef.close(true);
  }

}
