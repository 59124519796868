<div class="main-container">
    <div class="header">
        <div class="logo">
            <img src="assets/img/xa-logo-with-name.png"
                 alt="logo">
        </div>
        <div class="title">
            <h1>Customer feedback form</h1>
            <span>We would like to hear from you to serve you better next time</span>
        </div>
    </div>
    <mat-progress-bar mode="determinate"
                      [value]="successOrErrorPage ? 100 : ((currentStep) / steps.length * 100)"></mat-progress-bar>
    <div class="content-wrapper">
        <div *ngIf="!successOrErrorPage"
             class="content"
             [formGroup]="feedbackForm">
            <div *ngFor="let step of steps; let i = index"
                 [hidden]="i !== currentStep"
                 [formGroupName]="step.group"
                 class="step">
                <ng-container *ngFor="let input of step.input">
                    <div *ngIf="input.questionType === 'info'"
                         class="info-box">
                        <mat-icon *ngIf="input.icon"
                                  [svgIcon]="'lock'"></mat-icon>
                        {{ input.question }}
                    </div>
                    <div *ngIf="input.questionType === 'infoWithValue'"
                         class="info-box">
                        <mat-icon *ngIf="input.icon"
                                  [svgIcon]="'lock'"></mat-icon>
                        <span [innerHTML]="input.question"></span>
                    </div>
                    <div *ngIf="input.questionType === 'phoneNumber'"
                         class="input-wrapper">
                        <label class="form-label"
                               for="">ENTER MOBILE NUMBER <span class="required">*</span></label>
                        <div class="control-wrapper">
                            <xa-select formControlName="countryCode"
                                       [config]="{name: 'countryCode', showRequiredLabel: true, showHeaderIcon: true}"
                                       [options]="countryList"></xa-select>
                            <xa-text formControlName="mobileNumber"></xa-text>
                        </div>
                        <span *ngIf="getControl(step.group, 'mobileNumber').errors && getControl(step.group, 'mobileNumber').touched"
                              class="error">
                            <span *ngIf="feedbackForm.get(step.group).get('mobileNumber').errors['required']">Mobile number is required.</span>
                            <span *ngIf="feedbackForm.get(step.group).get('mobileNumber').errors['pattern']">Please enter valid mobile number.</span>
                        </span>
                    </div>
                    <div *ngIf="input.questionType === 'textbox'"
                         class="input-wrapper">
                        <xa-text [formControlName]="input.questionCode"
                                 [label]="input.question"
                                 [required]="true"></xa-text>
                        <span *ngIf="getControl(step.group, input.questionCode).errors && getControl(step.group, input.questionCode).touched"
                              class="error">
                            <span *ngIf="feedbackForm.get(step.group).get(input.questionCode).errors['required']">License plate is required.</span>
                            <span *ngIf="feedbackForm.get(step.group).get(input.questionCode).errors['pattern']">Only alpha numeric characters are allowed.</span>
                            <span *ngIf="feedbackForm.get(step.group).get(input.questionCode).errors['maxlength']">Only 12 characters allowed.</span>
                        </span>
                    </div>

                    <ng-container *ngIf="input.questionType === 'rating' || input.questionType === 'choice'">
                        <div class="input-wrapper rating-wrapper">
                            <img *ngIf="input.image"
                                 [src]="input.image"
                                 alt="Rating">
                            <span class="rating-title">
                                <span [innerHTML]="input.question"></span>
                                <span class="required">*</span>
                            </span>
                            <div class="rating-input-wrapper">
                                <div *ngIf="input.questionType === 'rating'"
                                     class="thumb thumb-down">
                                    <mat-icon [svgIcon]="'thumb-down'"></mat-icon>
                                    <span>{{ input.labels?.leftText }}</span>
                                </div>
                                <xa-radio-group [formControlName]="input.questionCode"
                                                [name]="input.questionCode"
                                                [required]="true"
                                                class="radio-group-display-flex">
                                    <xa-radio [value]="item"
                                              *ngFor="let item of input.options">
                                        <span class="label-with-icon"
                                              [ngClass]="{'yes': item === 'Yes', 'no': (item === 'No'  || item === ' No'), 'not-sure': (item === 'Not Sure' || item === ' Not Sure')}">
                                            {{ item }}
                                            <mat-icon *ngIf="input.questionType === 'choice'"
                                                      [svgIcon]="item === 'Yes' ? 'thumb-up' : (item === 'No' || item === ' No' ? 'thumb-down' : 'not-sure')"></mat-icon>
                                        </span>
                                    </xa-radio>
                                </xa-radio-group>
                                <div *ngIf="input.questionType === 'rating'"
                                     class="thumb thumb-up">
                                    <mat-icon [svgIcon]="'thumb-up'"></mat-icon>
                                    <span>{{ input.labels?.rightText }}</span>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>

            <div class="footer">
                <a *ngIf="currentStep < steps.length - 1"
                   (click)="nextStep()">
                    <xa-button [disabled]="!this.feedbackForm.get(this.steps[this.currentStep].group).valid">
                        <span> {{'next' | translate }}</span>
                    </xa-button>
                </a>
                <a *ngIf="currentStep === steps.length - 1"
                   (click)="submitForm()">
                    <xa-button [disabled]="!this.feedbackForm.get(this.steps[this.currentStep].group).valid">
                        <span> {{'submit' | translate }}</span>
                    </xa-button>
                </a>

                <a *ngIf="currentStep !== 0"
                   (click)="previousStep()">
                    <xa-button type="outline"
                               color="gray">
                        <span> {{'back' | translate }}</span>
                    </xa-button>
                </a>
            </div>
        </div>

        <div *ngIf="successOrErrorPage"
             class="content">
            <div class="step">
                <div class="input-wrapper rating-wrapper">
                    <img [src]="successOrErrorPage === 'success' ? 'assets/img/feedback/image-5.png' : 'assets/img/feedback/image-6.png'"
                         alt="Success">
                    <span *ngIf="successOrErrorPage === 'success'"
                          class="rating-title">
                        <p>Thank you!</p>
                        <p>We have successfully saved your valuable feedback..</p>
                    </span>
                    <span *ngIf="successOrErrorPage === 'error'"
                          class="rating-title">
                        <p>We have already saved your feedback..</p>
                        <p>Thank you!</p>
                    </span>
                </div>
            </div>

            <div class="footer"
                 style="justify-content: center;">
                <a href="/">
                    <xa-button>
                        <span> {{'Okay' | translate }}</span>
                    </xa-button>
                </a>
            </div>
        </div>
    </div>
</div>