import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class CalendarService {


  /**
   * constructor
   * @param http
   */
  constructor(
    private http: HttpClient
  ) {
  }


  /**
   * get calendar data
   * @returns
   */
  public getCalendarData(query: string): Observable<any> {
    const url = `${environment.bookingMgmtUrl}/odata/booking${query}`;
    return this.http.get(url);
  }

  /**
     * get booking data
     * @returns
     */
  public getBookingDataById(guid: string): Observable<any> {
    const url = `${environment.bookingMgmtUrl}/api/booking/${guid}`;
    return this.http.get(url);
  }

  /**
   * get lunch time
   * @returns
   */
  public getLunchTime(EmployeeGuid: any, fromDate: any, toDate: any): Observable<any> {
    const url = `${environment.bookingMgmtUrl}/api/employee/${EmployeeGuid}/${fromDate}/${toDate}`;
    return this.http.get(url);
  }

  /**
 * get employee schedule
 * @returns
 */
  public getSchedule(EmployeeGuid: any, fromDate: any, toDate: any): Observable<any> {
    const url = `${environment.bookingMgmtUrl}/api/employee/${EmployeeGuid}/bookingjournal/${fromDate}/${toDate}`;
    return this.http.get(url);
  }

  /**
 * get holiday
 * @returns
 */
  public getHolidays(): Date[] {
    return [
      // new Date(2023, 4, 19),
      // new Date(2023, 4, 25),
    ];
  }

  /**
   * getCustomer
   * @returns Observable
   */
  public getCustomer(query: string): Observable<any> {
    const url = `${environment.caseMgmtUrl}/odata/customer${query}`;
    return this.http.get(url);
  }

  /**
   * deleteBooking
   * @returns Observable
   */
  public deleteBooking(bookingGuid: any): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/repair/booking/${bookingGuid}`;
    return this.http.delete(url);
  }

  /**
   * updateBooking
   * @returns Observable
   */
  public updateBooking(bookingGuid: any, patchData: any): Observable<any> {
    const url = `${environment.bookingMgmtUrl}/api/booking/${bookingGuid}`;
    return this.http.patch(url, patchData);
  }

  /**
 * getDraftCustomer
 * @returns Observable
 */
  public getDraftCustomer(query): Observable<any> {
    const url = `${environment.caseMgmtUrl}/odata/draftcustomer?${query}`;
    return this.http.get(url);
  }

  /**
 * deleteDraftCustomer
 * @returns Observable
 */
  public deleteDraftCustomer(customerId: any): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/draftcustomer/${customerId}`;
    return this.http.delete(url);
  }

  /**
   * deleteRepair
   * @returns Observable
   */
  public deleteRepair(repairGuid: any): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/repair/${repairGuid}`;
    return this.http.delete(url);
  }

  /**
   * 
   * @returns 
   */
  public getUserSetting(): Observable<any> {
    const url = `${environment.autoCoreApi}/api/users/user-setting?domainId=3100`;
    return this.http.get(url);
  }

  /**
   * 
   * @param body 
   * @returns 
   */
  public postUserSetting(body): Observable<any> {
    const url = `${environment.autoCoreApi}/api/users/user-setting`;
    return this.http.post(url, body);
  }
}
