import { Component, Input, OnChanges, ViewContainerRef } from '@angular/core';
@Component({
  selector: 'xa-heading',
  templateUrl: './xa-heading.component.html',
  styleUrls: [ './xa-heading.component.scss' ]
})
export class XaHeading {
  @Input() color:string = '';
  @Input() size: string = '20';
  @Input() weight: string = '700';
  @Input() required: boolean = false;

  /**
   * 
   * @param _vcr reference
   */
  constructor(private _vcr: ViewContainerRef) {
  }

}
