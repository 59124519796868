import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Inject, InjectionToken, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FNOL_NEXT_LABEL, QUOTE_NEXT_LABEL, QUOTE_ROUTE } from 'src/app/config/configuration/configuration.constant';
import { ConfigurationService } from 'src/app/config/configuration/service/configuration.service';
import { repairEstimatedStatuses } from 'src/app/config/constants/app.constants';
import { CLAIM_STATUS } from 'src/app/config/constants/claims.constants';
import { AiAnalysisDialogComponent } from 'src/app/dialogs/ai-analysis-dialog/ai-analysis-dialog.component';
import { ImgDialogComponent } from 'src/app/dialogs/img-dialog/img-dialog.component';
import { DamageRecommendations, DamageReport, DamageReportItem } from 'src/app/model/chq-damage-report';
import { FooterModel, UploadStepModel } from 'src/app/model/chq-upload-model';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { ChqWidgetsHeaderModel } from 'src/app/model/chq-widgets-header-model';
import { damagePartMapper } from 'src/app/modules/quote/chq-new-cases/chq-new-customer/chq-new-customer-form-data-helper';
import { AiAnalysisService } from 'src/app/services/ai-analysis/ai-analysis.service';
import { BookingService } from 'src/app/services/booking/booking.service';
import { CaseService } from 'src/app/services/case/case.service';
import { ClaimsService } from 'src/app/services/claims/claims.service';
import { CommonService } from 'src/app/services/common/common.service';
import { InspectionService } from 'src/app/services/inspection/inspection.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { RepairEstimateService } from 'src/app/services/repair-estimate/repair-estimate.service';
import { ChqPdfGeneratorComponent } from 'src/app/widgets/chq-pdf-generator/chq-pdf-generator.component';
import { ChqReviewSectionComponent } from 'src/app/widgets/chq-review-section/chq-review-section.component';
import { exportMapper, FooterActions, HeaderActions, IconActions, PhotosAction, XAFileReviewSection, XaHeaderAction, XaImageAction } from '../xa-photo-upload-mode-selecter/xa-file-upload.model';
import { DEFAULT_AI_TIMEOUT, ENABLE_AI_RECURSION, RECURSION_MAX_RETRIES } from 'src/app/shared/damage-analysis/damage-analysis.component';
import { XaFileReviewSectionComponent } from '../xa-file-review-section/xa-file-review-section.component';
import { titleStyles } from '../xa-chart/xa-chart.component';

const damageAnalysisHeader: ChqWidgetsHeaderModel = {
  title: 'damage_analysis',
  step: 5,
  description: 'damage_analysis_msg',
  stepName: 'step_five',
};


@Component({
  selector: 'xa-damage-analysis',
  templateUrl: './damage-analysis.component.html',
  styleUrls: [ './damage-analysis.component.scss' ]
})
export class XaDamageAnalysisComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() currentInspectionId:number;
  @ViewChild('reviewSection') reviewSection:XaFileReviewSectionComponent;
  public aiAnalysisHeader: ChqWidgetsHeaderModel = damageAnalysisHeader;
  public imageStatusSteps: UploadStepModel[] = [];
  public pdfImageSteps: UploadStepModel[] = [];
  public caseId: string = '0';
  public caseStatus = '';
  public inspection: any;
  public retryCounter: number = 0;
  public currentStep: UploadStepModel;
  public currentAdditionalModeMultiple = false;
  public vehicleMakeModel: string;
  public domainId: number;
  public isReviewSection: boolean = true;
  public fromReviewPage: boolean;
  public objectId: number;
  private currentRetryCount = 0;
  public isBulkUploaded: boolean;
  @Input() caseDetail: any;
  @Input() claimDetail: any;
  @Input() claimGUID:any;
  @Input() isRepairStage: boolean;
  @Input() jobId: number;
  @Input() hideNext: boolean;
  @Input() isRepairModule: boolean;
  @Input() isClaimModule: boolean;
  @Input() steps: UploadStepModel[];
  @Input() isBulkUpload: boolean;
  @Input() minPhotoRequired: number = 0;
  @Input() isSurveyor: boolean;
  @Input() vehicleMetaData:any;
  @Input() fnolGUID:string = '';
  @Input() aiEstimationStatus:string = '';
  @Input() headerActions: XaHeaderAction[] = [];
  @Input() reviewHeaderLabel:string = '';
  @Input() claimantDetail:any;
  @Input() isQuoteAssessmentModule: boolean;
  public fileReviewSection:XAFileReviewSection;
  originalSteps: UploadStepModel[];
  public vehicleId: number;
  public deletedId: number[] = [];
  public inspectionId: number;
  public damageParts: DamageReport[] = [];
  public imageLoading: any = {};
  public damageRecommendationList: any = {};
  public currentStepIndex = 0;
  public currentRepairCategory: any;
  currentTimeoutVariable: any;
  private currentRepairStatus: string = '';
  private repairQuoteId = '';
  private isDestroyed:boolean = false;
  @ViewChild(ChqPdfGeneratorComponent) pdfElement: ChqPdfGeneratorComponent;
  @ViewChild(ChqReviewSectionComponent) chqReviewSection: ChqReviewSectionComponent;
  @Output() backEvent: EventEmitter<{ back: boolean }> = new EventEmitter();
  @Output() nextEvent: EventEmitter<any> = new EventEmitter();
  showRepairRecommendation = false
  damageReportData: any;
  @Output() damageRecommendations: EventEmitter<DamageRecommendations[]> = new EventEmitter();
  @Output() triggerHeaderAction: EventEmitter<XaHeaderAction> = new EventEmitter();


  public aiAnalysisResult: DamageReportItem[] = [];


  public pdfButtonModel: ChqWidgetsButtonModel = {
    label: 'Create PDF',
    type: 'outline',
    icon: 'pdf'
  }
  public editButtonModel: ChqWidgetsButtonModel = {
    label: '',
    icon: 'edit',
    type: 'outline'
  }

  public closeButtonModel: ChqWidgetsButtonModel = {
    label: '',
    icon: 'cross',
    type: 'outline-danger'
  }

  public pdfButton: ChqWidgetsButtonModel = {
    label: 'Create Pdf',
    type: 'outline',
    icon: 'pdf'
  }

  public nextButton: ChqWidgetsButtonModel = {
    label: 'Create estimate',
    type: 'outline',
  }
  public addAnalysisBtn: ChqWidgetsButtonModel = {
    label: 'Add Analysis',
    type: 'outline',
    icon: 'plus-active'
  }
  public repairRecommendationBtn: ChqWidgetsButtonModel = {
    label: 'Repair Recommendation',
    type: 'primary',
    icon: 'chip',
    onclick: this.openRepairRecommendation.bind(this)
  }

  private userPermission: any;

  public footer: FooterModel = {
    text: 'Next create estimate',
    icon: 'boardview',
    route: 'case/gt',
    status: 'UploadPhotos',
    currentStatus: '',
    caseId: '',
    showBack: true,
    hideEdit: true
  }
  subscription: Subscription
  isExternal: boolean = false;
  claimId: string = '';
  isAIAnalysedImage: boolean = false;

  public isTechnician: boolean = true;
  private aiFlags:any;
  currentUserOrg:any;
  fromRepairerView: boolean = false;


  /**
   * constructor
   * @param fb ChangeDetectorRef 
   */
  constructor(
    private cd: ChangeDetectorRef,
    private commonService: CommonService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private caseService: CaseService,
    private router: Router,
    private monitorService: MonitorService,
    private inspectionService: InspectionService,
    private estimateService: RepairEstimateService,
    private bookingService: BookingService,
    private claimService: ClaimsService,
    private aiService: AiAnalysisService,
    private translateService: TranslateService,
    private configurationService:ConfigurationService,
    @Inject(DEFAULT_AI_TIMEOUT) protected defaultAITimeout: number,
    @Inject(ENABLE_AI_RECURSION) protected enableAIRecursion: number,
    @Inject(RECURSION_MAX_RETRIES) protected recursionMaxRetries: number
  ) {
    if (commonService.pageName.closed) {
      commonService.pageName = new BehaviorSubject<string>(null);
    }
    commonService.pageName.next('photos');

    if (this.router.url.indexOf('/repairer-view/') != -1) {
      this.fromRepairerView = true; 
    }

    this.subscription = this.commonService.userProfileData.subscribe({
      next: (res: any) => {
        if (res) {
          const { userPermission, organizationDetail } = res.data;
          this.userPermission = userPermission;
          this.currentUserOrg = organizationDetail;
          const permissionList = userPermission.automotiveServices[0].permissions;
          this.isExternal = this.commonService.hasPermission('case.external', permissionList);

          this.isTechnician = this.commonService.roleName?.toLowerCase() == 'technician';
        }

      }
    });
  }

  /**
   * handle icon actions
   */
  handleIconActions(action:XaHeaderAction):void{
    switch(action.type){
    case HeaderActions.recommendation:
      this.showRepairRecommendation = true;
      break;
    case HeaderActions.pdf:
      this.createPdf();
      break;
    case HeaderActions.retry:
      this.refreshData();
      break;
    default:
      this.triggerHeaderAction.emit(action);
    }
    
  }

  /**
   * 
   * @param action 
   * handle Footer actions
   */
  handleFooterActions(action:XaImageAction):void{
    switch(action.type){
    case FooterActions.createPdf:
      this.createPdf();
      break;
    case FooterActions.retry:
      this.refreshData();
      break;
    case FooterActions.custom:
      action.callback(action);
      break
    }
  }

  /**
   * handle input actions
   */
  handleAction($event:PhotosAction):void{
    const { step, action } = $event;
    switch(action.iconAction){
    case IconActions.delete:{
      const message = 'By deleting this photo it will delete the photo from the AI analysis report only';
      this.commonService.openDeleteDialog('photo', message)
        .afterClosed().subscribe((data) => {
          if (data) {
            const id = step.inspectionItem[0].inspectionItemId;
            this.fileReviewSection.files = this.fileReviewSection.files.filter((step: UploadStepModel) => {
              return !step?.inspectionItem || step?.inspectionItem[0].inspectionItemId !== id;
            });
            this.fileReviewSection = { ...this.fileReviewSection }
            this.deletedId.push(id);
          }
        });
      
      break;
    }
    case IconActions.download:
      this.reviewSection.download(step);
    }
  }

  /**
   * get ai flags
   */
  getAiFlags():void{
    this.aiFlags = this.configurationService.getAIAnalysisFlags();
  }

  /**
   * showAI
   */
  get displayEdit():boolean{
    if(this.aiFlags){
      return this.aiFlags['AnalysisModification_Display']?.toLowerCase() === 'true'; 
    }
    return true;
  }

  /**
   * showAI
   */
  get displayCategory():boolean{
    if(this.aiFlags){
      return this.aiFlags['DamageCategory_Display']?.toLowerCase() === 'true'; 
    }
    return true;
  }

  /**
   * navigates to next page
   */
  handleNavigation(domainId:number): void {
    this.monitorService.logEvent('handleNavigation', [ 'NewDocumentComponent', 'addenda-quote', {
      caseId: this.objectId
    } ]);

    if(domainId === 2100){
      const stepData = this.configurationService.getStepByDomainId(2100)?.sort((a, b) => a.SortOrder - b.SortOrder);
      const imagesConfigIndex = stepData.findIndex((step) =>{ return step.ItemKey === 'Addenda_Quote_Photos'});
      if(imagesConfigIndex){
        if(imagesConfigIndex === (stepData -1)){
          return;
        }
        const route = QUOTE_ROUTE[stepData[imagesConfigIndex + 1].ItemKey];
        if(route === QUOTE_ROUTE.Addenda_Quote_Repair_Estimate){
          this.footer = { ...this.footer, finalRedirection: this.redirectToGtEstimate.bind(this) };
        }else{
          this.footer = { ...this.footer, route: `/quote/case/${this.caseId}/${route}`, finalRedirection: null };
        }
      }
    }

    if(domainId === 1100){
      this.footer = { ...this.footer, status: '', finalRedirection: ():void=>{
        this.nextEvent.emit({ 'value': this.getNextStep('Fnol_Photos', 1100), 'step': 'navigate' });
      } };
    }
   
  }

  /**
   * get next step
   */
  getNextStep(currentModule:string, domainId: number):string{
    const stepData = this.configurationService.getStepByDomainId(domainId)?.sort((a, b) => a.SortOrder - b.SortOrder);
    const imageConfigIndex = stepData.findIndex((step) =>{ return step.ItemKey === currentModule});
    if(imageConfigIndex){
      if(imageConfigIndex === (stepData -1)){
        return '';
      }
      return stepData[imageConfigIndex + 1].ItemKey;
    }
    return '';
  }

  /**
   * get unanalysed images
   */
  get analysedFiles(): boolean {
    return this.imageStatusSteps.filter((currentStep: UploadStepModel) => {
      return (currentStep.analysisStatus === 'Analysed');
    }).length > 0;
  }

  /**
 * get all not analysed images
   */
  get notAnalysedFiles(): boolean {
    return this.imageStatusSteps.length === 0 || this.imageStatusSteps.filter((currentStep: UploadStepModel) => {
      return (currentStep.analysisStatus?.toLowerCase() === 'analysis in progress');
    }).length > 0;
  }

  /**
   * claim permission
   * @param permissionName 
   */
  checkClaimPermission(permissionName: string): boolean {

    const automotiveService = this.userPermission?.automotiveServices;
    const permissionList = automotiveService?.find(x => x.automotiveServiceName.toLowerCase() === 'addenda claims')?.permissions;

    return this.commonService.hasPermission(permissionName, permissionList);
  }

  /**
   * Filter DamageDetails
   */
  filterDamageDetailsByRecommendation(): void {
    if (this.aiAnalysisResult?.length > 0) this.aiAnalysisResult = this.aiAnalysisResult.filter(el => el.damageRecommendation?.toLowerCase() !== 'clean')
  }

  /**
   * Show repair recommendation
   */
  openRepairRecommendation(): void {
    this.showRepairRecommendation = true
  }
  /**
   * Set current repair category
   */
  setCurrentRepairCategory(): void {
    this.currentRepairCategory = this.damageParts.find(el => el.inspectionItemId === this.currentStep.index);
  }
  /**
   * delete Analysis
   */
  deleteAnalysis(row: any): void {

    this.commonService.openDeleteDialog('', 'Are you sure you also want to delete the selected part?')
      .afterClosed().subscribe((data) => {
        if (data) {
          this.commonService.showLoading();
          this.aiService.deleteDamageAnalysis(row.damageId).subscribe({
            next: () => {
              this.commonService.hideLoading();
              this.fetchDamageAnalysis();
            },
            error: () => {
              this.commonService.hideLoading();
            }
          })
        }
      });
  }
  /**
   * add Analysis
   */
  addAnalysis(): void {
    const data = {
      editMode: false,
      inspectionID: this.inspectionId,
      inspectionItemID: this.damageParts.find(el => el.inspectionItemId === this.currentStepIndex)?.inspectionItemId
    }
    const dialogRef = this.dialog.open(AiAnalysisDialogComponent, { data: data })
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.fetchDamageAnalysis()
      }
    })
  }

  /**
   * edit Analysis
   */
  editAnalysis(row: any): void {
    const data = {
      editMode: true,
      damageName: row.damageName,
      partName: row.partName,
      damageRecommendation: row.damageRecommendation,
      damageId: row.damageId,
      inspectionItemID: row.inspectionItemId,
      inspectionID: this.inspectionId
    }
    const dialogRef = this.dialog.open(AiAnalysisDialogComponent, {
      data: data
    })
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.fetchDamageAnalysis()
      }
    })
  }



  /**
   * Fetch latest damage analysis
   */
  fetchDamageAnalysis(): void {
    this.commonService.showLoading()
    const detail = this.isRepairStage ? this.inspectionService.getDamageReportByJob(this.inspectionId, this.jobId) :
      this.inspectionService.getDamageReport(this.inspectionId);
    detail.subscribe({
      next: (res) => {
        if (res) {
          const damageDetail = res.damageDetails;
          this.aiAnalysisResult = damageDetail.find((el => el.inspectionItemId === this.currentStep.index)).damageDetails;
          this.filterDamageDetailsByRecommendation();
          this.currentStep.inspectionItem = this.aiAnalysisResult;
          if (this.currentStep.inspectionItem?.length > 0) this.currentStep.isEmpty = false;
        }

      }, error: () => {
        this.commonService.hideLoading()
      }, complete: () => {
        this.commonService.hideLoading()
      }
    })
  }
  /**
   * hide loading
   */
  hideLoading(): void {
    this.commonService.hideLoading();
    this.cd.detectChanges();
  }

  /**
   * handle next action frm image
   */
  handleNextActionFromImages(action: any, param2: any): void { 
    this.router.navigateByUrl('/claim/my-claim');
  }

  /**
 * checkPdf load
 */
  checkPdfLoad(): void {
    setTimeout(() => {
      this.commonService.hideLoading();
    });
  }


  /**
   * get unanalysed images
   */
  get unAnalysedFiles(): boolean {
    return this.imageStatusSteps.filter((currentStep: UploadStepModel) => {
      return (currentStep.analysisStatus === 'Analysis in Progress');
    }).length > 0;
  }

  /**
   * recursive damage report call
   */
  loadDamageReportInRecursion(caseId: string): void {
    if (!this.enableAIRecursion) {
      return;
    }
    if (!this.unAnalysedFiles) {
      return;
    }
    if(this.isDestroyed){
      return;
    }
    if (this.currentRetryCount >= this.recursionMaxRetries) {
      return;
    }
    this.currentTimeoutVariable = setTimeout(() => {
      this.loadApiData(caseId);
      this.currentRetryCount++;
    }, this.defaultAITimeout);
  }

  /**
   * create pdf
   */
  createPdf(): void {
    this.commonService.showLoading();
    this.monitorService.logEvent('createPdf', [ 'ChqDamageAnalysisComponent', 'addenda-quote', {
      caseId: this.caseId
    } ]);
    if (this.isRepairModule) {
      if (this.isRepairStage) {
        const { vehicle, customer } = this.caseDetail;
        this.caseDetail.caseDetail = customer;
        const keys = Object.keys(vehicle);
        for (const k in keys) {
          this.caseDetail[keys[k]] = vehicle[keys[k]];
        }
        this.pdfImageSteps = this.imageStatusSteps.filter((s: UploadStepModel) => s.analysisStatus === 'Analysed' && s.url !== '' && s.url !== null &&
          s.inspectionItem && this.deletedId.indexOf(s.inspectionItem[0]?.inspectionItemId) === -1);
        this.pdfElement.generatePDF();
        this.checkPdfLoad();
      } else {
        const repairId = this.route.parent?.snapshot.paramMap.get('id');
        this.inspectionService.getDamageReportJobPDF(repairId, this.inspectionId).subscribe({
          next: (resp: any) => {
            this.caseDetail = { ...resp[0]?.data };
            const { vehicle, customer } = this.caseDetail;
            this.caseDetail.caseDetail = customer;
            const keys = Object.keys(vehicle);
            for (const k in keys) {
              this.caseDetail[keys[k]] = vehicle[keys[k]];
            }
            this.damageParts = resp[1].damageDetails;
            this.damageReportData = resp[1];
            this.damageRecommendationList = resp[1].damageRecommendations;
            this.getDamageStepsByObject();
            this.pdfImageSteps = this.imageStatusSteps.filter((s: UploadStepModel) => s.analysisStatus === 'Analysed' && s.url !== '' && s.url !== null &&
              s.inspectionItem && this.deletedId.indexOf(s.inspectionItem[0]?.inspectionItemId) === -1);
            this.pdfElement.generatePDF();
            this.checkPdfLoad();
          },
          error: () => this.commonService.hideLoading()
        })
      }

    } else {

      let inspectionObservable;

      if (this.isClaimModule && this.isSurveyor) {
        inspectionObservable = this.inspectionService.getDamageReportSurveyorPDF(this.claimId || this.claimantDetail?.claimGuid, this.inspectionId, this.commonService.claimId || this.claimantDetail?.objectId)
      } else if (this.currentInspectionId && this.fnolGUID) {
        inspectionObservable = this.inspectionService.getDamageReport(this.currentInspectionId);
      } else {
        inspectionObservable = this.inspectionService.getDamageReportPDF(this.isClaimModule ? (this.claimId || this.claimantDetail?.claimGuid) : this.caseId, this.inspectionId, this.isClaimModule);
      }

      inspectionObservable.subscribe({  
        next: (resp: any) => {
          if (!this.isClaimModule && !this.fnolGUID && !this.claimantDetail) {
            this.caseDetail = { ...resp[0]?.data };
            const nextLabel = this.getNextStep('Addenda_Quote_Photos', 2100);
            this.footer = { ...this.footer, text: nextLabel ? QUOTE_NEXT_LABEL[nextLabel] :'next_create_estimate' };
            this.handleNavigation(2100);
          }else if(this.claimantDetail && !this.isClaimModule){
            this.claimDetail = { vehicleMetaData: { ...this.claimantDetail.vehicleDetails
              , registrationNumber: this.claimantDetail.vehicleDetails.plateNumber,
              vin: this.claimantDetail.vehicleDetails.vehicleChassisNumber,
            }, organizationDetail: { ...this.currentUserOrg,
              emailAddress: this.currentUserOrg.email,
              nameOfOrganization: this.currentUserOrg.name,
              companyLicenseNumber: this.currentUserOrg.licenseNumber
            } };
          }else if(this.fnolGUID){
            this.claimDetail = { vehicleMetaData: this.vehicleMetaData, organizationDetail: { ...this.currentUserOrg,
              emailAddress: this.currentUserOrg.email,
              nameOfOrganization: this.currentUserOrg.name,
              companyLicenseNumber: this.currentUserOrg.licenseNumber
            } };
            this.damageParts = resp.damageDetails;
            this.damageReportData = resp;
            this.damageRecommendationList = resp.damageRecommendations;
          } else {
            this.claimDetail = { ...resp[0] }
          }

          if(!this.fnolGUID){
            this.damageParts = resp[1].damageDetails;
            this.damageReportData = resp[1];
            this.damageRecommendationList = resp[1].damageRecommendations;
          }
         
          this.getDamageStepsByObject();
          this.pdfImageSteps = this.imageStatusSteps.filter((s: UploadStepModel) => s.analysisStatus === 'Analysed' && s.url !== '' && s.url !== null &&
            s.inspectionItem && this.deletedId.indexOf(s.inspectionItem[0]?.inspectionItemId) === -1);

          setTimeout(() => {
            this.pdfElement.generatePDF();
            this.checkPdfLoad();
          });


        },
        error: () => this.commonService.hideLoading()
      })
    }
  }


  /**
   * refresh Data
   */
  refreshData(): void {
    if (this.retryCounter === 0) {
      const caseId = this.route.parent?.snapshot.paramMap.get('id');
      this.monitorService.logEvent('refreshData', [ 'ChqDamageAnalysisComponent', 'addenda-quote', {
        caseId: caseId
      } ]);
      this.loadApiData(caseId, true);
    }
  }


  /**
   * on init
   */
  ngOnInit(): void {
    let entityId = this.route.parent?.snapshot.paramMap.get('id');
    this.monitorService.logEvent('ngOnInit', [ 'ChqDamageAnalysisComponent', 'addenda-quote', {
      caseId: entityId
    } ]);

    if(!entityId) {
      entityId = this.caseDetail?.repairGuid;
    }

    if (!this.isClaimModule) {
      this.nextButton.onclick = this.redirectToGtEstimate.bind(this);
    }else{
      if(!entityId){
        entityId = this.claimGUID;
      }
    }

    this.pdfButton.onclick = this.createPdf.bind(this);
    if (this.isRepairModule) {
      this.aiAnalysisHeader = { ...this.aiAnalysisHeader, isRepairModule: this.isRepairModule };
    } else if (this.isClaimModule) {
      this.aiAnalysisHeader = { ...this.aiAnalysisHeader, isClaimModule: this.isClaimModule };
    }

    this.footer = {
      ...this.footer,
      caseId: !this.isClaimModule ? entityId : null,
      handleBack: this.handleBack.bind(this),
      hideNext: this.hideNext,
      finalRedirection: this.redirectToGtEstimate.bind(this),
      currentStatus: this.currentRepairStatus,
      objectId: this.caseDetail?.id,
      aiEstimationStatus: this.aiEstimationStatus
    }

    this.fileReviewSection = {
      domainId: this.domainId,
      inspectionId: this.inspectionId,
      displayDialog: true,
      files: [],
      type: 'photo',
      multipleUpload: this.isBulkUpload,
      permissionTag: '',
      iconContainerClass: '',
      maxFileLength: 20,
      minFileLength: this.minPhotoRequired,
      enableBulk: false,
      enableSPI: true,
      isEditable: true,
      bulkUploadInspectionItemId: 0,
      objectId: this.caseDetail?.id,
      templateId: 0,
      aiReviewSection: true,
      footerActions: [
        {
          label: 'refresh',
          icon: '',
          type: FooterActions.retry,
          location: 'review'
        }, {
          label: 'next',
          icon: '',
          type: FooterActions.custom,
          btnType: 'outline',
          location: 'review',
          callback: this.handleNextActionFromImages.bind(this)
        } ],
      headerActions: [
        {
          label: 'repair_recommendation',
          icon: 'chip',
          type: HeaderActions.recommendation,
          requireFileUpload: true,
          location: 'AI',
          btnType: 'outline',
          btnColor: 'primary'
        },
        ...this.headerActions
      ],
      actions: [
        {
          icon: 'delete',
          iconClass: 'delete-icon border-light-gray',
          iconAction: IconActions.delete,
          displayIndicator: false
        },
        {
          icon: 'download-arrow',
          iconClass: 'border-light-gray',
          iconAction: IconActions.download,
          displayIndicator: false
        }
      ],
      objectType: '',
      label: this.reviewHeaderLabel || ''
    }

    this.loadApiData(entityId);

    if (this.isClaimModule && !this.currentInspectionId) {

      this.commonService.claimStatus.subscribe((claimStatus) => {
        if (claimStatus) {
          this.footer = { ...this.footer, currentStatus: claimStatus }
        }
      });

      this.minPhotoRequired = 0;
      this.footer = {
        ...this.footer,
        isClaimModule: true,
        claimId: entityId,
        //show submit for review in case of claim maker while for manager and reviewer show gotp garages 
        text: 'Go to Garages',
        hideNext: !(this.checkClaimPermission('claim.selfapproval') || this.checkClaimPermission('claim.submitforapproval')),
        finalRedirection: this.gotoGarages.bind(this)
      }
      this.nextButton.onclick = this.gotoGarages.bind(this);
    }

    if (repairEstimatedStatuses.includes(this.currentRepairStatus.toLowerCase())) {
      this.nextButton.label = 'View Estimate';
      this.nextButton.onclick = this.gotoEstimate.bind(this);
    }
  }

  /**
 * navigate to estimate
 */
  gotoEstimate(): void {
    const repairId = this.route.parent?.snapshot.paramMap.get('id');
    this.router.navigate([ `/repair/booking/${repairId}/estimate` ]);
  }


  /**
   * navigate to garage
   */
  gotoGarages(): void {
    this.router.navigate([ '/claim/claim-detail/' + this.claimId + '/garages' ]);
  }

  /**
   * ngOnChanges
   * @param changes 
   */
  ngOnChanges(changes: SimpleChanges): void {

    this.monitorService.logEvent('ngOnChanges', [ 'ChqDamageAnalysisComponent', 'addenda-quote', {
      changes
    } ]);
    if (changes['steps']?.currentValue) {
      this.originalSteps = [ ...this.steps ];
    }
  }

  /**
   * handle back
   */
  handleBack($event: { back: boolean }): void {
    const caseId = this.route.parent?.snapshot.paramMap.get('id');
    this.loadApiData(caseId);
    this.monitorService.logEvent('handleBack', [ 'ChqDamageAnalysisComponent', 'addenda-quote' ]);
    if (this.isReviewSection) {
      this.backEvent.emit($event);
    } else {
      this.isReviewSection = true;
    }
  }

  /**
   * on destroy
   */
  ngOnDestroy(): void {
    this.monitorService.logEvent('ngOnDestroy', [ 'ChqDamageAnalysisComponent', 'addenda-quote' ]);
    this.commonService.pageName.unsubscribe();
    if (this.currentTimeoutVariable) {
      clearTimeout(this.currentTimeoutVariable);
    }
    this.isDestroyed = true;
    this.subscription.unsubscribe();
  }


  /**
   * inspection detail by id
   */
  getDamageStepsByObject(): void {
    const damageSteps = [];
    for (let i = 0; i < this.damageParts?.length; i++) {
      const templatePart = this.damageParts[i];
      const damageStep = damagePartMapper(templatePart, i);
      if (this.deletedId && this.deletedId.indexOf(damageStep.inspectionItem[0]?.inspectionItemId) !== -1) {
        continue;
      }
      const dmgStep = {
        ...damageStep, mandatory: false,
        isSkipEnabled: true,
        isMassUpload: false,
        domainId: this.domainId, objectId: this.objectId,
        uploadedPartGuid: '',
        uploadedGuid: '',
      };
      dmgStep.action = exportMapper(dmgStep, this.fileReviewSection.actions);
      damageSteps.push(dmgStep);
    }
    if (this.chqReviewSection) {
      this.chqReviewSection.imageLoading = {};
    }
    this.imageStatusSteps = [ ...damageSteps ];
    this.fileReviewSection = { ...this.fileReviewSection, files: this.imageStatusSteps };
  }

  /**
   * handle Review action
   */
  handleReviewAction($event: any): void {
    this.monitorService.logEvent('handleReviewAction', [ 'ChqDamageAnalysisComponent', 'addenda-quote', {
      $event
    } ]);
    this.setCurrentRepairCategory();
    if ($event.mode === 'edit') {
      this.setCurrentStep($event.step);
      this.isReviewSection = false;
      this.fromReviewPage = true;
    } else if ($event.mode === 'upload') {
      console.log('e');
    } else {
      const id = $event.step.inspectionItem[0].inspectionItemId;
      this.imageStatusSteps = this.imageStatusSteps.filter((step: UploadStepModel) => {
        return !step?.inspectionItem || step?.inspectionItem[0].inspectionItemId !== id;
      });
      this.deletedId.push(id);
    }
  }

  /**
   * ngAfterViewInit hook
   */
  ngAfterViewInit(): void {
    this.cd.detectChanges();
    this.monitorService.logEvent('ngAfterViewInit', [ 'ChqDamageAnalysisComponent', 'addenda-quote' ]);
  }

  /**
   * open image dialog
   */
  imageDialog(state: string, url: string): void {
    const dialogRef = this.dialog.open(ImgDialogComponent, {
      data: {
        state,
        title: this.currentStep.stepName,
        image: url
      }, autoFocus: false
    });
    dialogRef.afterClosed().subscribe();
  }

  /**
   * get status
   */
  get validStatuses(): boolean {
    const validStatuses = [ 'draft', 'uploaddocuments', 'uploadphotos' ];
    return validStatuses.includes(this.currentRepairStatus.toLowerCase());
  }

  /**
   * check gtEstimate permission
   */
  checkGTEstimatePermission(): boolean {
    const { orgPackages } = this.userPermission;
    const repairEstimatePackage = orgPackages.filter((orgPackage: any) => {
      return orgPackage.automotiveServiceName === 'Repair Estimate';
    });

    if (repairEstimatePackage && repairEstimatePackage.length > 0) {
      const { permissions } = repairEstimatePackage[0];
      const gtEstimatePermission = permissions.filter((perm: any) => {
        return perm.permissionName === 'gt.estimate' || perm.permissionName === 'gt.estimate.vinquery';
      });
      if (gtEstimatePermission && gtEstimatePermission.length > 0) {
        return true;
      }
    }
    return false;
  }

  /**
   * go to gtEstimate
   */
  redirectToGtEstimate(): void {

    this.commonService.showLoading();
    if ((this.imageStatusSteps?.length + this.deletedId.length) < this.minPhotoRequired && this.isBulkUpload === true) {
      this.commonService.showToast(0, 'All 8 sides images are mandatory and cannot skip to next section.');
      this.commonService.hideLoading();
      this.monitorService.logEvent('redirectToGtEstimate', [ 'ChqDamageAnalysisComponent', 'addenda-quote', {
        caseId: this.caseId,
        length: this.imageStatusSteps?.length,
        message: 'Prevented redirection because all 8 sides images are mandatory and cannot skip to next section.'
      } ]);
      return;
    }

    if (this.aiAnalysisHeader.isRepairModule) {
      this.commonService.showLoading();
      const repairId = this.route.parent?.snapshot.paramMap.get('id');
      if (this.checkGTEstimatePermission() && this.validStatuses && !this.repairQuoteId) {
        this.estimateService.createEstimateFromRepair(repairId).subscribe({
          next: (resp: any) => {
            if (resp) {
              if (resp?.data?.url) {
                location.href = resp.data.url;
                sessionStorage.setItem('update', '1');
              }
            }
            this.commonService.hideLoading();
          },
          error: () => {
            this.commonService.hideLoading();
          }
        });
      } else {
        this.router.navigate([ `/repair/booking/${repairId}/estimate` ]);
      }
    } else
      if (this.caseStatus?.toLowerCase() === 'uploaddocuments') {
        this.monitorService.logEvent('redirectToGtEstimate', [ 'ChqDamageAnalysisComponent', 'addenda-quote', {
          caseId: this.caseId
        } ]);
        this.caseService.updateCaseStatus(this.footer.caseId, this.footer.status).subscribe({
          next: (resp: any): void => {
            this.estimateService.createEstimate(this.caseId, this.inspectionId, this.vehicleId, this.domainId).subscribe({
              next: (resp: any) => {
                if (resp) {
                  if (resp?.data?.url) {
                    location.href = resp?.data?.url;
                    sessionStorage.setItem('update', '1');
                  }
                }
              },
              complete: () => {
                this.commonService.hideLoading();
              }
            });
          }, error: () => {
            this.commonService.hideLoading();
          }
        })
      } else if (this.caseStatus?.toLowerCase() === 'uploadphotos') {
        this.monitorService.logEvent('redirectToGtEstimate', [ 'ChqDamageAnalysisComponent', 'addenda-quote', {
          caseId: this.caseId
        } ]);
        this.estimateService.createEstimate(this.caseId, this.inspectionId, this.vehicleId, this.domainId).subscribe({
          next: (resp: any) => {
            if (resp) {
              if (resp?.data) {
                location.href = resp.data.url;
                sessionStorage.setItem('update', '1');
              }
            }
          },
          complete: () => {
            this.commonService.hideLoading();
          }
        });
      } else {
        this.monitorService.logEvent('skipped_redirectToGtEstimate', [ 'ChqDamageAnalysisComponent', 'addenda-quote', {
          caseId: this.caseId
        } ]);
        this.router.navigate([ `/quote/case/${this.caseId}/estimate` ]);
      }

  }

  /**
   * update current Page
   */
  setCurrentStep(currentStep: UploadStepModel): void {
    if (currentStep.analysisStatus?.toLowerCase() === 'analysed') {
      this.currentStepIndex = currentStep.index;
      this.currentStep = currentStep;
      this.aiAnalysisResult = this.currentStep.inspectionItem || [];
      this.filterDamageDetailsByRecommendation();
      this.setCurrentRepairCategory()
    }
  }

  /**
   * load server details
   */
  loadApiData(entityId: string, isRefresh?: boolean): void {
    if ((entityId && entityId != '0' ) || this.currentInspectionId) {
      this.caseId = entityId;
      this.commonService.showLoading();

      if (location.href.indexOf('claim') !== -1 && !this.fnolGUID) {
        if (this.claimDetail) {
          const { id, claimStatus, domainId, inspectionId } = this.claimDetail;
          this.domainId = domainId;
          this.objectId = id;
          this.claimId = entityId;
          this.inspectionId = inspectionId;
          this.footer.currentStatus = claimStatus;
        }
      } else {
        if (!this.caseDetail && !this.currentInspectionId) {
          this.commonService.hideLoading();
          return;
        }
        if (location.href.indexOf('repair') !== -1) {
          const { id, vehicle, status, quoteGuid, domainId } = this.caseDetail;
          if (vehicle) {
            this.inspectionId = vehicle.inspectionId;
          }
          this.domainId = domainId;
          this.repairQuoteId = quoteGuid;
          this.currentRepairStatus = status;
          this.vehicleId = vehicle.vehicleId;
          this.objectId = id;
          this.footer.currentStatus = status;
        } else if(!this.currentInspectionId) {
          const { caseNumber, id, status, inspections, vehicleId, domainID } = this.caseDetail;
          this.caseStatus = status;
          this.domainId = domainID;
          if (status?.toLowerCase() !== 'uploaddocuments') {
            this.footer = { ...this.footer, status: '', caseId: entityId }
          }
          this.footer.currentStatus = status;
          if (inspections?.length > 0) {
            this.inspectionId = inspections[0].inspectionId;
          }
          this.vehicleId = vehicleId;
          this.aiAnalysisHeader.caseNumber = caseNumber;
          this.objectId = id;
          const nextLabel = this.getNextStep('Addenda_Quote_Photos', 2100);
          this.footer = { ...this.footer, text: nextLabel ? QUOTE_NEXT_LABEL[nextLabel] :'next_create_estimate' };
          this.handleNavigation(2100);
        }

        if(this.currentInspectionId){
          const nextLabel = this.getNextStep('Fnol_Photos', 1100);
          this.footer = { ...this.footer, text: nextLabel ? FNOL_NEXT_LABEL[nextLabel] :'next_create_estimate', icon: nextLabel ? '' : this.footer.icon };
          this.handleNavigation(1100);
          this.aiAnalysisHeader = { ...this.aiAnalysisHeader, isFnolModule: true };
          this.getAiFlags();
          this.nextButton = { ...this.nextButton, label: (nextLabel ? FNOL_NEXT_LABEL[nextLabel] :'next_create_estimate'), onclick: ():void=>{
            this.nextEvent.emit({ 'value': this.getNextStep('Fnol_Photos', 1100), 'step': 'navigate' });
          } 
          }
          
        }
        this.isAIAnalysedImage = this.imageStatusSteps.filter((s: UploadStepModel) => s.analysisStatus === 'Analysed').length > 0;
        this.repairRecommendationBtn.type = this.isAIAnalysedImage ? 'primary' : 'disabled';
      }
      
      if(this.currentInspectionId){
        this.inspectionId = this.currentInspectionId;
      }
      let detail;

      if (this.isRepairStage) {
        detail = this.inspectionService.getDamageReportByJob(this.inspectionId, this.jobId);
      } else if (this.isSurveyor) {
        detail = this.inspectionService.getDamageReportByJob(this.inspectionId, this.commonService.claimId || this.claimantDetail?.objectId);
      } else {
        detail = this.inspectionService.getDamageReport(this.inspectionId);
      }
      detail.subscribe({
        next: (result: any) => {
          if (result) {
            this.damageParts = result?.damageDetails;
            this.damageReportData = result
            this.damageRecommendationList = result.damageRecommendations;
            // Emit damageRecommendations key from API Response
            // this.damageRecommendations.emit()
            this.getDamageStepsByObject();
            this.vehicleMakeModel = this.damageParts[0]?.vehicleType;
            this.currentStep = this.imageStatusSteps[0];
            for (let i = 0; i < this.imageStatusSteps?.length; i++) {
              this.imageLoading[`${this.imageStatusSteps[i]['stepName']}_${this.imageStatusSteps[i]['index']}`] = false;
            }
            this.isAIAnalysedImage = this.imageStatusSteps.filter((s: UploadStepModel) => s.analysisStatus === 'Analysed').length > 0;
            this.repairRecommendationBtn.type = this.isAIAnalysedImage ? 'primary' : 'disabled';
          }
          if (isRefresh) {
            setTimeout(() => {
              this.retryCounter = 0;
              if (this.fileReviewSection && this.fileReviewSection.footerActions?.length > 0) {
                const retryAction = this.fileReviewSection.footerActions.find((action: any) => action.type === FooterActions.retry);
                if(retryAction){
                  retryAction.disabled = false;
                }
                this.fileReviewSection = { ...this.fileReviewSection };
              }

            }, 30000);
            this.retryCounter = 1;
            if (this.fileReviewSection && this.fileReviewSection.footerActions?.length > 0) {
              const retryAction = this.fileReviewSection.footerActions.find((action: any) => action.type === FooterActions.retry);
              if(retryAction){
                retryAction.disabled = true;
              }
              this.fileReviewSection = { ...this.fileReviewSection };
            }
          } else {
            this.loadDamageReportInRecursion(this.caseId);
          }
          this.commonService.hideLoading();
        },
        error: (err: any) => {
          this.commonService.hideLoading();
          this.monitorService.logException(err, SeverityLevel.Error);
        }
      });
    }
  }

  /**
   * call fraud detection and damage severity
   */
  updateDamageSeverity(): void {
    this.claimService.updateFraudDetection(this.claimId).subscribe({
      next: () => {
        this.commonService.hideLoading();
      },
      error: () => {
        this.commonService.hideLoading();
      }
    });
  }

  /**
   * update claim status to upload photos
   */
  updateClaimStatus(): void {
    this.claimService.updateClaimStatus(this.claimId, CLAIM_STATUS.UploadPhotos).subscribe({
      next: (response) => {
        if (response) {
          this.footer.currentStatus = this.claimDetail.claimStatus = CLAIM_STATUS.UploadPhotos;
          this.footer = { ...this.footer, hideNext: this.checkClaimPermission('claim.submitted') }
          this.chqReviewSection.nextButtonModel.type = 'primary';
          this.commonService.showInfoToast(5000, 'Please go to loss detail page to see the severity');
        }
        this.commonService.hideLoading();
      },
      error: () => {
        this.commonService.hideLoading();
      }
    });
  }

  /**
   * check if damage analysis is completed
   */
  damageAnalysisComplete($event: any): void {
    const uploading = $event.uploading;
    const status = $event.status;

    if (uploading|| this.unAnalysedFiles) {
      this.commonService.openConfirmYesNoDialogBig('Warning', 'Please wait as the AI analysis is in progress', '', 'OK', '', false);
      return;
    }
    
    const message = (status == CLAIM_STATUS.ClaimSubmittedForApproval) ? this.translateService.instant('msg_submit_approve_claim') : this.translateService.instant('msg_submitted_claim')
    const dialogRef = this.commonService.openConfirmYesNoDialogBig('', message);
    const submissionStatus = (status == CLAIM_STATUS.Submitted) ? 'review' : 'approval'; 

    dialogRef.afterClosed().subscribe((action: boolean) => {
      if (action) {
        this.commonService.showLoading();
        this.claimService.updateClaimStatus(this.claimId, status).subscribe({
          next: (response) => {
            this.commonService.hideLoading();
            if (response) {
              this.footer.currentStatus = this.claimDetail.claimStatus = status;
              this.commonService.openConfirmYesNoDialogBig('Notification', `Your request is submitted for ${submissionStatus}`, '', 'OK', '', false);
              this.router.navigate([ `claim/claim-detail/${this.claimId}/customer-view` ])
            }
          },
          error: () => {
            this.commonService.hideLoading();
          }
        });
      }
    });
  }
}
