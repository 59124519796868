import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { percentValidators } from 'src/app/helper/form/labour-rate.helper';
import { ChqWidgetsDropdownModel } from 'src/app/model/chq-widgets-dropdown-model';
import { ChqWidgetsInputModel, IControlValue } from 'src/app/model/chq-widgets-input-model';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { ChqDatetimepickerComponent } from '../chq-date-picker/chq-date-timepicker.component';
import { ChqDropdownComponent } from '../chq-dropdown/chq-dropdown.component';
import { validateRegex } from 'src/app/modules/quote/chq-new-cases/chq-new-customer/chq-new-customer-form-data-helper';

@Component({
  selector: 'chq-input',
  templateUrl: './chq-input.component.html',
  styleUrls: [ './chq-input.component.scss' ],
})

export class ChqInputComponent implements OnInit, AfterViewInit, OnChanges {
  @Input()
  public inputModel: ChqWidgetsInputModel = {
      displayValue: '',
      placeHolder: '',
      options: [],
      label: '',
      value: '',
      type: '',
      mode: '',
      name: '',
      showSelectedOption: true,
      cssClass: '',
      disabled: false
    };
  pattern: RegExp;
  public datepickerHeader?: any;
  public dropdownValidationMessage: string = '';
  public isPickerOpen: boolean = false;
  public formGroup: FormGroup = this.fb.group([]);
  @Output() controlOutput: EventEmitter<any> = new EventEmitter();
  @Output() clicked: EventEmitter<any> = new EventEmitter();
  @ViewChild('picker', { read: ElementRef }) calendarRef: ElementRef;
  landscape: MediaQueryList = window.matchMedia('(orientation: landscape),(max-device-width:1280)');
  @ViewChild('picker1') picker1: any;
  /**
   * CHecks if current browser is mac or not
   * @returns boolean
   */
  isiOS(): boolean {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  }


  /**
   * triggerClicked
   * 
   */
  triggerClicked(name: any): void {
    this.clicked.emit(name);
  }

  /**
   * autofill
   */
  get randomNumer(): number {
    return Math.random();
  }

  /**
   * trigger change
   */
  triggerObservable(): void {
    this.dropdownContainer?.getObservable();
  }

  /**
   * constructor
   * @param fb FormBuilder 
   */
  constructor(private fb: FormBuilder, private cd: ChangeDetectorRef, private monitorService: MonitorService) { }

  /**
   * ngAfterViewInit hook
   * @param fb FormBuilder 
   */
  ngAfterViewInit(): void {
    this.pattern = this.inputModel.pattern ?? /\d*/;
    this.monitorService.logEvent('ngAfterViewInit', [ 'ChqInputComponent', 'addenda-quote' ]);
    this.cd.detectChanges();
  }

  /**
   * detects changes in input attributes
   * @param changes 
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.monitorService.logEvent('ngOnChanges', [ 'ChqInputComponent', 'addenda-quote', {
      changes
    } ]);

    if (changes) {
      if (changes['inputModel'] && changes['inputModel'].currentValue) {
        if (this.inputModel.type === 'select') {
          this.dropdownModel = {
            ...this.inputModel,
            options: this.inputModel?.options || [],
            selectedOption: this.inputModel?.selectedOption || '',
            displayValue: this.inputModel?.displayValue || '',
            keyFieldName: this.inputModel?.fieldValue || '',
            showSelectOption: this.inputModel?.showSelectedOption !== undefined ? this.inputModel.showSelectedOption : true,
            mode: this.inputModel.mode,
            isMultiSelect: this.inputModel.isMultiSelect,
            showImageFlag: this.inputModel.showImageFlag
          };
          if (this.dropdownContainer) {
            this.dropdownContainer.setSelectedPrefill();
          }

        } else if (this.inputModel.value) {
          this.formGroup.get(this.inputModel.name)?.setValue(this.inputModel.value);
        } else {
          this.formGroup.get(this.inputModel.name)?.setValue('');
        }
        
        // if(this.inputModel.formControl && this.inputModel.formControl?.errors !== null){
        //   this.formGroup.get(this.inputModel.name).markAsDirty();
        //   this.formGroup.get(this.inputModel.name).markAllAsTouched();
        // }
      }
    }
  }

  /**
   * close dropdown
   */
  closeDropdown(): void {
    this.dropdownContainer?.closeDropdown();
  }

  /**
   * scrollIntoView
   */
  scrollIntoView($event: FocusEvent): void {
    if ($event) {
      ($event.target as HTMLElement).scrollIntoView();
    }
  }

  /**
   * checks if input is empty.
   * @returns void
   */
  checkInputValue(): boolean {
    return this.input?.nativeElement.value || this.inputModel.mode === 'text';
  }

  /**
   * on textarea input
   */
  textareaChange($event): void {
    const inputObj = this.formGroup.get(this.inputModel.name);
    this.controlOutput.emit({
      'name': this.inputModel.name, 'value': ($event.target as any).value,
      'isValid': inputObj?.valid
    });
  }
  /**
   * check and output control validation
   */
  checkInputStatus(): void {

    if (this.inputModel.type !== 'select' && this.inputModel.type !== 'checkbox') {

      this.formGroup.get(this.inputModel.name)?.statusChanges.subscribe(() => {
        setTimeout(() => {
          this.isPickerOpen = false;
        });
        const inputObj = this.formGroup.get(this.inputModel.name);
        //if (inputObj?.valid) {
        const controlVal = inputObj?.value;
        let selectedOptionId = null;
        if (this.inputModel.type === 'radio' || this.inputModel.type === 'checkbox') {
          const options = this.inputModel.options;
          const selectedOptions = options.filter((value: any) => {
            return value.label === controlVal;
          });
          if (selectedOptions && selectedOptions.length > 0) {
            selectedOptionId = selectedOptions[0].id;
          }

        }
        this.controlOutput.emit({
          'name': this.inputModel.name, 'value': inputObj?.value, 'keyFieldValue': selectedOptionId,
          'isValid': inputObj?.valid
        });
        //}
      });
    }

  }

  /**
   * getDropdown Values
  */
  getDropdownOutput($event: IControlValue): void {
    this.monitorService.logEvent('getDropdownOutput', [ 'ChqInputComponent', 'addenda-quote', {
      $event
    } ]);
    if ($event.validationMessage) {
      this.dropdownValidationMessage = $event.validationMessage;
    } else {
      this.dropdownValidationMessage = '';
    }
    this.controlOutput.emit($event);
  }

  /**
 * getCheckbox Values
*/
  checkboxValueChange($event: any, name: string, value: string, option: any): void {
    if ($event.validationMessage) {
      this.dropdownValidationMessage = $event.validationMessage;
    } else {
      this.dropdownValidationMessage = '';
    }
  
    option.checked = !option.checked;  
    this.controlOutput.emit({
      'name': name, 'value': $event.target.checked, 'keyFieldValue': value ? value : option?.id, 'type': 'checkbox'
    });
    setTimeout(()=>{
      const selected = this.inputModel.options?.filter((v)=>{
        return v.checked === true;
      });
      if(selected.length > 0){
        this.formGroup.get(this.inputModel.name)?.setValue(this.inputModel.value);
      }else{
        this.formGroup.get(this.inputModel.name)?.setValue('');
      }
    }, 10);
  }


  /**
   * update validations
   */
  updateCurrentValidation(pattern: string, retainValue?: boolean, nonMandatory?:boolean, isExternal?:boolean): void {

    this.monitorService.logEvent('updateCurrentValidation', [ 'ChqInputComponent', 'addenda-quote', {
      pattern,
      retainValue
    } ]);
    if (!retainValue) {
      this.formGroup.get(this.inputModel.name)?.setValue('');
    }

    let validation = Validators.pattern(pattern);
    if(isExternal){
      validation = validateRegex(pattern, isExternal);
    }
    if(nonMandatory){
      this.formGroup.get(this.inputModel.name)?.setValidators(Validators.compose([ validation ]));
    }else{
      this.formGroup.get(this.inputModel.name)?.setValidators(Validators.compose([ Validators.required, validation ]));
    }
    
    this.inputModel.validation.validationMessage = (error: ValidationErrors): string => {
      if (error['required']) {
        return 'Please enter mobile number'
      }
      if (error['pattern'] || error['invalidChar']) {
        return 'Please enter valid mobile number'
      }
      return '';
    }

    this.formGroup.get(this.inputModel.name)?.updateValueAndValidity();
    this.formGroup.get(this.inputModel.name)?.markAsUntouched();
  }

  /**
   *update validation for vehicle 
  */
  setValidatorForVehicle(validatorFunctions: ValidatorFn[]): void {
    this.monitorService.logEvent('setValidatorForVehicle', [ 'ChqInputComponent', 'addenda-quote' ]);
    this.formGroup.get(this.inputModel.name)?.setValidators(Validators.compose(validatorFunctions));

    this.inputModel.validation.validationMessage = (error: ValidationErrors): string => {
      if(this.inputModel.name == 'carMilege') {
        if (error['required']) {
          return 'Please enter car mileage'
        }
        if (error['maxlength']) {
          return 'Car mileage should have not more than 7 characters'
        }
        if (error['pattern']) {
          return 'Only numeric characters are allowed'
        }
        if(error['invalidCarMileage']) {
          return 'Car mileage should be greater than 0'
        }
      } else if(this.inputModel.name == 'licensePlate') {
        if (error['required']) {
          return 'Please enter license plate'
        }
        if (error['maxlength']) {
          return 'License plate should have not more than 12 characters'
        }
        if (error['pattern']) {
          return 'Only alphanumberic characters are allowed'
        }
        if (error['invalidLicensePlate']) {
          return 'License plate should not be 0'
        }
      }

      return '';
    }

    this.formGroup.get(this.inputModel.name)?.updateValueAndValidity();
    this.formGroup.get(this.inputModel.name)?.markAsUntouched();
  }

  /**
   *update validation for vehicle 
  */
  setValidatorForPolicyChasisPlateNumber(validatorFunctions: ValidatorFn[], type?:any): void {
    this.monitorService.logEvent('onFormUpdate', [ 'ChqInputComponent', 'addenda-claim' ]);
    this.formGroup.get(this.inputModel.name)?.setValidators(Validators.compose(validatorFunctions));

    this.inputModel.validation.validationMessage = (error: ValidationErrors): string => {
      if(this.inputModel.name == 'searchInputData') {
        if (error['required']) {
          return 'Please Enter Number.'
        }
        if (error['maxlength'] && type === 'Plate Number') {
          return 'Vehicle Registration / Plate Number should have not more than 12 characters'
        }
        if (error['pattern']&& type === 'Plate Number') {
          return 'Only alpha-numeric characters are allowed'
        } 
        if (error['pattern']&& type === 'Chasis Number') {
          return 'Vin should not contain I,Q,O'
        }
        if (error['invalidLicensePlate']) {
          return 'Vehicle Registration / Plate Number should not be 0'
        }
        if (error['minlength']) {
          return 'Vin should have 17 characters'
        }
        if (error['maxlength'] && type === 'Chasis Number') {
          return 'Vin should have not more than 17 characters'
        }
      } 
      return '';
    }

    this.formGroup.get(this.inputModel.name)?.updateValueAndValidity();
    this.formGroup.get(this.inputModel.name)?.markAsUntouched();
  }

  /**
   *update validation for controls 
  */
  setValidatorForControls(validatorFunctions: ValidatorFn[]): void {
    this.monitorService.logEvent('setValidatorForControls', [ 'ChqInputComponent', 'addenda-repair' ]);
    this.formGroup.get(this.inputModel.name)?.setValidators(Validators.compose(validatorFunctions));

    this.inputModel.validation.validationMessage = (error: ValidationErrors): string => {
      if(this.inputModel.name == 'communicationChannel') {
        if (error['required']) {
          return 'required_comm_chan'
        }
      } else if (this.inputModel.name == 'bookingInDate') {
        if (error['required']) {
          return 'required_date'
        } else if(error['invalidDateFormat']) {
          return 'msg_enter_future_date'
        }
      }

      return '';
    }

    this.formGroup.get(this.inputModel.name)?.updateValueAndValidity();
    this.formGroup.get(this.inputModel.name)?.markAsUntouched();
  }

  /**
   * remove input validators
   */
  setValidatorForLabour(): void {
    this.monitorService.logEvent('setValidatorForLabour', [ 'ChqInputComponent', 'addenda-quote' ]);
    this.formGroup.get(this.inputModel.name)?.setValidators(Validators.compose([ Validators.pattern('^\\d+$'), percentValidators() ]));

    this.inputModel.validation.validationMessage = (error: ValidationErrors): string => {
      if (error['pattern']) {
        return 'Only whole numbers are allowed'
      } else if (error['invalidData']) {
        return 'Value should be between 0 and 99'
      }
      return '';
    }

    this.formGroup.get(this.inputModel.name)?.updateValueAndValidity();
    this.formGroup.get(this.inputModel.name)?.markAsUntouched();
  }

  /**
   * set untouched
   */
  setUnTouched(): void {
    this.formGroup.get(this.inputModel.name)?.markAsUntouched();
    this.formGroup.get(this.inputModel.name)?.markAsPristine();
  } 

  /**
   * set dirty
   */
  setDirty(errors: any = null): void {
    this.formGroup.get(this.inputModel.name)?.markAsDirty();
    this.formGroup.get(this.inputModel.name)?.markAsTouched();
    if(errors) {
      this.formGroup.get(this.inputModel.name)?.setErrors(errors);
    }
 
  } 

  /**
   * onInit lifecycle hook
   */
  ngOnInit(): void {
    this.monitorService.logEvent('ngOnInit', [ 'ChqInputComponent', 'addenda-quote' ]);
    if (this.inputModel.type === 'select') {
      this.dropdownModel = {
        ...this.inputModel,
        options: this.inputModel?.options || [],
        selectedOption: this.inputModel?.selectedOption || '',
        keyFieldName: this.inputModel?.fieldValue || '',
        displayValue: this.inputModel?.displayValue || '',
        showSelectOption: this.inputModel?.showSelectedOption !== undefined ? this.inputModel.showSelectedOption : true,
      };
    }

    if (this.isiOS()) {
      this.datepickerHeader = null;
    } else {
      this.datepickerHeader = ChqDatetimepickerComponent;
    }
    const updateOn = this.inputModel.type === 'radio' ? 'change' : 'change';
    this.formGroup = this.fb.group([], { updateOn: updateOn });
    if (this.inputModel.validation?.validationFunction) {
      this.formGroup.addControl(
        this.inputModel.name,
        new FormControl((this.inputModel.type != 'number') ? (this.inputModel.value || '') : this.inputModel.value,
          this.inputModel.validation.validationFunction())
      )
    } else {
      this.formGroup.addControl(
        this.inputModel.name,
        new FormControl(this.inputModel.value || '')
      );
    }
    if (this.inputModel.value) {
      this.formGroup.get(this.inputModel.name).markAsTouched();
    } else if (this.inputModel.setUntouched) {
      this.formGroup.get(this.inputModel.name).markAsUntouched();
    }

    if (this.inputModel.readonly) {
      this.formGroup.get(this.inputModel.name).disable();
    }

    this.inputModel.formControl = this.formGroup.get(this.inputModel.name) as FormControl;
    this.checkInputStatus();
  }

  /**
 * datepicker closed
 */
  onClose(): void {
    this.isPickerOpen = false;
  }

  /**
   * opens datePicker
   * @param picker 
   */
  openDatePicker(picker: any): void {
    this.isPickerOpen = true;
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
      /*setTimeout(() => {
        const vh = window.innerHeight;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      }, 200);*/
    }
    setTimeout(() => {
      picker.open();
    }, 300);
  }

  /**
   * post search icon click
   */
  postSearchIconClick(): void {
    this.controlOutput.emit({ close: true });
  }

  /**
   * no validation
   */
  noValidation(): boolean {
    return true;
  }

  /**
  * onChange
  */
  addressChanged(address): void {
    this.controlOutput.emit({
      'name': this.inputModel.name, 
      'value': address,
      'isValid': true
    });
  }

  /**
   * returns formControl
   */
  get formControl(): AbstractControl<any, any> {
    return this.formGroup.controls[this.inputModel.name];
  }

  @ViewChild('input') input?: ElementRef;
  @ViewChild('dropdownContainer') dropdownContainer?: ChqDropdownComponent;
  dropdownModel?: ChqWidgetsDropdownModel;

  /** function */
  numericFilter(): void{
    this.formGroup.get(this.inputModel.name)?.setValue(this.formGroup.get(this.inputModel.name)?.getRawValue().replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'));
  }
}
