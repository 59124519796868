import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { BehaviorSubject, catchError, forkJoin, of, Subject } from 'rxjs';
import { PartialCaseDetail } from 'src/app/model/chq-cases-model';
import { ChqInspectionStep } from 'src/app/model/chq-inspection-step';
import { FooterModel, Modules, UploadStepModel } from 'src/app/model/chq-upload-model';
import { ChqWidgetsHeaderModel } from 'src/app/model/chq-widgets-header-model';
import { inspectionpartPartMapper } from 'src/app/modules/quote/chq-new-cases/chq-new-customer/chq-new-customer-form-data-helper';
import { BookingService } from 'src/app/services/booking/booking.service';
import { CaseService } from 'src/app/services/case/case.service';
import { CommonService } from 'src/app/services/common/common.service';
import { InspectionService } from 'src/app/services/inspection/inspection.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { RepairEstimateService } from 'src/app/services/repair-estimate/repair-estimate.service';
import { ChqImagesUploaderComponent } from 'src/app/widgets/chq-images-uploader/chq-images-uploader.component';
import { DamageReport } from 'src/app/model/chq-damage-report';
import { ConfigurationService } from 'src/app/config/configuration/service/configuration.service';
import { FNOL_NEXT_LABEL, QUOTE_NEXT_LABEL, QUOTE_ROUTE } from 'src/app/config/configuration/configuration.constant';
import { repairEstimatedStatuses } from 'src/app/config/constants/app.constants';
import { QuoteManagementService } from 'src/app/services/quote-management/quote-management.service';
import { AddPhotosComponent } from '../add-photos/add-photos.component';


const photosHeader: ChqWidgetsHeaderModel = {
  title: 'add_damage_photos',
  step: 4,
  description: 'damage_photos_msg',
  stepName: 'step_four'
};

@Component({
  selector: 'new-photos',
  templateUrl: './new-photos.component.html',
  styleUrls: [ './new-photos.component.scss' ]
})
export class NewPhotosComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() photosHeader: ChqWidgetsHeaderModel = photosHeader;
  @Input() currentObjectId: number;
  @Input() currentDomainId: number;
  @ViewChild('addPhotos') addPhotos: AddPhotosComponent;
  @Input() fnolGUID: string = '';
  @Input() currentInspectionID: number;
  @Input() currentVehicle: any;
  @Input() isReviewSection: boolean;
  @Input() filteredSteps: string[] = [];
  @Input() quoteAssessmentId: string = '';
  @Input() hideBulk:boolean;
  @Input() displayOutline:boolean;
  @Input() hideEstimate:boolean;
  @Input() hideVideo:boolean;
  @Input() hideAi:boolean;
  @Input() isQuoteAssessmentModule:boolean;
  @Input() quoteAssessmentData: any;
  @Input() submitSection: any;
  // @Input() status: any;
  @Output() currentUploadMode: EventEmitter<{ 'mode': string, 'id'?: string }> = new EventEmitter();
  @Output() imagesNextEvent: EventEmitter<any> = new EventEmitter();
  @Output() videoInspectionItemDetails: EventEmitter<any> = new EventEmitter();
  @Output() fraudDetectionEvent: EventEmitter<any> = new EventEmitter();
  addPhotosHeader: ChqWidgetsHeaderModel;
  public imageStatusSteps: UploadStepModel[] = [];
  public showUploading: boolean = false;
  public isMultipleUpload: boolean = false;
  public triggerMassUpload: boolean = false;
  public caseStatus = '';
  public caseId: string = '0';
  public inspection: any;
  public bookingId: string = '';
  public vehicleId: number;
  public domainId: number;
  public uploadType: string;
  public showDamageAnalysis: boolean;
  public objectId: number;
  public minimumPhotosRequired: number = 0;
  public hideContent: boolean = true;
  public currentAdditionalModeMultiple = false;
  public vehicleType: string;
  public isBulkUploaded: boolean;
  public caseData: any;
  public aiReport: { damageRecommendations: any[], damageDetails: DamageReport[] }
  public inspectionId: number;
  private currentInspectionTemplate: any;
  public hideUploader = false;
  private repairTypes: any = [];
  private aiFlags: any;
  private repairType = '';
  public noImagesData: boolean = false;
  private repairId: string = '';
  public inspectionTemplatesJSONString = '';
  private currentRepairStatus = '';
  public partialCaseDetail: PartialCaseDetail;
  public repairDetail: any;
  @ViewChild('imageUploader') imageUploader: ChqImagesUploaderComponent;
  @Output() stepLoad: EventEmitter<any> = new EventEmitter();
  public inspectionTemplateId: number;
  public currentObservable: (currentStep: UploadStepModel) => void;
  public inspectionTemplates: ChqInspectionStep[];
  public inspectionItemTemplates: ChqInspectionStep[];
  public videoInspectionItemTemplate: ChqInspectionStep;
  public inspectionTemplateList: any[];
  public inspectionDetail: any;
  public originalSteps: UploadStepModel[] = [];
  public showNoAccess: boolean = false;
  public footer: FooterModel = {
    text: 'Next create estimate',
    icon: 'boardview',
    route: 'case/gt',
    status: 'UploadPhotos',
    caseId: '',
    currentStatus: '',
    showButton: true,
    showUploadOption: true
  }
  showRepairRecommendation = false;

  statusUpdated: boolean = false;
  private currentOrgId: number;
  isExternal: boolean = false;
  aiEstimationStatus: string = undefined;
  /**
   * handle back
   */
  handleBack($event: { back: boolean }): void {
    this.showDamageAnalysis = !$event.back;
  }

  /**
   * get ai flags
   */
  getAiFlags(): void {
    this.aiFlags = this.configurationService.getAIAnalysisFlags();
  }

  /**
   * showAI
   */
  get displayAI(): boolean {
    if(this.hideAi) {
      return false
    } else if (this.aiFlags) {
      return this.aiFlags['DamageAnalysis_DisplayType']?.toLowerCase() === 'true';
    }
    return true;
  }

  /**
   * check repair types
   */
  get checkRepairTypes(): boolean {
    if (location.href.indexOf('repair') === -1) {
      return true;
    }

    if (this.repairType !== 'repair') {
      return true;
    }

    return this.repairTypes.filter((v) => {
      return v.repairServiceType === 'Body' || v.repairServiceType === 'Paint';
    }).length > 0;
  }

  /**
   * go to gtEstimate
   */
  redirectToGtEstimate(): void {
    this.commonService.showLoading();
    if (this.caseStatus?.toLowerCase() === 'uploaddocuments' || this.caseStatus?.toLowerCase() === 'uploadphotos') {
      this.estimateService.createEstimate(this.caseId, this.inspectionId, this.vehicleId, this.domainId).subscribe({
        next: (resp: any) => {
          if (resp) {
            if (resp?.data?.url) {
              location.href = resp.data.url;
              sessionStorage.setItem('update', '1');
            }
          }
        },
        complete: () => {
          this.commonService.hideLoading();
        }
      });
    } else {
      this.router.navigate([ `/quote/case/${this.caseId}/estimate` ]);
    }
  }

  @Input() sendRequest: number;

  /**
   * constructor
   * @param fb ChangeDetectorRef
   */
  constructor(private cd: ChangeDetectorRef, private commonService: CommonService,
    private route: ActivatedRoute,
    private router: Router, private caseService: CaseService,
    private monitorService: MonitorService,
    private inspectionService: InspectionService,
    private bookingService: BookingService,
    private configurationService: ConfigurationService,
    private estimateService: RepairEstimateService,
    private quoteManagementService: QuoteManagementService) {
    if (commonService.pageName.closed) {
      commonService.pageName = new BehaviorSubject<string>(null);
    }
    commonService.pageName.next('photos');

    if (this.commonService.accessRight.closed) {
      this.commonService.accessRight = new BehaviorSubject<boolean>(true);
    }
  }

  /**
   * update page status
   */
  updateCurrentStatus($event): void {
    this.monitorService.logEvent('updateCurrentStatus', [ 'ChqNewPhotosComponent', 'addenda-quote', {
      caseId: $event
    } ]);
    if ($event.initial) {
      this.setInitialMode();
      return;
    }
    this.showUploading = true;

    // if ((this.footer.repairId && this.footer.from == Modules.quote)
    // || (this.footer.caseId && this.footer.from == Modules.repair)) {

    //   this.commonService.showChangeWarningMessage(this.uploadBulkUploadFile.bind(this, $event.steps),
    //     this.footer.from == Modules.quote ? 'Repair' : 'Quote');
    // } else {
    //   this.uploadBulkUploadFile($event.steps);
    // }
    this.uploadBulkUploadFile($event.steps);
  }

  /**
   * handle delete all
   */
  handleDeleteAll(): void {
    this.setInitialMode();
  }

  /**
   * ngAfterViewInit hook
   */
  ngAfterViewInit(): void {
    this.commonService.updatePage('/quote/case/0', 'photos');
    this.cd.detectChanges();
    this.monitorService.logEvent('ngAfterViewInit', [ 'ChqNewPhotosComponent', 'addenda-quote' ]);
  }

  /**
 * upload bulk file
 * @param uploads
 */
  uploadBulkUploadFile(uploads: UploadStepModel[]): void {
    for (let i = 0; i < uploads?.length; i++) {

      const img = new Image();
      img.src = uploads[i].image as string;
      img.onload = (): void => {
        const uploadObject = {
          'height': img.height,
          'width': img.width,
          'inspectionId': this.inspectionId,
          'templateId': this.inspectionTemplateId,
          'type': this.vehicleType || '',
          'isBulk': true
        }
        const step = uploads[i];
        step.uploadInProgress = true;
        step.isSkipped = false;
        step.isUploadFailed = false;
        step.isUploadSuccess = false;
        this.monitorService.logEvent('uploadBulkUploadFile', [ 'ChqNewPhotosComponent', 'addenda-quote', {
          uploadObject
        } ]);
        this.inspectionService.uploadImageBulk(uploadObject, step.imageFile).subscribe({
          next: (data: any) => {
            step.url = data.rawImage?.rawAzureBlobUrl;
            step.inspectionItem = data;
            step.procedureSteps = 'Bulk Upload Image';
            this.imageUploader?.updateUploadStatus('success', step, null, data);
            this.footer = { ...this.footer, showUploadOption: false };
            if (location.href.includes('repair') && (this.currentRepairStatus?.toLowerCase() === 'draft' || this.currentRepairStatus?.toLowerCase() === 'uploaddocuments')) {
              this.updateRepairStatus(this.repairId);
            }
            if (location.href.includes('quote') && (this.footer.currentStatus?.toLowerCase() === 'uploaddocuments')) {
              this.updateStatus();
            }
          },
          error: (err: any) => {
            if (err.status != 401) {
              this.imageUploader.imageStatusSteps = this.imageUploader.imageStatusSteps.filter((ig: UploadStepModel) => {
                return (ig.index !== step.index || ig.stepName !== step.stepName || ig.uploadInProgress !== true);
              })
              this.imageUploader?.updateUploadStatus('failed', step, err, null, true);
            }
            this.monitorService.logException(err, SeverityLevel.Error);
          },
        })
      }

    }

  }


  /**
   * update repair status
   */
  updateRepairStatus(repairId: string): void {
    if (!this.statusUpdated) {
      this.statusUpdated = true;
      this.bookingService.updateRepairStatus('UploadPhotos', repairId, '').subscribe({
        next: () => {
          this.currentRepairStatus = 'UploadPhotos';
          this.footer.currentStatus = this.currentRepairStatus;
          this.commonService.showLoading();
          this.bookingService.getRepairDetailByGuid(this.repairId).subscribe({
            next: async (result: any) => {
              if (result?.data) {
                this.commonService.repairDetails.next(result.data);
                this.commonService.bookingId.next(result.data.repairNumber);
                this.commonService.hideLoading();
              }
            },
            error: (err: any) => {
              this.monitorService.logException(err, SeverityLevel.Error);
              this.commonService.hideLoading();
            }
          });
        }
      });
    }

  }

  /**
   * upload photos
   */
  uploadFile(currentStep: UploadStepModel): void {
    const uploadObject = {
      'height': currentStep.imageHeight,
      'width': currentStep.imageWidth,
      'inspectionId': this.inspectionId,
      'templateId': currentStep.inspectionItem.inspectionItemTemplateID || this.inspectionTemplateId,
      'inspectionitemId': currentStep.inspectionItem.id,
      'type': this.vehicleType || '',
      'isBulk': false
    }

    this.monitorService.logEvent('uploadFile', [ 'ChqNewPhotosComponent', 'addenda-quote', {
      uploadObject
    } ]);

    currentStep.uploadInProgress = true;
    currentStep.isSkipped = false;
    currentStep.isUploadFailed = false;
    currentStep.isUploadSuccess = false;
    this.commonService.inProgressSteps.next(1);
    this.footer = { ...this.footer, showUploadOption: false };
    this.commonService.showNotification(`Your photo ‘${currentStep.stepName}’ is currently uploading won’t be long and we’ll notify
       you when its uploaded, you can continue to take your photos.`);
    this.inspectionService.uploadImageBulk(uploadObject, currentStep.imageFile).subscribe({
      next: (data: any) => {
        const msg = `Your photo ‘${currentStep.stepName}’ is uploaded successfully.`;
        this.commonService.showNotification(msg);
        const inspectionItem = this.inspectionItemTemplates?.find((x: any) => x.inspectionItem.id == data?.id);
        inspectionItem.inspectionItem.rawAzureBlobUrl = data.rawImage?.rawAzureBlobUrl;
        if (inspectionItem) {
          currentStep.url = data.rawImage?.rawAzureBlobUrl;
          this.imageUploader.updateUploadStatus('success', currentStep, null, data);
        }
        if (location.href.includes('repair') && (this.currentRepairStatus == 'Draft' || this.currentRepairStatus === 'UploadDocuments')) {
          this.updateRepairStatus(this.repairId);
        }
        if (location.href.includes('quote') && (this.footer.currentStatus?.toLowerCase() === 'uploaddocuments')) {
          this.updateStatus();
        }
      },
      error: (err: any) => {
        if (err.status != 401) {
          const msg = `Your photo ‘${currentStep.stepName}’ failed to upload. Please try to upload again.`;
          this.commonService.showNotification(msg);
          this.imageUploader?.updateUploadStatus('failed', currentStep, err);
          this.monitorService.logException(err, SeverityLevel.Error);
        }
      },
    })
  }

  /**
   * on destroy
   */
  ngOnDestroy(): void {
    this.commonService.pageName.unsubscribe();
    this.commonService.accessRight.unsubscribe();
    if (location.href.includes('quote')) {
      this.commonService.isViewOnly = false;
    }
    this.monitorService.logEvent('ngOnDestroy', [ 'ChqNewPhotosComponent', 'addenda-quote' ]);
  }

  /**
   * showRepairButton
   */
  get showEstimateButton(): boolean {
    return this.currentInspectionTemplate?.minImageLimit === 0 && this.inspectionItemTemplates?.filter((value: ChqInspectionStep) => {
      return value.isSkipEnabled !== true;
    }).length === 0;
  }

  /**
   * map inspection from api
   * @param inspectionData
   */
  mapInspection(inspectionData: any): void {
    if (inspectionData) {
      try {
        this.inspection = inspectionData;
        this.uploadType = this.inspection.uploadType;
        let currentMode = 'SPI';
        if (this.uploadType) {
          this.footer = { ...this.footer, showUploadOption: false };
        }
        if (this.uploadType === 'Bulk') {
          currentMode = 'BULK';
        }
        const bulkUploadTemplateItem = this.inspectionTemplates?.filter(step => step.name === 'Bulk Upload Image');
        let bulkUploadedItemsWithImages = [];
        if(bulkUploadTemplateItem?.length > 0) {
          bulkUploadedItemsWithImages = this.inspection.inspectionItems?.filter((step) => {
            return (step.name === 'Bulk Upload Image' || step.inspectionItemTemplateID === bulkUploadTemplateItem[0]?.id)
              && step.rawAzureBlobUrl
          });
        }

        // Only for repairer view, since the inspection template ids are different
        if (this.router.url.toString().indexOf('/repairer-view/') != -1) {
          bulkUploadedItemsWithImages = this.inspection.inspectionItems?.filter((step) => {
            return step.rawAzureBlobUrl
          });
        }

        if ((bulkUploadedItemsWithImages?.length > 0 || this.uploadType === 'Bulk') && (!this.filteredSteps || this.filteredSteps?.length === 0)) {
          const massUploadItems = [];
          this.currentUploadMode.emit({ 'mode': 'multiple' });
          for (const i in bulkUploadedItemsWithImages) {
            const currentObj = {
              ...bulkUploadTemplateItem[0], name: bulkUploadedItemsWithImages[i].name || bulkUploadTemplateItem[0]?.name,
              inspectionItem: bulkUploadedItemsWithImages[i],
              procedureSteps: this.router.url.toString().indexOf('/repairer-view/') != -1 ? bulkUploadedItemsWithImages[i].procedureSteps : bulkUploadTemplateItem[0].procedureSteps
            }
            massUploadItems.push(currentObj);
          }
          this.inspectionItemTemplates = massUploadItems;
        } else {

          this.currentUploadMode.emit({ 'mode': 'single' });
          this.inspectionTemplates?.map((chqInspectionStep: ChqInspectionStep) => {
            const item = this.inspection.inspectionItems?.find((el: any) => el.inspectionItemTemplateID == chqInspectionStep.id);
            if (item) {
              chqInspectionStep['inspectionItem'] = item;
            }
          });
          this.inspectionItemTemplates = this.inspectionTemplates?.filter(step => step.name != 'Bulk Upload Image');
        }
        if (bulkUploadTemplateItem?.length > 0) {this.inspectionTemplateId = bulkUploadTemplateItem[0].id}else{
          this.inspectionTemplateId = this.inspectionTemplates[0].id;
        }

        if(this.hideVideo) {
          this.inspectionItemTemplates = this.inspectionTemplates?.filter(step => step.name != 'Video');
          this.videoInspectionItemTemplate = this.inspectionTemplates?.find(step => step.name === 'Video');
          this.videoInspectionItemDetails.emit(this.videoInspectionItemTemplate)
        }

        this.inspectionItemTemplates?.map((x: ChqInspectionStep) => {
          if (x.inspectionItem?.rawAzureBlobUrl) {
            x.isUploadSuccess = true;
            x.image = x.inspectionItem?.rawAzureBlobUrl;
          } else {
            if (!x.inspectionItem) {
              x.inspectionItem = { rawAzureBlobUrl: '' };
            }
            x.inspectionItem.rawAzureBlobUrl = '';
            x.isUploadFailed = false;
            x.isUploadSuccess = false;
            x.uploadInProgress = false;
          }
        })
        if (this.uploadType) {
          if (currentMode === 'SPI') {
            this.isMultipleUpload = false;
            this.showUploading = true;
            if (this.addPhotosHeader.isRepairModule) {
              this.photosHeader = { ...this.photosHeader, title: 'back_smart_photo' };
            }
            this.minimumPhotosRequired = this.inspectionItemTemplates?.filter((value: ChqInspectionStep) => {
              return value.isSkipEnabled !== true;
            }).length;
            this.currentObservable = this.uploadFile.bind(this);
          } else {
            this.currentObservable = this.uploadBulkUploadFile.bind(this);
            this.isMultipleUpload = true;
            this.minimumPhotosRequired = this.currentInspectionTemplate.minImageLimit;
            this.triggerMassUpload = true;
            this.isBulkUploaded = true;
            if (this.addPhotosHeader.isRepairModule) {
              this.photosHeader = { ...this.photosHeader, title: 'bulk_upload' };
            }
          }
        }
        // Set AI estimation status
        this.aiEstimationStatus = inspectionData.aiEstimationStatus
      } catch (error: any) {
        console.log(error);
      }
    }
  }

  /**
   * navigates to next page
   */
  handleNavigation(domainId: number): void {
    this.monitorService.logEvent('handleNavigation', [ 'NewDocumentComponent', 'addenda-quote', {
      caseId: this.objectId
    } ]);
    if (domainId === 2100) {
      const stepData = this.configurationService.getStepByDomainId(2100)?.sort((a, b) => a.SortOrder - b.SortOrder);
      const imagesConfigIndex = stepData.findIndex((step) => { return step.ItemKey === 'Addenda_Quote_Photos' });
      if (imagesConfigIndex) {
        if (imagesConfigIndex === (stepData - 1)) {
          return;
        }
        const route = QUOTE_ROUTE[stepData[imagesConfigIndex + 1].ItemKey];
        if (route === QUOTE_ROUTE.Addenda_Quote_Repair_Estimate) {
          this.footer = { ...this.footer, finalRedirection: this.redirectToGtEstimate.bind(this) };
        } else {
          this.footer = { ...this.footer, route: `/quote/case/${this.caseId}/${route}`, finalRedirection: null };
        }
      }
    }
    if (domainId === 1100) {
      this.footer = {
        ...this.footer, status: '', finalRedirection: (): void => {
          this.imagesNextEvent.emit({ 'value': this.getNextStep('Fnol_Photos', 1100), 'step': 'navigate' });
        }
      };
    }
    if(domainId === 4102){
      this.footer = {
        ...this.footer, status: '', finalRedirection: (): void => {
          this.imagesNextEvent.emit({ 'value': 'video', 'step': 'navigate' });
        }
      };
    }
  }

  /**
   * go fnol next
   */
  fnolNext(): void {
    this.imagesNextEvent.emit({ 'value': this.getNextStep('Fnol_Photos', 1100), 'step': 'navigate' });
  }

  /**
   * inspection detail by id
   */
  getInspectionTemplateByObject(): void {
    for (let i = 0; i < this.inspectionItemTemplates?.length; i++) {
      const templatePart = this.inspectionItemTemplates[i];
      const inspectionStep = inspectionpartPartMapper(templatePart, i);
      const isAdditionalImage = inspectionStep.stepName?.toLowerCase().indexOf('additional photo') != -1;
      const isMassUpload = inspectionStep.stepName?.toLowerCase().indexOf('bulk upload image') != -1;
      const isAdditionalDocumentFromReviewPageOnly = isAdditionalImage && !inspectionStep.url;
      this.imageStatusSteps.push({
        ...inspectionStep, mandatory: !templatePart.isSkipEnabled, index: i,
        isAdditionalDocumentFromReviewPageOnly,
        isMassUpload,
        updatedDate: inspectionStep.url ? templatePart?.inspectionItem?.updatedDate : '',
        uploadedDate: inspectionStep.url ? templatePart?.inspectionItem?.created : '',
        domainId: this.domainId, objectId: this.objectId,
        uploadedPartGuid: '',
        uploadedGuid: ''
      });
    }
  }

  /**
   * update status
   */
  updateStatus(): void {
    if (!this.statusUpdated) {
      this.statusUpdated = true;
      this.monitorService.logEvent('updateStatus', [ 'ChqNewPhotosComponent', 'addenda-quote' ]);
      this.caseService.updateCaseStatus(this.caseId, 'UploadPhotos').subscribe({
        next: () => {
          this.footer = { ...this.footer, status: '' };
          this.partialCaseDetail.status = 'UploadPhotos';
        }
      })
    }
  }

  /**
   * handled if step is skipped
   */
  stepSkipped(): void {
    if (location.href.includes('repair') && (this.currentRepairStatus?.toLowerCase() === 'draft' || this.currentRepairStatus?.toLowerCase() === 'uploaddocuments')) {
      this.updateRepairStatus(this.repairId);
    }
    if (location.href.includes('quote') && (this.footer.currentStatus?.toLowerCase() === 'uploaddocuments')) {
      this.updateStatus();
    }
  }

  /**
   * get next step
   */
  getNextStep(currentModule: string, domainId: number): string {
    const stepData = this.configurationService.getStepByDomainId(domainId)?.sort((a, b) => a.SortOrder - b.SortOrder);
    const imageConfigIndex = stepData.findIndex((step) => { return step.ItemKey === currentModule });
    if (imageConfigIndex !== -1) {
      if (imageConfigIndex === (stepData.length - 1)) {
        return '';
      }
      return stepData[imageConfigIndex + 1].ItemKey;
    }
    return '';
  }

  /**
   * load server details
   */
  loadApiData(id: string): void {
    if (id && id != '0') {
      this.caseId = id;
      this.bookingId = id;
      this.commonService.showLoading();
      this.inspectionService.getInspectionTemplateList(this.currentDomainId).subscribe({
        next: (inspectionList: any) => {
          let isRepair = false;
          let inspectionTemplateId = 0;
          if (location.href.includes('repair')) {
            isRepair = true;
          }
          const repairInspectionTemplate = inspectionList?.data;

          if (repairInspectionTemplate?.length > 0) {
            this.commonService.repairInspectionTemplate = repairInspectionTemplate[0];
            inspectionTemplateId = repairInspectionTemplate[0].id
          }
          const promiseApi: any = {};
          promiseApi.inspectionTemplate = this.inspectionService.getInspectionTemplate(inspectionTemplateId);
          if (isRepair) {
            promiseApi.bookingDetail = this.bookingService.getRepairDetailByGuid(id);

          } else if (!this.currentObjectId) {
            this.commonService.userProfileData.subscribe((res) => {
              if (res && res.data) {
                const { organizationDetail } = res.data;
                this.currentOrgId = organizationDetail.id;
                const permissionList = res?.data.userPermission.automotiveServices[0].permissions;
                this.isExternal = this.commonService.hasPermission('case.external', permissionList);
              }
            });
            promiseApi.caseDetail = this.caseService.getCaseDetail(id);
          }
          const serverDetails = forkJoin(promiseApi).pipe(
            catchError(error => of(error))
          )
          serverDetails.subscribe({
            next: (resultMap: any) => {
              if (resultMap.inspectionTemplate) {

                const inspectionTemplateDetail = resultMap.inspectionTemplate.inspectionItemTemplates;
                this.currentInspectionTemplate = resultMap.inspectionTemplate;
                if (resultMap.inspectionDetail) {
                  this.inspectionDetail = resultMap.inspectionDetail;
                }
                this.inspectionTemplates = inspectionTemplateDetail;
                this.inspectionTemplatesJSONString = JSON.stringify(this.inspectionTemplates);
                if (resultMap.caseDetail) {
                  const { caseNumber, id, status, statusId, domainID, inspections, vehicleModel, vehicleId, organizationDetail } = resultMap.caseDetail.data;
                  this.caseData = resultMap.caseDetail.data;
                  this.partialCaseDetail = resultMap.caseDetail.data;

                  if (this.currentOrgId != organizationDetail?.organizationId && !this.isExternal) {
                    this.commonService.isViewOnly = true;
                  } else {
                    this.commonService.isViewOnly = false;
                  }

                  this.vehicleType = vehicleModel;
                  this.vehicleId = vehicleId;
                  this.domainId = domainID;
                  this.commonService.caseStatus.next(status);
                  this.commonService.caseDetail.next(resultMap.caseDetail?.data);
                  this.caseStatus = status;
                  if (status?.toLowerCase() === 'uploaddocuments') {
                    //this.updateStatus();
                  } else {
                    this.footer = { ...this.footer, status: '', caseId: id }
                  }
                  this.footer.currentStatus = status;
                  if (inspections?.length > 0) {
                    this.inspectionId = inspections[0].inspectionId;
                    this.commonService.inspectionId.next(this.inspectionId);
                  }
                  this.photosHeader.caseNumber = caseNumber;

                  if (this.caseData.repairGuid) {
                    this.footer.repairId = this.caseData.repairGuid;
                    this.footer.from = Modules.quote;
                  }

                  this.objectId = id;
                  this.footer.objectId = id;
                  this.footer.domainId = this.domainId
                  const nextLabel = this.getNextStep('Addenda_Quote_Photos', this.domainId);
                  this.footer = { ...this.footer, text: nextLabel ? QUOTE_NEXT_LABEL[nextLabel] : 'next_create_estimate' };
                  if (repairEstimatedStatuses.includes(this.caseData?.status.toLowerCase())) {
                    this.footer = {
                      ...this.footer,
                      text: 'view_estimate',
                    }
                  }
                  this.handleNavigation(2100);
                }
                if (resultMap.bookingDetail) {
                  const { id, vehicle, repairServiceTypes, repairGuid, domainId, status, quoteId, repairType, repairNumber, isRepairCreatedFromCase } = resultMap.bookingDetail.data;
                  this.repairDetail = resultMap.bookingDetail.data;
                  const { vehicleModel, vehicleId } = vehicle;

                  this.objectId = id;
                  this.footer.objectId = id;
                  this.footer.domainId = this.domainId
                  this.domainId = domainId;
                  this.repairId = repairGuid;
                  this.currentRepairStatus = status;
                  this.repairTypes = repairServiceTypes;
                  this.repairType = repairType;
                  this.inspectionId = vehicle.inspectionId;
                  this.vehicleType = vehicleModel;
                  this.vehicleId = vehicleId;
                  this.footer = {
                    ...this.footer, currentStatus: status, isRepairModule: true, caseId: quoteId,
                    from: Modules.repair, repairTypes: repairServiceTypes, isRepairCreatedFromCase: isRepairCreatedFromCase, repairId: repairGuid
                  };
                  if (repairEstimatedStatuses.includes(this.repairDetail?.status.toLowerCase())) {
                    this.footer = {
                      ...this.footer,
                      text: 'view_estimate',
                    }
                  }
                  // Send booking data in observable
                  this.commonService.repairDetails.next(resultMap.bookingDetail.data)
                  this.commonService.bookingId.next(repairNumber);
                  this.commonService.repairStatus.next(status);
                }

                if (this.currentDomainId) {
                  this.domainId = this.currentDomainId;
                }

                if (this.currentObjectId) {
                  this.objectId = this.currentObjectId;
                }
                this.footer = { ...this.footer, objectId: this.objectId, domainId: this.domainId }
                if (this.currentInspectionID) {
                  this.inspectionId = this.currentInspectionID;
                  this.handleNavigation(this.currentDomainId);
                  this.getAiFlags();
                  if(this.currentDomainId === 1100){
                    const nextLabel = this.getNextStep('Fnol_Photos', this.currentDomainId);
                    this.footer = { ...this.footer, text: nextLabel ? FNOL_NEXT_LABEL[nextLabel] : 'add_photos', icon: '' };
                  }else if(this.currentDomainId === 4102){
                    const nextLabel = 'add_video';
                    this.footer = { ...this.footer, text: nextLabel, icon: '' };
                  }
                  
                 
                }

                if (this.inspectionId) {
                  this.inspectionService.getInspection(this.inspectionId).subscribe({
                    next: (inspectionDetailResponse: any) => {
                      if (inspectionDetailResponse) {
                        this.mapInspection(inspectionDetailResponse);
                        this.noImagesData = false;
                        this.getInspectionTemplateByObject();
                        setTimeout(() => {
                          this.hideContent = false;
                          if (this.isBulkUploaded) {
                            this.showUploading = true;
                            this.isMultipleUpload = true;
                            this.currentAdditionalModeMultiple = true;
                          }
                          if (this.uploadType || this.isReviewSection) {
                            this.imageUploader.goToReview();
                            this.showUploading = true;
                          }else{
                            if(this.hideBulk){
                              this.addPhotos.allowSingleUpload();
                            }
                          }
                          this.stepLoad.emit(this.imageStatusSteps);
                        });
                      }
                    },
                    error: () => {
                      this.noImagesData = true;
                    },
                    complete: () => {
                      this.commonService.hideLoading();
                    },
                  });
                } else {
                  this.noImagesData = true;
                  this.hideContent = false;
                  this.commonService.hideLoading();
                }
              } else {
                this.noImagesData = true;
                this.commonService.hideLoading();
              }
            }
          });
        }, error: () => {
          this.noImagesData = true;
          this.commonService.hideLoading();
        }
      });

    }
  }



  /**
   * oninit
   */
  ngOnInit(): void {
    const currentObjectId = this.route.parent?.snapshot.paramMap.get('id');
    this.addPhotosHeader = { ...this.photosHeader, isHeaderOnly: false };
    this.monitorService.logEvent('ngOnInit', [ 'ChqNewPhotosComponent', 'addenda-quote', {
      caseId: currentObjectId,
      repairId: currentObjectId
    } ]);

    this.footer = {
      ...this.footer, caseId: this.photosHeader.isRepairModule ? '' : currentObjectId,
      repairId: this.photosHeader.isRepairModule ? currentObjectId : '',
      quoteAssessmentId: this.photosHeader.isQuoteAssessmentModule ? this.quoteAssessmentId: '',
      handleBack: this.handleBack.bind(this), 
      finalRedirection: this.redirectToGtEstimate.bind(this),
    }
    // if(this.status) {
    //   this.footer = { ...this.footer, currentStatus: this.status }
    // }

    this.commonService.accessRight.subscribe({
      next: (res: boolean) => {
        if (!res) {
          this.showNoAccess = true;
        }
      }
    });

    this.loadApiData(currentObjectId || this.currentInspectionID?.toString());
    const container: any = document.querySelector('.addenda-container');
    if (container) {
      container.scrollTop = 0;
    }

    this.currentObservable = this.uploadFile.bind(this);
  }

  /**
   * reload inspection and map
   */
  public reloadInspection(): void {
    this.commonService.showLoading();
    this.inspectionTemplates = JSON.parse(this.inspectionTemplatesJSONString);
    if (this.inspectionId) {
      this.inspectionService.getInspection(this.inspectionId).subscribe({
        next: (inspectionDetailResponse: any) => {
          if (inspectionDetailResponse) {
            this.mapInspection(inspectionDetailResponse);
            this.getInspectionTemplateByObject();
            this.isMultipleUpload = false;

            this.isBulkUploaded = false;
            this.currentAdditionalModeMultiple = false;
            this.triggerMassUpload = false;
            setTimeout(() => {
              this.hideContent = false;
              this.showUploading = true;
            })

          }
        },
        complete: () => {
          this.commonService.hideLoading();
        },
      });
    }
  }


  /**
   * set to initial mode
   */
  setInitialMode(): void {
    this.showUploading = false;
    this.imageStatusSteps = [];
    this.triggerMassUpload = false;
    this.isBulkUploaded = false;
    this.imageUploader.isReviewSection = this.isReviewSection;
    this.isMultipleUpload = false;
    this.currentAdditionalModeMultiple = false;
  }

  /**
   *handle mode selection
   * @param $event
   */
  handleMode($event: any): void {
    this.monitorService.logEvent('handleMode', [ 'ChqNewPhotosComponent', 'addenda-quote', {
      $event
    } ]);
    if (!this.inspectionId) {
      this.currentUploadMode.emit({ 'mode': 'error', 'id': this.bookingId });
      return;
    }
    this.currentUploadMode.emit($event);

    if ($event.mode === 'single') {

      if (this.imageStatusSteps?.length === 0) {
        this.isMultipleUpload = false;
        this.isBulkUploaded = false;
        this.currentAdditionalModeMultiple = false;
        this.triggerMassUpload = false;
        this.minimumPhotosRequired = this.inspectionItemTemplates.filter((value: ChqInspectionStep) => {
          return value.isSkipEnabled !== true;
        }).length;
        this.reloadInspection();
      } else {
        this.isMultipleUpload = false;
        this.showUploading = true;
        this.isBulkUploaded = false;
        this.currentAdditionalModeMultiple = false;
        this.minimumPhotosRequired = this.currentInspectionTemplate.minImageLimit;
        this.triggerMassUpload = false;
      }

      this.currentObservable = this.uploadFile.bind(this);
    } else {
      this.imageStatusSteps = [];
      this.currentObservable = this.uploadBulkUploadFile.bind(this);
      this.isMultipleUpload = true;
      this.triggerMassUpload = true;
      this.showUploading = true;
    }
  }
  /**
   * updateAiEstimateStatus
   */
  updateAiEstimateStatus(event): void {
    this.aiEstimationStatus = event.status
  }

  /**
   * after completing upload process
   * @param $event 
   */
  completeUploadProcess($event): void {
    if ($event && this.quoteAssessmentId) {
      this.quoteManagementService.updateFraudDetection(this.quoteAssessmentId).subscribe({
        next: () => {
          this.commonService.hideLoading();
          this.fraudDetectionEvent.emit(true);
        },
        error: () => {
          this.fraudDetectionEvent.emit(false);
          this.commonService.hideLoading();
        }
      });
    }
    
  }
}
