import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE, MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { EditorModule } from '@tinymce/tinymce-angular';
import { DxDateBoxModule, DxScrollViewModule, DxValidationSummaryModule, DxValidatorModule } from 'devextreme-angular';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgImageSliderModule } from 'ng-image-slider';
import { NgOtpInputModule } from 'ng-otp-input';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxPrintModule } from 'ngx-print';
import { EditMobileDialogComponent } from 'src/app/dialogs/edit-mobile-dialog/edit-mobile-dialog.component';
import { ImgDialogComponent } from 'src/app/dialogs/img-dialog/img-dialog.component';
import { ChqLabourRateComponent } from 'src/app/dialogs/labour-rate/labour-rate.component';
import { OTPMobileDialogComponent } from 'src/app/dialogs/otp-mobile-dialog/otp-mobile-dialog.component';
import { PaymentAddComponent } from 'src/app/dialogs/payments/payment-add/payment-add.component';
import { PaymentListComponent } from 'src/app/dialogs/payments/payment-list/payment-list.component';
import { PaymentsComponent } from 'src/app/dialogs/payments/payments.component';
import { OperationAddComponent } from 'src/app/dialogs/repair-estimate-operation/operation-add/operation-add.component';
import { OperationListComponent } from 'src/app/dialogs/repair-estimate-operation/operation-list/operation-list.component';
import { PartAddComponent } from 'src/app/dialogs/repair-estimate-operation/part-add/part-add.component';
import { RepairEstimateOperationComponent } from 'src/app/dialogs/repair-estimate-operation/repair-estimate-operation.component';
import { IconsModule } from 'src/app/icons.module';
import { AddJobDialogComponent } from '../dialogs/add-job-dialog/add-job-dialog.component';
import { AddTaskDialogComponent } from '../dialogs/add-task-dialog/add-task-dialog.component';
import { AiAnalysisDialogComponent } from '../dialogs/ai-analysis-dialog/ai-analysis-dialog.component';
import { CommentDialogComponent } from '../dialogs/comment-dialog/comment-dialog.component';
import { EstimateApprovalComponent } from '../dialogs/estimate-approval/estimate-approval.component';
import { EstimateNotesComponent } from '../dialogs/estimate-notes/estimate-notes.component';
import { NotifyDialogComponent } from '../dialogs/notify-dialog/notify-dialog.component';
import { RequestApprovalDialogComponent } from '../dialogs/request-approval-dialog/request-approval-dialog.component';
import { DirectiveModule } from '../helper/directive/directive.module';
import { PipeModule } from '../helper/pipe.module';
import { MaterialModule } from '../material.module';
import { ButtonTabsComponent } from './button-tabs/button-tabs.component';
import { ChqButtonComponent } from './chq-button/chq-button.component';
import { ChqChartComponent } from './chq-chart/chq-chart.component';
import { CustomLoaderComponent } from './chq-custom-loader/chq-custom-loader.component';
import { ChqDatetimepickerComponent } from './chq-date-picker/chq-date-timepicker.component';
import { ChqDropdownComponent } from './chq-dropdown/chq-dropdown.component';
import { ChqEstimateDetailsComponent } from './chq-estimate-details/chq-estimate-details.component';
import { ChqImagesSliderComponent } from './chq-images-slider/chq-images-slider.component';
import { ChqImagesTablesComponent } from './chq-images-tables/chq-images-tables.component';
import { ChqImagesUploaderComponent } from './chq-images-uploader/chq-images-uploader.component';
import { ChqInputComponent } from './chq-input/chq-input.component';
import { ChqPdfGeneratorComponent } from './chq-pdf-generator/chq-pdf-generator.component';
import { ChqRepairDialogComponent } from './chq-repair-dialog/chq-repair-dialog.component';
import { ChqReviewSectionComponent } from './chq-review-section/chq-review-section.component';
import { ChqRichTextEditorComponent } from './chq-rich-text-editor/chq-rich-text-editor.component';
import { ChqSearchBarComponent } from './chq-search-bar/chq-search-bar.component';
import { ChqSearchDetailComponent } from './chq-search-detail/chq-search-detail/chq-search-detail.component';
import { ChqSearchResultSectionComponent } from './chq-search-result-section/chq-search-result-section.component';
import { ChqStepHeaderComponent } from './chq-step-header/chq-step-header.component';
import { ChqTabsComponent } from './chq-tabs/chq-tabs/chq-tabs.component';
import { ChqToggleComponent } from './chq-toggle/chq-toggle.component';
import { ChqUploadComponent } from './chq-upload/chq-upload.component';
import { ChqVerticalImagePreviewComponent } from './chq-vertical-image-preview/chq-vertical-image-preview.component';

/**core change */
import { NgxEchartsModule } from 'ngx-echarts';
import { XaCurrencyPipe } from '../helper/pipe/xa-currency/xa-currency.pipe';
import { DashboardViewDetailsComponent } from '../modules/home/dashboard-view-details/dashboard-view-details.component';
import { ChatDetailComponent } from './chat/chat-component/chat-detail.component';
import { ChatListComponent } from './chat/chat-list/chat-list.component';
import { XaButton } from './xa-button/xa-button.component';
import { XaChart } from './xa-chart/xa-chart.component';
import { XaExpandableTable } from './xa-expandable-table/xa-expandable-table.component';
import { XaCheckboxGroup, XaCheckboxItem } from './xa-forms/xa-checkbox-group/xa-checkbox-group.component';
import { XaCheckbox } from './xa-forms/xa-checkbox/xa-checkbox.component';
import { XaError } from './xa-error/xa-error.component';
import { XaNumeric } from './xa-forms/xa-numeric/xa-numeric.component';
import { XaRadio, XaRadioGroup } from './xa-forms/xa-radio/xa-radio.component';
import { XaSelect } from './xa-forms/xa-select/xa-select.component';
import { XaText } from './xa-forms/xa-text/xa-text.component';
import { XaTextarea } from './xa-forms/xa-textarea/xa-textarea.component';
import { XaDatePicker } from './xa-forms/xa-date-picker/xa-date-picker.component';
import { XaToggle } from './xa-forms/xa-toggle/xa-toggle.component';
import { XaPaginator } from './xa-paginator/xa-paginator.component';
import { XaSignature } from './xa-signature/xa-signature.component';
import { XaTable } from './xa-table/xa-table.component';
import { XaTabComponent } from './xa-tabs/xa-tab.component';
import { XaDateRangePickerComponent } from './xa-date-range-picker/xa-date-range-picker.component';
import { XaRating, XaRatingOption } from './xa-forms/xa-rating/xa-rating.component';
import { XaTabsComponent } from './xa-tabs/xa-tabs.component';
import { ChatComponent } from './chat/chat.component';
import { XaRecommendation } from './xa-forms/xa-recommendation/xa-recommendation.component';
import { XaPlaces } from './xa-forms/xa-places/xa-places.component';
import { XaTel } from './xa-forms/xa-tel/xa-tel.component';
import { XaFileInput } from './xa-forms/xa-file-input/xa-file-input.component';
import { XaSearch } from './xa-forms/xa-search/xa-search.component';
import { XaTimerComponent } from './xa-timer/xa-timer.component';
import { XaHeading } from './xa-heading/xa-heading.component';
import { XaParagraph } from './xa-paragraph/xa-paragraph.component';
import { XaRichTextEditorComponent } from './xa-rich-text-editor/xa-rich-text-editor.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { XaSection } from './xa-section/xa-section.component';
import { AiEstimateRepairRecommendationComponent } from './ai-estimate-repair-recommendation/ai-estimate-repair-recommendation.component';
import { DxGalleryModule } from 'devextreme-angular';
import { ManualEstimatePreviewComponent } from '../dialogs/manual-estimate-preview/manual-estimate-preview.component';
import { CameraComponent } from './camera/camera.component';
import { CameraOverlayComponent } from './camera-overlay/camera-overlay.component';
import { CaseSubmitSuccessComponent } from '../dialogs/case-submit-success/case-submit-success.component';
import { CaseSubmitWithQuoteIdComponent } from '../dialogs/case-submit-with-quote-id/case-submit-with-quote-id.component';
import { XaFileReviewSectionComponent } from './xa-file-review-section/xa-file-review-section.component';
import { XaPhotoUploadModeSelecterComponent } from './xa-photo-upload-mode-selecter/xa-photo-upload-mode-selecter.component';
import { XaPhotosComponent } from './xa-photos/xa-photos.component';
import { XaDamageAnalysisComponent } from './xa-damage-analysis/damage-analysis.component';
import { RepairRecommendationComponent } from './repair-recommendation/repair-recommendation.component';
import { XaDocumentComponent } from './xa-documents/xa-documents.component';
import { VideoComponent } from './video/video.component';
import { ChqRepairOrderComponent } from './chq-repair-order/chq-repair-order.component';
import { XaLabel } from './xa-label/xa-label.component';
import { DocumentIntelligenceDialogComponent } from '../dialogs/document-intelligence-dialog/document-intelligence-dialog.component';
import { HistoryFilterDialogComponent } from '../dialogs/history-filter-dialog/history-filter-dialog.component';
import { ReserveAdjustmentDialogComponent } from '../dialogs/reserve-adjustent-dialog/reserve-adjustment-dialog.component';
import { DriverConfirmDialogComponent } from '../dialogs/driver-confirm-dialog/driver-confirm-dialog.component';
import { ChqEstimateReportComponent } from './chq-estimate-report/chq-estimate-report.component';


@NgModule({
  declarations: [
    ChqStepHeaderComponent,
    ChqInputComponent,
    ChqDropdownComponent,
    ChqButtonComponent,
    ChqDatetimepickerComponent,
    ChqVerticalImagePreviewComponent,
    ChqUploadComponent,
    ChqImagesUploaderComponent,
    ChqReviewSectionComponent,
    ChqImagesSliderComponent,
    ChqImagesTablesComponent,
    CustomLoaderComponent,
    ChqRepairDialogComponent,
    ImgDialogComponent,
    ChqLabourRateComponent,
    ChqPdfGeneratorComponent,
    OTPMobileDialogComponent,
    EditMobileDialogComponent,
    RequestApprovalDialogComponent,
    NotifyDialogComponent,
    ChqSearchBarComponent,
    ChqSearchResultSectionComponent,
    ChqSearchDetailComponent,
    ChqTabsComponent,
    CommentDialogComponent,
    ChqToggleComponent,
    ChqEstimateDetailsComponent,
    RepairEstimateOperationComponent,
    OperationAddComponent,
    OperationListComponent,
    PartAddComponent,
    ChqRichTextEditorComponent,
    AddJobDialogComponent,
    AddTaskDialogComponent,
    PaymentsComponent,
    PaymentAddComponent,
    PaymentListComponent,
    ButtonTabsComponent,
    ChqChartComponent,
    EstimateApprovalComponent,
    AiAnalysisDialogComponent,
    EstimateNotesComponent,
    DashboardViewDetailsComponent,
    ChatListComponent,
    ChatDetailComponent,
    ChatComponent,
    AiEstimateRepairRecommendationComponent,
    ManualEstimatePreviewComponent,
    CaseSubmitSuccessComponent,
    CaseSubmitWithQuoteIdComponent,
    RepairRecommendationComponent,
    VideoComponent,
    DriverConfirmDialogComponent,
    DocumentIntelligenceDialogComponent,
    XaButton,
    XaError,
    XaText,
    XaNumeric,
    XaRadioGroup,
    XaRadio,
    XaCheckbox,
    XaTextarea,
    XaDatePicker,
    XaSelect,
    XaToggle,
    XaSignature,
    XaExpandableTable,
    XaTable,
    XaPaginator,
    XaChart,
    XaTabsComponent,
    XaTabComponent,
    XaDateRangePickerComponent,
    XaCheckboxGroup,
    XaCheckboxItem,
    XaRating,
    XaRatingOption,
    XaRecommendation,
    XaPlaces,
    XaTel,
    XaFileInput,
    XaSearch,
    XaTimerComponent,
    XaHeading,
    XaParagraph,
    XaFileReviewSectionComponent,
    XaPhotoUploadModeSelecterComponent,
    XaRichTextEditorComponent,
    XaSection,
    CameraOverlayComponent,
    CameraComponent,
    XaPhotosComponent,
    XaDamageAnalysisComponent,
    XaDocumentComponent,
    CameraComponent,
    ChqRepairOrderComponent,
    HistoryFilterDialogComponent,
    ReserveAdjustmentDialogComponent,
    XaLabel,
    ChqRepairOrderComponent,
    ChqEstimateReportComponent
  ],
  imports: [
    CommonModule,
    IconsModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollingModule,
    CdkTableModule,
    NgImageSliderModule,
    NgOtpInputModule,
    DxDateBoxModule,
    DxValidatorModule,
    DxValidationSummaryModule,
    EditorModule,
    TranslateModule,
    ColorPickerModule,
    NgApexchartsModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    DirectiveModule,
    NgxPrintModule,
    MaterialModule,
    PipeModule,
    DxScrollViewModule,
    DxGalleryModule
  ],
  exports: [
    ChqStepHeaderComponent,
    ChqInputComponent,
    ChqDropdownComponent,
    ChqButtonComponent,
    ChqDatetimepickerComponent,
    ChqVerticalImagePreviewComponent,
    ChqUploadComponent,
    ChqImagesUploaderComponent,
    ChqImagesSliderComponent,
    ChqImagesTablesComponent,
    CustomLoaderComponent,
    ChqReviewSectionComponent,
    ImgDialogComponent,
    ChqPdfGeneratorComponent,
    OTPMobileDialogComponent,
    EditMobileDialogComponent,
    RequestApprovalDialogComponent,
    NotifyDialogComponent,
    ChqSearchBarComponent,
    ChqSearchDetailComponent,
    ChqTabsComponent,
    CommentDialogComponent,
    ChqToggleComponent,
    ChqEstimateDetailsComponent,
    ChqRichTextEditorComponent,
    ButtonTabsComponent,
    ChqChartComponent,
    AiAnalysisDialogComponent,
    XaDatePicker,
    ChatListComponent,
    ChatDetailComponent,
    ChatComponent,
    CameraOverlayComponent,
    CameraComponent,
    VideoComponent,
    DriverConfirmDialogComponent,
    DocumentIntelligenceDialogComponent,
    XaButton,
    XaError,
    XaText,
    XaNumeric,
    XaRadioGroup,
    XaRadio,
    XaCheckbox,
    XaTextarea,
    XaSelect,
    XaToggle,
    XaSignature,
    XaTable,
    XaExpandableTable,
    XaPaginator,
    XaChart,
    XaTabsComponent,
    XaTabComponent,
    XaDateRangePickerComponent,
    XaCheckboxGroup,
    XaCheckboxItem,
    XaRating,
    XaFileReviewSectionComponent,
    XaPhotoUploadModeSelecterComponent,
    XaRatingOption,
    XaRecommendation,
    XaPlaces,
    XaFileInput,
    XaTel,
    XaSearch,
    XaTimerComponent,
    XaHeading,
    XaParagraph,
    XaRichTextEditorComponent,
    XaSection,
    XaPhotosComponent,
    XaDamageAnalysisComponent,
    RepairRecommendationComponent,
    XaDocumentComponent,
    HistoryFilterDialogComponent,
    XaLabel
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    MatSelectModule,
    MatFormFieldModule,
    MatExpansionModule,
    FormsModule,
    EditorModule,
    XaCurrencyPipe,
    MatRippleModule
  ]
})
export class WidgetsModule { }