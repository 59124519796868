<div class="container">
    <xa-file-review-section #reviewSection
                            *ngIf="!dataLoading"
                            (fileUpload)="handleUpload($event)"
                            [xaReviewOptions]="xaDocument.fileReviewSection"
                            (iconClick)="handleAction($event);">
        <xa-button (click)="handleFooterActions(step);"
                   [hidden]="(step.isUploadAction && xaDocument.fileReviewSection?.files?.length > 0) || (!step.isUploadAction && xaDocument.fileReviewSection?.files?.length === 0)"
                   *ngFor="let step of xaDocument?.fileReviewSection?.footerActions">
            <mat-icon [svgIcon]="step.icon"
                      aria-hidden="false"
                      *ngIf="step.icon"
                      aria-label="unchecked icon"></mat-icon>
            <span>{{step.label | translate}}</span>
        </xa-button></xa-file-review-section>
    <div *ngIf="dataLoading"
         class="spinner-loader">
        <mat-spinner [strokeWidth]="5"
                     [diameter]="50"
                     class="inner-loading"></mat-spinner>
    </div>
</div>