import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import { CommonService } from 'src/app/services/common/common.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { RepairEstimateService } from 'src/app/services/repair-estimate/repair-estimate.service';

dayjs.extend(utc)

@Component({
  selector: 'app-chq-estimate-report',
  templateUrl: './chq-estimate-report.component.html',
  styleUrls: [ './chq-estimate-report.component.scss' ]
})
export class ChqEstimateReportComponent {
  estimateMaxDate = new Date();
  estimateMinDate = new Date('1980-01-01');

  public estimateReportForm = new FormGroup({
    estimateDate: new FormControl(new Date(), [ Validators.required ]),
    comment: new FormControl('', [ Validators.maxLength(250) ])
  });

  /**
   Constructor.
   @constructor
   @param {any} data - Data passed to the component.
   @param {MatDialogRef} dialogRef - Reference to the dialog box.
   @param {MonitorService} monitorService - Service for monitoring operations.
 */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ChqEstimateReportComponent>,
    private repairEstimateService: RepairEstimateService,
    public monitorService: MonitorService,
    private commonService: CommonService,
  ) { 
    this.commonService.estimateReportInfo.subscribe((data) => {
      if (data) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].key === this.data.selectedReport && data[i].estimateDate) {
            this.estimateReportForm.get('estimateDate').setValue(data[i].estimateDate);
            this.estimateReportForm.get('comment').setValue(data[i].comments);
          }
        }
      }
    });
  }

  /**
    Closes the dialog with the given data.
  */
  closeDialog(): void {
    this.dialogRef.close();
  }

  /**
   * check for form validation
   * @returns {void}
   */
  get isEstimateReportFormValid(): boolean {
    return this.estimateReportForm.valid;
  }

  /**
      Save the dialog with the given data.
      @param {any} data - The data to be passed back to the dialog caller.
    */
  onSubmitForm(): void {
    if (!this.estimateReportForm) {
      return;
    }

    const formValue = this.estimateReportForm.getRawValue();
    const date = dayjs(formValue.estimateDate.toString()).utc().format('YYYY-MM-DDTHH:mm:ss.000') + 'Z';
    
    const postData = {
      'comment': formValue.comment,
      'estimateDate': date
    }
    
    this.commonService.showLoading();
    this.repairEstimateService.postSpecificDownloadUrl( postData, this.data.caseGuid, this.data.selectedReport, new Date().toString()).subscribe({
      next: (result: any) => {
        this.dialogRef.close();
        window.open(result.data.url, '_blank');
        this.commonService.callEstimationDetailMethod();
        this.commonService.hideLoading();
      },
      error: () => {
        this.commonService.hideLoading();
      }
    });
    
  }

  /**
      Closes the dialog with the given data.
    */
  onCancelForm(): void {
    this.dialogRef.close();
  }
}
