<div class="case-submit-with-quote-container fx-col">
    <div class="header">
        <h3>{{ 'Info' | translate }} </h3>
        <div class="close-icon">
            <mat-icon [svgIcon]="'cross'" (click)="cancelDialog(false);"></mat-icon>
        </div>
    </div>
    <div class="content fx-col fx-1" [formGroup]="formGroup">
        <h5>Please enter the quote ID to proceed</h5>
        <xa-text placeholder="Enter Quote ID" label="Add Quote id" formControlName="quoteId" [required]="true"></xa-text>
        <ng-container *ngIf="formGroup.get('quoteId').invalid">
            <xa-error *ngIf="formGroup.get('quoteId').errors['pattern']">{{"invalid_quote_id" | translate}}</xa-error>
            <xa-error *ngIf="formGroup.get('quoteId').errors['required']">Quote Id is required</xa-error>
        </ng-container>
    </div>
    <div class="footer form-data-footer">
        <xa-button type="default" [color]="this.isFormValid ? 'primary':'gray'" [disabled]="!this.isFormValid" (click)="onSubmitForm()">Submit</xa-button>
    </div>
</div>
