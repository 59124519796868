import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { ChqWidgetsInputModel, IControlValue } from 'src/app/model/chq-widgets-input-model';
import { BookingService } from 'src/app/services/booking/booking.service';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-estimate-approval',
  templateUrl: './estimate-approval.component.html',
  styleUrls: [ './estimate-approval.component.scss' ]
})
export class EstimateApprovalComponent implements OnInit {

  public approveRadio: ChqWidgetsInputModel = {
    placeHolder: 'Approve',
    label: 'Approve',
    name: 'approve',
    idField: '',
    value: '1',
    type: 'radio',
    hideLabel: true,
    options: [ { label: 'approve', id: 1 } ]
  }

  public notesModel: ChqWidgetsInputModel = {
    placeHolder: 'Let us know the issue in here.',
    label: 'NOTES',
    name: 'notes',
    value: '',
    type: 'textarea',
    maxLength: 300
  };

  private repairId: string = '';
  private urlToken: string = '';
  public checkedStatus = '';
  public reason = '';
  public status = '';

  private approvalRequestType = '';
  private domainId = 3100;

  public cancelled: boolean = false;
  public success: boolean = false;

  public declineRadio: ChqWidgetsInputModel = {
    placeHolder: 'Decline',
    label: 'Decline',
    name: 'decline',
    idField: '',
    value: '2',
    type: 'radio',
    hideLabel: true,
    options: [ { label: 'decline', id: 2 } ]
  }

  public cancelButtonModel: ChqWidgetsButtonModel = {
    label: 'Cancel',
    type: 'outline'
  }
  public nextButtonModel: ChqWidgetsButtonModel = {
    label: 'Send',
    type: 'disabled'
  }

  /**
    Constructor for the component.
    @param {CommonService} commonService - The CommonService instance.
    @param {BookingService} repairEstimateService - The RepairEstimateService instance.
    @param {ActivatedRoute} route - The ActivatedRoute instance.
  */
  constructor(
    public commonService: CommonService,
    private bookingService: BookingService,
    private route: ActivatedRoute,
  ) {

  }

  /**
   * cancelled
   */
  cancel(): void {
    this.cancelled = true;
  }

  /**
   * approve/reject repair
   */
  updateRepairStatus(): void {
    const status = this.checkedStatus === 'Approved' ? 'Approved' : 'Rejected';
    this.status = status;
    this.commonService.showLoading();

    if (this.approvalRequestType === 'case') {
      this.bookingService.updateQuoteStatusExternally(status, this.repairId, this.reason.trim(), this.urlToken, this.domainId).subscribe({
        next: () => {
          this.success = true;
          localStorage.setItem(this.urlToken, status);
          this.commonService.hideLoading();
        },
        error: () => {
          this.commonService.hideLoading();
        }
      })
    } else {
      this.bookingService.updateRepairStatusExternally(status, this.repairId, this.reason.trim(), this.urlToken).subscribe({
        next: () => {
          this.success = true;
          localStorage.setItem(this.urlToken, status);
          this.commonService.hideLoading();
        },
        error: () => {
          this.commonService.hideLoading();
        }
      })
    }

  }

  /**
   * on init
   */
  ngOnInit(): void {
    const el: any = document.getElementById('custom-loader');
    if (el) {
      el.style.display = 'none';
    }
    this.approvalRequestType = this.route.snapshot.url[0].path
    if (this.approvalRequestType === 'case') {
      this.domainId = 2100
    }

    this.repairId = this.route?.snapshot?.paramMap?.get('id');
    this.urlToken = this.route?.snapshot?.queryParams['token'];
    this.nextButtonModel = { ...this.nextButtonModel, onclick: this.updateRepairStatus.bind(this) };
    this.cancelButtonModel = { ...this.cancelButtonModel, onclick: this.cancel.bind(this) };
    if (localStorage.getItem(this.urlToken) && localStorage.getItem(this.urlToken) !== 'undefined') {
      this.success = true;
      this.status = localStorage.getItem(this.urlToken);
    }
  }

  /**
   * onFormUpdate
   */
  onFormUpdate($event: IControlValue): void {
    const { name, value } = $event;
    if (name === 'approve') {
      if (this.declineRadio.value !== 2 && value) {
        this.checkedStatus = 'Approved';
        this.nextButtonModel = { ...this.nextButtonModel, type: 'primary' };
        this.declineRadio = { ...this.declineRadio, value: '' };
      }
    } else if (name === 'decline') {
      if (this.approveRadio.value !== 1 && value) {
        this.checkedStatus = 'declined';
        if (this.reason.trim()) {
          this.nextButtonModel = { ...this.nextButtonModel, type: 'primary' };
        } else {
          this.nextButtonModel = { ...this.nextButtonModel, type: 'disabled' };
        }
        this.approveRadio = { ...this.approveRadio, value: '' };
      }
    } else if (name === 'notes') {
      this.reason = value;
      if (this.checkedStatus && this.checkedStatus === 'declined' && this.reason.trim()) {
        this.nextButtonModel = { ...this.nextButtonModel, type: 'primary' };
      } else {
        this.nextButtonModel = { ...this.nextButtonModel, type: 'disabled' };
      }
    }
  }
}
