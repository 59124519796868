import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as dayjs from 'dayjs';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { ChqWidgetsInputModel } from 'src/app/model/chq-widgets-input-model';
import { validateEmails } from 'src/app/modules/quote/chq-new-cases/chq-new-customer/chq-new-customer-form-data-helper';
import { CommonService } from 'src/app/services/common/common.service';
import { CoreService } from 'src/app/services/core/core.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { NotifyService } from 'src/app/services/notify/notify.service';
import { ChqInputComponent } from 'src/app/widgets/chq-input/chq-input.component';

export interface NotifyRequest {
  subject: string;
  messageContent: string;
  toEmails: string[];
  ccEmails?: string[];
  phoneNumber?: string;
  communicationTypeIds: number[],
  templateKey: string,
}


@Component({
  selector: 'history-filter-dialog',
  templateUrl: './history-filter-dialog.component.html',
  styleUrls: [ './history-filter-dialog.component.scss' ]
})
export class HistoryFilterDialogComponent {

  selectedClaimant:number;
  
  @Output() claimantFilterSelected = new EventEmitter();
  
  /**
   * handle close
   */
  cancel(): void {
    this.monitorService.logEvent('send', [ 'NotifyDialogComponent', 'addenda' ]);
    this.dialogRef.close();
  }

  /**
   * constructor
   * @param dialogRef 
   * @param data 
   */
  constructor(public dialogRef: MatDialogRef<HistoryFilterDialogComponent>,
    private monitorService: MonitorService,
    private coreService: CoreService,
    private commonService: CommonService,
    private notifyService: NotifyService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }
  
  /**
   * apply
   */
  apply(): void {
    this.monitorService.logEvent('send', [ 'NotifyDialogComponent', 'addenda' ]);
    const selectedClaimantName = this.data.find(x=>x.id === this.selectedClaimant)?.name || 'All Claimants';
    this.claimantFilterSelected.emit({ selectedClaimantId: this.selectedClaimant, selectedName: selectedClaimantName });
    this.dialogRef.close();
  }

}

