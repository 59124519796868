<div class="dialog-container">
    <div class="header">
        <span>
            <h3>Estimate date{{ data.selectedReport === 'ReportEstimateSummary' ? ' and comment' : '' }}</h3>
            <span>
                Enter the Date{{ data.selectedReport === 'ReportEstimateSummary' ? ' and Comments' : '' }} to be shown in the PDF file generated if required
            </span>
        </span>
        <div class="close-icon"
             (click)="closeDialog();">
            <mat-icon [svgIcon]="'cross'"></mat-icon>
        </div>
    </div>
    <div class="dialog-body">
        <div class="form-container">
            <form [formGroup]="estimateReportForm">
                <div class="col-6 input-wrapper">
                    <xa-date-picker name="estimateDate" formControlName="estimateDate" label="Estimate date" placeholder="DD/MM/YYYY" type="date" [maxDate]="estimateMaxDate" [minDate]="estimateMinDate"></xa-date-picker>
                </div>
               
                <div class="input-wrapper" *ngIf="data.selectedReport === 'ReportEstimateSummary'">
                    <div class="div-wrapper">
                        <xa-textarea  label="Comments" formControlName="comment" placeholder="Enter here" name="comment"></xa-textarea>
                        <xa-error *ngIf="estimateReportForm.get('comment').dirty && estimateReportForm.get('comment').hasError('maxlength')">
                            Only 250 characters allowed.
                        </xa-error></div>
                </div>
            </form>
        </div>
    </div>
    <div class="footer form-data-footer">
        <xa-button type="outline" color="gray" (click)="onCancelForm()">Cancel</xa-button>
        <xa-button type="default" [color]="this.estimateReportForm ? 'primary':'gray'" [disabled]="!this.isEstimateReportFormValid" (click)="onSubmitForm()">Done</xa-button>
    </div>
</div>