import { FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { allowExtension, photosSizeLimit } from 'src/app/config/constants/app.constants';
import { DamageReport } from 'src/app/model/chq-damage-report';
import { ChqInspectionStep } from 'src/app/model/chq-inspection-step';
import { AddCustomerData, AddCustomerForm, AddDocumentSteps, CustomerFormData } from 'src/app/model/chq-new-case-form.model';
import { UploadStepModel } from 'src/app/model/chq-upload-model';
import { CustomValidationResult } from 'src/app/model/chq-validation-model';
import { ChqWidgetsHeaderModel } from 'src/app/model/chq-widgets-header-model';

export const newCaseForm = (isExternal?:boolean):AddCustomerForm => {
  return {
    jobNumber: {
      placeHolder: 'repair_dms_id',
      name: 'jobNumber',
      label: 'repair_dms_id',
      value: 0,
      type: 'text',
      maxLength: 50,
      validation: {
        name: 'jobNo',
        validationFunction: ():ValidatorFn => {
          return Validators.compose([ Validators.required, Validators.maxLength(50), validateRegex('^[a-zA-Z0-9-]*$') ])
        },
        validationMessage: (error: ValidationErrors):string => {
          if (error['required']) {
            return 'required_dms'
          }
          if (error['maxlength']) {
            return 'max_length_dms'
          }
          if (error['invalidChar']) {
            return 'pattern_digit_alphabets_hyphen'
          }

          return '';
        }
      }
    },
    companyOrgType: {
      placeHolder: 'select_dot',
      name: 'companyOrgType',
      label: 'work_provider',
      value: 0,
      type: 'select',
      displayValue: 'name',
      idField: 'workProviderName',
      fieldValue: 'id',
      errorWhenEmpty: true,
      validation: {
        name: 'insuranceCompany',
        validationFunction: ():ValidatorFn => {
          return Validators.compose([ Validators.required ])
        },
        validationMessage: (error: ValidationErrors):string => {
          if (error['required']) {
            return 'required_provider'
          }
          return '';
        }
      },
      options: []
    },
    carOwnerSelector: {
      placeHolder: 'insurance_company',
      label: 'car_details',
      name: 'carOwnerSelector',
      idField: 'customerTypeID',
      value: 'Person',
      type: 'radio',
      options: [ { label: 'Person', id: 1 }, { label: 'Company', id: 2 } ],
    },
    claimNumber: {
      placeHolder: 'claims_no',
      label: 'CLAIM_NO',
      name: 'claimNumber',
      value: 0,
      type: 'text',
      validation: {
        name: 'claimNo',
        validationFunction: ():ValidatorFn => {
          return Validators.compose([ Validators.maxLength(25) ])
        },
        validationMessage: (error: ValidationErrors):string => {
          if (error['required']) {
            return 'required_claim'
          }
          if (error['maxlength']) {
            return 'max_length_claim'
          }
          if (error['invalidChar']) {
            return 'pattern_digit_alphabets'
          }
          return '';
        }
      }
    },
    claimType: {
      placeHolder: 'claim_type',
      label: 'claim_type',
      name: 'claimType',
      value: 0,
      type: 'select',
    },
    claimReason: {
      placeHolder: 'claim_reason',
      label: 'claim_reason',
      name: 'claimReason',
      value: 0,
      type: 'select',
    },
    companyName: {
      placeHolder: 'company_name',
      label: 'company_name',
      name: 'companyName',
      value: 0,
      type: 'text',
      validation: {
        name: 'companyName',
        validationFunction: ():ValidatorFn => {
          return Validators.compose([ Validators.required, Validators.maxLength(50) ])
        },
        validationMessage: (error: ValidationErrors):string => {
          if (error['required']) {
            return 'required_company'
          }
          if (error['maxlength']) {
            return 'max_length_company'
          }
          return '';
        }
      }
    },
    licenseId: {
      placeHolder: 'license_id_cin',
      label: 'license_id_cin',
      name: 'licenseId',
      value: 0,
      type: 'text',
      setUntouched: true,
      validation: {
        name: 'licenseId',
        validationFunction: ():ValidatorFn => {
          return Validators.compose([ Validators.required, Validators.maxLength(40), validateRegex('^[a-zA-Z0-9]*$') ])
        },
        validationMessage: (error: ValidationErrors):string => {
          if (error['required']) {
            return 'required_license'
          }
          if (error['maxlength']) {
            return 'max_length_license'
          }
          if (error['invalidChar']) {
            return 'pattern_digit_alphabets'
          }
          return '';
        }
      }
    },
    firstName: {
      placeHolder: 'first_name',
      label: 'first_name',
      name: 'firstName',
      value: 0,
      type: 'text',
      validation: {
        name: 'firstName',
        validationFunction: ():ValidatorFn => {
          return Validators.compose([ Validators.required, Validators.minLength(2), Validators.maxLength(50),
            Validators.pattern('^[a-zA-Z]+[a-zA-Z ,.\'-]+$')
            , validateRegex('^[A-Za-z]') ])
        },
        validationMessage: (error: ValidationErrors):string => {
          if (error['required']) {
            return 'required_first_name'
          }
          if (error['minlength']) {
            return 'first_name_min_length'
          }
          if (error['maxlength']) {
            return 'first_name_max_length'
          }
          if (error['pattern']) {
            return 'pattern_driver_name'
          }
          if (error['invalidChar']) {
            return 'valid_characters_name'
          }
          return '';
        }
      }
    },
    lastName: {
      placeHolder: 'last_name',
      label: 'last_name',
      name: 'lastName',
      value: 0,
      type: 'text',
      validation: {
        name: 'lastName',
        validationFunction: ():ValidatorFn => {
          return Validators.compose([ Validators.required, Validators.minLength(2), Validators.maxLength(50),
            Validators.pattern('^[a-zA-Z]+[a-zA-Z ,.\'-]+$')
            , validateRegex('^[A-Za-z]') ])
        },
        validationMessage: (error: ValidationErrors):string => {
          if (error['required']) {
            return 'required_last_name'
          }
          if (error['minlength']) {
            return 'last_name_min_length'
          }
          if (error['maxlength']) {
            return 'last_name_max_length'
          }
          if (error['pattern']) {
            return 'pattern_driver_name'
          }
          if (error['invalidChar']) {
            return 'valid_characters_name'
          }
          return '';
        }
      }
    },
    driverCode: {
      placeHolder: 'select_dot',
      label: 'code',
      name: 'driverCode',
      value: +91,
      showImageFlag: true,
      type: 'select',
      selectedOption: { 'phoneCountryCode': '+91' },
      displayValue: 'phoneCountryCode',
      fieldValue: 'phoneCountryCode',
      options: [ { label: '+90' }, { label: '+971' }, { label: '+977' } ],
      validation: {
        name: 'driverCode',
        validationFunction: ():ValidatorFn => {
          return Validators.compose([ Validators.required ])
        },
        validationMessage: (error: ValidationErrors):string => {
          if (error['required']) {
            return 'required_code'
          }
          return '';
        }
      }
    },
    driverPhone: {
      placeHolder: 'mobile_number',
      label: 'mobile_number',
      name: 'driverPhone',
      value: 0,
      type: 'number',
      validation: {
        name: 'driverPhone',
        validationFunction: ():ValidatorFn => {
          if (isExternal) {
            return Validators.compose([ Validators.required, Validators.pattern('^([0-9]+[0-9 ,.\'-]*|-)$') ])
          } else {
            return Validators.compose([ Validators.required, Validators.pattern('^[0-9]+[0-9 ,.\'-]+$') ])

          }
        },
        validationMessage: (error: ValidationErrors):string => {
          if (error['required']) {
            return 'required_mobile_number'
          }
          if (error['pattern']) {
            return 'valid_mobile_number'
          }
          return '';
        }
      }
    },
    email: {
      label: 'email_address',
      name: 'email',
      placeHolder: 'email',
      value: '',
      type: 'text',
      validation: {
        name: 'email',
        validationFunction: ():ValidatorFn => {
          return Validators.compose([ Validators.required, Validators.email,
            Validators.maxLength(50),
            Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$') ])
        },
        validationMessage: (error: ValidationErrors):string => {
          if (error['required']) {
            return 'email_required'
          }
          if (error['maxlength']) {
            return 'email_length'
          }
          if (error['pattern'] || error['email']) {
            return 'email_valid'
          }
          return '';
        }
      }
    }
  };
}


export const documentPartMapper = (mediaPart: any, index:number):AddDocumentSteps =>{
  const documentStep: AddDocumentSteps = {
    stepName: mediaPart.name,
    uploadStatus: 'notUploaded',
    status: 0,
    step: index,
    placeHolderUrl: mediaPart.sampleImageUrl,
    mediaTemplateGuid: '',
    url: mediaPart.image,
    mediaPartTemplateGuid: mediaPart.mediaPartTemplateGuid,
    uploadInProgress: false,
    isSkipped: false,
    isUploadFailed: !mediaPart.isUploadSuccess,
    isUploadSuccess: mediaPart.isUploadSuccess,
    instruction: mediaPart.mediaCaptureInstruction
  }
  return documentStep;
}



export const damagePartMapper = (step: DamageReport, index: number): UploadStepModel => {
  const damageStep: UploadStepModel = {
    stepName: step?.view || '',
    mandatory: false,
    placeHolderUrl: step?.sourceImageUrl,
    allowExtension: allowExtension,
    sizeLimit: photosSizeLimit,
    index: step.inspectionItemId,
    inspectionItem: (step.damageDetails && step.damageDetails?.length > 0)? step.damageDetails : [ { inspectionItemId: step.inspectionItemId } ],
    image: null,
    url: step?.annotationImageUrl,
    isSkipped: false,
    isEmpty: (step.damageDetails && step.damageDetails?.length > 0)? false: true,
    isUploadFailed: false,
    isUploadSuccess: step?.status === 'Analysed' || false,
    uploadInProgress: step?.status ==='Analysis in Progress' || false,
    templateGuid: '',
    partTemplateGuid: '',
    uploadStatus: 'notUploaded',
    analysisStatus: step?.status
  }
  return damageStep;
}

export const inspectionpartPartMapper = (step: ChqInspectionStep, index:number):UploadStepModel =>{
  const inspectionSteps: UploadStepModel = {
    ...step,
    stepName: step?.name,
    mandatory: step?.isSkipEnabled,
    placeHolderUrl: step?.name.indexOf('Additional') !== -1 ? '/assets/img/additional-image.png' : step?.pictogramImage.rawAzureBlobUrl,
    allowExtension: allowExtension,
    sizeLimit: photosSizeLimit,
    index,
    inspectionItem: step.inspectionItem,
    image: null,
    url: step?.inspectionItem?.rawAzureBlobUrl,
    thumbnailUrl: step?.inspectionItem?.thumbnailBlobUrl,
    isSkipped: false,
    isUploadFailed: step?.isUploadFailed || false,
    isUploadSuccess: step?.isUploadSuccess || false,
    uploadInProgress: step?.uploadInProgress || false,
    templateGuid: step?.inspectionItemTemplateGuid,
    partTemplateGuid: '',
    uploadStatus: 'notUploaded',
    isLocked: step?.isLocked,
    instruction: step.procedureSteps,
    overlayImage: step.inspectionItem?.overlayImage
  }
  return inspectionSteps;
}


export const caseResponseMapper = (caseDetailResponse:any):AddCustomerData=>{
  const formData: AddCustomerData = {
    'externalID': caseDetailResponse.externalId,
    'companyOrgType': caseDetailResponse?.caseDetail?.companyOrgType,
    'caseTypeID': caseDetailResponse.caseTypeId,
    'customerTypeID': caseDetailResponse.caseDetail?.customerTypeId || caseDetailResponse.caseDetail?.customerTypeID || caseDetailResponse?.customerTypeId || caseDetailResponse.customerTypeID,
    'customerTypeName': caseDetailResponse.customerTypeName,
    'domainID': 2100,
    'tariffId': caseDetailResponse.tariffId,
    'isTariffEditable': caseDetailResponse.isTariffEditable,
    'firstName': caseDetailResponse.caseDetail?.firstName,
    'lastName': caseDetailResponse.caseDetail?.lastName,
    'countryCode': caseDetailResponse.caseDetail?.countryCode,
    'mobileNumber': caseDetailResponse.caseDetail?.mobileNumber,
    'email': caseDetailResponse.caseDetail?.email,
    'claimNumber': caseDetailResponse.claimNumber,
    'claimType': caseDetailResponse.claimType,
    'claimReason': caseDetailResponse.claimReason,
    'companyName': caseDetailResponse.caseDetail?.companyName,
    'companyLicenseNumber': caseDetailResponse.caseDetail?.licenseNumber,
    'companyId': caseDetailResponse.caseDetail?.companyId,
    'partnershipId': caseDetailResponse?.partnershipId
  }
  return formData;
}

export const caseDataMapper = (caseDetailResponse:AddCustomerData, isExternal?:boolean):CustomerFormData=>{
  const formData:CustomerFormData = {
    'jobNumber': caseDetailResponse.externalID,
    'companyOrgType': caseDetailResponse.companyOrgType,
    'caseTypeID': caseDetailResponse.caseTypeID as number,
    'customerTypeID': isExternal && !caseDetailResponse.customerTypeId ? 2 : (caseDetailResponse.customerTypeID || caseDetailResponse.customerTypeId || 1),
    'firstName': isExternal && !caseDetailResponse.firstName ? '-' : caseDetailResponse.firstName,
    'lastName': isExternal && !caseDetailResponse.lastName ? '-' : caseDetailResponse.lastName,
    'driverCode': isExternal && !caseDetailResponse.mobileNumber ? '-' : caseDetailResponse.countryCode,
    'isTariffEditable': caseDetailResponse['isTariffEditable'],
    'tariffId': caseDetailResponse.tariffId,
    'driverPhone': isExternal && (!caseDetailResponse.mobileNumber || caseDetailResponse.mobileNumber === '-') ? '-'
      : parseInt(caseDetailResponse.mobileNumber),
    'email': caseDetailResponse.email,
    'claimNumber': caseDetailResponse.claimNumber,
    'claimReason': caseDetailResponse.claimReason,
    'claimType': caseDetailResponse.claimType,
    'companyName': isExternal && !caseDetailResponse.companyName ? '-' : caseDetailResponse.companyName,
    'companyId': caseDetailResponse.companyId,
    'partnershipId': caseDetailResponse.partnershipId,
    'carOwnerSelector': caseDetailResponse.customerTypeName || 'Person',
    'licenseId': isExternal && !caseDetailResponse.licenseNumber ? '-' : (caseDetailResponse.companyLicenseNumber || caseDetailResponse.licenseNumber)
  }
  return formData;
}


export const newCaseHeader: ChqWidgetsHeaderModel = {
  title: 'add_customer',
  step: 1,
  description: 'add_customer_msg',
  stepName: 'step_one',
};

export const validateRegex = (regex: string, isExternal?:boolean): ValidatorFn => {
  const result: CustomValidationResult = { 'invalidChar': false };
  return (control: FormControl) => {
    if(isExternal && control.value === '-'){
      return null;
    }
    if (control.value?.match(regex) === null
    ) {
      result['invalidChar'] = true;
      return result;
    }
    return null;
  };

}

export const validateEmails = (regex:string): ValidatorFn => {
  return (control: FormControl) => {
    const result: CustomValidationResult = { 'invalidEmail': false };
    const emailList = control.value?.split(',') || [];
    if(emailList?.length > 3){
      result['invalidEmailNumber'] = true;
      return result;
    }
    const invalidEmails = emailList.filter((email)=>{
      return email.trim().match(regex) === null;
    });
    if(invalidEmails.length > 0){
      result['invalidEmail'] = true;
      return result;
    }
    return null;
  };

}

const validateClaim = (regex: string): ValidatorFn => {
  const result: CustomValidationResult = { 'invalidChar': false };
  return (control: FormControl) => {
    if (control.value && control.value?.match(regex) === null
    ) {
      result['invalidChar'] = true;
      return result;
    }
    return null;
  };

}
