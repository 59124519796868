import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { XADropdownConfig } from 'src/app/model/xa-dropdown-model';
import { MatDialog } from '@angular/material/dialog';
import { TermsConditionsComponent } from 'src/app/dialogs/terms-conditions/terms-conditions.component';
import { CommonService } from 'src/app/services/common/common.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult, PublicClientApplication, SilentRequest } from '@azure/msal-browser';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ConfigurationService } from 'src/app/config/configuration/service/configuration.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: [ './landing-page.component.scss' ]
})
export class LandingPageComponent implements OnInit {
  name: string = '';
  year: number;
  config: XADropdownConfig = { 
    name: 'singleSelect', 
    label: '', 
    placeHolder: 'Select...',
    labelKey: 'Name',
    valueKey: 'TenantID'
  }
  options: Array<any> = [];
  selectedTenant: any;
  private loginPolicy:string='';
  public isDirectUrl:boolean = false;
  public logoUrl:string = '';
  public androidLink = '';
  public iosLink = '';
  public pageComponents:any = {};
  public baseConfigUrl:string = '';
  public urlObj:any;
  termsAndCondition: boolean = false;

  /**
   * constructor
   * @param activatedRoute 
   * @param dialog 
   * @param commonService 
   * @param httpClient 
   */
  constructor(
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private commonService: CommonService,
    private httpClient: HttpClient,
    private msalService: MsalService,
    private configurationService:ConfigurationService,
    private authService: AuthService
  ) {
    const name = this.activatedRoute.snapshot.paramMap?.get('name');
    if(name){
      this.processRouteParam(name);
    }else{
      const loginParam = localStorage.getItem('currentLoginParam');
      if(loginParam){
        const redirectUrl = `${location.origin}/landing/${loginParam}`;
        localStorage.setItem('currentLoginParam', '');
        localStorage.setItem('customLoginPolicy', '');
        window.open(redirectUrl, '_self');
      }else{
        this.iosLink = 'https://apps.apple.com/in/app/sharq-insurance-smart-services/id1659834586';
        this.androidLink = 'https://play.google.com/store/apps/details?id=com.sharqinsurance.smartservices';
      }
    }

    const isLoggedOut = localStorage.getItem('isLoggedOutForced');
    if(isLoggedOut === 'true'){
      localStorage.setItem('isLoggedOutForced', '');
      this.navigate();
      return;
    }

    this.year = new Date().getFullYear();
  }

  /**
   * process route param
   */
  async processRouteParam(param:string):Promise<void>{
    try{
      const decodedData = window.atob(param);
      const parsedObj = JSON.parse(decodedData);
      if(parsedObj) {
        localStorage.setItem('tenant', JSON.stringify(parsedObj));
        this.isDirectUrl = true;
        localStorage.setItem('currentLoginParam', param);
        if(parsedObj.loginPolicy){
          this.loginPolicy = parsedObj?.loginPolicy;
          localStorage.setItem('customLoginPolicy', this.loginPolicy);
        }else{
          localStorage.setItem('customLoginPolicy', '');
        }
        if(parsedObj.TenantID){
          this.urlObj = parsedObj;
        }
      }
    }catch(e){
      this.name = param?.toLowerCase();
    }
  }

  /**
   * handle config response
   */
  async handleConfigResponse(resp:any):Promise<void>{
    const currentConfiguration = resp;
    if(!resp?.TenantID){
      resp.TenantID = resp.TanentId;//added for backward compatibilty
    }

    if(this.urlObj && resp?.TenantID !== this.urlObj.TenantID ){
      this.navigate();
      return;
    }
    const backgroundUrlObj = this.configurationService.getIconData(resp); 
    const { iconUrl, backgroundImageUrl, androidLinkUrl, iosLinkUrl, pageComponents, baseConfigUrl } = backgroundUrlObj;
    this.androidLink = androidLinkUrl;
    this.baseConfigUrl = baseConfigUrl;
    this.iosLink = iosLinkUrl;
    this.logoUrl = iconUrl;
    this.pageComponents = pageComponents.find(x=>x.ItemName === 'DocumentSlots');
    if(!this.pageComponents){
      this.pageComponents = {};
    }
    const mainContainer = document.querySelector('.default-container') as HTMLElement;
    mainContainer.style.background = `url('${backgroundImageUrl}') 50% / cover no-repeat`;
    this.configurationService.setConfigData(currentConfiguration);
    await this.configurationService.setLandingPageTranslation(resp);
    this.commonService.hideLoading();
  }

  /**
   * load tenant specific config
   */
  async loadTenantSpecificConfig(key:string):Promise<void>{
    if(!this.urlObj){
      await this.handleConfigResponse(key);
      return
    }
    this.configurationService.getTenantConfiguration(this.urlObj.TenantID).subscribe({
      next: async (resp)=>{
        await this.handleConfigResponse(resp);
      }, error: ()=>{
        this.navigate();
        this.commonService.hideLoading();
      }
    })
  }

  /** 
   * load default tenant 
  */
  getDefaultConfigDetail():void{
    this.configurationService.getDefaultConfig().subscribe({
      next: async(resp)=>{
        if(resp){
          const fileName = resp?.data?.configurationFileURL;
          const fileJSON = await this.configurationService.getDefaultTranslation(fileName);
          this.loadTenantSpecificConfig(fileJSON);
        }
        this.commonService.hideLoading();
      }, error: ()=>{
        this.commonService.hideLoading();
      } })
  }

  /**
   * get tenant configuration
   */
  getTenantConfiguration():void{
    this.commonService.showLoading();
    if(!this.urlObj){
      this.getDefaultConfigDetail();
      return;
    }
    
    this.loadTenantSpecificConfig('');
  }

  /**
   * on init
   */
  ngOnInit(): void {
    
    const httpOptions = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      })
    }

    this.getTenantConfiguration();
   
    if(this.isDirectUrl){
      return;
    }
    
    this.commonService.showLoading();
    this.httpClient.get(environment.tenantList, httpOptions).subscribe({
      next: (response: any) => {
        this.options = response;
        this.commonService.hideLoading();
      },
      error: () => {
        this.commonService.hideLoading();
      }
    });
  }

  /**
   * isAuthenticated
   */
  get authenticated(): boolean {
    return this.msalService.instance.getActiveAccount() ? true : false;
  }

  /**
   * handle redirection
   */
  handleRedirection():void{
    let redirectUrl = '';
    if(this.loginPolicy){
      redirectUrl = `${location.origin}?lp=${this.loginPolicy}`;
    }else{
      localStorage.setItem('currentLoginParam', '');
      localStorage.setItem('customLoginPolicy', '');
      if (this.name) {
        redirectUrl = `${location.origin}?cid=${this.name}`
      } else {
        redirectUrl = `${location.origin}?cid=XA360`
      }
    }
      
    window.open(redirectUrl, '_self');
  }

  /**
   * navigate
   */
  navigate(): void {
    if(this.authenticated){
      const request = {
        redirectStartPage: '/',
        scopes: [ 'openid', 'profile', `${environment.b2cSettings.caseManagementAppClientId}` ]
      };  
      this.msalService.acquireTokenSilent(request as SilentRequest).subscribe({
        next: (result: AuthenticationResult)=>{
          localStorage.setItem('isLoggedOutForced', 'true');
          const msalInstance: PublicClientApplication = this.msalService.instance as PublicClientApplication;
          msalInstance['browserStorage'].clear();
          const logoutUrl = this.authService.buildLogoutRoute(result.idToken, localStorage.getItem('activePolicy'), localStorage.getItem('correlationId'));
          window.location.href = logoutUrl;
        }
      });
      
    }else{
      this.handleRedirection();
    }    
  }

  /**
   * proceed
   */
  proceed(): void {
    const tenant = this.options.find(x => x.TenantID === this.selectedTenant);
    if(tenant) localStorage.setItem('tenant', JSON.stringify(tenant));
    this.navigate();
  }

  /**
   * open dialog
   */
  openTermsAndConditions(event: Event): void {
    event.stopPropagation();
    this.dialog.open(TermsConditionsComponent, {
      width: '500px',
      height: 'auto',
      panelClass: 'terms-conditions',
      autoFocus: false
    });
  }
}
