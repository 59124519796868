import { style } from '@angular/animations';
import { min } from 'rxjs';

export const ReserveModificationConfig = {
  columns: [
    {
      id: 'id',
      width: '0px',
      style: 'display: none',
      header: {
        text: 'id',
        tooltip: '',
        style: 'display: none; max-width: 0'
      },
      cellStyle: 'display: none; max-width: 0',
      selector: {
        field: 'id',
      },
      widget: {
        type: 'text'
      }
    },
    {
      id: 'editable',
      width: '0px',
      style: 'display: none',
      header: {
        text: 'id',
        tooltip: '',
        style: 'display: none; max-width: 0'
      },
      cellStyle: 'display: none; max-width: 0',
      selector: {
        field: 'editable',
      },
      widget: {
        type: 'text'
      }
    }, {
      id: 'ReserveCode',
      sortable: false,
      selector: {
        field: 'reserveCode',
      },
      header: {
        text: 'reserve_code',
        tooltip: '',
        style: 'padding-left: 5px;'
      },
      widget: {
        type: 'text',
      },
    }, {
      id: 'ReserveType',
      sortable: false,
      selector: {
        field: 'reserveType',
      },
      header: {
        text: 'reserve_type',
        tooltip: '',
        style: 'padding-left: 5px;'
      },
      widget: {
        type: 'text',
      },
    },
    {
      id: 'Amount',
      sortable: false,
      selector: {
        field: 'toBePaid',
      },
      header: {
        text: 'amount',
        tooltip: '',
        style: 'padding-left: 5px;'
      },
      widget: {
        type: 'input',
        // validations: {
        //   minLength: 3,
        //   pattern: '^[0-9]*$'
        // }
      },
    },
    {
      id: 'action',
      sortable: false,
      width: '100px',
      cellStyle: 'justify-content: center;',
      selector: {
        field: 'mode',
      },
      header: {
        text: 'action',
        tooltip: '',        
        style: 'display: flex;justify-content: center;'
      },
      widget: {
        type: 'editable'
      },
    },
    {
      id: 'reserveDetailGuid',
      width: '0px',
      style: 'display: none',
      header: {
        text: 'reserveDetailGuid',
        tooltip: '',
        style: 'display: none; max-width: 0'
      },
      cellStyle: 'display: none; max-width: 0',
      selector: {
        field: 'reserveDetailGuid',
      },
      widget: {
        type: 'text'
      }
    }
  ]
};