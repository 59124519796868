<div class="header">
    <div class="title-container"
         *ngIf="!showSearchBar  && !showSearchDetail">
        <mat-icon class="pointer side-menu-action"
                  [svgIcon]="menuIcon"
                  (click)="toggleSideMenu()"></mat-icon>
        <div class="heading">
            <span class="title">{{ title | translate}}</span>
            <span class="sub-title"
                  *ngIf="subTitle">{{ subTitle | translate}} / {{ title | translate}}</span>
        </div>
    </div>
    <div class="tab-container"
         *ngIf="!showSearchBar  && !showSearchDetail && showTabMenus">
        <ng-container *ngFor="let tabMenu of tabMenus">
            <div class="tab-menu-item"
                 [ngClass]="{'tab-disabled':(tabMenu.index === 5 && caseStatusIndex < 5) || tabMenu.index > (caseStatusIndex + 1) || !caseId || showNoAccess,'text-primary': tabMenu.active}"
                 (click)="tabChanged(tabMenu)">
                <label for=""
                       [ngClass]="{'text-primary': tabMenu.active}">{{tabMenu.title | translate}}</label>
            </div>
        </ng-container>

    </div>
    <div class="menu-container"
         [ngStyle]="{'flex' : showSearchBar  || showSearchDetail ? '1': 'unset'}">
        <div class="menu"
             *ngIf="!showSearchBar  && !showSearchDetail">
            <div class="avatar"
                 mat-button
                 [matMenuTriggerFor]="menu">
                <img alt="avatar"
                     [src]="avatar"
                     (error)="avatar='';"
                     *ngIf="avatar else text" />
                <ng-template #text>
                    <label for="">{{avatarText}}</label>
                </ng-template>
            </div>
            <mat-menu #menu="matMenu"
                      xPosition="before"
                      backdropClass="profile-mat-menu">
                <div mat-menu-item
                     (click)="openProfile()">
                    <mat-icon svgIcon="person-add"
                              aria-hidden="false"
                              aria-label="profile icon"
                              color="black"></mat-icon>{{ 'my_profile' | translate}}
                </div>
                <div class="separator"></div>
                <div mat-menu-item
                     (click)="logout()">
                    <mat-icon [svgIcon]="'logout'"
                              aria-hidden="false"
                              aria-label="logout icon"></mat-icon>
                    {{ 'logout' | translate}}
                </div>
            </mat-menu>
        </div>
        <ng-container *chqPermission="'notification'">
            <div class="menu"
                 *ngIf="!showSearchBar  && !showSearchDetail && (hasRepairPermission || hasClaimPermission || hasQuoteAssessmentPermission)"
                 [matMenuTriggerFor]="notificationsMenu"
                 #notificationsMenuTrigger="matMenuTrigger"
                 (menuOpened)="notificationMenuOpened()">
                <mat-icon [svgIcon]="'bell'"
                          aria-hidden="false"
                          aria-label="notification icon"></mat-icon>
                <span class="notification-unread-counter"
                      [hidden]="notificationList.length === 0 || notificationUnreadCount === 0">{{ notificationUnreadCount
                    }}</span>
                <mat-menu #notificationsMenu="matMenu"
                          xPosition="before"
                          backdropClass="notification-mat-menu">

                    <div (click)="stopEventPropagation($event)"
                         class="w-100">
                        <h3 class="notification-header">{{'notifications' | translate}}</h3>
                        <div class="w-100 flex justify-space-between"
                             [ngClass]="{'text-muted':+notificationUnreadCount === 0}"
                             style="padding: 28px 0;">
                            <span>{{notificationTotalCount}} messages</span>
                            <a (click)="setAllNotificationsRead()"
                               [hidden]="notificationList.length === 0">{{'mark_all_read' | translate}}</a>
                        </div>
                        <div *ngIf="notificationTotalCount === 0"
                             class="flex"
                             style="flex-wrap: wrap; text-align: center; flex-direction: column;">
                            <img alt='no-data'
                                 src="assets/img/no-data.png"
                                 style="margin: 100px 0px 15px;" />
                            <span>{{'no_notifications' | translate}}</span>
                            <span>{{'no_notifications_msg' | translate}}</span>
                        </div>
                        <div class="separator-100"
                             [hidden]="notificationTotalCount === 0"></div>
                        <cdk-virtual-scroll-viewport #scroller
                                                     itemSize="20"
                                                     class="notification-details-panel"
                                                     *ngIf="notificationTotalCount !== 0">
                            <ng-container *cdkVirtualFor="let item of notificationList">
                                <div (click)="setNotificationRead(item)"
                                     class="notification-detail">
                                    <div [innerHTML]="item.Title"
                                         class="notification-title"></div>
                                    <ng-container *ngIf="item.CreatedDate">
                                        <ng-container>
                                            <label for="">{{ checkAgoFormat(item.CreatedDate)}}</label>
                                        </ng-container>
                                    </ng-container>
                                    <span class="notification-unread-dot"
                                          *ngIf="!item.IsRead"></span>
                                    <button *ngIf="item.isShowActionButton"
                                            type="button"
                                            class="btn-primary col-12 notification-button"
                                            (click)="notificationNavigate($event,item.Data?.RepairGuid, item.Data?.Action, item)">
                                        {{ item.actionButtonText | translate }}
                                    </button>
                                    <div class="separator-100"></div>
                                </div>
                            </ng-container>
                        </cdk-virtual-scroll-viewport>
                        <div *ngIf="notificationLoading$ | async">
                            <mat-spinner [strokeWidth]="5"
                                         [diameter]="50"
                                         [mode]="'indeterminate'"
                                         style="margin: auto;"></mat-spinner>
                        </div>
                    </div>
                </mat-menu>
            </div>
        </ng-container>

        <ng-container *xaCheckPermission="{serviceName: 'Addenda Repair', 'permissionKey': 'whatsapp.general'}">
            <div *ngIf="!showSearchBar  && !showSearchDetail && hasRepairPermission"
                 class="menu"
                 (click)="openChatWindow();">
                <mat-icon svgIcon="messaging"
                          aria-hidden="false"
                          aria-label="whatsapp icon"></mat-icon>
                <span class="notification-unread-counter"
                      [hidden]="generalCommunicationMessagesCount === 0">{{ generalCommunicationMessagesCount }}</span>
            </div>
        </ng-container>

        <div class="menu"
             *ngIf="!showSearchBar  && !showSearchDetail && (hasRepairPermission || hasClaimPermission || hasQuotePermission)"
             (click)="showSearchBar=true;">
            <mat-icon svgIcon="search"
                      aria-hidden="false"
                      aria-label="search icon"></mat-icon>
        </div>
        <chq-search-bar *ngIf="showSearchBar"
                        (closeSearch)="close();"
                        (handleMoreResultClick)="loadMorePages($event);"
                        [placeHolder]="searchPlaceHolder"
                        [isClaimModule]="hasClaimPermission"></chq-search-bar>
        <chq-search-detail *ngIf="showSearchDetail"
                           (closeSearch)="close();"
                           [isClaimModule]="hasClaimPermission"
                           [searchKey]="searchResultString"></chq-search-detail>
    </div>
</div>


<mat-sidenav-container class="messaging-sidenav-container">
    <mat-sidenav #messaging
                 class="messaging-sidenav"
                 [fixedInViewport]="true"
                 [mode]="'over'"
                 [position]="'end'">
        <chat *ngIf="messaging.opened"
              (closeNav)="closeNav($event)"
              [ConversationGuid]="ConversationGuid"
              [isGeneralCommunication]="true"> </chat>
    </mat-sidenav>
</mat-sidenav-container>