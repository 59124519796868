<div class="dialog-container">
    <div mat-dialog-close class="btn-close" (click)="close()">
        <mat-icon class="cross" svgIcon="cross" aria-hidden="false"
           aria-label='close icon'></mat-icon>
   </div>
    
    <div mat-dialog-content class="img-wrapper">
        <video controls>
            <source [src]="data.videoUrl" type="video/mp4">
            Your browser does not support HTML video.
          </video>
    </div>
</div>