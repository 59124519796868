import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CaseSubmitSuccessComponent } from '../case-submit-success/case-submit-success.component';
import { QuoteManagementService } from 'src/app/services/quote-management/quote-management.service';
import { CommonService } from 'src/app/services/common/common.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { CASE_STATUS } from 'src/app/config/constants/quote.assessment.constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-case-submit-with-quote-id',
  templateUrl: './case-submit-with-quote-id.component.html',
  styleUrls: [ './case-submit-with-quote-id.component.scss' ]
})
export class CaseSubmitWithQuoteIdComponent {
  public formGroup: FormGroup;
  quoteId: string = '';
  isFormValid = false;
  /**
    Constructor for the CaseSubmitWithQuoteIdComponent.
    @constructor
    @param {any} data - Data passed to the component.
    @param {MatDialogRef} dialogRef - Reference to the dialog box.
    @param {MonitorService} monitorService - Service for monitoring operations.
  */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CaseSubmitWithQuoteIdComponent>,
    public dialog: MatDialog,
    private commonService: CommonService,
    private quoteManagementService: QuoteManagementService,
    private monitorService: MonitorService,
    private router: Router,
  ) { }

  /**
   * on init
   */
  ngOnInit(): void {
    this.formGroup = new FormGroup({
      'quoteId': new FormControl('')
    });
    this.formGroup.get('quoteId').setValidators([ Validators.required, Validators.pattern(/^\S{3,}$/) ])
    if(this.data?.quoteId) {
      this.formGroup.get('quoteId').setValue(this.data.quoteId);
      this.quoteId = this.data.quoteId;
      this.checkFormValidation()
    }
    this.formGroup.get('quoteId').valueChanges.subscribe((v) => {
      this.quoteId = v;
      this.checkFormValidation()
    })
  }

  /**
      Closes the dialog with the given data.
      @param {any} data - The data to be passed back to the dialog caller.
    */
  checkFormValidation(): void {
    this.isFormValid = this.formGroup.get('quoteId').valid
  }
  /**
      Closes the dialog with the given data.
      @param {any} data - The data to be passed back to the dialog caller.
    */
  cancelDialog(data: any): void {
    this.dialogRef.close(false);
  }
  /**
      Closes the dialog with the given data.
      @param {any} data - The data to be passed back to the dialog caller.
    */
  onSubmitForm(): void {
    this.commonService.showLoading();
    this.quoteManagementService.updateQuoteId({
      QuoteNo: this.quoteId
    }, this.data.caseGuid).subscribe({
      next: (result: any) => {
        this.commonService.hideLoading();
        if(result) {
          this.dialogRef.close(false);
          
          const dialogRef = this.dialog.open(CaseSubmitSuccessComponent, {
            data: {
              quoteId: this.quoteId
            },
            width: '450px',
            panelClass: 'case-submit-success-panel',
            autoFocus: false
          });
          dialogRef.afterClosed().subscribe({
            next: () => {
              this.commonService.caseManagementStatus.next(CASE_STATUS.Completed);
              this.router.navigate([ '/case' ]);
            }
          });
        }
      },
      error: (err: any) => {
        this.commonService.hideLoading();
        this.monitorService.logException(err);
      }
    });
    
  }


}
