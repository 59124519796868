<div class="video-uploader h-100 flex align-center justify-center">
    <div class="content">
        <div class="step-wrapper"
             [ngClass]="{'border-gray-dotted': !serverVideoUrl && !selectedFile}">
            <div class="step-container">
                <div class="step-desc">
                    <div class="step-text">
                        <div class="step-title">
                            <label for="">360 Video <span class="mandatory"
                                      *ngIf="mandatory">&nbsp;*</span></label>
                            <mat-icon *ngIf="((uploadStatus.toLowerCase() ==='isuploadsuccess' || serverVideoUrl) && uploadStatus.toLowerCase() !== 'uploadinprogress') else unchecked"
                                      svgIcon="checked"
                                      aria-hidden="false"
                                      aria-label="checked icon"></mat-icon>
                            <ng-template #unchecked>
                                <mat-icon svgIcon="unchecked"
                                          *ngIf="uploadStatus.toLowerCase() !== 'uploadinprogress'"
                                          aria-hidden="false"
                                          aria-label="unchecked icon"></mat-icon>
                            </ng-template>
                            <chq-custom-loader #loader
                                               [showLoading]="processingVideo || uploadStatus.toLowerCase() === 'uploadinprogress' "></chq-custom-loader>
                        </div>
                        <div class="step-title ai-status-text">
                            <span>{{uploadStatus.toLowerCase() === 'uploadinprogress' ? 'Upload in Progress' : (uploadStatus.toLowerCase() === 'isuploadsuccess' ? 'Uploaded': 'Not Uploaded')}}</span>
                        </div>
                    </div>
                </div>
                <div class="step-image">
                    <mat-icon class='video-icon'
                              svgIcon="play"
                              aria-hidden="false"
                              aria-label="play icon"
                              (click)="openVideoPlayer()"></mat-icon>
                    <ng-container *ngIf="localVideoUrl !== ''; else videoUrlTemplate">
                        <video playsinline
                               preload="metadata"
                               class="video-placeholder local">
                            <source [src]="localVideoUrl+'#t=0.1'"
                                    type="video/mp4">
                        </video>
                    </ng-container>
                    <ng-template #videoUrlTemplate>
                        <video playsinline
                               class="video-placeholder server"
                               preload="metadata"
                               *ngIf="serverVideoUrl">
                            <source [src]="serverVideoUrl+'#t=0.1'"
                                    type="video/mp4">
                        </video>
                    </ng-template>
                    <input hidden
                           *ngIf="!showInternalCamera"
                           #uploader
                           type="file"
                           name="imageData"
                           id="fileDropRef"
                           (change)="handleUpload($event)"
                           accept="video/mp4,video/x-m4v,video/*" />
                    <xa-video #video
                              *ngIf="showInternalCamera"
                              [forceOrientation]="true"
                              (processingVideo)="processingVideoHandler();"
                              (videoRecorded)="handleUpload($event);"></xa-video>
                </div>
                <div class="step-action-icon"
                     *ngIf="status !== 'Completed'">
                    <mat-icon svgIcon="edit"
                              class="edit-icon border-light-gray"
                              *ngIf="localVideoUrl || serverVideoUrl"
                              aria-hidden="false"
                              (click)="showInternalCamera? openVideo(): uploader.nativeElement.click()"
                              aria-label="edit icon"></mat-icon>
                    <xa-button *ngIf="!serverVideoUrl && !localVideoUrl"
                               (click)="showInternalCamera? openVideo(): uploader.nativeElement.click()"
                               type="primary"
                               class="left">{{ (showInternalCamera ? 'capture_video':'upload_video') | translate }}</xa-button>
                    <mat-icon svgIcon="delete"
                              class="delete-icon border-danger"
                              aria-hidden="false"
                              *ngIf="localVideoUrl || serverVideoUrl"
                              (click)="$event.stopPropagation();delete();"
                              aria-label="unchecked icon"></mat-icon>
                </div>
            </div>
        </div>
        <span class="file-size-text">
            {{'max_file_allowed' | translate}}{{ (maxMbAllowed + 'MB') + '(mp4)'}}
        </span>
    </div>
</div>