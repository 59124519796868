import { AbstractControl, ValidatorFn } from '@angular/forms';

export class StepData {
  group: string;
  input: StepInput[]
}

export class labelData {
  leftText: string;
  rightText; string;
}

export class StepInput {
  questionType: string;
  question: string;
  icon?: string;
  questionCode?: string;
  options?: unknown[];
  image?: string;
  labels?: labelData | any;
}

/**
 * 
 * @returns 
 */
export function validateMobileNumberBasedOnCode(countryCode: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {

    if (control.value) {
      return null;
    }

    return { incorrectFormat: 'Mobile number format is not correct.' }
  };
}

export const countryCodeList = [
  {
    'urlFlag': 'https://stautofounddev001.blob.core.windows.net/country/Bahrain.webp?sv=2020-10-02&st=2022-09-06T06%3A19%3A27Z&se=2037-09-07T06%3A19%3A00Z&sr=b&sp=r&sig=lUP8YkOIXGbOHFQVUWprK9NY8cFYSYQEFmQomEnxUVg%3D',
    'label': '+973',
    'value': '+973',
    'regExpression': '^(?:3|5|6|7|8|9|)[0-9]{7}$'
  },
  {
    'urlFlag': 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/Flag_of_India.svg/23px-Flag_of_India.svg.png',
    'label': '+91',
    'value': '+91',
    'regExpression': '^([6-9])[0-9]{9}$'
  },
  {
    'urlFlag': 'https://stautofounddev001.blob.core.windows.net/country/Kuwait.webp?sv=2020-10-02&st=2022-09-06T06%3A38%3A26Z&se=2037-09-07T06%3A38%3A00Z&sr=b&sp=r&sig=nunMwNOJ%2FrTKSDV5iAxgKodnnwQD%2Ffp4goL7Ku0l4pc%3D',
    'label': '+965',
    'value': '+965',
    'regExpression': '^(?:5|6|9|)[0-9]{7}$'
  },
  {
    'urlFlag': 'https://stautofounddev001.blob.core.windows.net/country/Oman.webp?sv=2020-10-02&st=2022-09-06T06%3A38%3A48Z&se=2037-09-07T06%3A38%3A00Z&sr=b&sp=r&sig=htU4ebk3jkVDmSR%2FXucYBP2%2F8bi1865O79mX4sLqgVU%3D',
    'label': '+968',
    'value': '+968',
    'regExpression': '^(?:2|7|9|)[0-9]{7}$'
  },
  {
    'urlFlag': 'https://stautofounddev001.blob.core.windows.net/country/Qatar.webp?sv=2020-10-02&st=2022-09-06T06%3A39%3A13Z&se=2037-09-07T06%3A39%3A00Z&sr=b&sp=r&sig=inTnPOkquORFnnxuVaRHsuTDrurtcmFnVe5GnsrSC50%3D',
    'label': '+974',
    'value': '+974',
    'regExpression': '^[0-9]{8}$'
  },
  {
    'urlFlag': 'https://stautofounddev001.blob.core.windows.net/country/KSA.webp?sv=2020-10-02&st=2022-09-06T06%3A19%3A57Z&se=2037-09-07T06%3A19%3A00Z&sr=b&sp=r&sig=3oTSGPcMa4cF5BLY4nVlPzclTPruZsxYr6MjVyUoGhc%3D',
    'label': '+966',
    'value': '+966',
    'regExpression': '^(?:05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$',
  },
  {
    'urlFlag': 'https://stautofounddev001.blob.core.windows.net/country/UAE.webp?sv=2020-10-02&st=2022-09-06T06%3A40%3A06Z&se=2037-09-07T06%3A40%3A00Z&sr=b&sp=r&sig=KLMOP4%2FlZMrmpL4P2ahVY2Oh04uL3VNLthe6JIxaFxc%3D',
    'label': '+971',
    'value': '+971',
    'regExpression': '^(?:50|51|52|54|55|56|58)[0-9]{7}$'
  },
  {
    'urlFlag': 'https://stautofounddev001.blob.core.windows.net/country/UK.png?sp=r&st=2022-12-13T15:49:30Z&se=2037-12-13T23:49:30Z&spr=https&sv=2021-06-08&sr=b&sig=ltwFfka6rn4AMY3nyCUqd7fyjp4QBz%2F8072YrloQpV8%3D',
    'label': '+44',
    'value': '+44',
    'regExpression': '^(?:0|\\+?44)(?:\\d\\s?){9,10}$'
  }
]

export const customerFeedbackStepData = [
  {
    group: 'defaultStep1',
    input: [
      { questionType: 'info', question: 'We take privacy issues seriously. You can be sure that your personal data is securely protected.', icon: 'save' },
      {
        questionType: 'infoWithValue',
        question: '<p>Dear Customer,</p><p>Your feedback is valuable to us. Please proceed to enter the feedback on the service provided for the repair of your vehicle (License plate no: {{license_plate_number}})</p>'
      }
    ]
  }
];