import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { XaFileUploadSelector } from './xa-file-upload.model';

@Component({
  selector: 'xa-photo-upload-mode-selector',
  templateUrl: './xa-photo-upload-mode-selector.component.html',
  styleUrls: [ './xa-photo-upload-mode-selector.component.scss' ]
})
export class XaPhotoUploadModeSelecterComponent {
  //Input attribute 
  @Input() uploadModeSelector:XaFileUploadSelector;

  //Output events
  @Output() mode: EventEmitter<{ 'mode': string }> = new EventEmitter();  

  /**
   * constructor
   */
  constructor( private monitorService: MonitorService) {}

  /**
   * initialize single Upload
   */
  allowSingleUpload():void {
    this.monitorService.logEvent('allowSingleUpload', [ 'XaPhotoUploadModeSelecterComponent', 'addenda' ]);
    this.mode.emit({ 'mode': 'single' })
  }

  /**
   * initialize multiple Upload
   */
  allowMultipleUpload():void {
    this.monitorService.logEvent('allowMultipleUpload', [ 'XaPhotoUploadModeSelecterComponent', 'addenda' ]);
    this.mode.emit({ 'mode': 'multiple' })
  }
}
