<div class="add-photo-container">
    <chq-step-header [chqModel]="photosHeader"
                     *ngIf="(photosHeader.isRepairModule !== true && photosHeader.isClaimModule !== true && photosHeader?.isFnolModule !== true) ||  photosHeader.isHeaderOnly === true"></chq-step-header>
    <div class="photo-container"
         *ngIf="(showSPI || !hideBulk) && !repairUnapproved && !commonService.isViewOnly">
        <div class="card-container">
            <div class="card"
                 *ngIf="showSPI">
                <label for=""
                       class="card-title">{{ 'smart_photo_inspection' | translate }}</label>
                <p class="card-action">{{ 'open_your_camera' | translate }}</p>
                <p class="card-description">
                    {{ 'add_photo_msg' | translate }}
                </p>
                <chq-button [buttonModel]="cameraButtonModel"></chq-button>
            </div>
            <div class="card"
                 *ngIf="!hideBulk">
                <label for=""
                       class="card-title">{{ 'bulk_upload' | translate }}</label>
                <p class="card-action">{{ 'upload_device' | translate }}</p>
                <p class="card-description">
                    {{ 'bulk_msg' | translate }}
                </p>
                <chq-button [buttonModel]="bulkUploadButtonModel"></chq-button>
            </div>
        </div>
    </div>
    <div class="wrapper"
         *ngIf="(!showSPI && hideBulk) || repairUnapproved || commonService.isViewOnly">
        <div class="no-document-div">
            <img alt="no document"
                 src="assets/icons/no-document.svg">
            <label class="no-vehicle-content"
                   for="">
                {{ (repairUnapproved ? 'start_job' : 'bulk_spi_disabled') | translate}}
            </label>

        </div>
    </div>
    <div class="footer-full-wrapper">
        <div class="footer-image-wrapper wrapper-flex">
            <div class="image-wrapper">
                <img alt="banner"
                     src="assets/img/{{ + photosHeader?.isFnolModule ? 'addenda.png': ((photosHeader.isRepairModule && !fromRepairerView) ?'addenda-repair.png': (photosHeader.isClaimModule || fromRepairerView) ? 'addenda-claims.png' : 'addenda-quote.png')}}" />
            </div>
            <div class="footer-button-wrapper">
                <xa-button type="outline" color="gray" (click)="commonService.requestCustomer.next(true)" *ngIf="sendRequest === 1 || sendRequest === 2">
                    <span *ngIf="sendRequest === 2"> {{'send_request' | translate }}</span>
                    <span *ngIf="sendRequest === 1"> {{'request_missing_information' | translate }}</span>
                </xa-button>
                <chq-button [buttonModel]="cancelButtonModel"
                            *ngIf="showDelete && photosHeader?.isRepairModule && !fromRepairerView"></chq-button>
                <chq-button *ngIf="showEstimate && !hideEstimate &&  !photosHeader.isRepairModule && !photosHeader?.isFnolModule && !fromRepairerView"
                            class="margin-right"
                            [buttonModel]="nextButton"></chq-button>
                <xa-button *ngIf="photosHeader?.isFnolModule"
                           (click)="nextEvent.emit();">
                    {{'submit_fnol' | translate}}
                </xa-button>
                <chq-button [buttonModel]="notifyButtonModel"
                            *ngIf="showNotify"></chq-button>
                <chq-button *ngIf="showComplete"
                            class="margin-right"
                            [buttonModel]="addPartButtonModel"></chq-button>
                <chq-button *ngIf="showSubmitForReview"
                            [buttonModel]="submitForReviewModel"></chq-button>
                <chq-button *ngIf="showSubmitForApproval"
                            [buttonModel]="submitForApprovalButtonModel"></chq-button>
                <ng-container *chqClaimRoles="'claimGarageNavigate'">
                    <chq-button [buttonModel]="navigateToGarageButton"
                                *ngIf="photosHeader.isClaimModule"></chq-button>
                </ng-container>
                <ng-container *chqClaimRoles="'claimAssignBack'">
                    <chq-button *ngIf="showAssignBackButton"
                                [buttonModel]="assignBackApprovalButtonModel"></chq-button>
                </ng-container>
            </div>
        </div>
    </div>
</div>