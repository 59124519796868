import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, forkJoin, of, Subscription } from 'rxjs';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { menuItem, menuItems } from 'src/app/model/menu';
import { CommonService } from 'src/app/services/common/common.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { InspectionService } from 'src/app/services/inspection/inspection.service';
import { MediaCollectionService } from 'src/app/services/media-collection/media-collection.service';
import { ImgDialogComponent } from 'src/app/dialogs/img-dialog/img-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-chq-repair-order',
  templateUrl: './chq-repair-order.component.html',
  styleUrls: [ './chq-repair-order.component.scss' ]
})
export class ChqRepairOrderComponent implements OnInit, OnDestroy {
  @Input() inspectionId: any;
  @Input() repairGuid: string;
  @Input() objectId: number;
  @Input() domainId: number;
  @Input() isRepairOrderVehicle: any;
  @Input() isRepairOrderCustomer: any;
  @Output() closeSearch: EventEmitter<any> = new EventEmitter();
  @Output() backToSearchResultPage = new EventEmitter<boolean>();
  repairPermission: boolean = false;
  quotePermission: boolean = false;
  subscription: Subscription
  isExternal: boolean = false;
  menuItems: menuItem[] = menuItems;
  repairerView: boolean = false;
  customerDetails: boolean = true;
  vehicleDetails: boolean = true;
  photos: boolean = true;
  documents: boolean = true;
  filteredInspectionItems: any[] = [];
  filteredMediaParts: any[] = [];
  public showSearchResults = false;
  selectedCustomerDetails: boolean = true;
  selectedVehicleDetails: boolean = true;
  selectedPhotoDetails: boolean = true;
  selectedDocumentDetails: boolean = true;
  selectedPhotos: any[] = [];
  selectedDocuments: any[] = [];
  
  public createButtonModel: ChqWidgetsButtonModel = {
    label: 'Create',
    type: 'primary',
    icon: '',
    onclick: this.onCreate.bind(this)
  }

  public cancelButtonModel: ChqWidgetsButtonModel = {
    label: 'Cancel',
    type: 'outline',
    icon: '',
    onclick: this.onCancel.bind(this)
  }

  /**
   * constructor
   * @param router 
   */
  constructor(private commonService: CommonService,
    private inspectionService: InspectionService,
    private router: Router,
    private dialog: MatDialog,
    private mediaCollectionService: MediaCollectionService,
    private monitorService: MonitorService) {
    this.commonService.userPermission.subscribe((res: any) => {
      this.repairPermission = res.repair;
      this.quotePermission = res.quote;
    });

    this.subscription = this.commonService.userProfileData.subscribe({
      next: (res: any) => {
        if (res) {
          const automotiveServices = res?.data?.userPermission?.automotiveServices;
          const permissionList = automotiveServices.find(x => x.automotiveServiceName.toLowerCase() === 'carheal quote')?.permissions;
          const quoteMenus = this.menuItems.find(x => x.name === 'quote')?.childMenu;
          if (quoteMenus && quoteMenus?.length > 0 && permissionList && permissionList?.length > 0) {
            this.isExternal = this.commonService.hasPermission('case.external', permissionList);
          }
        }
      }
    });
  }
  
  /**
   * on init
   */
  ngOnInit(): void {
    this.monitorService.logEvent('ngOnInit', [ 'ChqRepairOrderComponent', 'addenda-repair' ]);
    if(this.isRepairOrderVehicle){
      this.commonService.showLoading();
      const promiseApi: any = {
        inspectionDetails: this.inspectionService.getInspection(this.inspectionId),
        mediaTemplateDetails: this.mediaCollectionService.getMediaTemplate()
      }
  
      const result = forkJoin(promiseApi).pipe(
        catchError(error => of(error))
      )
      result.subscribe((dict: any) => {
        if (dict) {
          if(dict.inspectionDetails){
            this.getInspection(dict.inspectionDetails); 
          }
          if(dict.mediaTemplateDetails){
            this.getDocumentAndCallDocumentIntelligence(dict.mediaTemplateDetails);
          }
        } else {
          this.commonService.hideLoading();
        }
      });
    }
    if(this.isRepairOrderCustomer){
      this.commonService.showLoading();
      const promiseApi: any = {
        mediaTemplateDetails: this.mediaCollectionService.getMediaTemplate()
      }
  
      const result = forkJoin(promiseApi).pipe(
        catchError(error => of(error))
      )
      result.subscribe((dict: any) => {
        if (dict) {
          if(dict.mediaTemplateDetails){
            this.getDocumentAndCallDocumentIntelligence(dict.mediaTemplateDetails);
          }
        } else {
          this.commonService.hideLoading();
        }
      });
    }
  }

  /**
   * close
   */
  close(): void {
    this.monitorService.logEvent('close', [ 'ChqRepairOrderComponent', 'addenda-repair' ]);
    this.showSearchResults = false;
    this.closeSearch.emit();
  }

  /**
   * on create click
   */
  onBackClick(): void {
    this.commonService.isRepairOrderVehicle.next(false);
    this.commonService.isRepairOrderCustomer.next(false);
    this.backToSearchResultPage.emit(true);
  }

  /**
* check for form validation and set button type
* @returns {void}
*/
  checkForFormValidation(): void {
    if (this.isFormValid()) {
      this.createButtonModel.type = 'primary';
    } else {
      this.createButtonModel.type = 'disabled';
    }
  }

  /**
 * check for form validation
 * @returns {void}
 */
  isFormValid(): boolean {
    let isValid = true;
    if((this.isRepairOrderCustomer && !this.selectedCustomerDetails) || (this.isRepairOrderVehicle && !this.selectedCustomerDetails && !this.selectedVehicleDetails)){
      isValid = false;
    } else {
      isValid = true;
    }
    return isValid;
  }

  /**
 * on cancel click
 */
  onCancel(): void {
    this.backToSearchResultPage.emit(false);
    this.isRepairOrderVehicle = false;
    this.isRepairOrderCustomer = false;
    this.commonService.isRepairOrderVehicle.next(false);
    this.commonService.isRepairOrderCustomer.next(false);
  }

  /**
 * get inspection
 */
  getInspection(inspectionDetailResponse: any): void {
    if (inspectionDetailResponse) {
      this.filteredInspectionItems = inspectionDetailResponse.inspectionItems
        ?.filter(item => item.rawAzureBlobUrl !== '')
        .map(item => ({ ...item, selected: true }));
      this.updatePhotosCheckbox();
    }
  }

  /**
 * get document and call document intelligence
 */
  getDocumentAndCallDocumentIntelligence(data: any): void {
    const mediaTemplate = data?.data;
    if (mediaTemplate?.length > 0) {
      const { id } = mediaTemplate[0];
      this.mediaCollectionService.getMediaDocumentData(id, this.objectId, this.domainId).subscribe({
        next: (data: any) => {
          if (data) {
            this.filteredMediaParts = data.data.medias
              .map((media) => {
                const filteredMediaParts = media.mediaParts.filter(part => part.mediaPartGuid !== '00000000-0000-0000-0000-000000000000');

                if (filteredMediaParts.length > 0) {
                  return {
                    ...media,
                    mediaParts: filteredMediaParts.map(part => ({ ...part, selected: true }))
                  };
                }
                return null;
              })
              .filter(media => media !== null);
            this.updateDocumentsCheckbox();
            this.commonService.hideLoading();
          }
        },
        error: (err: any) => {
          this.commonService.hideLoading();
          this.monitorService.logException(err);
        },
        complete: () => {
          this.commonService.hideLoading();
        }
      });
    }else{
      this.commonService.hideLoading();
    }
  }

  /**
 * on photo select
 */
  onPhotoSelect(item, event): void {
    item.selected = event;
    this.updatePhotosCheckbox();
  }

  /**
 * update photos checkbox
 */
  updatePhotosCheckbox(): void {
    this.photos = this.filteredInspectionItems?.some(item => item.selected);
  }

  /**
 * on document select
 */
  onDocumentSelect(document, event): void {
    document.selected = event;
    this.updateDocumentsCheckbox();
  }

  /**
 * update documents checkbox
 */
  updateDocumentsCheckbox(): void {
    this.documents = this.filteredMediaParts.some(part => part.mediaParts.some(document => document.selected));
  }

  /**
 * Handle selection changes for customer details checkbox
 */
  onCustomerDetailsChange(isChecked: boolean): void {
    this.selectedCustomerDetails = isChecked;
    this.checkForFormValidation();
  }

  /**
 * Handle selection changes for vehicle details checkbox
 */
  onVehicleDetailsChange(isChecked: boolean): void {
    this.selectedVehicleDetails = isChecked;
    this.checkForFormValidation();
  }

  /**
 * Handle selection of photos
 */
  onDocumentsChange(event: any): void {
    this.documents = event;
    this.filteredMediaParts.forEach(part => part.mediaParts.forEach(document => document.selected = true));
  }

  /**
 * Handle selection of photos
 */
  onPhotosChange(event: any): void {
    this.photos = event;
    this.filteredInspectionItems.forEach(item => item.selected = true);
  }

  /**
  * on create click
  */
  onCreate(): void {
    this.monitorService.logEvent('onCreate', [ 'ChqRepairOrderComponent', 'addenda-repair' ])
    if (!this.isFormValid()) {
      return;
    }

    const selectedCustomerDetails = this.selectedCustomerDetails;
    const selectedVehicleDetails = this.selectedVehicleDetails;
    const selectedPhotos = this.filteredInspectionItems.filter(item => item.selected);
    const selectedDocuments = this.filteredMediaParts.map(item => ({
      ...item,
      mediaParts: item.mediaParts.filter(mediaPart => mediaPart.selected)
    }));

    const cloneRepair: any = {
      repairGuid: this.repairGuid,
      isCustomerDetails: selectedCustomerDetails,
    }

    if(this.isRepairOrderVehicle){
      cloneRepair.isVehicleDetails = selectedVehicleDetails
    }

    if (cloneRepair.isCustomerDetails && selectedDocuments.length > 0) {
      cloneRepair.mediaDocuments = selectedDocuments.map(doc => ({
        mediaTemplateName: doc.name,
        mediaPartTemplateNames: doc.mediaParts
          .filter(part => part.name)
          .map(part => part.name)
      }));
    }
    if (cloneRepair.isVehicleDetails && selectedPhotos.length > 0) {
      cloneRepair.inspectionItemIds = selectedPhotos.map(photo => photo.id)
    }
  
    this.commonService.cloneRepairOrderData.next(cloneRepair);

    this.router.navigateByUrl('/repair/booking/0/refresh', { skipLocationChange: true }).then(() => {
      this.router.navigate([ 'repair/booking/0' ]);
    });
    this.close();
  }

  /**
   * open image dialog
   */
  imageDialog(state: string, url: string | ArrayBuffer, name: string): void {
    const dialogRef = this.dialog.open(ImgDialogComponent, {
      data: {
        state,
        title: name,
        image: url
      }, autoFocus: false
    });
    dialogRef.afterClosed().subscribe();
  }

  /**
   * show delete
   */
  openPDFinNewTab(url: string): void {
    window.open(url, '_blank');
  }

  /**
* on destroy
*/
  ngOnDestroy(): void {
    this.selectedCustomerDetails = true;
    this.selectedVehicleDetails = true;
    this.selectedPhotoDetails = true;
    this.selectedDocumentDetails = true;
    this.subscription.unsubscribe();
    this.commonService.isRepairOrderVehicle.next(null);
    this.commonService.isRepairOrderCustomer.next(null);
  }

}
