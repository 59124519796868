<div class="dialog-container">
    <div class="header">
        <h3>{{ 'filter_name' | translate }}</h3>
        <div class="close-icon"
             (click)="cancel();">
            <mat-icon [svgIcon]="'cross'"></mat-icon>
        </div>
    </div>
    <div mat-dialog-content
         class="email-dialog-body">
        <div class="form-container">
            <xa-radio-group [(ngModel)]="selectedClaimant">
                <xa-radio [value]="option.id"
                          *ngFor="let option of data">{{option.name}}</xa-radio>
            </xa-radio-group>
        </div>
    </div>
    <div class="btn-container">
        <xa-button class="right"
                   (click)="apply()">
            {{'apply'|translate}}
        </xa-button>
        <xa-button [type]="'outline'"
                   (click)="selectedClaimant = null"
                   class="right">
            {{'reset_all'|translate}}
        </xa-button>
    </div>
</div>