<div *ngIf="!showNoAccess"
     [ngClass]="{'photo-container': photosHeader.isRepairModule}">
    <ng-container *chqPermission="'newBooking'">
        <add-photos [caseData]="caseData"
                    [caseNumber]="photosHeader.caseNumber"
                    [showSPI]="checkRepairTypes"
                    [hideBulk]="hideBulk"
                    #addPhotos
                    [photosHeader]="addPhotosHeader"
                    (mode)="handleMode($event)"
                    *ngIf="!showUploading"
                    [hidden]="hideContent"
                    (nextEvent)="fnolNext();"
                    [hideEstimate]="hideEstimate"
                    [showEstimate]="showEstimateButton"
                    [repairData]="repairDetail"
                    [sendRequest]="sendRequest"></add-photos>
    </ng-container>

    <div class="wrapper"
         *ngIf="noImagesData">
        <div class="no-document-div">
            <img alt="no-document"
                 src="assets/icons/no-document.svg">
            <label for=""
                   class="no-vehicle-content">
                {{'no_photos_configured' | translate }}
            </label>

        </div>
        <div class="empty-wrapper">
            <div class="step-container"></div>
            <div class="step-container"></div>
            <div class="step-container"></div>
            <div class="step-container"></div>
        </div>
    </div>

    <div *ngIf="noImagesData"
         class="footer-full-wrapper">
        <div class="footer-image-wrapper">
            <div class="image-wrapper">
                <img alt="module-logo"
                     src="assets/img/{{ + (photosHeader?.isFnolModule || photosHeader?.isQuoteAssessmentModule) ? 'addenda.png': (photosHeader?.isRepairModule ?'addenda-repair.png':'addenda-quote.png') }}" />
            </div>
        </div>
    </div>

    <chq-images-uploader [hidden]="showDamageAnalysis || !showUploading"
                         (handleDeleteAllEvent)="handleDeleteAll()"
                         (backToUpload)="this.setInitialMode();"
                         [currentAdditionalModeMultiple]="currentAdditionalModeMultiple"
                         [fileUploaderService]="currentObservable"
                         #imageUploader
                         [footerOptions]="footer"
                         [bothOptionEnabled]="!hideBulk && checkRepairTypes"
                         [quoteAssessmentStatus]="quoteAssessmentData?.caseStatus || ''"
                         [hideAI]="!displayAI"
                         (updateMassUpload)="updateCurrentStatus($event);"
                         *ngIf="(showUploading === true || isMultipleUpload) || (imageStatusSteps && imageStatusSteps.length > 0)"
                         [isMassUpload]="isMultipleUpload"
                         [showReTakeDelete]="true"
                         [filteredSteps]="filteredSteps"
                         [imageStatusSteps]="imageStatusSteps"
                         [header]="photosHeader"
                         [inspectionId]="inspectionId"
                         [displayOutline]="displayOutline"
                         [inspection]="'photos'"
                         (stepSkipped)="stepSkipped()"
                         (updateAiEstimateStatus)="updateAiEstimateStatus($event)"
                         (completeUploadProcess)="completeUploadProcess($event)"
                         [sendRequest]="sendRequest"
                         [submitSection]="submitSection"
                         [quoteAssessmentData]="quoteAssessmentData"></chq-images-uploader>
    <damage-analysis *ngIf="showDamageAnalysis && displayAI"
                     [isBulkUpload]="isMultipleUpload"
                     [currentInspectionId]="currentInspectionID"
                     [minPhotoRequired]="minimumPhotosRequired"
                     [caseDetail]="partialCaseDetail || repairDetail"
                     [steps]="imageStatusSteps"
                     [fnolGUID]="fnolGUID"
                     [vehicleMetaData]="currentVehicle"
                     (nextEvent)="this.imagesNextEvent?.emit();"
                     [isRepairModule]="photosHeader.isRepairModule"
                     (backEvent)="handleBack($event)"
                     [aiEstimationStatus]="aiEstimationStatus"
                     [isQuoteAssessmentModule]="isQuoteAssessmentModule"
                     [quoteAssessmentData]="quoteAssessmentData"
                     [sendRequest]="sendRequest"></damage-analysis>
</div>
<no-access-error *ngIf="showNoAccess"></no-access-error>