import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiPagination } from 'src/app/widgets/xa-paginator/xa-paginator.component';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FNOLService {

  /**
   * constructor
   * @param http 
   */
  constructor(
    private http: HttpClient
  ) {
  }

  /**
 * createFNOL
 * @returns Observable
 */
  public createFNOL(body: any): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/v2/FNOL`;
    const headers = new HttpHeaders({ 'version': 'V2' });
    return this.http.post(url, body, { headers });
  }

  /**
   * create vehicle
   */
  createVehicle(body:any):Observable<any>{
    const vehicleType = body.vehicleTypeId || '';
    const url = `${environment.autoApi}/api/v1/Vehicles/AddVehicle?bodyTypeId=${vehicleType}`;
    return this.http.post(url, body);
  }

  /**
   * getClaimList
   * @returns Observable
   *
   * @param pagination
   * @param queryParams
   * @returns
   */
  getClaimList(pagination: ApiPagination, queryParams: string = null): Observable<any> {
    const skip = (pagination.currentPage - 1) * pagination.pageSize;
    queryParams = (!queryParams) ? `$top=${pagination.pageSize}&$skip=${skip}` : `$top=${pagination.pageSize}&$skip=${skip}&${queryParams}`
    const orderBy = `&$orderby=${pagination.orderBy}`
    const select = '$select=AssigneeId, AssigneeName, ClaimGuid, ClaimNo, CreatedDate, CustomerName, FnolId, Id, PolicyNo, Severity, Status, VehicleName, VehicleNumber, CreatedByUserName, IsAssignedBack, CreatedUserId&';
    const url = `${environment.fnolApiUrl}/odata/Claim?${select}${queryParams}${orderBy}`;
    return this.http.get(url);
  }

  /**
   * @param queryParams
   * @returns
   */
  getAssignedClaimList(pagination: ApiPagination, queryParams: string = null): Observable<any> {
    const skip = (pagination.currentPage - 1) * pagination.pageSize;
    queryParams = (!queryParams) ? `$top=${pagination.pageSize}&$skip=${skip}` : `$top=${pagination.pageSize}&$skip=${skip}&${queryParams}`
    const orderBy = `&$orderby=${pagination.orderBy}`
    const select = '$select=AssigneeId, AssigneeName, ClaimGuid, ClaimNo, CreatedDate, CustomerName, FnolId, Id, PolicyNo, Severity, Status, VehicleName, VehicleNumber, CreatedByUserName, IsAssignedBack, CreatedUserId&';
    const url = `${environment.fnolApiUrl}/odata/Claim?${select}${queryParams}${orderBy}`;
    const headers = new HttpHeaders().append('isAssigned', 'true');
    return this.http.get(url, { headers: headers });
  }

  /**
   *
   * @param queryParams
   * @returns
   */
  getTotalCountClaim(queryParams: string = ''): Observable<any> {
    const url = `${environment.fnolApiUrl}/odata/Claim/$count?${queryParams}`;
    return this.http.get(url);
  }

  /**
   *
   */
  getTotalCountAssignedClaim(queryParams: string = ''): Observable<any> {
    const url = `${environment.fnolApiUrl}/odata/Claim/$count?${queryParams}`;
    const headers = new HttpHeaders().append('isAssigned', 'true');
    return this.http.get(url, { headers: headers });
  }

  /**
   * getAssignedStatusCount
   * @returns
   */
  getAssignedStatusCount(): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Claim/statusCount`;
    return this.http.get(url);
  }

  /**
   *
   * @returns
   */
  getAssignee(): Observable<any> {
    const url = `${environment.autoCoreApi}/api/Users/org-users`;
    return this.http.get(url);
  }

  /**
   * getListClaimReviewers
   * @returns
   */
  setAssignee(AssigneeObject: any): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Claim/assignment`;
    return this.http.put(url, AssigneeObject);
  }

  /**
   *
   * @returns
   */
  getCustomerByOrgId(orgId: string): Observable<any> {
    const url = `${environment.fnolApiUrl}/odata/Claim?$apply=filter(OrgId eq ${orgId} AND CustomerName ne null)/groupby((CustomerName))`;
    return this.http.get(url);
  }

  /**
   * getListClaimReviewers
   * @returns
   */
  getListClaimReviewers(): Observable<any> {
    const url = `${environment.autoCoreApi}/api/Users/claim-reviewer`;
    return this.http.get(url);
  }

  /**
   * update vehicle
   */
  updateVehicle(body:any):Observable<any>{
    const url = `${environment.autoApi}/api/v1/Vehicles/UpdateVehicle`;
    return this.http.put(url, body);
  }

  /**
 * delete vehicle
 */
  deleteVehicle(id:number):Observable<any>{
    const url = `${environment.autoApi}/api/v2/Vehicles/${id}`;
    const headers = new HttpHeaders({ 'version': 'V2' });
    return this.http.delete(url, { headers });
  }

  /**
   * map fnol inspection
   */
  mapFnolInspection(body:any):Observable<any>{
    const url = `${environment.fnolApiUrl}/api/v2/FNOL/vehicle-inspection`;
    const headers = new HttpHeaders({ 'version': 'V2' });
    return this.http.put(url, body, { headers });
  }

  /**
   * get vehicle list
   */
  getVehicleList():Observable<any>{
    const url = `${environment.autoApi}/api/v1/Vehicles/GetVehicleListByUser`;
    const headers = new HttpHeaders({ 'version': 'V2' });
    return this.http.get(url);
  }

  /**
   * get application feature
   */
  applicationFeature():Observable<any>{
    const url = `${environment.autoCoreApi}/api/application-feature`;
    const headers = new HttpHeaders({ 'applicationId': '1' });
    return this.http.get(url, { headers });
  }

  /**
   * inspection vehicle mapper
   */
  inspectionVehicleMapper(body:any):Observable<any>{
    const url = `${environment.autoApi}/api/v1/Inspection/InspectionVehicle`;
    const headers = new HttpHeaders({ 'inspectionId': body.inspectionId, 'vehicleId': body.vehicleId });
    return this.http.put(url, body, { headers });
  }

  /**
 * complete FNOL
 * @returns Observable
 */
  public completeFNOL(body: any): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/FNOL/Completed`;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const headers = new HttpHeaders({ 'X-Application-Id': environment.platformConfiguration.ApplicationId });
    return this.http.put(url, body, { headers });
  }
  
  /**
   * get claim statuses
   */
  public getClaimStatuses():Observable<any>{
    const url = `${environment.fnolApiUrl}/api/lookup/FNOLClaimStatus`;
    return this.http.get(url);
  }

  /**
   * update status
   * @param guid 
   * @param status 
   * @returns 
   */
  public updateFNOLStatus(guid: string, status: string): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/FNOL/${guid}/${status}`;
    return this.http.put(url, null);
  }
}
