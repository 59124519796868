<div class="section-container" *ngIf="searchModel?.data?.length > 0">
    <div class="header" [ngClass]="{'is-ios':isiOS}">
        <mat-icon [svgIcon]="searchModel?.icon"></mat-icon>
        <h5>{{searchModel?.title}}</h5>
    </div>
    <div class="search-table-container">
        <table class="main-table">
            <thead>
                <th *ngFor="let key of searchModel?.keyValue">{{key.displayValue}}</th>
                <th *ngIf="searchModel?.actions"></th>
            </thead>
            <tbody>
                <tr *ngFor="let row of searchModel?.data" [ngClass]="{'quote': searchModel?.title === 'quotes'}">
                    <td *ngFor="let key of searchModel?.keyValue">
                        <span *ngIf="key.displayValue === 'Date'">{{row[key.keyValue] | date:'dd/MM/yyyy hh:mm
                            a'}}</span>
                        <span
                            *ngIf="key.displayValue !== 'Date' && key.displayValue !== 'Status'">{{row[key.keyValue]}}</span>
                        <div *ngIf="key.displayValue === 'Status'"
                            [ngClass]="row.status?.replace(' ', '')?.toLowerCase()" class="text-button">
                            <span>{{ getDisplayStatus(row[key.keyValue]) }}</span>
                        </div>
                    </td>
                    <td class="actions" *ngIf="(searchModel?.actions && !isClaimModule)|| (searchModel?.actions && (repairerView || userIsBMOrSA))">
                        <div class="action-wrapper">
                            <div class="action-button" *ngFor="let action of searchModel?.actions">
                                <div *ngIf="action.icon && ((action.icon !== 'calendar' && action.icon !== 'calculate') || (((action.icon === 'calendar' && (this.repairPermission || this.userIsBMOrSA) && (searchModel?.title !== 'quotes' || (searchModel?.title === 'quotes' && validStatuses.includes(row.status) && !row.repairGuid))) 
                                    || (action.icon === 'calculate' && this.quotePermission))))" class="icon-button"
                                        (click)="handleAction(row, action.icon);">
                                        <mat-icon [svgIcon]="action.icon"></mat-icon>
                                    </div>
                                
                                <div *ngIf="!action.icon" [ngClass]="row.status?.replace(' ', '')" class="text-button"
                                    (click)="handleAction(row, action.prop);">
                                    <span>{{row[action.prop]}}</span>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td class="actions" *ngIf="isClaimModule && !repairerView">
                        <div class="action-wrapper">
                            <div class="action-button">
                                <div class="icon-button" (click)="handleAction(row, 'view');">
                                        <mat-icon [svgIcon]="'view'"></mat-icon>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <xa-paginator [searchMode]="'search'"
            *ngIf="showPagination && searchModel?.data?.length > 0 && (pagination.count > pagination.pageSize)"
            #paginator (paginationChange)="handlePaginationChange($event)" [pagination]="pagination">
        </xa-paginator>
    </div>
</div>