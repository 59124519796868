import { Component, Input, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'xa-paragraph',
  templateUrl: './xa-paragraph.component.html',
  styleUrls: [ './xa-paragraph.component.scss' ]
})
export class XaParagraph {
  @Input() color:string = '';
  @Input() size:string = '14';
  @Input() weight:string = '500';
  @Input() required: boolean = false;

  /**
   * 
   * @param _vcr reference
   */
  constructor(private _vcr: ViewContainerRef) {
  }

}
