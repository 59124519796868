<div class="search-container">
    <div class="row">
        <div class="col-12 bar-padding">
            <div class="input-wrapper" #row >
                <mat-icon [svgIcon]="'search'" (click)="search(searchBox.value)"></mat-icon>
                <input type="text" autocomplete="off" class="" aria-describedby="search-text" autofocus
                        #searchBox id="search-box" autocapitalize="none"  (keyup.enter)="search(searchBox.value)" 
                        placeholder="{{placeHolder}}"/>
                    <mat-icon [svgIcon]="'cross'" class="right-icon"  (click)="close()"></mat-icon>
            </div>
        </div>
      </div>
      <div class="search-result-container" [ngClass]="{ 'no-data-wrapper': !searchResult || groupedSearchResult?.length === 0, 'search-result-full': !hasMoreResult , 'ios': isiOS, 'android': isAndroid }" [ngStyle]="{'width': currentWidth}" *ngIf="showSearchResults || isRepairOrderVehicle || isRepairOrderCustomer">
         <div class="search-result-section-container" *ngIf="(searchResult && !empty && !isRepairOrderVehicle && !isRepairOrderCustomer)">
            <div *ngFor="let searchData of groupedSearchResult">
                <chq-search-result-section (closeSearch)="close();" (redirectUser)="handleUserAction($event);" [searchModel]="searchData" [isClaimModule]="isClaimModule"></chq-search-result-section>
            </div>
            
         </div>
        <div class="search-result-repair-order-container"
             *ngIf="isRepairOrderVehicle || isRepairOrderCustomer">
            <app-chq-repair-order (closeSearch)="close();" [inspectionId]="inspectionId" [repairGuid]="repairGuid" [objectId]="objectId" [domainId]="domainId" [isRepairOrderVehicle]="isRepairOrderVehicle" [isRepairOrderCustomer]="isRepairOrderCustomer" (backToSearchResultPage)="backToSearchResultPage($event)"></app-chq-repair-order>
        </div>
         <div class="search-result-no-data-container" *ngIf="!searchResult || empty">
            <mat-icon [svgIcon]="'no-data'" class="no-data"></mat-icon>
            <label>
                Sorry, no matches were found.
            </label>
            <label>
                Try a new search
            </label>
            <div class="button-wrapper">
                <chq-button *ngIf="repairPermission && !isExternal" [buttonModel]="bookingButton"></chq-button>
                <chq-button *ngIf="quotePermission && !isExternal" [buttonModel]="estimateButton"></chq-button>
            </div>
        </div>
        <div class="more-wrapper" *ngIf="hasMoreResult" (click)="more();">
            <mat-icon [svgIcon]="'arrow-search-right'"></mat-icon>
            <span>
                See More
            </span>
        </div>
      </div>
</div>
<div class="back-drop" [ngClass]="{'ios': isiOS,'android': isAndroid  }" *ngIf="showSearchResults"></div>