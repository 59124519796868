import { Component, ElementRef, EventEmitter, inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CameraService } from 'src/app/services/camera/camera.service';
import { CameraOverlayComponent } from '../camera-overlay/camera-overlay.component';
import { CommonService } from 'src/app/services/common/common.service';
import {
  Platform
} from '@angular/cdk/platform';

@Component({
  selector: 'xa-video',
  templateUrl: './video.component.html',
  styleUrls: [ './video.component.scss' ]
})
export class VideoComponent implements OnInit, OnDestroy {
  @ViewChild('videoElement') videoElement: ElementRef<HTMLVideoElement>;
  @ViewChild('overlayElement') overlayElement: CameraOverlayComponent;
  @Input() autoStart:boolean = false;
  @Input() outlineUrl:string = '';
  @Input() procedureStep:string = '';
  @Input() displaySkip:boolean = false;
  @Input() currentStep:string='';
  @Input() skipText:string = '';
  @Input() forceOrientation:boolean = false;
  @Input() showTiltText:boolean = false;
  @ViewChild('div') div:HTMLDivElement;
  @Output() videoRecorded = new EventEmitter<any>();
  @Output() skipped = new EventEmitter<any>();
  @Output() processingVideo = new EventEmitter<any>();
  recordedBlobs = [];
  
  private mediaRecorder:MediaRecorder | void= null;
  public image:string;
  public processing:boolean = false;
  public showCamera:boolean = false;
  public isRecording:boolean = false;
  platform = inject(Platform);


  /**
   * constructor
   * @param cameraService 
   */
  constructor(private cameraService: CameraService, private commonService:CommonService
  ) {
    this.cameraService.videoObjectObservable.subscribe({
      next: (event:any)=>{
        if(!event){
          return;
        }
        if (event.data && event.data.size > 0 && event.action ==='blob') {
          this.recordedBlobs.push(event.data);
        }else if(event.action ==='upload'){
          this.emitUpload(event.data);
        }else if(event.action === 'converting'){
          this.processingVideo.emit();
        }
       
      }
    })
  }


  /**
   * ng oninit
   */
  ngOnInit(): void {
    if(this.autoStart){
      this.startCamera();
    }
    this.cameraService.checkAndLoadMpeg();
  }

  /**
   * get orientation
   */
  get getOrientationLock():boolean{
    //|| this.configurationService.getCameraFlags()?.Camera_Orientation?.toLowerCase() === 'landscape'
    return this.forceOrientation ;
  }

  /**
   * start camera
   * 
   */
  async startCamera():Promise<any>{
    this.recordedBlobs = [];
    this.mediaRecorder = await this.cameraService.initVideo(this.videoElement.nativeElement, this.platform.ANDROID || this.platform.IOS).catch((e) => {
      this.commonService.showToast(0, `Error initializing camera: ${e.message}`);
      this.closeCamera();
    });
    if(window.innerHeight > window.innerWidth){
      this.showTiltText = true;
    }
    this.showCamera = true;
  }

  /**
   * close camera
   */
  closeCamera():void{
    this.image = null;
    this.showCamera = false;
    if(this.mediaRecorder){
      this.cameraService.stopRecording(this.mediaRecorder, this.recordedBlobs);
    }
  }

  /**
   * start recording
   */
  startRecording():void{
    if(this.mediaRecorder){
      this.cameraService.startRecording(this.mediaRecorder);
      this.isRecording = true;
    }
  }

  /**
   * start recording
   */
  stopRecording():void{
    if(this.mediaRecorder){
      let rotate = false;
      if(window.innerHeight > window.innerWidth){
        rotate = true;
      }
      this.cameraService.stopRecording(this.mediaRecorder, this.recordedBlobs);
      this.isRecording = false;
      this.image = null;
      this.showCamera = false;
    }
  }
  
  /**
   * emit upload
   */
  emitUpload(blob:Blob):void{
    this.videoRecorded.emit({ target: { files: [ blob ] } });
    this.showCamera = false;
    this.image = null;
  }

  /***
   * update skip
   */
  updateSkip():void{
    this.skipped.emit();
  }

  /**
   * ng on destroy
   */
  ngOnDestroy(): void {
    this.cameraService.stopCamera();
  }
}
