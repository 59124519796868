import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Components, exportMapper, FooterActions, HeaderActions, IconActions, PhotosAction, XaHeaderAction, XaImageAction, XAImages } from '../xa-photo-upload-mode-selecter/xa-file-upload.model';
import { XaFileReviewSectionComponent } from '../xa-file-review-section/xa-file-review-section.component';
import { InspectionService } from 'src/app/services/inspection/inspection.service';
import { FileService } from 'src/app/services/file/file.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { CommonService } from 'src/app/services/common/common.service';
import { catchError, forkJoin, Observer, of } from 'rxjs';
import { ChqInspectionStep } from 'src/app/model/chq-inspection-step';
import { UploadStepModel } from 'src/app/model/chq-upload-model';
import { inspectionpartPartMapper } from 'src/app/modules/quote/chq-new-cases/chq-new-customer/chq-new-customer-form-data-helper';
import * as dayjs from 'dayjs';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { LossDetailComponent } from 'src/app/modules/claim/claim-management/loss-detail/loss-detail.component';

@Component({
  selector: 'xa-photos',
  templateUrl: './xa-photos.component.html',
  styleUrls: [ './xa-photos.component.scss' ]
})
export class XaPhotosComponent implements OnInit {
  //Input attributes
  @Input() xaImages:XAImages;
  @ViewChild('reviewSection') reviewSection:XaFileReviewSectionComponent;
  @Output() headerActions: EventEmitter<XaHeaderAction> = new EventEmitter();
  @Output() completeUploadProcess: EventEmitter<any> = new EventEmitter();
  @Output() damageAnalysisComplete: EventEmitter<any> = new EventEmitter();
  @Output() updateAiEstimateStatus: EventEmitter<any> = new EventEmitter();
  

  //private variables
  private currentInspectionTemplate: any;
  public currentIndex:number;
  public inspectionDetail: any;
  public deletedId:number[] = [];
  private inspectionTemplates: ChqInspectionStep[];
  private inspectionItemTemplates: ChqInspectionStep[];
  public inspectionTemplateList: any[];
  private inspectionTemplatesJSONString = '';
  private inspectionTemplateId:number;
  private inspection: any;
  public displayBack:boolean;
  private activeComponent:number;
  public historyStack:number[] = [];
  private isBulkUploaded:boolean;
  public hideSelector:boolean;
  public hideUploader:boolean;
  public dataLoading:boolean;
  public currentVisibleModule: 'review' | 'AI' | 'recommendation' | 'selection' | 'upload' | 'AI-Detail';
   
  /**
  * constructor
  */
  constructor( private monitorService: MonitorService,
    private commonService:CommonService,
    private inspectionService:InspectionService,
    private fileService:FileService) {}

  /**
   * handle input actions
   */
  handleAction($event:PhotosAction):void{
    const { step, action } = $event;
    switch(action.iconAction){
    case IconActions.delete:
      this.showDeleteConfirmation(step);
      break;
    case IconActions.comment:
      this.reviewSection.showCommentDialog(step, this.xaImages.inspectionId);
      break;
    case IconActions.upload, IconActions.edit:
      this.reviewSection.triggerFileUpload(step);
      break;
    case IconActions.upload:
      this.reviewSection.triggerFileUpload();
      this.reviewSection.selectedStep = step;
      break;
    case IconActions.download:
      this.reviewSection.download(step);
    }
  }


  /**
   * trigger upload
   */
  triggerUpload():void{
    this.reviewSection.triggerMultipleUpload();
  }

  /**
   * handleUpload
   */
  handleUpload($event):void{
    this.hideUploader = true;
    this.xaImages = { ...this.xaImages, displayReviewSection: true, displayAI: false,
      displaySelector: false, displayUploader: true };
    setTimeout(()=>{
      this.reviewSection.handleImageOutput($event.fileUploadEvent);
    })
  }

  /**
   * show delete confirmation
   */
  showDeleteConfirmation(uploadStepModel: UploadStepModel): void {
    const message = (this.xaImages.fileReviewSection.type == 'photo' && this.xaImages.fileReviewSection.aiReviewSection === true) ?
      'By deleting this photo it will delete the photo from the AI analysis report only' : '';
    this.commonService.openDeleteDialog((this.xaImages.currentPage == 'photos') ? 'photo' : this.xaImages.currentPage, message)
      .afterClosed().subscribe((data) => {
        if (data) {
          this.deleteImage(uploadStepModel);
        }
      });
  }

  /**
   * handle back
   */
  handleBack():void{
    this.setInitialMode();
  }

  /**
   *  delete image
   */
  deleteImage(uploadStepModel: UploadStepModel): void {
    this.commonService.showLoading();

    if (uploadStepModel.inspectionItem.id) {
      this.inspectionService.deleteInspection(uploadStepModel.inspectionItem.id).subscribe((resp: any) => {
        if (uploadStepModel.inspectionItem) {
          const index = this.xaImages.fileReviewSection.files.findIndex((ex: UploadStepModel) => {
            return ex.inspectionItem?.id === uploadStepModel.inspectionItem?.id;
          });
          this.deleteSelectedImage(uploadStepModel, index);
        } else {
          const index = uploadStepModel.index;
          this.deleteSelectedImage(this.xaImages.fileReviewSection.files[index], index);
        }
        if (uploadStepModel.procedureSteps === 'Bulk Upload Image') {
          this.xaImages.fileReviewSection.files = [ ...this.xaImages.fileReviewSection.files.filter((st: UploadStepModel) => st.inspectionItem?.id !== uploadStepModel.inspectionItem?.id) ];
        }
        this.commonService.hideLoading();
      }, () => {
        this.commonService.hideLoading();
      });
    } else {
      this.commonService.hideLoading();
    }
  }

  /**
   * update deleted image
   */
  deleteSelectedImage(currentStep: UploadStepModel, index: number): void {

    currentStep.contentType = '';
    currentStep.url = '';
    currentStep.uploadedGuid = '';
    currentStep.updatedDate = '';
    currentStep.uploadedDate = '';
    currentStep.uploadInProgress = false;
    currentStep.isSkipped = false;
    currentStep.isUploadSuccess = false;
    currentStep.uploadedPartGuid = '';
    currentStep.isUploadFailed = false;
    currentStep.imageFile = null;
    currentStep.image = null;
    currentStep.thumbnailUrl = null;
    currentStep.uploadStatus = 'notUploaded';
    currentStep.action = exportMapper(currentStep, this.xaImages.fileReviewSection.actions);

    if (currentStep.stepName.indexOf('Additional') != -1) {
      currentStep.isAdditionalDocumentFromReviewPageOnly = true;
    }

    if (currentStep.isMassUpload || currentStep.procedureSteps === 'Bulk Upload Image') {
      this.xaImages.fileReviewSection.files = this.xaImages.fileReviewSection.files.filter((step: UploadStepModel) => {
        return step?.inspectionItem?.id != currentStep?.inspectionItem?.id;
      });
      if(this.xaImages.fileReviewSection.files.length === 0){
        this.setInitialMode();
        return;
      }
      this.xaImages.fileReviewSection = { ...this.xaImages.fileReviewSection };
    } else {
      this.xaImages.fileReviewSection.files[index] = { ...currentStep };
      this.xaImages.fileReviewSection.files = [ ...this.xaImages.fileReviewSection.files ];
    }

    this.xaImages.fileReviewSection = { ...this.xaImages.fileReviewSection };
    
    if(this.xaImages.onImageDeleted){
      this.xaImages.onImageDeleted(currentStep);
    }

    const uploadedImagesCount = this.xaImages.fileReviewSection.files.filter((step: UploadStepModel) => {
      return step.url;
    }).length

    if (uploadedImagesCount === 0) {
      this.setInitialMode();
    }

  }

  /**
   * set to initial mode
   */
  setInitialMode(): void {
    this.xaImages.fileReviewSection = { ...this.xaImages.fileReviewSection, files: [], multipleUpload: false };
    this.xaImages = { ...this.xaImages, uploadMode: '', displayReviewSection: false, displaySelector: true };
    this.isBulkUploaded = false;
  }

  /**
   * ng onInit
   */
  ngOnInit(): void {
    this.loadApiData(this.xaImages.inspectionId);
  }
  
  /**
   * map inspection from api
   * @param inspectionData 
   */
  mapInspection(inspectionData: any): void {
    if (inspectionData) {
      try {
        this.inspection = inspectionData;
        const uploadType = this.inspection.uploadType;
        this.xaImages.uploadMode = uploadType;
        let currentMode = 'SPI';
        if (uploadType === 'Bulk') {
          currentMode = 'BULK';
        }
        const bulkUploadTemplateItem = this.inspectionTemplates?.filter(step => step.name === 'Bulk Upload Image');
        let bulkUploadedItemsWithImages = this.inspection.inspectionItems?.filter((step) => {
          return (step.name === 'Bulk Upload Image' || step.inspectionItemTemplateID === bulkUploadTemplateItem[0].id)
              && step.rawAzureBlobUrl
        });

        // only show the uploaded images
        if (this.xaImages.isSurveyor) {
          bulkUploadedItemsWithImages = this.inspection.inspectionItems?.filter((step) => {
            return step.rawAzureBlobUrl
          });
        }
          
        if (bulkUploadedItemsWithImages?.length > 0 || uploadType === 'Bulk') {
          const massUploadItems = [];
          for (const i in bulkUploadedItemsWithImages) {
            const currentObj = {
              ...bulkUploadTemplateItem[0], name: bulkUploadedItemsWithImages[i].name || bulkUploadTemplateItem[0].name,
              inspectionItem: bulkUploadedItemsWithImages[i],
              procedureSteps: this.xaImages.isSurveyor ? bulkUploadedItemsWithImages[i].procedureSteps : bulkUploadTemplateItem[0].procedureSteps
            }
            massUploadItems.push(currentObj);
          }
          this.inspectionItemTemplates = massUploadItems;
        } else {
          this.inspectionTemplates?.map((chqInspectionStep: ChqInspectionStep) => {
            const item = this.inspection.inspectionItems?.find((el: any) => el.inspectionItemTemplateID == chqInspectionStep.id);
            if (item) {
              chqInspectionStep['inspectionItem'] = item;
            }
          });
          this.inspectionItemTemplates = this.inspectionTemplates?.filter(step => step.name != 'Bulk Upload Image');
        }
        if(bulkUploadTemplateItem?.length > 0) {this.inspectionTemplateId = bulkUploadTemplateItem[0].id}else{
          this.inspectionTemplateId = this.inspectionTemplates[0].id;
        }
        this.xaImages.fileReviewSection.bulkUploadInspectionItemId = this.inspectionTemplateId;

        this.inspectionItemTemplates?.map((x: ChqInspectionStep) => {
          if (x.inspectionItem?.rawAzureBlobUrl) {
            x.isUploadSuccess = true;
            x.image = x.inspectionItem?.rawAzureBlobUrl;
            x.isLocked = x.inspectionItem?.isLocked;
          } else {
            if (!x.inspectionItem) {
              x.inspectionItem = { rawAzureBlobUrl: '' };
            }
            x.inspectionItem.rawAzureBlobUrl = '';
            x.isUploadFailed = false;
            x.isUploadSuccess = false;
            x.uploadInProgress = false;
          }
        });
        if (uploadType) {
          if (currentMode === 'SPI' && !this.xaImages.isSurveyor) {
            const minimumPhotosRequired = this.inspectionItemTemplates?.filter((value: ChqInspectionStep) => {
              return value.isSkipEnabled !== true;
            }).length;
            this.xaImages.fileReviewSection = { ...this.xaImages.fileReviewSection, observer: this.setObserver.bind(this), multipleUpload: false, minFileLength: minimumPhotosRequired };
          } else {
            //this.currentObservable = this.uploadBulkUploadFile.bind(this);
            this.isBulkUploaded = true;
            this.xaImages.fileReviewSection = { ...this.xaImages.fileReviewSection, observer: this.setObserver.bind(this), multipleUpload: true, minFileLength: this.currentInspectionTemplate.minImageLimit };
          }
        }
      } catch (error: any) {
        console.log(error);
      }
    }
  }

  /**
   * inspection detail by id
   */
  getInspectionTemplateByObject(): void {
    const files:UploadStepModel[] = [];
    for (let i = 0; i < this.inspectionItemTemplates?.length; i++) {
      const templatePart = this.inspectionItemTemplates[i];
      const inspectionStep = inspectionpartPartMapper(templatePart, i);
      const isAdditionalImage = inspectionStep.stepName?.toLowerCase().indexOf('additional photo') != -1;
      const isMassUpload = inspectionStep.stepName?.toLowerCase().indexOf('bulk upload image') != -1;
      const isAdditionalDocumentFromReviewPageOnly = isAdditionalImage && !inspectionStep.url;
      const step = {
        ...inspectionStep, mandatory: !templatePart.isSkipEnabled, index: i,
        isAdditionalDocumentFromReviewPageOnly,
        isMassUpload,
        updatedDate: inspectionStep.url ? templatePart?.inspectionItem?.updatedDate : '',
        uploadedDate: inspectionStep.url ? templatePart?.inspectionItem?.created : '',
        domainId: this.xaImages.domainId, objectId: this.xaImages.objectId,
        uploadedPartGuid: '',
        uploadedGuid: ''
      };
      step.action = exportMapper(step, this.xaImages.fileReviewSection.actions) || [];
      files.push(step);
    }
    this.xaImages.fileReviewSection.files = files;
    this.xaImages.fileReviewSection = { ...this.xaImages.fileReviewSection };
    this.xaImages.fileReviewSection.observable = this.inspectionService.uploadImageBulk.bind(this);
    this.currentIndex = 0;
    this.xaImages = { ...this.xaImages }
  }

  /**
   * load server details
   */
  loadApiData(id: number): void {
    if (id && id != 0) {
      this.dataLoading = true;
      this.inspectionService.getInspectionTemplateList().subscribe({
        next: (inspectionList: any) => {
          let inspectionTemplateId = 0;
          const repairInspectionTemplate = inspectionList?.data;

          if (repairInspectionTemplate?.length > 0) {
            this.commonService.repairInspectionTemplate = repairInspectionTemplate[0];
            inspectionTemplateId = repairInspectionTemplate[0].id
          }
          const promiseApi: any = {};
          promiseApi.inspectionTemplate = this.inspectionService.getInspectionTemplate(inspectionTemplateId);
          const serverDetails = forkJoin(promiseApi).pipe(
            catchError(error => of(error))
          )
          serverDetails.subscribe({
            next: (resultMap: any) => {
              if (resultMap.inspectionTemplate) {

                const inspectionTemplateDetail = resultMap.inspectionTemplate.inspectionItemTemplates;
                this.currentInspectionTemplate = resultMap.inspectionTemplate;
                if (resultMap.inspectionDetail) {
                  this.inspectionDetail = resultMap.inspectionDetail;
                }
                this.inspectionTemplates = inspectionTemplateDetail;
                this.inspectionTemplatesJSONString = JSON.stringify(this.inspectionTemplates);
                const prop = this.xaImages.isSurveyor ? 'getInspectionSurveyor': 'getInspection';
                this.inspectionService[prop](id, this.xaImages.objectId).subscribe({
                  next: (inspectionDetailResponse: any) => {
                    if (inspectionDetailResponse) {
                      this.mapInspection(this.xaImages.isSurveyor ? inspectionDetailResponse.data:inspectionDetailResponse);
                      this.getInspectionTemplateByObject();
                      this.dataLoading = false;
                      setTimeout(() => {
                        if (this.isBulkUploaded) {
                          this.xaImages.fileReviewSection.multipleUpload = true;
                          this.xaImages.fileReviewSection.enableSPI = false;
                          this.xaImages.fileReviewSection.enableBulk = true;
                        }
                        if (this.xaImages.uploadMode) {
                          this.activeComponent = Components.review;
                          this.currentVisibleModule = 'review';
                          if(this.isBulkUploaded){
                            this.xaImages.fileReviewSection.enableSPI = false;
                          }
                          this.xaImages = { ...this.xaImages, displayAI: false, displayReviewSection: true, displaySelector: false, displayUploader: false }
                        }
                        this.xaImages = { ...this.xaImages };
                      })
                    }
                  },
                  error: ()=>{
                    this.commonService.hideLoading();
                  },
                  complete: () => {
                    this.commonService.hideLoading();
                  },
                });

               
              }else{
                this.commonService.hideLoading();
              }
            }
          });
        }, error: ()=>{
          this.commonService.hideLoading();
        }
      });

    }
  }

  /**
   * handle icon actions
   */
  handleIconActions(action:XaHeaderAction):void{
    switch(action.type){
    case HeaderActions.deleteAll:
      this.handleDeleteAll();
      break;
    default:
      this.headerActions.emit(action);
    }
    
  }

  /**
   * handle delete all
   */
  handleDeleteAll(): void {
    const files = this.xaImages.fileReviewSection.files.filter((step: UploadStepModel) => {
      return step.url;
    })

    const uploaded = this.xaImages.fileReviewSection.files.filter((step: UploadStepModel) => {
      return step.uploadInProgress === true;
    });
    if (uploaded.length > 0) {
      this.commonService.showToast(0, 'wait_image_upload');
      return;
    } else if (files.length === 0) {
      this.commonService.showToast(0, 'No images present.');
      return;
    }

    this.commonService.openDeleteDialog('Photos', 'Are you sure you want to delete all these images?')
      .afterClosed().subscribe((data) => {
        if (data) {
          this.commonService.showLoading();
          this.inspectionService.deleteAllImages(this.xaImages.inspectionId).subscribe({
            next: () => {
              if(this.xaImages.onImageDeleted){
                this.xaImages.onImageDeleted();
              }
              this.setInitialMode();
              this.commonService.hideLoading();
            },
            error: () => {
              this.commonService.hideLoading();
            }
          });
        }
      });
  }

  /**
   * show error dialog
   */
  displayMissingInspectionMessage():void{
    this.commonService.openConfirmationOkDialog('Notification', 'Vehicle data is missing in order to add car images.').afterClosed().subscribe({
      next: (result:boolean)=>{
        //sd
      },
      error: ()=>{
        this.commonService.hideLoading();
      }
    });
  }


  /**
   * reload inspection and map
   */
  public reloadInspection(callback:()=>void): void {
    this.commonService.showLoading();
    this.inspectionTemplates = JSON.parse(this.inspectionTemplatesJSONString);
    if (this.xaImages.inspectionId) {
      this.inspectionService.getInspection(this.xaImages.inspectionId).subscribe({
        next: (inspectionDetailResponse: any) => {
          if (inspectionDetailResponse) {
            this.mapInspection(inspectionDetailResponse);
            this.getInspectionTemplateByObject();
            callback();
          }
        },
        complete: () => {
          this.commonService.hideLoading();
        },
      });
    }
  }

  /**
   * update document status
   */
  updateUploadStatus(status: string, currentStep: UploadStepModel, err?: any, responseData?: any, isBulk?: boolean): void {
    this.monitorService.logEvent('updateUploadStatus', [ 'ChqImagesUploaderComponent', 'addenda-quote', {
      status,
      currentStep,
      responseData,
      isBulk
    } ]);

    const uploadedGuid = currentStep.uploadedGuid;
    const uploadedPartGuid = currentStep.uploadedPartGuid;

    this.reviewSection.xaUpload?.resetValue();
    if (status == 'failed') {
      currentStep.uploadInProgress = false;
      currentStep.isSkipped = false;
      currentStep.isUploadSuccess = false;
      currentStep.isUploadFailed = false;
      if (isBulk) {
        currentStep.isUploadFailed = true;
      } else {
        currentStep.imageFile = null;
        currentStep.image = null;
      }

      currentStep.action = exportMapper(currentStep, this.xaImages.fileReviewSection.actions);
      const message = (err && err.error?.msg) ? err.error?.msg
        : `Your photo ‘${currentStep.stepName}’ failed to upload. Please try to upload again.`
      const status = err ? err.status : 0;
      this.commonService.showToast(status, message);
      if (currentStep.isAdditionalDocumentFromReviewPageOnly) {
        const step: UploadStepModel = {
          stepName: this.inspection === 'photos' ? 'Additional Image' : 'Additional Documents',
          uploadStatus: 'notUploaded',
          url: '',
          image: '',
          index: -1,
          isAdditionalDocumentFromReviewPageOnly: true,
          sizeLimit: currentStep.sizeLimit,
          allowExtension: currentStep.allowExtension,
          mandatory: false,
          isUploadSuccess: false,
          uploadInProgress: false,
          isUploadFailed: false,
          isSkipped: false,
          isMassUpload: currentStep.isMassUpload,
          templateGuid: currentStep.templateGuid,
          partTemplateGuid: currentStep.partTemplateGuid,
          placeHolderUrl: '/assets/icons/additional-image.svg'
        }
      }
    } else if (status == 'success') {
      currentStep.uploadInProgress = false;
      currentStep.isSkipped = false;
      currentStep.isUploadFailed = false;
      currentStep.isUploadSuccess = true;
      currentStep.uploadStatus = 'success';
      currentStep.updatedDate = dayjs().toISOString();
      currentStep.uploadedDate = dayjs().toISOString();
      currentStep.isAdditionalDocumentFromReviewPageOnly = false;
      if (this.inspection === 'photos') {
        currentStep.inspectionItem = responseData;
      }

      if (responseData) {
        if (currentStep.originalName) {
          currentStep.stepName = currentStep.originalName;
        }

        currentStep.uploadedGuid = responseData.mediaGuid;
        currentStep.uploadedPartGuid = responseData.mediaPartGuid;
      }

      currentStep.action = exportMapper(currentStep, this.xaImages.fileReviewSection.actions);
      this.updateAiEstimateStatus.emit({
        status: null
      });

    }

    const uploaded = this.xaImages.fileReviewSection.files.filter((step: UploadStepModel) => {
      return step.uploadInProgress === true;
    });
    this.commonService.inProgressSteps.next(uploaded.length);

    if(this.xaImages.displayReviewSection){
      this.xaImages.fileReviewSection = { ...this.xaImages.fileReviewSection };
    }else{
      this.xaImages.fileReviewSection = { ...this.xaImages.fileReviewSection };
    }

    if (uploaded.length === 0) {
      if (this.xaImages.fileReviewSection.type == 'photo') {
        if(this.xaImages.onMultipleImagesUploadComplete){
          this.xaImages.onMultipleImagesUploadComplete();
        }
        this.completeUploadProcess.emit(true);
      } else {
        // for update no need to call the document intelligence api
        if (uploadedGuid && uploadedPartGuid) {
          this.completeUploadProcess.emit(false);
        } else {
          this.completeUploadProcess.emit(true);
        }
      }
    }

    
  }

  /**
   * set observer
   */
  setObserver(isMultiple:boolean, step:UploadStepModel):Observer<any>{
    if(isMultiple){
      return {
        next: (data: any):void => {
          step.url = data.rawImage?.rawAzureBlobUrl;
          step.inspectionItem = data;
          step.procedureSteps = 'Bulk Upload Image';
          this.updateUploadStatus('success', step, null, data);
          if(this.xaImages.onImageUploadAction){
            this.xaImages.onImageUploadAction(step);
          }
        },
        error: (err: any):void => {
          if (err.status != 401) {
            this.xaImages.fileReviewSection.files = this.xaImages.fileReviewSection.files.filter((ig: UploadStepModel) => {
              return (ig.index !== step.index || ig.stepName !== step.stepName || ig.uploadInProgress !== true);
            })
            this.updateUploadStatus('failed', step, err, null, true);
          }
          this.monitorService.logException(err, SeverityLevel.Error);
        },
        complete: ():void=>{
          this.commonService.hideLoading();
        }
      }
    }
    
    return {
      next: (data: any):void => {
        const msg = `Your photo ‘${step?.stepName}’ is uploaded successfully.`;
        this.commonService.showNotification(msg);
        const inspectionItem = this.inspectionItemTemplates?.find((x: any) => x.inspectionItem.id == data?.id);
        inspectionItem.inspectionItem.rawAzureBlobUrl = data.rawImage?.rawAzureBlobUrl;
        if (inspectionItem) {
          step.url = data.rawImage?.rawAzureBlobUrl;
          this.updateUploadStatus('success', step, null, data);
        }

        if(this.xaImages.onImageUploadAction){
          this.xaImages.onImageUploadAction(step);
        }
      },
      error: (err: any):void => {
        if (err.status != 401) {
          const msg = `Your photo ‘${step.stepName}’ failed to upload. Please try to upload again.`;
          this.commonService.showNotification(msg);
          this.updateUploadStatus('failed', step, err);
          this.monitorService.logException(err, SeverityLevel.Error);
        }
      
      },
      complete: ():void=>{
        this.commonService.hideLoading();
      }
    };
  }

  /**
   * handle mass upload
  */
  handleMassUpload($event:any):void{
    this.xaImages.fileReviewSection = { ...this.xaImages.fileReviewSection, files: [ ...this.xaImages.fileReviewSection.files, ...$event.files ] };
    this.xaImages.fileReviewSection = { ...this.xaImages.fileReviewSection };
  }
  
  /**
   *handle mode selection
   * @param $event 
   */
  handleMode($event: any): void {
    this.monitorService.logEvent('handleMode', [ 'ChqNewPhotosComponent', 'addenda-quote', {
      $event
    } ]);
    if (!this.xaImages.inspectionId) {
      this.displayMissingInspectionMessage();
      return;
    }
    
    this.xaImages.displaySelector = false;
    this.xaImages.displayReviewSection =true;
    if ($event.mode === 'single') {
      if(this.xaImages.fileReviewSection.files.length === 0){
        this.xaImages.fileReviewSection = { ...this.xaImages.fileReviewSection,
          multipleUpload: false, enableBulk: false, enableSPI: true }
        this.reloadInspection(()=>{
          const minimumPhotosRequired = this.inspectionItemTemplates.filter((value: ChqInspectionStep) => {
            return value.isSkipEnabled !== true;
          }).length;
          this.xaImages.fileReviewSection = { ...this.xaImages.fileReviewSection, minFileLength: minimumPhotosRequired,
            multipleUpload: false, enableBulk: false, enableSPI: true, observer: this.setObserver.bind(this) }
        });
      }else{
        const minimumPhotosRequired = this.inspectionItemTemplates.filter((value: ChqInspectionStep) => {
          return value.isSkipEnabled !== true;
        }).length;
        this.xaImages.fileReviewSection = { ...this.xaImages.fileReviewSection, minFileLength: minimumPhotosRequired,
          multipleUpload: false, enableBulk: false, enableSPI: true, observer: this.setObserver.bind(this) }
      }
    } else {
      this.xaImages.fileReviewSection = { ...this.xaImages.fileReviewSection, files: [], enableBulk: true, observer: this.setObserver.bind(this), multipleUpload: true, enableSPI: false };
    }
  }

  /**
   * handle footer actions
   */
  handleFooterActions(action:XaImageAction):void{
    switch(action.type){
    case FooterActions.upload:
      this.triggerUpload();
      break;
    case FooterActions.custom:
      action.callback(action);
      break
    }
  }
}
