import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { DamageReport } from 'src/app/model/chq-damage-report';
import { ChqInspection } from 'src/app/model/chq-inspection';
import { ChqInspectionStep } from 'src/app/model/chq-inspection-step';
import { environment } from 'src/environments/environment';
import { FileService } from '../file/file.service';

@Injectable({
  providedIn: 'root'
})

export class InspectionService {
  inspection: ChqInspection;
  currentInspectionStep: ChqInspectionStep;
  inspectionData: any;

  /**
     * constructor
     * @param httpClient
     * @param fileService
     */
  constructor(private httpClient: HttpClient,
    private route: ActivatedRoute,
     private fileService: FileService) {

  }

  /**
     * get templateDetail
     * @returns
     */
  getInspectionTemplate(inspectionTemplateId:number): Observable<any> {
    const url = `${environment.autoApi}/api/v1/InspectionTemplate/${inspectionTemplateId}`;
    return this.httpClient.get<ChqInspection>(url);
  }

  /**
     * get templateDetail
     * @returns
     */
  getDamageReport(inspectionId:number): Observable<any> {
    const url = `${environment.autoApi}/api/v1/Inspection/DamageReport/${inspectionId}`;
    return this.httpClient.get<DamageReport[]>(url);
  }

  /**
     * get damageReport Aggregate
     * @returns
     */
  getDamageReportPDF(id:string, inspectionId:number, isClaimModule?:boolean): Observable<any> {
    let url = `${environment.xaAggregationApi}/caseinspectiondamagereportdata/${id}/1/${inspectionId}`;

    if (isClaimModule) {
      url = `${environment.xaAggregationApi}/claiminspectiondamagereportdata/${id}/1/${inspectionId}`;
    }
    
    return this.httpClient.get<any>(url);
  }

  /**
     * get damageReport Aggregate
     * @returns 
     */
  getDamageReportPDFThirdParty(id:string, inspectionId:number, headers:any = {}): Observable<any> {
    const url = `${environment.xaAggregationApi}/claiminspectiondamagereportdata/${id}/1/${inspectionId}`;
    
    return this.httpClient.get<any>(url, { headers });
  }

  /**
   * get damageReport Aggregate
   * @returns
   */
  getDamageReportJobPDF(repairGuid:string, inspectionId:number): Observable<any> {
    const url = `${environment.xaAggregationApi}/repairinspectiondamagereportdata/${repairGuid}/1/${inspectionId}`;
    return this.httpClient.get<any>(url);
  }

  /**
   * get damageReport surveyor
   * @returns
   */
  getDamageReportSurveyorPDF(claimGuid:string, inspectionId:number, objectId: number): Observable<any> {
    const url = `${environment.xaAggregationApi}/claimSurveyordamagereportdata/${claimGuid}/1/${inspectionId}/${objectId}`;
    return this.httpClient.get<any>(url);
  }

  /**
   * get Repair-communication-media-inspection Aggregate
   * @returns
   */
  getRepairCommunicationMedia(version:number = 1, repairGuid:string, domainId:number, templateKey:number, collectionId:number, objectId:number, inspectionId:number): Observable<any> {
    const url = `${environment.xaAggregationApi}/repair-communication-media-inspection/${version}/${repairGuid}/${domainId}/${templateKey}/${collectionId}/${objectId}/${inspectionId}`;
    return this.httpClient.get<any>(url);
  }


  /**
     * get createInspection
     * @returns
     */
  createInspection(obj: any): Observable<any> {
    const url = `${environment.autoApi}/api/v1/Inspection`;
    return this.httpClient.post(url, obj);
  }

  /**
     * create/update comment
     * @returns
     */
  updateComment(obj: any): Observable<any> {
    const url = `${environment.autoApi}/api/v1/Inspection/Note`;
    return this.httpClient.post(url, obj);
  }

  /**
   * delete comment
   */
  deleteComment(inspectionItemId):Observable<any> {
    const url = `${environment.autoApi}/api/v1/Inspection/DeleteNote/${inspectionItemId}`;
    return this.httpClient.delete(url);
  }


  /**
   * delete all images
   */
  deleteAllImages(inspectionId):Observable<any> {
    const url = `${environment.autoApi}/api/v1/Inspection/DeleteAllImages/${inspectionId}`;
    return this.httpClient.delete(url);
  }

  /**
     * get uploadMassUpload
     * @returns
     */
  uploadImageBulk(obj: any, file: File, reportProgress?: boolean, observe?: 'events'): Observable<any> {
    const formData = new FormData();
    formData.append('imageFile', file);
    if (obj.inspectionitemId) {
      formData.append('inspectionitemId', obj.inspectionitemId);
    }
    formData.append('inspectionId', obj.inspectionId);
    formData.append('inspectionItemTemplateId', obj.templateId);
    formData.append('isBulkUpload', obj.isBulk.toString());
    formData.append('horizontelResolution', obj.height || 1080);
    formData.append('verticalResolutation', obj.width || 720);
    formData.append('cameraZoomLevel', '1');
    formData.append('ambientLighting', '1');
    formData.append('isColorGlossy', 'false');
    formData.append('hasAtleastDent', 'false');
    formData.append('dimension', '100');
    formData.append('backgroungColor', '1');
    formData.append('imageResolution', '1');
    formData.append('hasReflections', 'false');
    formData.append('backgroundTexture', '1');
    formData.append('hasAtLeastScratch', 'false');
    formData.append('application', 'carhealquote');
    formData.append('metadata', '1');
    formData.append('iqaMode', '1');
    formData.append('uploadipadress', '1');
    formData.append('vehicleType', obj.type);
    formData.append('isSPI', obj.isBulk ? 'False':'True');
    const url = `${environment.autoApi}/api/v1/Inspection/ImageUpload?inspectionImageType=1&vehicleZoomLevel=1`;
    return this.fileService.uploadFile(url, formData, reportProgress, observe);
  }

  /**
     * get uploadMassUpload
     * @returns
     */
  uploadImageBulkJob(obj: any, file: File, reportProgress?: boolean, observe?: 'events'): Observable<any> {
    const formData = new FormData();
    formData.append('imageFile', file);
    if (obj.inspectionitemId) {
      formData.append('inspectionitemId', obj.inspectionitemId);
    }
    formData.append('inspectionId', obj.inspectionId);
    formData.append('inspectionItemTemplateId', obj.templateId);
    formData.append('isBulkUpload', obj.isBulk.toString());
    formData.append('horizontelResolution', obj.height);
    formData.append('verticalResolutation', obj.width);
    formData.append('cameraZoomLevel', '1');
    formData.append('ambientLighting', '1');
    formData.append('isColorGlossy', 'false');
    formData.append('hasAtleastDent', 'false');
    if(obj.objectId){
      formData.append('objectId', obj.objectId);
    }
    if(obj.domainId){
      formData.append('domainId', obj.domainId);
    }
    formData.append('dimension', '100');
    formData.append('backgroungColor', '1');
    formData.append('imageResolution', '1');
    formData.append('hasReflections', 'false');
    formData.append('backgroundTexture', '1');
    formData.append('hasAtLeastScratch', 'false');
    formData.append('application', 'carhealquote');
    formData.append('metadata', '1');
    formData.append('iqaMode', '1');
    formData.append('uploadipadress', '1');
    formData.append('vehicleType', obj.type);
    formData.append('isSPI', obj.isSPI);
    const url = `${environment.autoApi}/api/v2/Inspection/ImageUpload?inspectionImageType=1&vehicleZoomLevel=1`;
    return this.fileService.uploadFile(url, formData, reportProgress, observe);
  }

  /**
     * delete image inspection
     */
  deleteInspection(id:number):Observable<any> {
    const url = `${environment.autoApi}/api/v1/Inspection/DeleteImage/${id}`;
    return this.httpClient.delete(url, {
      responseType: 'text',
    });
  }

  /**
     * get inspection details
     * @returns
     */
  getInspection(id: number): Observable<any> {
    const url = `${environment.autoApi}/api/v1/Inspection/${id}`;
    return this.httpClient.get(url);
  }

  /**
     * get inspection details from job
     * @returns
     */
  getInspectionSurveyor(id: number, claimId:number): Observable<any> {
    const url = `${environment.autoApi}/api/v1/Inspection/Item/${id}/${claimId}`;
    return this.httpClient.get<HttpResponse<any>>(url);
  }

  /**
     * get inspection details from job
     * @returns
     */
  getInspectionJobDetail(id: number, jobId:number): Observable<any> {
    const url = `${environment.autoApi}/api/v1/Inspection/Item/${id}/${jobId}`;
    return this.httpClient.get<HttpResponse<any>>(url, { observe: 'response' });
  }

  /**
     * get inspection details from job
     * @returns
     */
  createInspectionItemJobDetail(body:any): Observable<any> {
    const url = `${environment.autoApi}/api/v1/Inspection/InspectionItem`;
    return this.httpClient.post(url, body);
  }

  /**
   * get damage report by job
   */
  getDamageReportByJob(inspectionid:number, jobId:number):Observable<any>{
    const url = `${environment.autoApi}/api/v1/Inspection/DamageReportByObjectId/${inspectionid}/${jobId}`;
    return this.httpClient.get(url);
  }

  /**
   * get inspection list
   */
  getInspectionTemplateList(domainId?:number):Observable<any>{
    const obj:any = {};
    if(domainId){
      const headers = new HttpHeaders().append('domainId', domainId.toString());
      obj.headers = headers;
    }
    const url = `${environment.autoApi}/api/v1/InspectionTemplate`;
    return this.httpClient.get(url, obj);
  }

  /**
     * update Inspection Image
     * @returns
     */
  uploadInspectionImage(url: string, file: File):Observable<any> {
    const formData = new FormData();
    formData.append('imageFile', file);
    const fullUrl = `${environment.autoApi}/api/v1/${url}`;
    return this.httpClient.post(fullUrl, formData);
  }

  /**
     * get Default location and contact
     * @returns
     */
  getDefaultLocationContact(): Observable<any>{
    const url = `${environment.autoApi}/api/v1/Inspection/default-location-contact`
    return this.httpClient.get(url);
  }

  /**
     * update inspection photo
     * @returns
     */
  uploadInspectionPhoto(endpoint: string, file: File, reportProgress?: boolean, observe?: 'events'):Observable<any> {
    const formData = new FormData();
    formData.append('imageFile', file);
    const url = `${environment.autoApi}/api/v1/${endpoint}`
    return this.fileService.uploadFile(url, formData, reportProgress, observe);
  }

  /**
   * sendToInsurer
   * @param {string} repairGuid
   * @param {string} docType
   * @returns {Observable<any>}
   */
  public sendToInsurer(repairGuid: string, docType: string): Observable<any>{
    const url = `${environment.caseMgmtUrl}/api/integrationrepair/send-estimate/${repairGuid}/${docType}`;
    return this.httpClient.get(url);
  }

  /**
   * getAllDocumentDownloadURL
   * @param {string} repairGuid
   * @param {string} docType
   * @returns {Observable<any>}
   */
  public getAllPhotosDownloadURL(inspectionId: number, objectId: string): Observable<any>{
    const url = `${environment.autoApi}/api/v1/Inspection/document-zip/${inspectionId}/${objectId}`;
    return this.httpClient.get(url);
  }

  /**
   * get AI Inference report
   * @param inspectionId 
   * @returns 
   */
  getAIInference(inspectionId:number, objectId:number, isVideo?:boolean, inspectionItemId?:number): Observable<any> {
    let headers = new HttpHeaders().append('inspectionId', inspectionId.toString())
      .append('objectId', objectId.toString()).append('inferenceType', isVideo ? '2':'1');
    if(inspectionItemId){
      headers = headers.append('inspectionItemId', inspectionItemId.toString());
    }
    const url = `${environment.autoApi}/api/v1/Inspection/AIInferenceReport`;
    return this.httpClient.get(url, { headers: headers });
  }
}
