import { Route } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { LogoutExtComponent } from './b2c-policy-core/logout-ext/logout-ext.component';
import { LogoutComponent } from './b2c-policy-core/logout/logout.component';
import { FnolGuard } from './guards/fnol-guard/fnol.guard';
import { HomeGuard } from './guards/home-guard/home.guard';
import { MainGuard } from './guards/main-guard/main.guard';
import { OtpGuard } from './guards/otp-guard/otp.guard';
import { RepairGuard } from './guards/repair-guard/repair.guard';
import { LayoutComponent } from './layout/layout.component';
import { RegisteredGarageAssignmentActionComponent } from './modules/claim/claim-management/third-party-registered-garages/registered-garage-assignment-action/registered-garage-assignment-action.component';
import { RegisteredGarageRequestEstimateActionComponent } from './modules/claim/claim-management/third-party-registered-garages/registered-garage-request-estimate-action/registered-garage-request-estimate-action.component';
import { RegisteredGarageRequestInvoiceActionComponent } from './modules/claim/claim-management/third-party-registered-garages/registered-garage-request-invoice-action/registered-garage-request-invoice-action.component';
import { VerifyOtpComponent } from './modules/claim/claim-management/third-party-registered-garages/verify-otp/verify-otp.component';
import { LandingPageComponent } from './modules/fnol/landing-page/landing-page.component';
import { SubmitMissingInfoComponent } from './modules/quote-assessment/submit-missing-info/submit-missing-info.component';
import { EstimateApprovalComponent } from './modules/repair/estimate-approval/estimate-approval.component';
import { CustomerFeedback } from './public/customer-feedback/customer-feedback.component';
import { NoAccessErrorComponent } from './shared/error-page/no-access-error/no-access-error.component';
import { NotFoundErrorComponent } from './shared/error-page/not-found-error/not-found-error.component';

export const routes: Route[] = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: 'repair/estimate/:id/approval', pathMatch: 'full', component: EstimateApprovalComponent },
  { path: 'case/estimate/:id/approval', pathMatch: 'full', component: EstimateApprovalComponent },
  { path: 'claim/assignment', canActivate: [ OtpGuard ], component: RegisteredGarageAssignmentActionComponent },
  { path: 'claim/estimate', canActivate: [ OtpGuard ], component: RegisteredGarageRequestEstimateActionComponent },
  { path: 'claim/invoice', canActivate: [ OtpGuard ], component: RegisteredGarageRequestInvoiceActionComponent },
  { path: 'claim/verify-otp', component: VerifyOtpComponent },
  { path: 'quote-assessment/verify-otp', component: VerifyOtpComponent, data: { module: 'quote-assessment' } },
  { path: 'logout', component: LogoutComponent },
  { path: 'logoutext', component: LogoutExtComponent },
  { path: 'landing/:name', component: LandingPageComponent },
  { path: 'landing', component: LandingPageComponent },
  { path: ':service/:objectId/feedback', component: CustomerFeedback },
  { path: 'quote-assessment/submit-missing-info', canActivate: [ OtpGuard ], component: SubmitMissingInfoComponent },
  {
    path: '',
    canActivate: [ MsalGuard ],
    component: LayoutComponent,
    children: [
      {
        path: 'home',
        canActivate: [ HomeGuard ],
        loadChildren: () =>
          import('./modules/home/home.module').then(
            m => m.HomeModule
          )

      },
      {
        path: 'quote',
        canActivate: [ MainGuard ],
        data: { automotiveService: 'Carheal Quote' },
        loadChildren: () =>
          import('./modules/quote/quote.module').then(
            m => m.QuoteModule
          )

      },
      {
        path: 'repair',
        canActivate: [ MainGuard ],
        data: { automotiveService: 'Addenda Repair' },
        loadChildren: () =>
          import('./modules/repair/repair.module').then(
            m => m.RepairModule
          )

      },
      {
        path: 'settings',
        canActivate: [ RepairGuard ],
        data: { automotiveService: 'Addenda Repair' },
        loadChildren: () =>
          import('./modules/settings/settings.module').then(
            m => m.SettingsModule
          )

      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./modules/user-profile/user-profile.module').then(
            m => m.UserProfileModule
          )

      },
      {
        path: 'claim',
        canActivate: [ MainGuard ],
        data: { automotiveService: 'Addenda Claims' },
        loadChildren: () =>
          import('./modules/claim/claim.module').then(
            m => m.ClaimsModule
          )
      },
      {
        path: 'claimSetting',
        canActivate: [ MainGuard ],
        data: { automotiveService: 'Addenda Claims' },
        loadChildren: () =>
          import('./modules/claim-settings/claim-settings.module').then(
            m => m.ClaimSettingsModule
          )
      },
      {
        path: 'repairer-view',
        canActivate: [ MainGuard ],
        data: { automotiveService: 'Addenda Claims' }, //TODO Change Automotive Service
        loadChildren: () =>
          import('./modules/repairer-view/repairer-view.module').then(
            m => m.RepairerViewModule
          )
      },
      {
        path: 'case',
        canActivate: [ MainGuard ],
        data: { automotiveService: 'Quote Assessment' },
        loadChildren: () =>
          import('./modules/quote-assessment/quote-assessment.module').then(
            m => m.QuoteAssessmentModule
          )
      },
      {
        path: 'accessDenied',
        component: NoAccessErrorComponent
      },
      {
        path: 'notFound',
        component: NotFoundErrorComponent
      },
      {
        path: 'fnol',
        canActivate: [ MainGuard, FnolGuard ],
        data: { automotiveService: 'Fnol' },
        loadChildren: () =>
          import('./modules/fnol/fnol.module').then(
            m => m.FnolModule
          )
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'home'
  },
];
