import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MonitorService } from 'src/app/services/monitor/monitor.service';

@Component({
  selector: 'ai-inference-dialog',
  templateUrl: './ai-inference-dialog.component.html',
  styleUrls: [ './ai-inference-dialog.component.scss' ]
})
export class AiInferenceDialogComponent {
  public imageLoading: any = {};
  public imageMapper = {
    'front': 'From Front',
    'leftfront': 'Front Left',
    'leftside': 'Left Side',
    'leftrear': 'Rear Left',
    'rightfront': 'Front Right',
    'rear': 'From Rear',
    'rightrear': 'Rear Right',
    'rightside': 'Right Side'
  }
  /**
   * constructor
   * @param dialogRef 
   * @param data 
   */
  constructor(public dialogRef: MatDialogRef<AiInferenceDialogComponent>,
    private monitorService: MonitorService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if(this.data?.isVideo && this.data?.vehicleImages?.length > 0){
      const imageVariables = this.data?.vehicleImages[0]?.vehicleInfo.filter(val => val?.value?.toLowerCase().includes('https://'));
      imageVariables?.forEach((val) => {
        this.imageLoading[val.index] = false;
      });
      this.data.vehicleImages[0].imagesInfo = imageVariables;
    }else{
      this.data?.vehicleImages?.forEach((val) => {
        this.imageLoading[val.index] = false;
      });
    }
   
  }

  /**
   * close dialog
   */
  close(): void {
    this.dialogRef.close(true);
    this.monitorService.logEvent('close', [ 'AiInferenceDialogComponent', 'addenda-quote' ]);
  }

  /**
   * set loading
   */
  setImageLoading(name: string): void {
    if (this.imageLoading[name]) {
      return;
    }
    this.imageLoading[name] = true;
  }

}
