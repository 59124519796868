<div class="section-container">
    <div class="header">
        <div class="left">
            <div class="action row align-center">
                <div>
                    <mat-icon class="arrow-icon"
                              svgIcon="arrow-back-left"
                              (click)="onBackClick()"></mat-icon>
                </div>
                <div class="header-title">
                    <h3 class="main-header">New repair order</h3>
                    <div class="sub-header"
                         *ngIf="isRepairOrderCustomer">Create new repair order with same customer</div>
                    <div class="sub-header"
                         *ngIf="isRepairOrderVehicle">Create new repair order with same customer and vehicle details</div>
                </div>
            </div>
        </div>
    </div>
    <div class="line"></div>
    <p class="content-title">Please select details from below for adding to the new repair order</p>
    <div class="main-contianer">
        <div class="checkbox-group ml-12">
            <div class="row col-12">
                <div class="col-6">
                    <div class="row">
                        <xa-checkbox [(ngModel)]="customerDetails"
                                     (ngModelChange)="onCustomerDetailsChange($event)"></xa-checkbox>
                        <span class="blend-name">Customer details</span>
                    </div>
                </div>
                <div class="col-6">
                    <div class="row"
                         *ngIf="!isRepairOrderCustomer">
                        <xa-checkbox [(ngModel)]="vehicleDetails"
                                     (ngModelChange)="onVehicleDetailsChange($event)"></xa-checkbox>
                        <span class="blend-name">Vehicle details</span>
                    </div>
                </div>
            </div>
            <div class="row col-12">
                <div class="col-6">
                    <div class="row"
                         *ngIf="selectedCustomerDetails">
                        <xa-checkbox [(ngModel)]="documents"
                                     (ngModelChange)="onDocumentsChange($event)"></xa-checkbox>
                        <span class="blend-name">Documents</span>
                    </div>
                    <div class="row"
                         *ngIf="selectedCustomerDetails && documents">
                        <ng-container *ngFor="let document of filteredMediaParts">
                            <ng-container *ngFor="let part of document.mediaParts">
                                <div class="images-container mt-5">
                                    <div class="image-group">
                                        <div class="row checkbox-group">
                                            <xa-checkbox class="checkbox ml-2"
                                                         (ngModelChange)="onDocumentSelect(part, $event)"
                                                         [(ngModel)]="part.selected">
                                            </xa-checkbox>
                                            <span class="document-name">{{ part.name }}</span>
                                        </div>
                                        <div *ngIf="part?.contentType ==='application/pdf'"
                                             class="document-preview"
                                             (click)="openPDFinNewTab(part?.getURL)"></div>
                                        <img [src]="part?.getURL"
                                             *ngIf="part?.contentType !=='application/pdf'"
                                             alt="Inspection Document"
                                             (click)="imageDialog('',part?.getURL, part.name? part.name : 'Bulk Upload Image');"
                                             class="img-preview">
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
                <div class="col-6">
                    <div class="row"
                         *ngIf="selectedVehicleDetails && !isRepairOrderCustomer">
                        <xa-checkbox [(ngModel)]="photos"
                                     (ngModelChange)="onPhotosChange($event)"></xa-checkbox>
                        <span class="blend-name">Photos</span>
                    </div>
                    <div class="row images-container mt-5"
                         *ngIf="selectedVehicleDetails && photos && !isRepairOrderCustomer">
                        <div class="image-group"
                             *ngFor="let item of filteredInspectionItems">
                            <div class="row checkbox-group">
                                <xa-checkbox class="checkbox ml-2"
                                             (ngModelChange)="onPhotoSelect(item, $event)"
                                             [(ngModel)]="item.selected"></xa-checkbox>
                                <span class="photos-name">{{ item.name }}</span>
                            </div>
                            <img [src]="item.rawAzureBlobUrl"
                                 alt="Inspection Photo"
                                 (click)="imageDialog('',item?.rawAzureBlobUrl, item.name ? item.name : 'Bulk Upload Image');"
                                 class="img-preview">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        <div class="button-container action d-flex">
            <chq-button [buttonModel]="cancelButtonModel"></chq-button>
            <chq-button [buttonModel]="createButtonModel"></chq-button>
        </div>
</div>