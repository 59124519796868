import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common/common.service';
import { FNOLService } from 'src/app/services/fnol/fnol.service';
@Injectable({
  providedIn: 'root'
})
export class FnolGuard implements CanActivate {
  /**
   * constructor
   * @param router
   */
  constructor(
    private commonService: CommonService,
    private msalGuard: MsalGuard,
    private router: Router,
    private fnolService: FNOLService
  ) { }

  /**
 * canActivate
 * @returns
 */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.msalGuard.canActivate(next, state)) {
      return this.checkAppFeaturePackage(next, state);
    } else {
      this.router.navigate([ 'home' ]);
      return false;
    }
  }

  /**
* checkUserPermission
* @returns
*/
  checkAppFeaturePackage(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.commonService.applicationFeatureData.pipe(
      switchMap((res: any) => {
        if (res) {
          if (res?.data && res?.data.length > 0) {
            const services = res.data[0].services;
            if (services && services.length > 0) {
              const fnolService = services.find(x => x.serviceName.toLowerCase() === 'fnol');
              if (fnolService) {
                return of(true);
              }
            }
            this.router.navigate([ 'accessDenied' ]);
            return of(false);
          }
          this.router.navigate([ 'accessDenied' ]);
          return of(false);
        } else {
          this.commonService.showLoading();
          return this.fnolService.applicationFeature().pipe(
            switchMap((res) => {
              if (res) {
                this.commonService.hideLoading();
                this.commonService.applicationFeatureData.next(res);
                if (res?.data && res?.data.length > 0) {
                  const services = res.data[0].services;
                  if (services && services.length > 0) {
                    const fnolService = services.find(x => x.serviceName.toLowerCase() === 'fnol');
                    if (fnolService) {
                      const url = state.url;
                      const splittedUrl = url.split('/');
                      const lastSplit = splittedUrl[splittedUrl.length - 1];
                      const fileClaimFeature = fnolService.features.find(x => x.featureName === 'file.claim');
                      const listClaimFeature = fnolService.features.find(x => x.featureName === 'claim.transactions');
                      if (!fileClaimFeature && (lastSplit === 'fnol' || lastSplit.includes('fnol?selectedIndex'))) {
                        this.router.navigate([ 'fnol/claim-list' ]);
                        return of(false);
                      }
                      if (!listClaimFeature && lastSplit === 'claim-list') {
                        this.router.navigate([ 'fnol' ]);
                        return of(false);
                      }
                      return of(true);
                    }
                  }
                  this.router.navigate([ 'accessDenied' ]);
                  return of(false);
                }
                this.router.navigate([ 'accessDenied' ]);
                return of(false);
              } else {
                this.commonService.hideLoading();
                this.router.navigate([ 'accessDenied' ]);
                return of(false);
              }
            })
          )
        }
      })
    );
  }

}
