import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-case-submit-success',
  templateUrl: './case-submit-success.component.html',
  styleUrls: [ './case-submit-success.component.scss' ]
})
export class CaseSubmitSuccessComponent {
  /**
      Constructor for the CaseSubmitWithQuoteIdComponent.
      @constructor
      @param {any} data - Data passed to the component.
      @param {MatDialogRef} dialogRef - Reference to the dialog box.
      @param {MonitorService} monitorService - Service for monitoring operations.
    */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CaseSubmitSuccessComponent>,
  ) { }

  /**
     Closes the dialog with the given data.
     @param {any} data - The data to be passed back to the dialog caller.
   */
  cancelDialog(data: any): void {
    this.dialogRef.close(false);
  }
}
