import { MsalService } from '@azure/msal-angular';
import { ConfigurationService } from './service/configuration.service';

/**
 * 
 * @param provider 
 * @param msalBroadcastService 
 * @param translate 
 * @returns 
 */
export function appConfigFactory(
  configurationService: ConfigurationService,
  msalService: MsalService
): () => Promise<any> {
  return () => {
    return new Promise((resolve) => {
      msalService.handleRedirectObservable().subscribe({
        next: () => {
          if (!window.location.pathname.includes('/landing') && !window.location.pathname.includes('/feedback')) {
            configurationService?.validateAndPullConfigFile();
          }
          resolve(true);
        },
        error: (err) => {
          console.log(err);
          resolve(true);
        }
      });
    });
  }
}